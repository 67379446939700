#offer-creatives {
  position: relative;
  margin-bottom: 1rem;

  .tabs-creatives {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .select-creative-type {
    text-align: center;
    padding: 3rem 0 3.5rem 0;

    .text {
      color: $cr-grey65;
      font-size: 1.5rem;
    }
  }

  .pop-under-code {
    padding: 2rem 2.5rem;
    background-color: $light-grey;
    margin: 1rem 0;
  }

  .overlay-warnings {
    @include flexbox(row, nowrap, flex-start);

    background-color: #505050;
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    padding: 0.75rem;

    svg {
      margin: 0 0.75rem;
      width: 1.5rem;
      min-width: 1.5rem;
      max-width: 1.5rem;
    }
    span {
      @include flexbox(row, wrap, flex-start);

      align-self: center;
      span {
        &:first-of-type {
          font-weight: 500;
        }
        align-self: center;
        margin: 0 0.25rem;
      }
    }
  }

  .creative-card {
    color: $cr-grey;

    .card-image {
      position: relative;
      background-color: $cr-grey;
      background: linear-gradient(135deg, $cr-grey 0%, black 100%);
      line-height: 0;
      cursor: pointer;

      &.file {
        i.code {
          display: none;
        }

        i.download {
          font-size: 40px;
          top: calc(50% + 24px);
          left: calc(50% + 30px);
        }
        .content-file {
          display: block;
          padding: 50px;
          position: relative;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .no-preview-image {
          width: 100px;
          height: 100px;
          background: #000;
          margin: 0 auto;
          border-radius: 10px;
          .text {
            line-height: 100px;
            color: #fff;
            -webkit-font-smoothing: antialiased;
          }
        }
      }

      .creative-card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        i {
          display: none;
        }

        &.selected {
          background-color: rgba(0, 0, 0, 0.5);

          i {
            display: block;
          }
        }
      }

      i {
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        font-size: 100px;
        color: #fff;
      }
    }

    .card-inner-content {
      width: 85%;
      float: left;

      .card-title-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .card-title {
          line-height: normal;
          font-size: 1rem;
          word-break: break-all;
          font-weight: normal;
        }
      }

      .card-infos-container {
        font-size: 0.9rem;

        .type {
          text-transform: uppercase;
        }

        span {
          &:after {
            content: ' - ';
          }

          &:last-child {
            &:after {
              content: none;
            }
          }
        }
      }
    }

    img {
      display: inline-block;
    }

    iframe {
      border: none;
    }

    .card-options {
      width: 15%;
      float: left;
    }
  }

  .creatives-preview-container {
    position: fixed;
    z-index: 121;
    padding: 0;
    margin: 12px;

    .creatives-preview {
      padding: 1rem;
      background-color: #fff;
      border-radius: 0.7rem;
      position: relative;

      .creatives-preview-close {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 24px;
        height: 24px;
        background-color: #000;
        cursor: pointer;
        border-radius: 50%;

        i {
          font-size: 18px;
          position: relative;
          top: 3px;
          left: 3px;
          color: #fff;
        }
      }
    }
  }

  .selected-creatives-title {
    h2 {
      color: #fff;
      font-weight: 300;
      background-color: $cr-grey65;
      padding: 1.02rem 1rem 0.786rem 1rem;
      margin: 0;

      div {
        float: left;
        margin: 3px 0;
      }

      .send-selected-creatives {
        color: #fff;
        font-size: 1rem;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        bottom: 6px;
        float: right;
        cursor: pointer;

        i {
          position: relative;
          top: 6px;
          margin: 0 10px;
          display: inline-block;
        }
      }
    }
  }

  .tabs {
    margin-bottom: 1rem;
  }
}

@include responsive('phone') {
  #offer-creatives {
    .selected-creatives-title {
      h2 {
        div {
          float: none;
        }

        .send-selected-creatives {
          float: left;
          margin-top: 1rem;

          i {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.addToolColor {
  color: #00bd00;
}
.bannerWaves {
  border-radius: 0.75rem;
  margin-bottom: 1em;
  display: grid;
  //max-height: 110px;
  overflow: hidden;
  max-width: 100%;
}

.bannerWave1 {
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
}
.bannerWave {
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
}
