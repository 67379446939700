.standard-button {
    @include flexbox(row, nowrap, flex-start);

    &.btn-styled {
        border-radius: 0.25rem;
        padding: 0.75rem;

        &.bg-primary-color {
            color: white;
        }
        &.highlight {
            background-color: $low-highlight-color;
            color: white;
        }
        &.grey {
            background-color: $grey;
            color: white;
        }
        &.warning {
            background-color: $warning-color;
            color: white;
        }
    }
    &.active:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    & > * {
        align-self: center;
    }

    .btn-icon {
        @include flexbox(row, nowrap, flex-start);

        margin-right: 0.5rem;
    }
}
