.slider-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-top: 60px;

  @media (max-width: 576px) {
    margin-top: 0;
  }
}

.smartlink-slider-wrapper {
  display: flex;
  transition: transform 500ms ease-out;
  width: 100%;
}

.smartlink-slider-slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-start;
  flex-flow: column;
  opacity: 1;
  padding: 0 3px;
  height: 100vh;
  @media (max-width: 1390px) {
    height: unset;
  }
  &.active {
    opacity: 1;

    &:last-child {
      height: 100%;
    }
  }

  .dimmer {
    position: absolute;
  }

  @media (max-width: 1390px) {
    margin: 25px 0;
    justify-content: flex-start;
  }
}
