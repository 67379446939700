.home-featured-offer {
    .image-container {
        float: left;
        margin-right: 15px;
        margin-top: 5px;
    }

    .offer-infos {
        width: calc(100% - 65px);
        float: left;

        .title-container {
            float: left;
            width: calc(66.6666% - 15px);
            padding-right: 15px;
            margin: 0.9rem 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            span {
                color: $cr-grey;
                font-size: 1.1rem;
            }
        }

        .payout-container {
            float: left;
            width: 33.3333%;
            color: $cr-grey65;
            font-size: 1.1rem;
            font-weight: bold;
            margin: 0.9rem 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            .payout {
                margin-right: 5px;
            }
        }
    }
}
