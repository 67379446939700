.payment-history-widget {
    color: $cr-grey65;
    margin-bottom: 1rem;

    &.light-color {
        color: #fff;

        .notice {
            color: #108210;
        }

        hr {
            background-color: #108210;
            opacity: 1;
        }
    }

    &:not(.light-color) {
        .next-payment {
            .amount {
                color: $cr-grey;
            }
        }
    }

    h3 {
        font-size: 1.1rem;
        margin: 1.2rem 0 0.1rem;
        line-height: 1.4;
    }
    .amount {
        font-size: 2rem;
        font-weight: bold;
    }
    .notice {
        font-size: 1rem;
        font-weight: 300;
    }

    strong {
        font-weight: 700;
    }

    hr {
        margin-top: 1rem;
        background-color: $cr-pale-grey;
        opacity: 0.5;
        height: 1px;
        border: none;
    }
}
