$borderRadius: 10px;
$offerTablePadding: 30px;
$offerTablePadding2: 25px;

$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

@mixin fontLarge {
  font-size: 15px;
}

@mixin fontSmall {
  font-size: 13px;
}

.offerListingTable {
  width: calc(100% + 30px);
  display: flex;
  flex-direction: column;
  margin: 0 -15px;

  @media (max-width: $brSwitchLayout) {
    padding: 0 10px;
  }

  .offerListingTitle {
    width: 100%;
    height: 52px;
    background: #333333;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 21px;
    padding: 0 0 0 15px;
  }

  .offerListingLoadingBar,
  .offerListingLoadedBar {
    position: relative;
    height: 5px;
  }

  .offerListingLoadedBar {
    background: #00bd00;
  }

  .offerListingInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 $borderRadius $borderRadius;
    background: #ffffff;

    @media (max-width: $brSwitchLayout) {
      background: none;
      box-shadow: none;
    }
  }

  .offerListingGrid {
    width: 100%;
    display: grid;
    font-size: 15px;
    margin-top: 0;

    @media (max-width: $br1) {
      font-size: 13px;
    }

    @media (max-width: $br3) {
      font-size: 16px;
      gap: 15px;
      padding: 0 15px;
    }

    @media (max-width: $brSwitchLayout) {
      margin-top: 0;
      padding: 0;
    }
  }

  .offersSearchFilterTags {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    padding-top: $offerTablePadding;

    @media (max-width: $br1) {
      padding-top: $offerTablePadding2;
    }

    @media (max-width: $brSwitchLayout) {
      padding: 15px 10px 0;
    }
  }

  .offerListingCountBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $offerTablePadding 0 10px;

    @media (max-width: 1850px) {
      margin: 25px 0 10px;
    }

    @media (max-width: $brSwitchLayout) {
      margin: 20px 0;
    }

    .offerListingCount {
      font-size: 18px;
      line-height: normal;
      transition: 0.3s;
      opacity: 0;

      &.initialOffersFetched {
        opacity: 1;
      }

      span {
        font-weight: 800;
      }
    }
  }
}

.offerListingPagination {
  width: 100%;
  background: #f8f8f8;
  border-radius: 0 0 $borderRadius $borderRadius;
  padding: 10px 25px;

  .dropdown {
    top: auto;
    bottom: 100%;
  }

  @media (max-width: $brSwitchLayout) {
    padding: 20px 0 0;
  }
}
