@use "sass:math";

a {
    text-decoration: none;
}

html {
    line-height: 1.5;

    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    line-height: 1.1;
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
}
h1 {
    font-size: $h1-fontsize;
    line-height: 110%;
    margin: math.div($h1-fontsize, 2) 0 math.div($h1-fontsize, 2.5) 0;
}
h2 {
    font-size: $h2-fontsize;
    line-height: 110%;
    margin: math.div($h2-fontsize, 2) 0 math.div($h2-fontsize, 2.5) 0;
}
h3 {
    font-size: $h3-fontsize;
    line-height: 110%;
    margin: math.div($h3-fontsize, 2) 0 math.div($h3-fontsize, 2.5) 0;
}
h4 {
    font-size: $h4-fontsize;
    line-height: 110%;
    margin: math.div($h4-fontsize, 2) 0 math.div($h4-fontsize, 2.5) 0;
}
h5 {
    font-size: $h5-fontsize;
    line-height: 110%;
    margin: math.div($h5-fontsize, 2) 0 math.div($h5-fontsize, 2.5) 0;
}
h6 {
    font-size: $h6-fontsize;
    line-height: 110%;
    margin: math.div($h6-fontsize, 2) 0 math.div($h6-fontsize, 2.5) 0;
}

// Text Styles
em {
    font-style: italic;
}
strong {
    font-weight: 500;
}
small {
    font-size: 75%;
}
.light {
    font-weight: 300;
}
.thin {
    font-weight: 200;
}

.flow-text {
    font-weight: 300;
    $i: 0;
    @while $i <= $intervals {
        @media only screen and (min-width: 360 + ($i * $interval-size)) {
            font-size: 1.2rem * (1 + (0.02 * $i));
        }
        $i: $i + 1;
    }

    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
        font-size: 1.2rem;
    }
}
