.checkbox-field [type='checkbox'] + label {
    height: 20px;
    line-height: 20px;
}

.checkbox-field.green-checkbox {
    [type='checkbox'] + label {
        color: $cr-green;

        &:after {
            border-color: $cr-green;
        }
    }
}

.checkbox-field {
    .filled-in[type='checkbox']:checked + label {
        &:after {
            background-color: $cr-green;
            border-color: $cr-green;
        }
    }
}

.checkbox-field {
    [type='checkbox'].filled-in:disabled:not(:checked) + label:before {
        background-color: transparent;
        border: 2px solid transparent;
    }

    [type='checkbox'].filled-in:disabled:not(:checked) + label:after {
        border-color: transparent;
        background-color: #bdbdbd;
    }

    [type='checkbox'].filled-in:disabled:checked + label:before {
        background-color: transparent;
    }

    [type='checkbox'].filled-in:disabled:checked + label:after {
        background-color: #bdbdbd;
        border-color: #bdbdbd;
    }
}

.canhide-checkbox {
    margin-left: 25px;
}
