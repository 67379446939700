#offer-email-instructions {
    color: $cr-grey49;

    .div-container-title {
        margin: -1px;
    }
    .email-instructions {
        @include flexbox(row, wrap, flex-start);

        & > * {
            @include flexbox(column, nowrap, flex-start);

            flex: 1;
            min-width: 260px;

            h4 {
                padding-bottom: 0.75rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                font-size: 1.76rem;
                font-weight: 500;
            }
        }
    }

    .suppression-list {
        @include flexbox(row, wrap, flex-start);

        font-weight: 500;

        * {
            align-self: center;
        }
        & > * {
            margin-bottom: 1rem;
        }
        .btn {
            @include flexbox(row, nowrap, space-between);

            min-width: 240px;
            line-height: 3rem;

            span {
                font-size: 1.1rem;
            }
            i {
                font-size: 2rem;
                margin-left: 0.5rem;
            }
        }
        .merge-list {
            flex: 1;
            margin-left: 1.5rem;
        }
    }
}
