#offer-countries-modal {
    color: $cr-grey65;

    ul.list-letter {
        margin-top: 2rem;
        width: 915px;

        li {
            float: left;
            cursor: pointer;
            text-transform: uppercase;
            width: 2.5rem;
            font-size: 1.5rem;
            cursor: pointer;

            &.unavailable {
                color: #ccc;
                cursor: default;
            }
        }
    }

    .list-countries-by-letter-container {
        overflow-x: scroll;

        ul.list-countries-by-letter {
            & > li {
                float: left;

                ul.list-countries {
                    float: left;

                    & > li {
                        text-transform: capitalize;

                        img {
                            position: relative;
                            top: 6px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .letter {
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-top: 0.7rem;
        margin-bottom: 0.1rem;
    }
}
