$grey: #919191;

$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

@mixin idBoxLarge {
  font-size: 16px;
}

@mixin idBoxMedium {
  font-size: 12px;
}

.offerListingBottomDetails {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  &.offerInfoTopDetails {
    .idBox {
      font-weight: 600;
      @media (max-width: $br1) {
        @include idBoxMedium;
      }
    }
  }

  .idBox {
    width: 100%;
    @include idBoxMedium;
    color: $grey;

    @media (max-width: $br3) {
      @include idBoxLarge;
    }
  }

  .listingVertical {
    padding: 2px 10px;
    color: $grey;
    border: 1px solid $grey;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    line-height: normal;
    background: #ffffff;
  }
}
