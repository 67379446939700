.intro-jerkmate-box {
    position: relative;
    display: flex;

    @media (max-width: 768px) {
        flex-flow: column;
    }

    h1 {
        position: relative;
        font-size: 42px;
        font-weight: 900;

        @media (max-width: 1255px) {
            font-size: 32px;
        }

        @media (max-width: 768px) {
            font-size: 26px;
        }
    }
    p {
        position: relative;
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    svg {
        height: 100%;
        width: 70%;
        @media (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
            margin-top: 30px;
        }
    }

    .intro-content {
        .affiliates-box {
            display: flex;
            .green-rectangle {
                position: relative;
                display: block;
                height: 105px;
                min-width: 55px;
                background-color: #03bc04;
                border-radius: 10px;
                margin: 20px 0 0 30px;

                @media (max-width: 540px) {
                    height: 70px;
                    min-width: 40px;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: -60%;
                    transform: translateY(-50%);
                    @media (max-width: 540px) {
                        width: 80px;
                    }
                }
            }

            .affiliates-content {
                margin-top: 20px;
                margin-left: 30px;
                @media (max-width: 540px) {
                    margin-left: 0;
                }
                .affiliates-number {
                    font-size: 34px;
                    font-weight: bold;
                    margin-left: 30px;
                }
                .affiliates-text {
                    font-size: 16px;
                    font-weight: normal;
                    margin: -10px 0 0px 30px;
                    @media (max-width: 540px) {
                        font-size: 12px;
                    }
                    .affiliates-green {
                        color: #03bc04;
                    }
                }
            }
        }
    }
}
