$transitionAmount: 1s;

$br0: 1500px;
$br1: 1279px;
$br2: 767px;

.jerkmate-card {
  width: calc(25% - 15px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &.active {
    .jerkmate-card-inner {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: $br0) {
    width: calc(33% - 15px);
  }

  @media (max-width: $br1) {
    width: calc(50% - 15px);
  }

  @media (max-width: $br2) {
    width: 100%;
  }

  .jerkmate-card-inner {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    background: #f8f8f8;
    padding: 20px 55px 20px 30px;
    cursor: pointer;
    box-shadow: 0px 4px 14px 0px rgba(217, 217, 217, 0.35);
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(100%);
    transition: $transitionAmount;
    border: 1px solid #f8f8f8;

    @media (max-width: $br2) {
      height: auto;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
    }

    &:hover {
      border: 1px solid #7b7b7b;
      filter: drop-shadow(4px 7px 14px rgba(123, 123, 123, 0.5));
      span {
        filter: drop-shadow(4px 7px 14px rgba(123, 123, 123, 0.35));

        svg {
          transition: 0.3s;
        }
      }

      h3 {
        color: #00bc00;
        font-weight: 800;
      }

      button {
        box-shadow: 4px 7px 14px 0px rgba(123, 123, 123, 0.35);
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 69px;
      height: 69px;
      flex-shrink: 0;
      border-radius: 100%;
      background-color: #fff;
      transition: 0.3s;
      @media (max-width: $br2) {
        text-align: center;
      }

      img {
        width: 40px;
      }
    }

    h3 {
      margin-top: 20px;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-transform: capitalize;
      transition: 0.3s;

      @media (max-width: $br2) {
        text-align: center;
      }
    }

    p {
      color: #000;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin: 20px 0;
      transition: 0.3s;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      opacity: 1;
      transform: translateY(0);
      @media (max-width: $br2) {
        -webkit-line-clamp: 2;
        text-align: center;
      }
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    button {
      display: inline-flex;
      padding: 2px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: #00bc00;
      color: #fff;
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
      transition: 0.3s;

      @media (max-width: $br2) {
        padding: 8px 50px;
      }
    }
  }
}
