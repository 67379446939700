.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.animationContainer {
    display: block;
    position: relative;
}

@-webkit-keyframes reg-appear {
    from {
        opacity: 0;
        -webkit-transform: translateY(10%) scale(0.8);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0) scale(1);
    }
}
@keyframes reg-appear {
    from {
        opacity: 0;
        -webkit-transform: translateY(10%) scale(0.8);
        transform: translateY(10%) scale(0.8);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
    }
}

@-webkit-keyframes reg-slideToLeft-enter {
    from {
        opacity: 0;
        -webkit-transform: translateX(20%) scale(0.8);
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0) scale(1);
    }
}
@keyframes reg-slideToLeft-enter {
    from {
        opacity: 0;
        -webkit-transform: translateX(20%) scale(0.8);
        transform: translateX(20%) scale(0.8);
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0) scale(1);
        transform: translateX(0) scale(1);
    }
}

@-webkit-keyframes reg-slideToLeft-leave {
    from {
        opacity: 1;
        -webkit-transform: translateX(0) scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-20%) scale(0.8);
    }
}
@keyframes reg-slideToLeft-leave {
    from {
        opacity: 1;
        -webkit-transform: translateX(0) scale(1);
        transform: translateX(0) scale(1);
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-20%) scale(0.8);
        transform: translateX(-20%) scale(0.8);
    }
}

@-webkit-keyframes scaleDownCenter {
    from {
    }
    to {
        opacity: 0;
    }
}
@keyframes scaleDownCenter {
    from {
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes scaleUpCenter {
    from {
        opacity: 0;
        -webkit-transform: scale(1.3);
    }
}
@keyframes scaleUpCenter {
    from {
        opacity: 0;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
}

.transition-enter {
    -webkit-animation: scaleUpCenter 0.4s $inEaseing both;
    animation: scaleUpCenter 0.4s $inEaseing both;
}

.transition-enter.transition-enter-active {
    -webkit-animation: scaleUpCenter 0.4s $inEaseing both;
    animation: scaleUpCenter 0.4s $inEaseing both;
}

.transition-appear {
    -webkit-animation: scaleUpCenter 0.4s $inEaseing both;
    animation: scaleUpCenter 0.4s $inEaseing both;
}

.transition-appear.transition-appear-active {
    -webkit-animation: scaleUpCenter 0.4s $inEaseing both;
    animation: scaleUpCenter 0.4s $inEaseing both;
}

.transition-leave {
    -webkit-animation: scaleDownCenter 0.4s $inEaseing both;
    animation: scaleDownCenter 0.4s $inEaseing both;
}

.transition-leave.transition-leave-active {
    -webkit-animation: scaleDownCenter 0.4s $inEaseing both;
    animation: scaleDownCenter 0.4s $inEaseing both;
}

/**
 * Animation du tableau des Brands
 */
.animationTable.animated,
.animationBrandsTableRows,
.animationPaymentHistoryTable {
    @for $i from 1 to 100 {
        tr.tablerow-enter.tablerow-enter-active:nth-child(#{$i}) {
            -webkit-transition-delay: $i * 50ms;
            transition-delay: $i * 50ms;

            td div {
                -webkit-transition-delay: $i * 50ms;
                transition-delay: $i * 50ms;
            }
        }
    }

    tr.tablerow-enter {
        border-bottom: 0 solid transparent;
        transition: all 300ms $inEaseing;
        td {
            div {
                opacity: 0.01;
                border-width: 0;
                padding: 0 5px;
                -webkit-transform: translateY(10%);
                transform: translateY(10%);
                height: 0;
                overflow: hidden;
                transition: all 300ms $inEaseing;
            }
        }
    }

    tr.tablerow-enter.tablerow-enter-active {
        border-bottom: 1px solid #d0d0d0;
        transition: all 300ms $inEaseing;
        td {
            div {
                opacity: 1;
                border-width: 1px;
                padding: 15px 5px;
                height: auto;
                margin-top: 0;
                -webkit-transform: translateY(0%);
                transform: translateY(0%);
                transition: all 300ms $inEaseing;
            }
        }
    }

    tr.tablerow-leave {
        border-bottom: 0 solid transparent;
        transition: all 0ms;
        td {
            div {
                opacity: 0;
                border-width: 0;
                padding: 0;
                transition: all 0ms;
            }
        }
    }

    tr.tablerow-leave.tablerow-leave-active {
        border-bottom: 0 solid transparent;
        transition: all 0ms;
        td {
            div {
                opacity: 0;
                border-width: 0;
                padding: 0;
                transition: all 0ms;
            }
        }
    }

    /* Transition animé des rows des tableau

  tr.tablerow-leave {
    border-bottom: 1px solid #d0d0d0;
    transition: all 0ms $inEaseing;
    td{
      div{
        opacity: 1;
        border-width: 1;
        padding: 15px 5px;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        transition: all 0ms $inEaseing;
      }
    }
  }

  tr.tablerow-leave.tablerow-leave-active {
    border-bottom: 0 solid transparent;
    transition: all 0ms $inEaseing;
    td{
      div{
        opacity: 0.01;
        border-width: 0;
        padding: 0 5px !important;
        height: 0 !important;
        -webkit-transform: translateY(-10%);
        transform: translateY(-10%);
        transition: all 0ms $inEaseing;
        overflow: hidden;
      }
    }
  }
  */
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.15, 1.15, 1.15);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    animation-name: pulse;
}

/**
      Animation: Fade In et Out
**/

@-webkit-keyframes fadeOut {
    from {
    }
    to {
        opacity: 0;
    }
}
@keyframes fadeOut {
    from {
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

.fade-enter,
.fade-enter.fade-enter-active,
.fade-appear,
.fade-appear.fade-appear-active {
    -webkit-animation: fadeIn 0.4s $inEaseing both;
    animation: fadeIn 0.4s $inEaseing both;
}

.fade-leave,
.fade-leave.fade-leave-active {
    -webkit-animation: fadeOut 0.4s $inEaseing both;
    animation: fadeOut 0.4s $inEaseing both;
}

/**
      Animation: Slide Down and Up
      Note: works only on elements from 950px to 0px
**/

@-webkit-keyframes slideUp {
    from {
        max-heignt: 950px;
        opacity: 1;
    }
    to {
        max-height: 0;
        opacity: 0;
    }
}
@keyframes slideUp {
    from {
        max-heignt: 950px;
        opacity: 1;
    }
    to {
        max-height: 0;
        opacity: 0;
    }
}

@-webkit-keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-heignt: 950px;
        opacity: 1;
    }
}
@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-heignt: 950px;
        opacity: 1;
    }
}
