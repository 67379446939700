.scroller {
  overflow: hidden;
  font-size: 14px;
  line-height: em(22);

  nav {
    display: block;
    position: relative;
    overflow-x: hidden;

    @media (max-width: 1345px) {
      overflow-x: scroll;
    }

    .scroller-container {
      display: inline-block;
    }
  }

  .tabs {
    height: 53px;
    margin: 0 0 10px;
    padding: 10px 0;

    @media (max-width: 1279px) {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }

    ul {
      display: inline-block;
      position: relative;
      margin: 0;
      padding: 0;
      clear: both;
      height: 33px;

      li {
        display: inline-block;
        position: relative;
        padding: 0 15px;
        margin-right: 20px;
        border-bottom: 5px solid #ececec;

        &:hover {
          border-bottom-color: $cr-pale-green;
        }

        &.active {
          border-bottom-color: $cr-green;
        }

        &:not(.active):not(.disabled) {
          cursor: pointer;
        }

        span {
          display: block;
          position: relative;
          padding: 5px 0;
          font-size: em(24);
          color: $cr-grey;
        }

        &[disabled],
        &.disabled {
          &:hover {
            border-bottom-color: #ececec;
          }
          span {
            opacity: 0.6;
          }
        }
      }
    }

    &.small {
      margin: 0 0 5px;
      padding: 5px 0;

      ul {
        li {
          padding: 0 5px;
          margin-right: 35px;
          font-size: em(12);
        }
      }
    }
  }
}
