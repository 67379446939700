.country-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    justify-content: center;

    li {
        width: 20px;
        height: 15px;
        background-size: 20px 20px;
        background-position: center center;
        margin: 2px;
    }
}
