.offerListingChip {
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  padding: 5px 8px;
  padding-right: 10px;
  line-height: normal;
  font-weight: 600;

  svg {
    width: 13px;
    min-width: 13px;
  }

  &.withHover {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
