#home-blog {
    img {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);
        border-radius: 2px;
    }

    h2 {
        font-size: 1.5rem;
        margin: 0;
        text-transform: uppercase;
        color: $cr-grey49;
    }

    span {
        color: $cr-greyText;
        font-size: 1.25rem;
    }

    p {
        color: $cr-grey49;
        font-size: 1.1rem;
    }

    .post {
        display: block;
        padding: 1.5rem 1rem 1rem 1rem;
        border-bottom: 1px solid $cr-pale-grey;

        &:last-child {
            border: none;
        }

        &:hover {
            background-color: #e4e4e4;
        }
    }
}
