.profile-info-section {
  position: relative;
  border-radius: 20px;
  border: 1px solid #dadada;
  background: #fff;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    padding: 15px;
  }

  &.locked {
    min-height: 150px;
  }

  &.multiple-subsections {
    padding: 0;
    border: none;
    background: transparent;

    @media (max-width: 768px) {
      &.locked {
        padding: 30px 0;
      }
    }
  }

  .locked-header {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-radius: 19px;
    background: rgba(231, 231, 231, 0.7);
    backdrop-filter: blur(5px);
    z-index: 5;
    padding: 20px;

    .header-wrapper {
      display: flex;
      gap: 30px;
      max-width: 70%;

      @media (max-width: 768px) {
        max-width: unset;
      }

      .locked-header-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }

      h2 {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
      }

      .subtitle {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      svg {
        margin-top: 20px;
        min-width: 50px;
        height: 50px;

        @media (max-width: 768px) {
          min-width: 40px;
          height: 40px;
        }
      }

      .btn {
        margin-top: 20px;
        display: flex;
        padding: 10px 60px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .info-header {
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    .header-wrapper {
      position: absolute;
      width: 100%;
      height: 110px;
      left: 0;

      @media (max-width: 768px) {
        height: 72px;
      }
    }
    h1 {
      color: black;
      margin-bottom: 0;
      margin-right: 8px;
      @media (max-width: 768px) {
        font-size: 18px !important;
      }
    }

    .profile-section-subtitle {
      margin-top: 8px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .info-btn {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .icon-info {
        font-size: 18px;
        color: white;
        border-radius: 50%;
        background-color: #00bd00;
        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    .icon {
      svg {
        min-width: 40px;
        height: 40px;
      }
    }

    .icons {
      margin-left: auto;
      display: flex;
      .warning-icon {
        display: none;
        margin-right: 4px;
        &.invalid {
          display: inline-block;
        }
      }

      .arrow-icon {
        &.open {
          rotate: 180deg;
        }
      }
    }
  }

  h2 {
    text-align: left;
  }

  .profile-section-subtitle-mobile {
    display: none;
    &.open {
      margin: 5px 0 15px 0;
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  form {
    width: 100%;
    display: none;
    flex-flow: column;

    &.open {
      display: flex;
    }
    .input-field {
      margin: 20px 0;

      input {
        margin: 0;
      }
    }

    .input-field:not(:has(.select-wrapper)) label {
      transform: translateY(-140%);
    }
  }

  button {
    width: 100%;
    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .children-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 20px;

    &.hidden {
      display: none;
    }
  }
}
