#big-icon {
    display: block;
    position: relative;
    width: 93px;
    height: 93px;
    color: #fff;
    border-radius: 50%;
    text-align: center;

    i {
        font-size: 50px;
        padding-top: 21px;
    }

    &.smartlink i {
        padding-top: 8px;
    }

    svg {
        overflow: visible;
        margin-top: 20px;
        width: 58.67px;
        height: auto;
    }

    &.small {
        width: 64px;
        height: 64px;

        i {
            font-size: 34px;
            padding-top: 16px;
        }
    }
    &.black-and-white {
        background-color: #fff;
        border: 3px solid #5d5d5d;
        width: 94px;
        height: 94px;
        svg {
            margin-top: 12px;
            width: 65px;
            path {
                fill: #333;
            }
        }
    }
}
