#offer-payout-tiers-modal {
    color: $cr-grey65;

    .list-countries-by-letter-container {
        overflow-x: scroll;
        ul.list-countries-by-letter {
            & > li {
                float: left;

                ul.list-countries {
                    float: left;

                    & > li {
                        text-transform: capitalize;
                        line-height: 1.3;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    .letter {
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-top: 0.7rem;
        margin-bottom: 0.1rem;
    }
}
