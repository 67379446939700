#offer-restrictions {
    @include flexbox(column, wrap, flex-start);

    h4 .title svg path {
        fill: red;
    }

    .restrictions {
        @include flexbox(row, wrap, flex-start);

        margin: 10px 5px 0;
        padding-top: 14px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);

        > :first-child {
            flex: 0 0 0;
        }
        > :not(:first-child) {
            flex: 0 0 100%;
        }
        ul {
            @include flexbox(row, wrap, flex-start);

            margin: 0 5px;

            li {
                @include flexbox(row, nowrap, flex-start);

                margin-bottom: 0.75rem;
                padding-right: 0.75rem;
                line-height: 1.15rem;
                flex: 1 0 50%;

                div.ico {
                    margin-right: 5px;

                    svg {
                        align-self: center;
                        height: 15px;
                        width: auto;

                        path {
                            fill: red;
                        }
                    }
                }
            }
        }
    }
    @include responsive('tablet at least') {
        .restrictions {
            > :first-child {
                flex: 0 0 20%;
            }
            > :not(:first-child) {
                flex: 0 0 80%;
            }
        }
    }
}
