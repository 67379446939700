$degAnimation: 20deg;
$scaleAnimation: 1.2;
@keyframes notificationAnimation {
  0% {
    transform: rotate(-$degAnimation) scale($scaleAnimation);
  }
  10% {
    transform: rotate($degAnimation) scale($scaleAnimation);
  }
  20% {
    transform: rotate(-$degAnimation) scale($scaleAnimation);
  }
  30% {
    transform: rotate($degAnimation) scale($scaleAnimation);
  }
  40% {
    transform: rotate(-$degAnimation) scale($scaleAnimation);
  }
  50% {
    transform: rotate(0deg) scale($scaleAnimation);
  }
  100% {
    transform: rotate(0deg) scale($scaleAnimation);
  }
}

.next-lvl-tooltip {
  width: fit-content !important;
}

#site-header {
  display: block;
  position: fixed;
  width: 100%;
  height: 80px;
  padding-top: 10px;
  z-index: 50002;

  .header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 992px) {
      justify-content: space-between;
      align-items: unset;
    }
  }

  .menu-burger {
    display: none;
    @media (max-width: 992px) {
      margin-left: 15px;
      display: flex;
      align-items: center;
      width: fit-content !important;
      flex: 0 0 auto;

      .icon {
        margin-top: 0 !important;
      }
    }
  }

  .header-logo {
    margin-right: auto;
    @media (max-width: 992px) {
      margin-right: 0;
      width: fit-content;
      flex: 1;
      text-align: center;
    }
  }

  .utils {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 992px) {
      flex: 0 0 auto;
    }

    .mobile-gamification-badge {
      overflow: initial !important;
      display: none;

      @media (max-width: 991px) {
        display: flex;
        width: 41px;
        margin-top: 5px;
      }

      .gamification-infos {
        display: flex;
        align-items: center;
        cursor: pointer;

        .badge-streak {
          display: flex;
          align-items: center;
          position: relative;
          width: 40px;
          height: 31px;

          .badge {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 31px;
          }
          .badge-level {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            color: #01262e;
            text-shadow: 0 1px 10px #fff;
            font-size: 13px;
            font-weight: 900;

            &.silver {
              top: 60%;
            }
          }
        }
      }
    }

    .rank-gamification-box {
      display: flex;
      gap: 22px;
      padding: 5px 30px;
      border-radius: 10px;
      border: 1px solid #00bd00;
      background: #252525;
      cursor: pointer;

      .icon-link {
        display: flex;
        align-items: center;
        padding: 0;
      }

      .gamification-infos {
        display: flex;
        align-items: center;

        .badge-streak {
          display: flex;
          align-items: center;
          position: relative;
          margin-right: 8px;
          width: 40px;
          height: 31px;
          cursor: pointer;

          .badge {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 31px;
          }
          .badge-level {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            color: #01262e;
            text-shadow: 0 1px 10px #fff;
            font-size: 13px;
            font-weight: 900;

            &.silver {
              top: 60%;
            }
          }
        }

        .week-streak {
          display: flex;
          flex-flow: column;

          .week-text {
            color: #e4e4e4;
            font-size: 16px;
            font-weight: 700;
          }

          .lvl-text {
            color: #fff;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
    }

    .user-img {
      display: flex;
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 3px;
      background: #606060;
      align-items: center;

      svg {
        width: 20px;
        height: auto;
        margin: auto;

        .fill {
          fill: #fff;
        }
      }

      &.gamification {
        display: flex;
        position: relative;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        margin-right: 10px;
        background: #fff !important;
        align-items: center;

        svg {
          width: 15px;
          height: auto;
          margin: auto;

          .fill {
            fill: #8a8a8a !important;
          }
        }
      }
    }

    .user-infos {
      display: flex;
      flex-flow: column;
      position: relative;
      margin-right: 20px;
    }

    .user-name {
      font-size: em(14);
      color: inherit;
      line-height: 20px;
    }

    .user-status {
      line-height: 20px;
      font-weight: 500;
    }

    .notifications {
      display: inline-block;
      position: relative;
      padding: 15px 10px 0;
      color: inherit;
      cursor: pointer;
      overflow: visible !important;

      @media (max-width: 992px) {
        display: flex;
        align-items: center;
        padding-top: 5px;
      }
      i {
        font-size: 26px;
        user-select: none;
        &.animated {
          transform: scale(1.1);
          animation: notificationAnimation 1s ease-in-out infinite alternate;
          -webkit-animation: notificationAnimation 1s ease-in-out infinite alternate;
          transform-origin: 50% 25%;
        }
      }

      .number {
        display: block;
        position: absolute;
        top: 14px;
        right: 3px;
        height: 19px;
        width: 19px;
        text-align: center;
        border-radius: 50%;
        color: inherit;
        user-select: none;
      }

      .notifications-list {
        cursor: auto;
        background-color: $cr-grey;
        position: absolute;
        top: 70px;
        right: 0;
        width: 270px;
        font-size: 1rem;
        line-height: 1.3;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.35);

        .z-depth-2,
        ul {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }

        .see-all-notifications {
          width: 100%;
          background-color: $cr-grey65;
          padding: 8px 0;
          text-align: center;
          text-transform: uppercase;
          color: #87e764;
          cursor: pointer;
        }

        .no-new-notifications {
          width: 100%;
          padding: 15px 0;
          text-align: center;
          cursor: pointer;

          span {
            color: #ffffff;
          }
        }

        ul {
          li {
            padding: 15px;
            margin: 0;
            border-bottom: 1px solid $cr-greyText;
            float: none;

            &:has(a.liWithLink) {
              padding: 0;
            }

            a.liWithLink {
              padding: 15px;
            }

            &:last-child {
              border: none;
            }
          }

          * {
            white-space: preserve !important;
          }
        }
      }
    }

    .logout {
      position: relative;
      padding: 13px 0 0;
      color: inherit;
      cursor: pointer;

      span {
        font-size: em(14);
        line-height: 30px;
        margin-right: 10px;
      }

      i {
        display: inline-block;
        position: relative;
        font-size: 26px;
        margin-top: 2px;
      }
    }
  }

  .icon-link {
    display: flex;
    position: relative;
    color: inherit;
    font-size: em(14, $browser-context);
    padding: 11px 0 6px;
    -webkit-transition: $inAnimationLinks;
    transition: $inAnimationLinks;

    span {
      display: inline-block;
      position: relative;
      padding: 2px 0 0;
    }

    i {
      font-size: em(24, $browser-context);
    }

    &:hover {
      -webkit-transition: $outAnimationLinks;
      transition: $outAnimationLinks;
    }
  }

  .menu-link {
    top: 6px;
    left: 15px;
    width: 80px;
    z-index: 20;

    i {
      float: left;
      margin-right: 10px;
    }
    .icon {
      display: inline-block;
      position: relative;
      width: 22px;
      height: 14px;
      margin-right: 10px;
      margin-top: 5px;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.3s $inEaseing;
        -moz-transition: 0.3s $inEaseing;
        -o-transition: 0.3s $inEaseing;
        transition: 0.3s $inEaseing;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
    }

    &.open {
      .icon {
        span:nth-child(1) {
          top: 6px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        span:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        span:nth-child(3) {
          top: 6px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }

  .logo {
    max-width: 260px;
    display: block;
    position: relative;
    padding: 4px 0 0;

    @media (max-width: 1091px) {
      padding: 4px 0 0;
    }

    @media (max-width: 992px) {
      margin: 0 auto;
    }

    @media (max-width: 480px) {
    }

    img,
    .next-lvl-logo {
      max-width: 100%;
      margin-top: 5px;
      width: 260px;
    }

    svg {
      width: 355px;

      @media (max-width: 1091px) {
        width: unset;
        max-width: 355px;
      }

      @media (max-width: 992px) {
        max-width: 330px;
      }

      @media (max-width: 480px) {
        max-width: 230px;
      }
    }
  }

  .utils {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li:not(:nth-child(3)) {
      min-width: fit-content;
    }

    li:nth-child(3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    li {
      display: flex;
      position: relative;
      margin-right: 30px;

      .logout {
        i {
          margin-left: 10px;
        }
      }
      .languages {
        margin-left: 20px;
        i {
          margin-left: 10px;
        }
      }
    }
  }
}

.isdisabled {
  #site-header {
    .utils > li > span {
      color: lighten($cr-darker-pale-grey, 10%);
    }
    .utils li .logout {
      margin-left: 5px;
    }
    .icon-link {
      color: lighten($cr-darker-pale-grey, 10%);

      &:hover,
      &.active {
        color: inherit;
      }
    }
  }
}

#headerDropDown {
  left: auto !important;
  right: 10px;
  max-width: calc(100vw - 40px);

  .list-icon {
    font-size: em(14, $browser-context);

    i {
      position: absolute;
      right: 16px;
    }

    & > a,
    & > span {
      padding: 7px 50px 7px 16px;
    }
  }
}

@include responsive('tablet') {
  #site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .logo {
      text-align: center;
    }

    .utils {
      li {
        margin-right: 0;
      }
    }
  }
}

@include responsive('phone to tablet') {
  #site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .logo {
      text-align: center;
    }

    .utils > li > span {
      display: none;
    }

    .menu-link {
      & > span {
        display: none;
      }
    }

    .utils {
      li {
        margin-right: 0;
      }
    }
  }
}
