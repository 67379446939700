.simplified-textarea {
    padding-right: 0;
    padding-left: 0;

    textarea {
        height: 120px;
        padding: 10px 70px 10px 10px;
        border-bottom: 1px solid #333;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .icon-validation {
        position: absolute;
        margin-left: -32px;
        margin-top: 9px;
    }
    .followCharNumber {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    &.error {
        @media (max-width: 550px) {
            .followCharNumber {
                bottom: 28px;
            }
        }
    }
}
