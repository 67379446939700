#offer-tracking-link-customization-container {
    @include flexbox(column, nowrap, flex-start);

    .div-container {
        @include flexbox(column, nowrap, flex-start);

        &.isOpen {
            height: 100%;
        }

        .div-container-title {
            margin: 0;
        }
        .div-container-content {
            padding: 1rem;
        }
        .sub-ids {
            @include flexbox(row, wrap, space-between);

            .sub-id {
                @include flexbox(row, nowrap, flex-start);

                flex: 0 0 50%;
                min-width: 180px;
                padding: 0 0.5rem;

                .input-field {
                    flex: 1;
                }

                .sub-ids-extender {
                    @include flexbox(row, nowrap, flex-start);

                    margin-left: 0.5rem;
                    align-self: center;
                    width: 2rem;

                    svg {
                        width: 100%;
                        align-self: center;
                    }
                }
            }

            button.btn {
                align-self: center;
                height: 3.25rem;
                padding: 0.7rem 2.5rem;
                line-height: 1.3rem;
            }
        }
        .tracking-link {
            padding: 0 0.5rem;
        }
    }
}
