/* Remove Focus Boxes */
select:focus {
  outline: 1px solid lighten($cr-green, 47%);
}
button:focus {
  outline: none;
  background-color: lighten($button-color, 4%);
}

label {
  font-size: $label-font-size;
  color: $input-border-color;
}

/***************************
   Text Inputs + Textarea
****************************/

// Style Placeholders
::-webkit-input-placeholder {
  color: lighten($input-border-color, 20%);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: lighten($input-border-color, 20%);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: lighten($input-border-color, 20%);
}

:-ms-input-placeholder {
  color: lighten($input-border-color, 20%);
}

// Text inputs
input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='time'],
input[type='date'],
input[type='datetime-local'],
input[type='tel'],
input[type='number'],
input[type='search'],
textarea.materialize-textarea {
  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $input-border-color;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;

  font-size: 1rem;
  margin: 0 0 15px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all 0.3s;

  // Disabled input style
  &:disabled,
  &[readonly='readonly'] {
    color: $input-disabled-color;
    border-bottom: 1px dotted $input-disabled-color;
  }
  // Disabled label style
  &:disabled + label,
  &[readonly='readonly'] + label {
    color: $input-disabled-color;
  }
  // Focused input style
  &:focus:not([readonly]):not([id^='tellUsMore']) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }
  // Focused label style
  &:focus:not([readonly]) + label {
    color: $input-focus-color;
  }
  // Valid Input Style
  &.valid,
  &:focus.valid {
    border-bottom: 1px solid $input-success-color;
    box-shadow: 0 1px 0 0 $input-success-color;
  }
  // Custom Success Message
  &.valid + label:after,
  &:focus.valid + label:after {
    content: attr(data-success);
    color: $input-success-color;
    opacity: 1;
  }
  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    border-bottom: 1px solid $input-error-color;
    box-shadow: 0 1px 0 0 $input-error-color;
  }
  // Custom Error message
  &.invalid + label:after,
  &:focus.invalid + label:after {
    content: attr(data-error);
    color: $input-error-color;
    opacity: 1;
  }

  // Form Message Shared Styles
  & + label:after {
    display: block;
    content: '';
    position: absolute;
    top: 65px;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
  }
}

// Styling for input field wrapper
.input-field {
  position: relative;
  margin-top: 1rem;

  label {
    color: $input-border-color;
    position: absolute;
    top: 0.8rem;
    left: $gutter-width;
    font-size: 1rem;
    cursor: text;
    transition: 0.2s ease-out;
  }
  label.active {
    font-size: $label-font-size;
    transform: translateY(-140%);
  }

  // Prefix Icons
  .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    transition: color 0.2s;

    &.active {
      color: $input-focus-color;
    }
  }
  .prefix ~ input,
  .prefix ~ textarea {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
  }
  .prefix ~ textarea {
    padding-top: 0.8rem;
  }
  .prefix ~ label {
    margin-left: 3rem;
  }

  .input-loader-container {
    display: flex;
    align-items: center;
    position: relative;

    input {
      flex-grow: 1;
    }

    .loading-ring-container {
      position: absolute;
      right: 0;
    }
  }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem);
    }
  }
  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem);
    }
  }
}

// Search Field
.input-field input[type='search'] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);

  &:focus {
    background-color: $input-bg-color;
    border: 0;
    box-shadow: none;
    color: #444;

    & + label i,
    & ~ .mdi-navigation-close,
    & ~ .material-icons {
      color: #444;
    }
  }

  & + label {
    left: 1rem;
  }

  & ~ .mdi-navigation-close,
  & ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: 0.3s color;
  }
}

// Default textarea
textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;

  &.materialize-textarea {
    overflow-y: hidden; /* prevents scroll bar flash */
    padding: 1.6rem 0; /* prevents text jump on Enter keypress */
    resize: none;
    min-height: 3rem;
  }
}

// For textarea autoresize
.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem; /* prevents text jump on Enter keypress */
}

/***************
  Radio Buttons
***************/

/* Remove default Radio Buttons */
[type='radio'] {
  position: absolute;
  left: -9999px;
  visibility: hidden;
  &:not(:disabled) + label {
    cursor: pointer;
  }
  + label {
    position: relative;
    padding-left: 35px;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: 0.28s ease;

    -khtml-user-select: none; /* webkit (konqueror) browsers */
    user-select: none;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 4px;
      width: 16px;
      height: 16px;
      z-index: 0;
      transition: 0.28s ease;
    }
  }
}

/* Unchecked styles */
[type='radio']:not(:checked) + label:before {
  border-radius: 50%;
  border: 2px solid $radio-empty-color;
}
[type='radio']:not(:checked) + label:after {
  border-radius: 50%;
  border: 2px solid $radio-empty-color;
  z-index: -1;

  transform: scale(0);
}

/* Checked styles */
[type='radio']:checked + label:before {
  border-radius: 50%;
  border: 2px solid transparent;
}
[type='radio']:checked + label:after {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
  background-color: $radio-fill-color;
  z-index: 0;
  transform: scale(1.02);
}

/* Radio With gap */
[type='radio'].with-gap:checked + label:before {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
}
[type='radio'].with-gap:checked + label:after {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
  background-color: $radio-fill-color;
  z-index: 0;
  transform: scale(0.5);
}

/* Disabled Radio With gap */
[type='radio'].with-gap:disabled:checked + label:before {
  border: 2px solid $input-disabled-color;
}
[type='radio'].with-gap:disabled:checked + label:after {
  border: none;
  background-color: $input-disabled-color;
}

/* Disabled style */
[type='radio']:disabled:not(:checked) + label:before,
[type='radio']:disabled:checked + label:before {
  background-color: transparent;
}
[type='radio']:disabled + label {
  color: $input-disabled-color;
}
[type='radio']:disabled:not(:checked) + label:before {
  border-color: $input-disabled-color;
}
[type='radio']:disabled:checked + label:after {
  background-color: $input-disabled-color;
  border-color: $input-disabled-solid-color;
}

/***************
   Checkboxes
***************/

/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left;
}
form p:last-child {
  margin-bottom: 0;
}

/* Remove default checkbox */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

// Checkbox Styles
[type='checkbox'] {
  &:not(:disabled) + label {
    cursor: pointer;
  }
  // Text Label Style
  + label {
    position: relative;
    padding-left: 35px;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;

    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  /* checkbox aspect */
  + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid $radio-empty-color;
    border-radius: 1px;
    margin-top: 2px;
  }

  &:not(:checked):disabled + label:before {
    border: none;
    background-color: $input-disabled-color;
  }
}

[type='checkbox']:checked {
  + label:before {
    top: -4px;
    left: -3px;
    width: 12px;
    height: 22px;
    border-color: $radio-fill-color;
    border-top: none;
    border-left: 3px;
    transform: rotate(40deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }

  &:disabled + label:before {
    border-color: $input-disabled-color;
  }
}

/* Indeterminate checkbox */
[type='checkbox']:indeterminate {
  + label:before {
    left: -10px;
    top: -11px;
    width: 10px;
    height: 22px;
    border-color: $radio-fill-color;
    border-top: none;
    border-left: none;
    border-bottom: none;
    transform: rotate(90deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }

  // Disabled indeterminate
  &:disabled + label:before {
    border-color: $input-disabled-color;
    background-color: transparent;
  }
}

// Filled in Style
[type='checkbox'].filled-in {
  // General
  + label:after {
    border-radius: 2px;
  }
  + label:before,
  + label:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
  }

  // Unchecked style
  &:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;

    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
  }

  &:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid $radio-empty-color;
    top: 0px;
    z-index: 0;
  }

  // Checked style
  &:checked {
    + label:before {
      top: 0;
      left: 1px;
      width: 8px;
      height: 13px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid $input-bg-color;
      border-bottom: 2px solid $input-bg-color;
      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);

      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

    + label:after {
      top: 0px;
      width: 20px;
      height: 20px;
      border: 2px solid $secondary-color;
      background-color: $secondary-color;
      z-index: 0;
    }
  }
  // Disabled style
  &:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent;
  }

  &:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: $input-disabled-solid-color;
  }

  &:disabled:checked + label:before {
    background-color: transparent;
  }

  &:disabled:checked + label:after {
    background-color: $input-disabled-solid-color;
    border-color: $input-disabled-solid-color;
  }
}

/***************
     Switch
***************/
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: $switch-checked-lever-bg;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: $switch-bg-color;
}

.switch label .lever {
  content: '';
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: $switch-unchecked-lever-bg;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: $switch-unchecked-bg;
  border-radius: 21px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}

// Switch active style
input[type='checkbox']:checked:not(:disabled) ~ .lever:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px transparentize($switch-bg-color, 0.9);
}

input[type='checkbox']:not(:disabled) ~ .lever:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
}

.switch label input[type='checkbox']:checked + .lever:after {
  left: 24px;
}

// Disabled Styles
.switch input[type='checkbox'][disabled] + .lever {
  cursor: default;
}
.switch label input[type='checkbox'][disabled] + .lever:after,
.switch label input[type='checkbox'][disabled]:checked + .lever:after {
  background-color: $input-disabled-solid-color;
}

/***************
  Select Field
***************/
.select-label {
  position: absolute;
}

.select_multichoice {
  .input-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .labelText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 10px;
  }
  .maxCount {
    font-weight: normal;
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    margin: 0 0 0 15px;
    &.max {
      color: #00bd00;
      animation: scaleIt 0.4s;
      font-weight: 700;
    }
    @keyframes scaleIt {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .select-wrapper {
    order: 2;
    width: 100%;
  }
  label:not(.select-dropdown label) {
    width: 100%;
    position: relative !important;
    top: 0 !important;
    order: 1 !important;
    display: flex !important;
    line-height: 110%;
    justify-content: space-between;
  }
}

.select_custom {
  select {
    display: block !important;
    appearance: none;
  }
  .input-field {
    label {
      position: relative;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.select-wrapper {
  position: relative;

  input.select-dropdown {
    position: relative;
    cursor: pointer;
    // color: #444;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $input-border-color;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px 0;
    padding: 0;
    display: block;
    z-index: 9;
  }

  span.caret {
    color: inherit;
    position: absolute;
    right: 0;
    top: 16px;
    font-size: 10px;
    &.disabled {
      color: $input-disabled-color;
    }
  }

  & + label {
    position: absolute;
    top: -14px;
    font-size: $label-font-size;
  }
}

select:not(.native) {
  display: none;
}
select.browser-default {
  display: block;
}

// Disabled styles
select:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none; /* IE10+ */
  border-bottom: 1px dotted rgba(0, 0, 0, 0.26);
}

.select-wrapper i {
  color: rgba(0, 0, 0, 0.26);

  &.prefix {
    top: 9px;
    left: 7px;
  }
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
  &.inactive {
    background-color: #ffffff !important;
    cursor: default !important;
    span,
    label {
      color: #989898 !important;
      cursor: default !important;
      &::before {
        border-color: #989898 !important;
      }
    }
    &:hover {
      background-color: #ffffff !important;
      span,
      label {
        color: #989898 !important;
        &::before {
          border-color: #989898 !important;
          cursor: default;
        }
      }
    }
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: rgba(0, 0, 0, 0.7);
  }

  & > span {
    color: rgba(0, 0, 0, 0.4);
  }

  & ~ li:not(.optgroup) {
    padding-left: 1rem;
  }
}

/*********************
      File Input
**********************/
.file-field {
  position: relative;

  .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px;
  }

  input.file-path {
    width: 100%;
  }

  .btn {
    float: left;
    height: 3rem;
    line-height: 3rem;
  }

  span {
    cursor: pointer;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

/***************
      Range
***************/

.range-field {
  position: relative;
}

input[type='range'],
input[type='range'] + .thumb {
  @extend .no-select !optional;
  cursor: pointer;
}

input[type='range'] {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  margin: 15px 0px;
  padding: 0;
}
input[type='range'] + .thumb {
  position: absolute;
  border: none;
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: $radio-fill-color;
  top: 10px;
  margin-left: -6px;

  transform-origin: 50% 50%;
  transform: rotate(-45deg);

  .value {
    display: block;
    width: 30px;
    text-align: center;
    color: $radio-fill-color;
    font-size: 0;
    transform: rotate(45deg);
  }

  &.active {
    border-radius: 50% 50% 50% 0;

    .value {
      color: $input-bg-color;
      margin-left: -1px;
      margin-top: 8px;
      font-size: 10px;
    }
  }
}

input[type='range']:focus {
  outline: none;
}

// WebKit
input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 3px;
  background: #c2c0c2;
  border: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: $radio-fill-color;
  transform-origin: 50% 50%;
  margin: -5px 0 0 0;
  transition: 0.3s;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

// FireFox
input[type='range'] {
  /* fix for FF unable to apply focus style bug  */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
}

input[type='range']::-moz-range-track {
  height: 3px;
  background: #ddd;
  border: none;
}

input[type='range']::-moz-range-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: $radio-fill-color;
  margin-top: -5px;
}

/*hide the outline behind the border*/
input[type='range']:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type='range']:focus::-moz-range-track {
  background: #ccc;
}

// IE 10+
input[type='range']::-ms-track {
  height: 3px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #777;
}

input[type='range']::-ms-fill-upper {
  background: #ddd;
}

input[type='range']::-ms-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: $radio-fill-color;
}

input[type='range']:focus::-ms-fill-lower {
  background: #888;
}

input[type='range']:focus::-ms-fill-upper {
  background: #ccc;
}

/***************************
   Text Inputs + Textarea
****************************/
select {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 3rem;
}
