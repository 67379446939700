#offer-tracking-link-lp {
  .div-container-content {
    padding: 0;
  }
  h4 {
    @include flexbox(row, nowrap, flex-start);

    margin: 15px 5px 0;
    padding-top: 10px;

    @media (min-width: 1500px) {
      margin: 0 5px 0;
      padding-top: 0;
    }

    & > :first-child {
      flex: 5;
    }
    & > :not(:first-child) {
      flex: 1;
    }
    * {
      align-self: center;
    }

    .title {
      @include flexbox(row, nowrap, flex-start);

      svg {
        height: 25px;
        min-height: 25px;
        max-height: 25px;
        width: auto;
        margin-right: 5px;
      }
    }

    .devices-icons {
      @include flexbox(row, nowrap, flex-end);

      align-self: center;
      font-size: 1.25rem;
      font-weight: bold;

      & > * {
        align-self: center;
        margin-left: 10px;

        i {
          vertical-align: middle;
        }

        &:not(.device-icon):after {
          content: ' :';
        }
      }
    }
  }
}
