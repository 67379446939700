#ranking {
  @media (max-width: 1500px) and (min-width: 1224px) {
    width: 40%;
    margin-right: 0 !important;
    height: 335px !important;
  }
}

#home-ranking {
  .payout-title {
    margin-top: 1rem;
    color: $cr-green;
    text-align: center;
  }

  .payout {
    color: $cr-grey;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }

  .rank-number {
    font-size: 4.5rem;
    line-height: 1.1;
    color: $cr-grey;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .rank-indicator {
      display: flex;
      span {
        display: flex;
        flex-flow: row;
        width: fit-content;

        &.inverse-arrow {
          transform: rotate(180deg);
          svg {
            path {
              stroke: #ff0d0d;
            }
          }
        }
      }
    }
  }

  .ranks {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 0 10%;
    overflow: hidden;
    margin-bottom: 1rem;

    .rank {
      float: left;
      width: 25%;
      text-align: center;
      border-right: 0px solid #b0b0b0;
      border-left: 0px solid #b0b0b0;
      padding: 6px 0;
      height: 34px;
      border-top: none;
      border-bottom: none;
      cursor: default;
      background-color: $light-grey;
      max-width: 43px;

      &.affiliate {
        svg {
          margin-top: 2px;
        }
      }

      &.vip {
        svg {
          margin-top: 1px;
        }
      }

      &.elite {
        svg {
          margin-top: 1px;
        }
      }

      svg {
        path,
        polygon {
          fill: $cr-grey65;
        }
      }

      &.active {
        background: $cr-green;
        border: none;

        svg {
          path,
          polygon {
            fill: #fff;
          }
        }
        &:focus {
          background-color: $cr-green !important;
        }
      }

      &:first-child {
        border-left: none;
        border-radius: 7px 0 0 7px;
      }

      &:nth-child(7) {
        border-radius: 0;
        border-radius: 0 7px 7px 0;
      }

      &:focus {
        background-color: #e5e5e5;
      }
    }
    .rank {
      &:last-child {
        border-right: none;
        border-radius: 0 7px 7px 0;
      }
    }
  }

  .until {
    float: left;
    width: 50%;
    text-align: center;

    .rising-star {
      @media only screen and (min-width: 1500px) and (max-width: 1600px) {
        font-size: 12px;
      }
    }

    .position {
      color: $cr-green;

      .sub-position {
        color: #8a8a8a;
      }
    }

    .amount {
      color: $cr-grey;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 1rem;
    }
  }

  .not-ranked {
    margin: 10px 0;

    .not-ranked-title {
      margin-top: 5px;
      margin-bottom: 15px;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 900;

      small {
        display: block;
        margin-bottom: 5px;
        text-transform: none;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
      }
    }

    .actions {
      li:not(:last-child) {
        border-bottom: 1px solid #d9d9d9;
      }

      li:not(:first-child) {
        margin-top: 15px;
        @media (max-width: 1500px) and (min-width: 1224px) {
          margin-top: 5px;
        }
      }

      .actions-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        color: #494949;
        @media (max-width: 1500px) and (min-width: 1224px) {
          margin-bottom: 5px;
        }
      }

      .action-icon {
        display: flex;
        flex: 0 0 55px;
        height: 55px;

        svg {
          margin: auto;
          width: 53px;
          height: auto;

          .fill {
            fill: #000 !important; // !important to fight the inlined stuff in svgs and allow reuse
          }
        }
      }

      .action-caption {
        margin: 0;
        padding: 0 0 0 20px;
        color: #3d3d3d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .more {
          &:hover {
            text-decoration: underline;
            color: #00bd00;
          }
          span {
            white-space: nowrap;
            color: #00bd00;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
