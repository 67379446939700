#offer-conversion-flow {
    @include flexbox(column, nowrap, flex-start);

    span.title {
        @include flexbox(row, nowrap, flex-start);

        margin: 0 0 1rem;
        font-size: 1.76rem;
        font-weight: 500;
        line-height: 2rem;

        i.material-icons {
            font-size: 2rem;
            margin-right: 0.75rem;
        }
    }

    ol {
        margin: 0;
        padding: 1rem;
        list-style: none;

        & > :not(:first-child) {
            border-top: 2px solid #dcdcdc;
        }

        li {
            @include flexbox(row, nowrap, flex-start);

            padding: 0.5rem 0;

            span.step-number:after {
                content: ':';
                margin: 0 1.25rem 0 0.25rem;
            }
        }
    }
}
