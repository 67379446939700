#offer-header {
    padding: 0.75rem 0;
    background-color: #666;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;

    .container-fluid.app-container {
        width: 100%;
        max-width: 1920px;

        .navbar {
            @include flexbox(row, wrap, space-between);

            width: 100%;

            .left-section,
            .right-section {
                @include flexbox(row, nowrap, flex-start);

                align-self: center;
            }
            .left-section > * {
                margin-right: 1rem;
            }
            .right-section > * {
                margin-left: 1rem;
            }
        }
    }
}
