#main-loading-bar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 50004;
    transition: $inAnimationLinks;

    &.hide {
        opacity: 0;
    }

    & > .bar {
        display: block;
        position: relative;
        height: 100%;
        transition: $inAnimationLinks;
    }
}
