$br0: 1335px;
$br1: 550px;
$br1Min: 551px;

.jerky-pink-box {
  width: 100%;
  position: relative;
  margin: 75px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 980px;
  font-family: 'Montserrat';

  @media (max-width: $br0) {
    margin: 65px 0 0;
  }

  @media (max-width: $br1) {
    width: 100%;
    margin: 45px 0 0;
  }

  .jerky-pink-scaler {
    width: 100%;
    aspect-ratio: 980 / 215;

    @media (max-width: $br1) {
      aspect-ratio: 409 / 150;
    }
  }

  .jerky-pink-section {
    width: 980px;
    height: 215px;
    border-radius: 19px;
    background: #fa4d92;
    color: #ffffff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left top;

    @media (max-width: $br1) {
      width: 409px;
      height: 150px;
    }

    &.active {
      .jerky-anim {
        img {
          @media (min-width: $br1Min) {
            &.jerky-head {
              margin: -8px 0 0;
            }
            &.jerky-body {
              bottom: 3px;
            }
            &.jerky-arm-left {
              transform: rotate(-16deg);
            }
            &.jerky-arm-right {
              bottom: 86px;
              transform: rotate(20deg);
            }
          }
          @media (max-width: $br1) {
            &.jerky-head {
              margin: 2px;
            }
            &.jerky-body {
              bottom: -2px;
            }
            &.jerky-arm-left {
              top: 12px;
              transform: rotate(-16deg);
            }
            &.jerky-arm-right {
              bottom: 45px;
              transform: rotate(20deg);
            }
          }
        }
      }

      .jerky-coins-left {
        img {
          @media (min-width: $br1Min) {
            &:first-child {
              left: 80px;
              top: -25px;
              transform: rotate(-23deg);
            }

            &:nth-child(2) {
              left: -20px;
              top: 75px;
              transform: rotate(-20deg);
            }

            &:last-child {
              left: 105px;
              bottom: -75px;
            }
          }
          @media (max-width: $br1) {
            &:first-child {
              left: 53px;
              top: -5px;
              transform: rotate(-23deg);
            }

            &:nth-child(2) {
              left: -13px;
              top: 66px;
              transform: rotate(-12deg);
            }

            &:last-child {
              left: 45px;
              bottom: -39px;
            }
          }
        }
      }
      .jerky-coins-right {
        img {
          @media (min-width: $br1Min) {
            &:first-child {
              right: 110px;
              transform: rotate(-9deg);
            }

            &:nth-child(2) {
              top: 50px;
              right: -30px;
            }

            &:last-child {
              right: 50px;
              transform: rotate(12deg);
            }
          }
          @media (max-width: $br1) {
            &:first-child {
              right: 68px;
            }

            &:nth-child(2) {
              right: -1px;
              top: 55px;
            }

            &:last-child {
              right: 30px;
              transform: rotate(19deg);
            }
          }
        }
      }
    }

    .title {
      width: 90%;
      max-width: 859px;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      text-transform: uppercase;
      font-size: 128px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 900;
      text-align: center;
      z-index: 100;
      cursor: default;

      @media (max-width: $br1) {
        font-size: 57px;
        z-index: 500;
      }
    }

    .jerky-anim img,
    .jerky-coins-left img,
    .jerky-coins-right img {
      transition: 0.3s;
    }

    .jerky-anim {
      width: 228px;
      height: 215px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 200;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $br1) {
        width: 125px;
        height: 121px;
        z-index: 700;
      }

      .jerky-body-box {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 0 0 0 29px;
      }

      img {
        &.jerky-head {
          width: 100%;
          margin: -12px 0 0;
          z-index: 100;
          @media (max-width: $br1) {
            margin: 0;
          }
        }
        &.jerky-body {
          width: 82px;
          position: relative;
          bottom: 1px;
          z-index: 0;
          @media (max-width: $br1) {
            width: 46px;
            bottom: -4px;
            left: -8px;
          }
        }
        &.jerky-arm-left {
          width: 52px;
          position: absolute;
          top: 12px;
          left: 0;
          right: 107px;
          margin: auto;
          z-index: 300;
          @media (max-width: $br1) {
            width: 28px;
            top: 10px;
            right: 61px;
          }
        }
        &.jerky-arm-right {
          width: 70px;
          position: absolute;
          right: -18px;
          bottom: 89px;
          transform-origin: center left;
          z-index: 300;
          @media (max-width: $br1) {
            width: 38px;
            bottom: 50px;
            right: -11px;
          }
        }
      }
    }

    .jerky-coins-left,
    .jerky-coins-right {
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        position: absolute;

        &:first-child {
          z-index: 0;
        }

        &:nth-child(2) {
          z-index: 300;
        }

        &:last-child {
          z-index: 300;
        }
      }
    }

    .jerky-coins-left {
      left: 0;
      align-items: flex-start;

      img {
        @media (min-width: $br1Min) {
          &:first-child {
            top: -50px;
            left: 65px;
          }

          &:nth-child(2) {
            top: 55px;
            left: -15px;
          }

          &:last-child {
            bottom: -50px;
            left: 140px;
          }
        }
        @media (max-width: $br1) {
          &:first-child {
            width: 73px;
            top: -25px;
            left: 32px;
          }

          &:nth-child(2) {
            width: 53px;
            top: 77px;
            left: -7px;
          }

          &:last-child {
            width: 84px;
            bottom: -37px;
            left: 40px;
          }
        }
      }
    }

    .jerky-coins-right {
      right: 0;
      align-items: flex-end;

      img {
        @media (min-width: $br1Min) {
          &:first-child {
            top: -55px;
            right: 60px;
          }

          &:nth-child(2) {
            top: 25px;
            right: -45px;
          }

          &:last-child {
            bottom: -60px;
            right: 80px;
          }
        }
        @media (max-width: $br1) {
          &:first-child {
            width: 67px;
            top: 4px;
            right: 40px;
          }

          &:nth-child(2) {
            width: 52px;
            top: 45px;
            right: -10px;
          }

          &:last-child {
            width: 87px;
            bottom: -25px;
            right: 53px;
          }
        }
      }
    }
  }
}
