html,
body {
  height: 100%;
  font-size: 14px;
  min-width: 100%;
  touch-action: manipulation;
}

body[style='overflow: hidden;'] {
  position: fixed !important;
}

@media (max-width: 991px) {
  body.dropdownFilterOpen {
    overflow: hidden;
  }
}

.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

#root {
  position: relative;
  height: 100%;
}

.white-card {
  background-color: #fff;
}

#application {
  position: relative;
  height: 100%;

  &.hideContent {
    overflow: hidden;
  }

  &.notMounted {
    opacity: 0;
  }

  &.isLoading {
    *:hover {
      cursor: wait !important;
    }
  }

  .modal {
    max-height: 75vh;
    top: 12.5vh !important;

    &.hasFooter {
      box-sizing: border-box;

      .modal-content {
        max-height: calc(70vh - 90px);
        overflow-y: auto !important;
      }

      .modal-footer {
        bottom: 0 !important;
      }
    }
  }
}

.video-svg-icon-path-image {
  &:hover {
    path {
      fill: #000000;
    }
  }
}

.videoModalIcon {
  max-width: 640px;

  .videoModalIcon-video {
    margin-top: 3rem;
    max-width: 100%;
  }
}

.select-wrapper {
  .dropdown-content {
    min-height: 76px;
    max-height: 325px;
  }
}

.table-responsive {
  overflow-y: hidden;
}

.select-field {
  label {
    i.tooltipped {
      display: inline-block;
      float: right;
      font-size: 1.5em;
      margin-left: 5px;
      margin-top: -1px;
    }
  }
}

#page-wrap {
  width: 100%;
  position: relative;
  min-height: 100%;
  background-color: $pale-grey;
  will-change: width;
  padding-bottom: 115px;
  overflow: hidden;
}

#daterange-component-container {
  line-height: 0.95em !important;
  max-width: 1px;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

//** CrakRevenue Colors classes
.cr-grey {
  background-color: $cr-grey;
}
.cr-grey-text {
  color: $cr-grey;
}
.cr-grey65 {
  background-color: $cr-grey65;
}
.cr-grey65-text {
  color: $cr-grey65;
}
.cr-dark-grey {
  background-color: $cr-dark-grey;
}
.cr-dark-grey-text {
  color: $cr-dark-grey;
}
.cr-pale-grey {
  background-color: $cr-pale-grey;
}
.cr-pale-grey-text {
  color: $cr-pale-grey;
}
.fab-grey {
  background-color: $fab-grey;
}

.yellow {
  color: #ffe200;
}

.pointer {
  cursor: pointer;
}

.app-container {
  padding-left: 100px;
  padding-right: 40px;
  padding-top: 80px;
  transition: padding-left 200ms $inEaseing;
  will-change: width;
}

.lean-overlay {
  background-color: #000;
}

textarea.materialize-textarea {
  padding: 0;
}

.input-field {
  label.active + textarea {
    padding: 1.6rem 0 !important;
  }
}

.datepicker__tether-element {
  z-index: 20000;

  .datepicker__navigation svg {
    position: static;
    top: 0;
  }
}

.right-align {
  text-align: right;
}

.slowTransitions {
  transition: all 300ms $inEaseing !important;
}

.noResults {
  color: $cr-darker-pale-grey;
  font-size: em(18);
  text-align: center;
  padding: 40px 0;

  a {
    color: $cr-green;
  }
}

.copyBtn,
.send-btn {
  &:not(.disabled) {
    cursor: pointer;
  }

  i,
  .text {
    display: inline-block;
    position: relative;
  }

  .text {
    padding: 0 10px;
  }
}

[type='checkbox']:not(:checked),
[type='checkbox']:checked,
[type='radio']:not(:checked),
[type='radio']:checked {
  visibility: visible;
}

.backArrow {
  display: inline-block;
  color: $cr-dark-grey;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 1.1rem;

  i {
    font-size: 2.5rem;
    position: relative;
    top: 0.9rem;
  }
}

.materialize-textarea {
  margin-top: 0.8em !important;
}

.select-field.canExclude {
  .dropdown-title {
    display: block;
    position: relative;
    font-size: em(16);
    color: $cr-green;
    padding: 1em 16px 7px;

    .close-dropdown {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;

      &:hover {
        color: #000;
      }
    }
  }

  .dropdown-content {
    overflow: hidden !important;

    &.include {
      .block-buttons {
        button.exclude {
          background-color: #dfdfdf !important;
          box-shadow: none;
          color: #9f9f9f !important;
        }
      }
    }

    &.exclude {
      .block-buttons {
        button.include {
          background-color: #dfdfdf !important;
          box-shadow: none;
          color: #9f9f9f !important;
        }
      }

      input[type='checkbox']:checked + label {
        &:after {
          border: 2px solid $cr-red-error;
          background-color: $cr-red-error;
        }
      }
    }
  }

  .dropdown-title {
    display: none;
  }

  .block-buttons {
    button {
      text-align: center;
      padding: 5px;
      width: 50%;
      margin: 0 !important;

      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &.exclude {
        background-color: $cr-red-error;
      }
    }
  }

  .input-field {
    margin: 0;
    padding: 0 16px;

    i {
      color: $cr-green;
      padding-top: 15px;
    }
  }

  ul {
    max-height: 209px;
    overflow: auto;
    margin: 0;
  }
}

@include responsive('xlarge') {
  #application {
    &.menuOpen {
      .app-container {
        padding-left: 275px; // largeur du menu
      }
    }
  }
}

@include responsive('large') {
  #application {
    &.menuOpen {
      .app-container {
        padding-left: 275px; // largeur du menu
      }
    }
  }
}

@include responsive('normal') {
  #application {
    &.menuOpen {
      .app-container {
        padding-left: 275px; // largeur du menu
      }
    }
  }
}

@include responsive('tablet') {
  .app-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .datepicker .datepicker__duration-selector {
    display: none;
  }
}

@include responsive('phone to tablet') {
  .app-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .filter-btn {
    width: 100%;
    line-height: 50px;
    height: 50px;
  }

  .select-field {
    margin-bottom: 2rem;
  }

  .select-field.canExclude {
    .dropdown-title {
      display: block;
      position: relative;
      font-size: em(16);
      color: $cr-green;
      padding: 1em 16px 7px;

      .close-dropdown {
        display: inline-block;
        cursor: pointer;
      }
    }

    .dropdown-content.active {
      display: block !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      height: 100vh !important;
      width: 100vw !important;
      max-height: 3000px !important;
      padding-right: 15px;
    }

    .block-buttons {
      padding: 7px 16px;

      button {
        text-align: center;
        padding: 0;
        width: 50%;

        &:first-child {
          border-radius: 2px 0 0 2px;
        }
        &:last-child {
          border-radius: 0 2px 2px 0;
        }

        &.exclude {
          background-color: $cr-red-error;
        }
      }
    }

    .input-field {
      margin: 0;
      padding: 0 16px;

      i {
        color: $cr-green;
        padding-top: 15px;
      }
    }

    ul {
      max-height: 70%;
      overflow: auto;
    }
  }

  .mobile select.initialized {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 100;

    option:nth-child(1) {
      display: none;
    }
  }

  .datepicker .datepicker__duration-selector {
    display: none;
  }
}

@include responsive('phone') {
  .app-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .datepicker .datepicker__duration-selector {
    display: none;
  }
}

h1 {
  font-size: 2.08rem;
  line-height: 110%;
  margin: 1.14rem 0 0.912rem 0;
}

h2 {
  font-size: 1.76rem;
  line-height: 110%;
  margin: 1.02rem 0 0.786rem 0;
}

h3 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 0.91rem 0 0.702rem 0;
}

h4 {
  font-size: 1.52rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
}

h5 {
  font-size: 1.32rem;
  line-height: 110%;
  margin: 0.74rem 0 0.643rem 0;
}

h6 {
  font-size: 1rem;
  line-height: 110%;
  margin: 0.5rem 0 0.4rem 0;
}

.waves-effect {
  will-change: initial;
}

.input-field label.pre-label {
  font-size: 0.8rem;
  color: #333;
  position: relative;
  top: auto;
  left: auto;
}

#habla_window_div {
  max-width: calc(100% - 40px);
}

.notice-container {
  display: block;
  margin: 0 0 13px 20px;
  .select-wrapper__icon,
  .notice-icon {
    position: absolute;
    left: -20px;
    top: 0;
  }
  .notice {
    color: grey;
    a {
      color: inherit;
      span.text {
        text-decoration: underline;
      }
    }
  }
}

.input-field,
.select-field {
  .field-error {
    margin-bottom: 1rem;
  }

  &.error {
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='url'],
    input[type='time'],
    input[type='date'],
    input[type='datetime-local'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    textarea.materialize-textarea {
      border-bottom: 1px solid $cr-red-error;

      &:focus {
        &:not([readonly]) {
          box-shadow: 0 1px 0 0 $cr-red-error;
        }
      }
    }

    label {
      &.active {
        color: $cr-red-error;
      }
    }
  }
}

.field-error {
  color: $cr-red-error;
  margin-bottom: 15px;
}

.input-field {
  &.select-field {
    &.error {
      .active {
        color: #333333;
      }
    }
  }
}

.choice-group {
  &.error {
    .groupLabel,
    .field-error {
      color: $cr-red-error;
    }
  }
}

.groupLabel {
  display: block;
  position: relative;
  margin: 10px 0;

  &.error {
    color: $cr-red-error;
  }
}

.capitalize {
  text-transform: capitalize;
}

.unbreakable {
  white-space: pre;
}

body {
  &.mobile-device {
    .visible-mobile {
      display: block !important;
    }
    .hidden-mobile {
      display: none;
    }
  }
  &.desktop-device {
    .visible-desktop {
      display: block !important;
    }
    .hidden-desktop {
      display: none;
    }
  }
}

.page-title {
  h1 {
    font-size: em(24);
    color: $cr-grey65;
    display: inline-block;
    margin-bottom: 5px;

    i {
      color: $cr-green;
      font-size: 26px;
      margin-right: 15px;
      float: left;
    }

    a > i {
      color: $cr-grey65;
    }
  }

  p {
    margin-top: 0;
    padding-left: 42px;
    color: $cr-grey65;
    font-size: em(13);
  }
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: $cr-green;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.switch label input[type='checkbox'] + .lever {
  background-color: $cr-grey65;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
}

.switch label input[type='checkbox'] + .lever:after {
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
}

.rotate-315 {
  transform: rotate(315deg);
}

// Google optimize
.async-hide {
  opacity: 0 !important;
}

/*
  Grosseur des labels
*/

.input-field label.active,
.groupLabel {
  font-size: em(14);
}

.select-wrapper + label.active {
  top: 11px;
}
.select-wrapper + label.groupLabel.active {
  top: 12px;
}

#signup .groupLabel[for='averageRevenue'].active {
  top: 70px;
}

#daterange-component {
  label[for='datepicker__startdate'],
  label[for='datepicker__date'] {
    font-size: em(14);
  }
  .select-wrapper + label.active {
    top: 12px;
  }
  .select-wrapper + label.groupLabel.active {
    top: 12px;
  }
}

#signup-references-infos {
  label[for='howYouHear'] {
    top: 40px;
  }
}

.mobile-device {
  overflow-x: hidden;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus,
.btn-link:visited {
  display: inline-block;
  padding: 0;
  text-decoration: underline;
  color: $cr-green;
  transition: color 0.15s linear;
  background: none;
  border-radius: 0;
  font-weight: 400;
  cursor: pointer;
  border: none;
  box-shadow: none;
  &:hover {
    color: $cr-grey;
  }
}

ul.list {
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding: 0 0 0 15px;
    margin: 2px 0;
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      position: absolute;
      left: 2px;
      border-radius: 50%;
      top: 0.4em;
    }
  }
}

.primary-color,
.standard-colors,
.basic-colors:focus,
.basic-colors:hover,
.hov-primary-color:focus,
.hov-primary-color:hover {
  color: $cr-green !important;
}
.primary-darker-color,
.standard-colors:focus,
.standard-colors:hover,
.basic-colors:active {
  color: $cr-dark-green !important;
}

.bg-primary-color,
.standard-bg-colors,
.basic-bg-colors:focus,
.basic-bg-colors:hover,
.bg-primary-lighter-color {
  background-color: $cr-green !important;
}
.bg-primary-darker-color,
.basic-bg-colors:active,
.standard-bg-colors:hover {
  background-color: $cr-dark-green !important;
}

.fill-primary-color path,
.fill-primary-color polygon {
  fill: $cr-green !important;
}

.hyperlink span {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.colorpicker {
  .colorpicker_new_color,
  .colorpicker_current_color {
    height: 52px;
    left: 213px;
    width: 130px;
  }
  .colorpicker_current_color {
    top: 78px;
  }
  .colorpicker_field,
  .colorpicker_hex {
    display: none;
  }
  .colorpicker_submit {
    font-size: 14px;
    text-align: center;
    width: 130px;
  }
}
