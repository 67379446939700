#offer-tags-bar {
    padding: 0.75rem 0;
    background-color: #ecebeb;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;

    .container-fluid.app-container {
        @include flexbox(row, wrap, flex-start);

        padding-top: 0;
        width: 100%;
        max-width: 1920px;
        * {
            align-self: center;
        }

        .offer-tag:not(:last-child) {
            margin-right: 1rem;
        }
        .offer-tag {
            @include flexbox(row, nowrap, space-between);

            border-radius: 2rem;
            padding: 0.6rem 0.75rem;

            span {
                margin: 1px 0.75rem 0 0;
            }
            svg {
                width: 1.65rem;
                height: 1.65rem;
            }
            &#exclusive-offer svg path {
                fill: $low-highlight-color;
            }
        }
    }
}
