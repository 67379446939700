.ellipsis-parent {
    overflow: hidden;
    position: relative;

    .ellipsis-child {
        display: inline-block;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        right: -17px;
        bottom: 0;
        left: 0;
    }
}
