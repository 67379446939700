#pagination {
  color: $cr-grey65;
  padding-right: 15px;
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }
  &.bannerPagination {
    padding: 0;
    color: #333333;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 11px !important;
      margin: 2px 0 -10px;
    }

    .clearfix {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 300px;
        justify-content: flex-start;
      }
    }

    #rowsPerPage {
      padding-right: 0;
    }

    .rowsPerPage {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      order: 1;
    }

    .results {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      order: 2;

      .arrows {
        margin: 0 0 0 15px;

        .btn-flat {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }

    span {
      font-weight: 700 !important;
    }

    .text {
      padding: 0 !important;
    }

    .pagination-select {
      margin: 0 0 0 10px !important;

      .input-field {
        margin: 0 !important;
      }
    }

    .select-dropdown {
      margin: 0 !important;
      font-weight: 600 !important;
    }

    .caret {
      top: 7px !important;
    }

    .btn-flat {
      i {
        font-size: 30px !important;
      }
    }
  }

  #viewmode-component + & {
    margin-top: 7px;
  }

  .pagination-select {
    width: 70px;
    margin: 0 20px;
  }

  .title-container {
    padding-top: 9px;

    h3 {
      margin-right: 10px;
    }

    h3,
    p {
      display: inline;
    }
  }

  .input-field {
    margin-top: 0px;
  }

  .rowsPerPage,
  .results {
    .text {
      display: inline-block;
    }
  }

  .results {
    text-align: right;
    margin-left: 20px;
  }

  .select-wrapper {
    input.select-dropdown {
      border: 0;
      margin: 3px 0 0;
    }

    span.caret {
      top: 13px;
    }
  }

  .arrows {
    display: inline-block;
    text-align: right;

    @include responsive('phone') {
      margin-left: 0px;
    }

    .btn-flat {
      display: inline-block;
      margin-left: 10px;
      padding: 0 10px;

      @include responsive('phone') {
        margin-left: 0px;
      }

      i {
        font-size: 26px;
      }
    }
  }
}
