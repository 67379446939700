.loading-bar {
    width: 100%;
    height: 4px;
    position: absolute;
    overflow: hidden;
    color: white;

    div:not(.loader) {
        position: absolute;
        height: 100%;
        left: 50%;
    }
    .bar-1 {
        opacity: 0.05;

        &.active {
            animation: stretch 2.8s ease 0s infinite;
        }
    }
    .bar-2 {
        opacity: 0.2;

        &.active {
            animation: stretch 2.8s ease 0.4s infinite;
        }
    }
    .bar-3 {
        opacity: 0.4;

        &.active {
            animation: stretch 2.8s ease 0.8s infinite;
        }
    }

    .bar-4 {
        opacity: 1;

        .active {
            animation: stretch 2.8s ease 0.12s infinite;
        }
    }

    @keyframes stretch {
        0% {
            padding: 0 0 0 0;
            left: 50%;
            z-index: 4;
        }
        25% {
            z-index: 3;
        }
        50% {
            padding: 0 50% 0 50%;
            left: 0;
            z-index: 2;
        }
        100% {
            padding: 0 50% 0 50%;
            left: 0;
            z-index: 1;
        }
    }
    &.container-loader {
        .bar-1 {
            opacity: 0.75;
        }
        .bar-2 {
            opacity: 0.5;
        }
        .bar-3 {
            opacity: 0.2;
        }
        .bar-4 {
            opacity: 1;
        }
    }
}

.loading-section-container {
    &.full-screen {
        background: #000;
        opacity: 0.3;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999999;

        .loading-section {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
    &:not(.full-screen) {
        text-align: center;
    }

    .loading-section-bar {
        display: inline-block;
        width: 8px;
        height: 30px;
        margin: 0 2px;
        animation: loading 1s ease-in-out infinite;

        &:nth-child(1) {
            animation-delay: 0s;
        }
        &:nth-child(2) {
            animation-delay: 0.09s;
        }
        &:nth-child(3) {
            animation-delay: 0.18s;
        }
        &:nth-child(4) {
            animation-delay: 0.27s;
        }
    }
}

@keyframes loading {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1, 2.2);
    }
    40% {
        transform: scale(1);
    }
}
