#profile-settings {
    padding-top: 20px;
    max-width: 1400px;

    h2 {
        font-size: em(20);
        color: $cr-grey65;
    }

    .notice {
        color: $cr-grey65;
        margin-bottom: 0.5rem;
    }
}
