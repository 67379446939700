#offers-table {
  tbody {
    tr {
      .offer-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .offer-status {
    background-color: #c7c7c7;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.pending {
      opacity: 0.25;
    }

    &.rejected {
      background-color: $warning-color;
    }
  }

  .icon-new {
    margin-left: -3px;
    margin-top: 12px;
  }

  .country-list {
    max-width: 75px;
    margin: 0 auto;
  }

  .centered {
    text-align: center;
  }

  .no-epc {
    opacity: 0.35;
  }

  .view-site {
    width: 100px;
    @media (max-width: 1324px) and (min-width: 1000px) {
      display: flex;
      justify-content: center;
      width: fit-content;
    }
    a {
      color: inherit;
      font-size: 14px;
      text-transform: uppercase;

      span {
        position: relative;
        bottom: 6px;
        margin-left: 3px;
        @media (max-width: 1324px) and (min-width: 1000px) {
          display: none;
        }
      }
    }
  }
}
