@use "sass:math";
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;

/*** Buttons ***/
$button-bg-color-disabled: #dfdfdf !default;
$button-color: $cr-green !default;
$button-color-disabled: #9f9f9f !default;
$button-color-flat: #343434 !default;
$button-color-raised: #fff !default;
$button-floating-size: 37px !default;
$button-height: 36px !default;
$button-font-size-shared: 1.3rem !default;
$button-large-icon-font-size: 1.6rem !default;
$button-line-height: 36px !default;

/*** Typography ***/
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.2rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;

// Flowtext
$range: $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: math.div($range, $intervals) !default;

$mat-light-grey-5: #fafafa;
$mat-light-grey-4: #f5f5f5;

$shadow1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$shadow1-half: 0 2px 4.5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
$shadow2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$shadow4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$shadow5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

$gutter-width: 15px;

/*** Forms ***/
$radio-fill-color: $cr-green !default;
$radio-empty-color: #5a5a5a !default;
$secondary-color: $cr-pale-green;

$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(
    lighten($secondary-color, 25%),
    25%
) !default;
$switch-unchecked-bg: #f1f1f1 !default;
$switch-unchecked-lever-bg: #818181 !default;

/*** Dropdown ***/
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $cr-dark-grey !default;
$dropdown-item-height: 50px !default;

$badge-bg-color: $secondary-color !default;

/*** Tables ***/
$table-border-color: #d0d0d0 !default;
$table-striped-color: #f2f2f2 !default;

/*** cards ***/
$card-padding: 20px !default;
$card-bg-color: #fff !default;
$card-link-color: $secondary-color !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;

$element-top-margin: math.div($gutter-width, 3) !default;
$element-bottom-margin: math.div($gutter-width * 2, 3) !default;
