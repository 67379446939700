#smart-link-customize {
  margin: 0 auto;
  @media (max-width: $screen-sm-min) {
    margin-top: 2.5rem;
  }
  label {
    left: 0;
  }
  .smart-link-status {
    background-color: #c7c7c7;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    margin: 6px 10px 6px 4px;

    &.approved {
      background-color: $cr-green;
    }

    &.pending {
      background-color: $cr-green;
      opacity: 0.25;
    }

    &.rejected {
      background-color: $warning-color;
    }
  }

  h2.section-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: $cr-green;
    text-transform: uppercase;
  }

  .extra-earnings {
    text-transform: uppercase;
    font-size: 1.2rem;
    @media (max-width: $screen-md) {
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  .switchs {
    white-space: nowrap;
    margin-top: 2rem;
    .extra-switch {
      margin-bottom: 2rem;
    }
  }

  .promotionMethods-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin: 0;

    @media (max-width: 1390px) {
      gap: 14px;
    }

    .radio-field {
      display: flex;
      padding: 30px 40px;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid #d6d6d6;
      background: #fff;
      cursor: pointer;

      @media (max-width: 1390px) {
        padding: 15px 20px;
        align-items: center;
      }

      &:hover {
        border: 1px solid #00bd00;
        transition: all 0.3s ease;
        label {
          color: #00bd00;
          transition: all 0.3s ease;
        }

        svg {
          path {
            stroke: #00bd00;
            transition: all 0.3s ease;
          }
        }
      }
      label {
        padding-left: 0;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
}

.radio-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  &:before {
    content: none;
  }

  @media (max-width: 1390px) {
    flex-flow: column;
    align-items: center;
    gap: 40px;
  }

  .radio-box {
    position: relative;
    display: flex;
    width: 250px;
    padding: 40px 20px 30px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    border: 1px solid #d6d6d6;
    background: #fff;
    cursor: pointer;

    &:hover {
      border-radius: 20px;
      border: 1px solid var(--Colors-Grey-84, #d6d6d6);
      background: #fff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
      transition: all 0.3s ease;
      svg {
        g {
          path {
            stroke: #00bd00;
            transition: all 0.3s ease;
          }
        }
      }

      .radio-field {
        span {
          color: #00bd00;
          transition: all 0.3s ease;
        }
      }

      .get-started-btn {
        background: #00d100;
        transition: all 0.3s ease;
      }
    }

    @media (max-width: 1390px) {
      width: 100%;
    }

    .recommended-tag {
      position: absolute;
      right: -12px;
      top: 5px;
      display: flex;
      height: 25px;
      padding: 4px 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 5px;
      background: #feeff9;
      color: #a91a90;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      letter-spacing: -0.12px;

      @media (max-width: 1390px) {
        top: 10px;
        right: 15px;
      }
    }

    p {
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: -10px -10px 0;
      letter-spacing: -0.1px;

      br {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }
    }

    .get-started-btn {
      display: flex;
      width: 210px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 5px;
      background: #00bd00;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-top: auto;

      @media (max-width: 1390px) {
        width: 100%;
      }
    }

    .radio-field {
      label {
        padding-left: 0;
        &:before,
        &:after {
          content: none;
        }
      }
      span {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }
  }

  .selected {
    border: 1px solid #00bd00;
  }
}

@include responsive('xlarge') {
  #smart-link-customize {
    margin: 0 auto;
  }
}

@include responsive('phone') {
  .extra-earnings {
    text-align: center;
  }

  .disabled-slider-grey {
    text-align: center;
  }
}

@include responsive('phone to tablet') {
  .extra-earnings {
    text-align: center;
  }

  .disabled-slider-grey {
    text-align: center;
  }
}
