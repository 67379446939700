// Grid Variables
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

// XLarge screen / wide desktop
$container-xlarge-desktop: (1440px + $grid-gutter-width) !default;
//** For `$screen-xlg-min` and up.
$container-xlg: $container-xlarge-desktop !default;
