.BrokenStreakModal {
  background: linear-gradient(180deg, #2c3031 0%, #1d1d1b 84.59%);
  overflow-y: auto;
  overflow-x: hidden;
  .header-modal {
    background: url(/img/broken-modal-top-bg.png) no-repeat center center fixed;
    background-size: cover;
    height: 250px;
    margin: 0;

    button {
      right: 5%;
      top: 10%;
    }

    .badge-section {
      width: 100%;
      height: 100%;
      margin: 0;
      .badge {
        width: 259px;
        height: 259px;
        top: 50%;
        z-index: 2;
        &.bronze {
          animation: broken-bronze-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }

        &.silver {
          animation: broken-silver-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }

        &.gold {
          animation: broken-gold-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }

        &.emerald {
          animation: broken-emerald-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }
      }

      .shadow {
        position: absolute;
        background-color: #000;
        width: 144px;
        height: 10px;
        filter: blur(5px);
        bottom: 0;
        border-radius: 100%;
        animation: shadow-animation 0.1s ease-in-out forwards;
        animation-delay: 1s;
      }
    }
  }
  .week-streak-section {
    margin: 13px 23px 23px;
    .rotate-title {
      display: flex;
      flex-flow: column;
      text-align: center;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;

      @media (max-width: 576px) {
        margin-top: 10px;
      }
      .first-title {
        transform: rotate(-4.375deg);
        font-size: 62px;
        font-weight: 900;
      }

      .second-title {
        font-size: 24px;
        line-height: 16px;
        color: #00bd00;
      }
    }

    .progress-bar {
      margin-top: 20px;
      margin-bottom: 25px;

      @media (max-width: 576px) {
        margin-left: -15px;
      }
      .left-side,
      .right-side {
        display: flex;
        gap: 3px;
        align-items: center;

        .bar {
          &.active {
            animation: bar-active-green-to-grey-animation 0.2s ease-in-out forwards;
            animation-delay: 1s;
          }

          animation: bar-green-to-grey-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }

        .whale-lvl {
          animation: whale-green-to-grey-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;

          .lvl-chip {
            animation: lvl-chip-green-to-grey-animation 0.2s ease-in-out forwards;
            animation-delay: 1s;
          }
        }
      }

      .left-side {
        animation: progress-bar-left-animation 0.2s ease-in-out forwards;
        animation-delay: 1s;
      }

      .right-side {
        animation: progress-bar-right-animation 0.2s ease-in-out forwards;
        animation-delay: 1s;
      }

      .broken-bar {
        height: 22px;
        @media (max-width: 576px) {
          height: 17px;
        }
        path {
          animation: broken-bar-green-to-grey-animation 0.2s ease-in-out forwards;
          animation-delay: 1s;
        }
        &.active {
          path {
            fill: #00bd00;
            animation: active-broken-bar-green-to-grey-animation 0.2s ease-in-out forwards;
            animation-delay: 1s;
          }
        }
      }
    }

    p {
      margin: 15px 36px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      @media (max-width: 576px) {
        font-size: 14px;
        margin: 0 20px;
      }
    }
  }

  .gamification-btn {
    margin-bottom: 30px;
  }

  @keyframes broken-bronze-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
      rotate: 0;
    }

    50% {
      opacity: 1;
      transform: translate(-80px, -30px);
      rotate: 20deg;
    }

    100% {
      opacity: 1;
      transform: translate(-100px, -40px);
      rotate: 20deg;
    }
  }

  @keyframes broken-silver-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
      rotate: 0;
    }

    50% {
      opacity: 1;
      transform: translate(-80px, -40px);
      rotate: 20deg;
    }

    100% {
      opacity: 1;
      transform: translate(-100px, -50px);
      rotate: 20deg;
    }
  }

  @keyframes broken-gold-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
      rotate: 0;
    }

    60% {
      opacity: 1;
      transform: translate(-80px, -60px);
      rotate: 20deg;
    }

    100% {
      opacity: 1;
      transform: translate(-100px, -70px);
      rotate: 20deg;
    }
  }

  @keyframes broken-emerald-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
      rotate: 0;
    }

    50% {
      opacity: 1;
      transform: translate(-80px, -65px);
      rotate: 20deg;
    }

    100% {
      opacity: 1;
      transform: translate(-100px, -75px);
      rotate: 20deg;
    }
  }

  @keyframes shadow-animation {
    0% {
      right: 35%;
      filter: blur(5px);
    }

    100% {
      filter: blur(2px);
      right: 25%;
    }
  }

  @keyframes progress-bar-left-animation {
    0%,
    40% {
      transform: rotate(0);
    }

    40% {
      transform: rotate(-8deg);
    }

    80% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(-5deg);
    }
  }

  @keyframes progress-bar-right-animation {
    0%,
    40% {
      transform: rotate(0);
    }

    40% {
      transform: rotate(8deg);
    }

    80% {
      transform: rotate(3deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }

  @keyframes bar-green-to-grey-animation {
    0%,
    40% {
      background: #01262e;
      border-color: #00bd00;
    }

    60%,
    100% {
      background: #5e6368;
      border-color: #5e6368;
    }
  }

  @keyframes bar-active-green-to-grey-animation {
    0%,
    40% {
      background: #00bd00;
      border-color: #00bd00;
    }

    60%,
    100% {
      background: #5e6368;
      border-color: #5e6368;
    }
  }

  @keyframes whale-green-to-grey-animation {
    0%,
    40% {
      background: #01262e;
      border-color: #00bd00;
    }

    60%,
    100% {
      background: #1e1e1c;
      border-color: #5e6368;
    }
  }

  @keyframes lvl-chip-green-to-grey-animation {
    0%,
    40% {
      background: #00bd00;
    }

    60%,
    100% {
      background: #5e6368;
    }
  }

  @keyframes broken-bar-green-to-grey-animation {
    0%,
    40% {
      fill: #01262e;
      stroke: #00bd00;
    }

    60%,
    100% {
      fill: #5e6368;
      stroke: #5e6368;
    }
  }

  @keyframes active-broken-bar-green-to-grey-animation {
    0%,
    40% {
      fill: #00bd00;
      stroke: #00bd00;
    }

    60%,
    100% {
      fill: #5e6368;
      stroke: #5e6368;
    }
  }
}
