#profile-identity {
  .field-error {
    margin-bottom: 5px;
  }

  .input-field {
    margin-top: 20px;
  }

  input {
    margin-bottom: 5px;
  }

  &.locked {
    .locked-content {
      text-align: center;
      padding-bottom: 60px;
      h1 {
        font-size: 1.7em;
      }
    }
    div:not(.locked-content) {
      opacity: 0.8;
      * {
        pointer-events: none;
        color: #656565 !important;
      }
    }
  }

  .customSelects {
    .fields .field {
      margin: 0 0 15px 0;
      label {
        font-weight: normal;
      }
      button {
        margin-top: 0;
        text-align: left;
      }
    }
  }
}

.verified-identity {
  p {
    background: #00bd001a;
    padding: 15px;
    border-radius: 10px;
  }

  a {
    color: #00bd00;
    font-weight: bold;
    cursor: pointer;
  }
  button {
    border-radius: 5px;
  }
}

.input-text-identity-company {
  label {
    margin-left: -16px;
  }
}

.jumio-modal-size {
  max-width: 800px;
  width: 90%;
  max-height: 80vh;

  @media (max-width: 992px) {
    overflow: hidden;
  }

  .modal-inner {
    padding-top: 0;
  }

  .jumio-modal {
    margin: 25px 0;
    overflow: auto;
    max-height: 75vh;
    overflow-x: hidden;

    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 40px;
      @media (max-width: 481px) {
        font-size: 25px;
      }
    }
    p {
      text-align: center;
      max-width: 90%;
      margin: 25px auto;
      @media (max-width: 481px) {
        max-width: 90%;
      }
    }
    button {
      display: block;
      width: 75%;
      margin: auto;
      @media (max-width: 481px) {
        width: 80%;
      }
    }
    strong {
      font-weight: bold;
    }

    .link-disabled {
      pointer-events: none;
      cursor: default;
      filter: grayscale(100%);
    }

    .privacy-policy-text {
      font-size: 13px;
    }

    .modalPreparationSection {
      text-align: left;
      width: 91%;
      margin: 0 auto;
      @media (max-width: 481px) {
        width: 93%;
      }

      .modalPreparationList {
        li {
          display: flex;
          padding: 10px 0;

          &::before {
            content: '·';
            font-size: 80px;
            vertical-align: middle;
            line-height: 20px;
          }

          & p {
            margin-block-start: 0;
            margin-block-end: 0;
            max-width: 100%;
            text-align: left;
            margin: 0;
          }
        }
      }
    }

    .modalCheckbox {
      width: 90%;
      margin: 0 auto;
      text-align: left;
      display: block;
      margin-bottom: 30px;
    }
  }
}
