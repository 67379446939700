.radio-field.big-radio [type='radio'] + label {
    height: 32px;
    line-height: 32px;

    &:after,
    &:before {
        width: 20px;
        height: 20px;
    }
}

.radio-field.grey-radio [type='radio']:checked + label {
    &:after,
    &:before {
        border-color: $cr-grey65;
    }

    &:after {
        background-color: $cr-grey65;
    }
}
