.simple-signup {
    &__container {
        &__box {
            .dropdown-content li {
                min-height: 33px;
            }
            &__splash {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                #splash-whale {
                    padding-bottom: 10%;
                    #splash-whale-gif {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 200px;
                        height: 200px;
                    }
                    p {
                        font-size: 24px;
                        text-align: center;
                        color: #494949;
                    }
                    body {
                        background: grey;
                        font-family: helvetica;
                        font-size: 72px;
                    }
                    @keyframes blink {
                        0% {
                            opacity: 0.2;
                        }
                        20% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 0.2;
                        }
                    }
                    .saving span {
                        animation-name: blink;
                        animation-duration: 1.4s;
                        animation-iteration-count: infinite;
                        animation-fill-mode: both;
                    }
                    .saving span:nth-child(2) {
                        animation-delay: 0.2s;
                    }
                    .saving span:nth-child(3) {
                        animation-delay: 0.4s;
                    }
                    svg {
                        path {
                            fill: #329032;
                        }
                    }
                }
            }
        }
    }
}
