.about {
  .simple-signup__container__box {
    .checkbox-field.simple-signup__checkbox {
      label {
        &:before {
          @media (min-width: 645px) {
            top: -4px;
          }
        }

        &:after {
          @media (min-width: 645px) {
            top: -2px;
          }
        }
      }
    }
  }

  input {
    padding-right: 40px !important;
  }

  #offline {
    padding: 0;
    overflow: hidden;
    .offline-container-simplified {
      max-width: unset;
      padding: 15px 30px;
    }

    .offline-header {
      position: absolute;
      top: 0;
      display: none;
    }

    .fields {
      position: relative;
    }

    .header {
      z-index: 1;
    }

    .container-fluid {
      height: 100vh;

      @media (max-width: 576px) {
        overflow-y: scroll;
        padding-bottom: 50px;
      }
    }
  }

  #site-footer {
    display: none;
  }

  #create-account-accept-terms {
    display: none;
  }

  .simple-signup__checkbox {
    margin-bottom: 15px;

    label {
      align-content: center;
    }
  }

  .fields {
    .field-container {
      width: 100%;
      max-width: 700px;
      align-self: center;

      .field {
        .dropdown {
          max-height: 225px !important;
        }

        .labelBox {
          margin: 20px 0;
          @media (max-width: 576px) {
            margin: 10px 0;
          }
          label {
            max-width: 500px;
            color: #000;
            font-size: 27px;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            @media (max-width: 768px) {
              font-size: 25px;
            }
            span {
              color: #000;
              font-size: 27px;
              font-weight: 700;
              line-height: normal;
            }
          }
        }

        &:nth-child(3) {
          label {
            font-size: 1em;
            font-weight: bold;
            color: #333;

            span {
              font-size: 1em;
              font-weight: bold;
              color: #333;
            }
          }
        }
      }
      .field-error {
        margin-bottom: 0;
      }
      .businessSignupSimpleInput {
        label {
          margin: 20px 0;
          @media (max-width: 576px) {
            margin: 10px 0;
          }
        }
        input {
          margin-bottom: 20px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }
      }

      .simplified-textarea,
      .simplified-input {
        margin: 20px 0;
        @media (max-width: 576px) {
          margin: 10px 0;
        }
        label {
          margin-bottom: 20px;
          color: #000;
          font-size: 27px;
          font-weight: 700;
          line-height: normal;
          @media (max-width: 768px) {
            font-size: 25px;
          }

          @media (max-width: 576px) {
            margin-bottom: 10px;
          }

          &:nth-child(2) {
            font-size: 1em;
            font-weight: bold;
            color: #333;

            span {
              font-size: 1em;
              font-weight: bold;
              color: #333;
            }
          }

          span {
            color: #000;
            font-size: 27px;
            font-weight: 700;
            line-height: normal;
            @media (max-width: 768px) {
              font-size: 25px;
            }
          }
        }

        .field {
          &:nth-child(2) {
            label {
              font-size: 1em;
              font-weight: bold;
              color: #333;

              span {
                font-size: 1em;
                font-weight: bold;
                color: #333;
              }
            }
          }
        }

        textarea {
          border-radius: 4px;
          border: 1px solid #dadada;
          color: #505050;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          height: 180px;
        }
      }

      .radio-btn-wrapper {
        display: flex;
        flex-flow: column;
        gap: 20px;

        @media (max-width: 576px) {
          gap: 10px;
        }
      }

      .radio-btn-container {
        display: flex;
        padding: 15px 30px;
        align-items: center;
        gap: 40px;
        align-self: stretch;
        border-radius: 10px;
        border: 1px solid #dadada;
        background: #fff;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        margin: 0;
        label {
          display: flex;
          justify-content: center;
          flex-flow: column;
          transition: unset;
          margin: 0;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          &:before {
            transition: unset;
          }
        }
        [type='radio'] {
          visibility: hidden;
        }
        &:hover {
          label {
            @media (max-width: 992px) {
              color: #000;
            }
            &:before {
              background: #fff;
              border: 1px solid #fff;

              @media (max-width: 992px) {
                background: transparent;
                border: 1px solid #00bd00;
              }
            }
          }
          border-radius: 10px;
          border: 1px solid #00bd00;
          background: #00bd00;

          @media (max-width: 992px) {
            background: transparent;
          }
        }
      }

      .identity-warning {
        .identity-warning-header {
          display: flex;
          margin: 20px 0;

          @media (max-width: 576px) {
            margin: 10px 0;
          }
          gap: 10px;
          .icon-box {
            display: flex;
            padding: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: #e9e9e9;
          }
          .warning-text {
            display: flex;
            flex-flow: column;
            justify-content: center;
            label {
              margin: 0;
              color: #000;
              font-size: 27px;
              font-weight: 700;
              line-height: normal;

              @media (max-width: 768px) {
                font-size: 25px;
              }
            }
          }
        }
        p {
          color: #1e1e1e;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.176px;
        }
      }
    }
  }
}
