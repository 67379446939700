#address-suggestion {
  label {
    left: 0;
  }

  .input-field {
    margin-bottom: 0;
  }

  input {
    margin-bottom: 9px;
  }

  .suggestion-list {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0;

    &:empty {
      padding: 0;
    }
  }

  .suggestion-item {
    border-bottom: 1px solid #333333;
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    &:hover {
      background-color: $cr-green;
      color: white;

      p {
        color: white;
      }

      svg {
        fill: white;

        path {
          fill: white;
        }
      }
    }

    &:last-child {
      border-bottom: unset;
    }

    &.customize {
      color: $cr-green;

      &:hover {
        svg {
          path {
            stroke: white;
          }
        }
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }

    p {
      align-content: center;
      margin: 0;
    }
  }
}

.valid-address-information {
  p {
    text-align: center;
  }

  span {
    color: $cr-green;
    cursor: pointer;
  }
}

.valid-address-information-box {
  opacity: 0;

  &.fadeIn {
    transition: 0.5s;
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
    transition: 0.3s ease-in;
  }
  .customSelects {
    .fields .field {
      margin: 0 0 15px 0;
      label {
        font-weight: normal;
      }
      button {
        margin-top: 0;
        margin-bottom: 15px;
      }
    }
  }
}
