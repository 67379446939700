// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// X-Large screen / x-wide desktop
$screen-xlg: 1500px !default;
$screen-xlg-min: $screen-xlg !default;
$screen-xlg-desktop: $screen-xlg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xlg-min - 1) !default;

// Colors
//** Palette de couleurs Crakrevenue <https://color.adobe.com/fr/Crakrevenue-Phoenix-color-theme-6238737>
$cr-pale-grey: #dadada;
$cr-darker-pale-grey: #4a4a4a;
$cr-grey: #333333;
$cr-dark-grey: #070707;
$cr-pale-green: #87e764;
$cr-green: #00bd00;
$cr-dark-green: #108210;
$cr-grey65: #656565;
$cr-grey49: #494949;
$cr-mid-grey: #808080;
$cr-red-error: #d83d20;
$cr-greyText: #969696;
$cr-green-notif: #ecffe5;
$cr-gold: #c0aa66;
$mfc-green: #139b4b;
$pale-grey: #f8f8f8;
$light-grey: #e5e5e5;
$grey: #252525;
$fab-grey: #4d4d4d;

$success-color: $cr-green !default;
$error-color: $cr-red-error !default;
$link-color: blue !default;
$low-highlight-color: #0e8686;
$warning-color: #cb0a1c;

// Text
$browser-context: 14; // Default

/*** Forms ***/
// Text Inputs + Textarea
$input-border-color: $cr-grey !default;
$input-bg-color: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $cr-green !default;
$label-font-size: 0.8rem !default;
$input-disabled-color: rgba(0, 0, 0, 0.26) !default;
$input-disabled-solid-color: #bdbdbd !default;

/** Transitions **/
$inEaseing: cubic-bezier(0.645, 0.045, 0.355, 1);
$outEaseing: cubic-bezier(0.165, 0.84, 0.44, 1);

$inAnimationLinks: all 200ms $inEaseing; // http://easings.net/fr#easeOutExpo
$outAnimationLinks: all 300ms $outEaseing; // http://easings.net/fr#easeInExpo

// Navigation light shadow
$shadow-left: inset -7px 0px 7px -7px rgba(0, 0, 0, 0.57);
$shadow-right: inset 7px 0px 7px -7px rgba(0, 0, 0, 0.57);
$shadow-top: inset 0px 7px 7px -7px rgba(0, 0, 0, 0.57);
$shadow-bottom: inset 0px -7px 7px -7px rgba(0, 0, 0, 0.57);

$assetsFolder: '/assets';
