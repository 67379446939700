.table-loading-state {
  overflow: hidden;
}
.table-wrapper {
  .loadingResults {
    color: $cr-darker-pale-grey;
    font-size: em(18);
    text-align: center;
    padding: 40px 0;
  }
  .table-container {
    padding-bottom: 4px;
  }
}
.table {
  tr {
    th {
      color: $cr-greyText;
      font-weight: 400;
      font-size: 0.88em;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;

      i {
        font-size: 1.5em;

        &.sorted {
          display: inline-block;
        }
      }

      .label {
        display: flex;
        gap: 5px;
        align-items: center;
        .tooltip-wrapper > div {
          display: flex;
        }
        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
          &:hover {
            fill: #00bd00;
          }
        }
      }

      .help {
        top: 0;
        color: inherit;
        transition: color 0.25s;

        i {
          display: block;
          top: calc(50% - 0.45em);
          right: -1.25em;
          width: 1em;
          font-size: 0.9em;
        }
      }

      &.sortable {
        cursor: pointer;

        &:hover {
          background-color: #f2f2f2;

          i {
            display: inline-block;
          }
        }
      }
    }

    td:first-child,
    th:first-child {
      padding-left: 36px;
    }

    td:last-child,
    th:last-child {
      padding-right: 36px;
    }

    td {
      padding: 5px 15px;

      a {
        color: #000;
      }
    }
  }
}

.black-header {
  .table {
    tr {
      th {
        color: #fff;
        background-color: $cr-grey;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }

    &.sortable {
      &:hover {
        background-color: lighten($cr-grey, 10%);
      }
    }
  }
}
