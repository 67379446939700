.dropdown-menu {
    border-radius: 0;
    margin-top: 0;
}
.typeahead {
    border-radius: 0;
    margin-top: 2px;
    z-index: 1051;
}
.dropdown-menu {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    left: 0;
    list-style: outside none none;
    margin: 2px 0 0;
    min-width: 160px;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: #eee;
    background-repeat: repeat-x;
    color: #333;
    outline: 0 none;
    text-decoration: none;
}
.dropdown-menu > li > a {
    color: $cr-dark-green;
    font-size: 12px;
    margin: 0 5px;
    padding: 5px 10px;
}
.dropdown-menu > li > a {
    clear: both;
    color: #333333;
    display: block;
    font-weight: normal;
    line-height: 20px;
    padding: 3px 20px;
    white-space: nowrap;
}
.dropdown-menu a {
    transition: all 0.2s ease-out 0s;
}
.dropdown-menu a strong {
    font-weight: bold;
}
