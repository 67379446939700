// shared styles
.btn,
.btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: $button-height;
    line-height: $button-line-height;
    // margin-bottom: 15px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
    // Gets rid of tap active state
    -webkit-tap-highlight-color: transparent;
}
// Disabled shared style
.btn.disabled,
.btn-floating.disabled,
.btn-large.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-floating:disabled {
    background-color: $button-bg-color-disabled !important;
    box-shadow: none;
    color: $button-color-disabled !important;
    cursor: default;
    * {
        pointer-events: none;
    }

    &:hover {
        background-color: $button-bg-color-disabled;
        color: $button-color-disabled;
    }
}
// Shared icon styles
.btn,
.btn-floating,
.btn-large,
.btn-flat {
    i {
        font-size: $button-font-size-shared;
        line-height: inherit;
    }
}

// Raised Button
.btn {
    text-decoration: none;
    color: $button-color-raised;
    background-color: $button-color;
    text-align: center;
    letter-spacing: 0.5px;
    @extend .z-depth-1;
    transition: 0.2s ease-out;
    cursor: pointer;

    &:hover {
        background-color: lighten($button-color, 5%);
        @extend .z-depth-1-half;
    }
}

// Floating button
.btn-floating {
    display: inline-block;
    color: $button-color-raised;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: $button-floating-size;
    height: $button-floating-size;
    line-height: $button-floating-size;
    padding: 0;
    background-color: $button-color;
    border-radius: 50%;
    @extend .z-depth-1;
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;

    i {
        width: inherit;
        display: inline-block;
        text-align: center;
        color: $button-color-raised;
        font-size: $button-large-icon-font-size;
        line-height: $button-floating-size;
    }

    &:hover {
        @extend .z-depth-1-half;
    }
    &:before {
        border-radius: 0;
    }
    &.btn-large {
        width: $button-floating-size * 1.5;
        height: $button-floating-size * 1.5;
        i {
            line-height: $button-floating-size * 1.5;
        }
    }
}
// button fix
button.btn-floating {
    border: none;
}

// Fixed Action Button
.fixed-action-btn {
    &.active {
        ul {
            visibility: visible;
        }
    }
    &.horizontal {
        padding: 0 0 0 15px;
        ul {
            text-align: right;
            right: 64px;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            left: initial;
            width: 500px; /*width 100% only goes to width of button container */

            li {
                display: inline-block;
                margin: 15px 15px 0 0;
            }
        }
    }
    position: fixed;
    right: 23px;
    bottom: 23px;
    padding-top: 15px;
    margin-bottom: 0;
    z-index: 998;

    ul {
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        bottom: 64px;
        margin: 0;
        visibility: hidden;

        li {
            margin-bottom: 15px;
        }

        a.btn-floating {
            opacity: 0;
        }
    }
}

// Flat button
.btn-flat {
    box-shadow: none;
    background-color: transparent;
    color: $button-color-flat;
    cursor: pointer;

    &.disabled {
        color: lighten(#999, 10%);
        cursor: default;
    }
}

// Large button
.btn-large {
    @extend .btn;
    height: $button-height * 1.5;
    line-height: 56px;

    i {
        font-size: $button-large-icon-font-size;
    }
}

// Block button
.btn-block {
    display: block;
}
