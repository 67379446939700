#home-page {
  position: relative;
  padding-bottom: 40px;
  max-width: 1920px;
  padding-top: 90px;

  #payment {
    @media (max-width: 1500px) and (min-width: 1224px) {
      display: none;
    }
  }

  #video-modal {
    iframe {
      width: 100%;
      height: 500px;
    }
  }

  #noel-modal {
    max-height: 94%;
    width: 100%;
    max-width: 1102px;
    top: 2% !important;

    .promo-image-container {
      text-align: center;

      img {
        width: 100%;
      }
    }

    .modal-close {
      position: absolute;
      right: 25px;
      z-index: 9;

      i {
        color: #000;
      }
    }
  }

  .mobile-ordering-box {
    @media (max-width: 768px) {
      margin: 0;
      display: flex;
      flex-flow: column;

      #promotion,
      .payment-mobile {
        border-radius: 10px !important;
      }

      .div-container {
        border-radius: 0;

        .div-container-title {
          border-radius: 0;
        }
      }
      .mobile-order-1 {
        order: 1;
      }
      .mobile-order-2 {
        order: 2;
      }
      .mobile-order-3 {
        order: 3;
      }
      .mobile-order-4 {
        order: 4;
      }
      .mobile-order-5 {
        order: 5;
      }
      .mobile-order-6 {
        order: 6;
      }
      .mobile-order-7 {
        order: 7;
      }
      .mobile-order-8 {
        order: 8;
      }
    }
  }
}

@include responsive('normal') {
  #home-page {
    #noel-modal {
      max-width: none;
      width: 90%;

      .promo-image-container {
        img {
          width: 80%;
        }
      }
    }
  }
}

@include responsive('phone to tablet') {
  #home-page {
    #noel-modal {
      .promo-image-container {
        img {
          width: 100%;
        }
      }
    }

    #video-modal {
      iframe {
        width: 100%;
        height: 300px;
      }
    }
  }
}
