#offer-payout {
    @include flexbox(column, nowrap, flex-start);

    h4 {
        .title svg path {
            fill-rule: evenodd;
        }
        .payout-info {
            @include flexbox(column, nowrap, center);

            .global-payout-info {
                @include flexbox(row, nowrap, flex-start);

                .payout span {
                    margin-right: 10px;
                }
                .payout-type {
                    align-self: center;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                }
            }
            #btn-offer-payout-tiers {
                &:before {
                    content: '+';
                    margin-right: 5px;
                }
                font-weight: 400;
                font-size: 1rem;
                line-height: 1rem;
            }
        }
    }
    .ellipsis-element {
        text-decoration: underline;
        &:before {
            content: '...';
            margin-right: 5px;
            margin-left: 5px;
        }
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
        i.material-icons {
            margin-left: 5px;
            vertical-align: middle;
        }
    }
    .conversion-cap {
        margin-right: 5px;
    }
}
