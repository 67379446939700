#offer-creatives-table {
    .copyBtn {
        padding: 0 15px;
        height: 21px;
        line-height: 23px;
    }

    .show-link {
        cursor: pointer;
        text-transform: uppercase;

        i {
            position: relative;
            top: 7px;
            margin-right: 10px;
        }
    }

    .checkbox-field [type='checkbox'] + label {
        padding-left: 15px;
    }

    .title {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .show-preview {
        padding: 0 3px;
        margin: 5px 0;
        display: inline-block;
        border-radius: 3px;
        cursor: pointer;

        i {
            color: #fff;
            width: 22px;
            height: 22px;
            font-size: 22px;
            line-height: 12px;
            position: relative;
            top: 5px;
        }
    }
}
