$br1: 1279px;
$fontSize1: 16px;
$fontSize2: 14px;

#offer-request-modal,
#offer-requested-modal {
  position: relative;

  .offer-request-body {
    .titleBox {
      width: 100%;

      &.OfferRequestSentModal {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .subline {
          max-width: 630px;
        }
      }

      h2 {
        font-size: 36px;
        font-weight: bold;
        margin: 0;

        @media (max-width: $br1) {
          font-size: 25px;
        }
      }
      .subline {
        font-size: $fontSize1;
        margin: 15px 0 0;
        @media (max-width: $br1) {
          font-size: $fontSize2;
        }
      }
    }

    .offer-request-questions {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 50px 0;
      margin: 50px 0;

      @media (max-width: $br1) {
        gap: 30px 0;
        margin: 30px 0;
      }
    }

    .fieldRow {
      width: 100%;

      .input-field,
      .field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 !important;

        label {
          position: relative !important;
          transform: translateY(0) !important;
          left: 0 !important;
          top: 0 !important;
          order: 1;
          font-size: $fontSize1 !important;
          font-weight: 700;
          margin: 0 0 10px;
          transition: 0s !important;
          @media (max-width: $br1) {
            font-size: $fontSize2;
          }
        }

        div:not(.svgsRight) {
          width: 100%;
          order: 2;
        }

        input,
        select {
          margin: 0 !important;
          font-size: $fontSize1;
          @media (max-width: $br1) {
            font-size: $fontSize2;
          }
        }
      }
    }

    .buttonBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $br1) {
        margin: 20px 0;
      }

      button {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        padding: 15px 52px;
        user-select: none;
        color: #ffffff;
      }
    }
  }

  .errorMessage {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #d83d20;
  }
}
