.select-wrapper input.select-dropdown {
  text-overflow: ellipsis;
  width: calc(100% - 10px);
  padding-right: 10px;
  margin: 0 0 20px 0;
}
.select-wrapper {
  &__icon {
    position: relative;
    top: 0.45rem;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 21px;
  }
}

.select-tooltip {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.hide-input {
  display: none;
}

.multiple-select-dropdown li.disabled:first-of-type,
.select-dropdown li.disabled:first-of-type {
  display: none;
}

.dropdown-content li > label,
.dropdown-content li > span {
  padding: 14px 16px !important;
}

.dropdown-content li {
  background-color: #fff;
  &:hover {
    background-color: $cr-green;
    label {
      color: #fff;
    }
    [type='checkbox'] + span:before {
      border-color: #ffffff;
    }
    span {
      color: #fff;
    }
    [type='checkbox'] + label:before {
      border-color: #ffffff;
    }
  }
}

body.mobile-device {
  .select-wrapper {
    select.multiple {
      display: none;
    }
    select {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }
}
.input-field.select-field label.disabled {
  color: rgba(0, 0, 0, 0.26);
}

.dropdown-content {
  li > span > label {
    top: 0.8rem;
    left: 15px;
  }
  // Checkbox Styles
  [type='checkbox'] {
    &:not(:disabled) + span {
      cursor: pointer;
    }
    // Text Label Style
    + span {
      position: relative;
      padding-left: 35px;
      display: inline-block;
      height: 25px;
      line-height: 25px;
      font-size: 1rem;

      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
      -khtml-user-select: none; /* webkit (konqueror) browsers */
      -ms-user-select: none; /* IE10+ */
    }

    /* checkbox aspect */
    + span:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      z-index: 0;
      border: 2px solid $radio-empty-color;
      border-radius: 1px;
      margin-top: 2px;
    }

    &:not(:checked):disabled + span:before {
      border: none;
      background-color: $input-disabled-color;
    }
  }

  [type='checkbox']:checked {
    + span:before {
      top: -4px;
      left: -3px;
      width: 12px;
      height: 22px;
      border-color: $radio-fill-color;
      border-top: none;
      border-left: 3px;
      transform: rotate(40deg);
      backface-visibility: hidden;
      transform-origin: 100% 100%;
    }

    &:disabled + span:before {
      border-color: $input-disabled-color;
    }
  }

  /* Indeterminate checkbox */
  [type='checkbox']:indeterminate {
    + span:before {
      left: -10px;
      top: -11px;
      width: 10px;
      height: 22px;
      border-color: $radio-fill-color;
      border-top: none;
      border-left: none;
      border-bottom: none;
      transform: rotate(90deg);
      backface-visibility: hidden;
      transform-origin: 100% 100%;
    }

    // Disabled indeterminate
    &:disabled + span:before {
      border-color: $input-disabled-color;
      background-color: transparent;
    }
  }

  // Filled in Style
  [type='checkbox'].filled-in {
    // General
    + span:after {
      border-radius: 2px;
    }
    + span:before,
    + span:after {
      content: '';
      left: 0;
      position: absolute;
      /* .1s delay is for check animation */
      transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
      z-index: 1;
    }

    // Unchecked style
    &:not(:checked) + span:before {
      width: 0;
      height: 0;
      border: 3px solid transparent;
      left: 6px;
      top: 10px;

      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);
      -webkit-transform-origin: 20% 40%;
      transform-origin: 100% 100%;
    }

    &:not(:checked) + span:after {
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid $radio-empty-color;
      top: 0px;
      z-index: 0;
    }

    // Checked style
    &:checked {
      + span:before {
        top: 0;
        left: 1px;
        width: 8px;
        height: 13px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $input-bg-color;
        border-bottom: 2px solid $input-bg-color;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);

        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }

      + span:after {
        top: 0px;
        width: 20px;
        height: 20px;
        border: 2px solid $secondary-color;
        background-color: $secondary-color;
        z-index: 0;
      }
    }
    // Disabled style
    &:disabled:not(:checked) + span:before {
      background-color: transparent;
      border: 2px solid transparent;
    }

    &:disabled:not(:checked) + span:after {
      border-color: transparent;
      background-color: $input-disabled-solid-color;
    }

    &:disabled:checked + span:before {
      background-color: transparent;
    }

    &:disabled:checked + span:after {
      background-color: $input-disabled-solid-color;
      border-color: $input-disabled-solid-color;
    }
  }
}
