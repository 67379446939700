.copyBtn {
    float: right;
    &.disabled {
        opacity: 0.5 !important;
    }

    font-size: 18px;
    padding: 0 5px;

    i,
    .text {
        display: inline-block;
        position: relative;
    }

    i {
        font-size: 24px;
    }

    .text {
        font-size: 20px;
    }

    &.disabled {
        cursor: not-allowed;

        .text,
        i {
            color: $cr-grey65;
        }
    }
}
