.info {
    display: inline-block;
    position: relative;
    color: $cr-grey65;
    text-transform: uppercase;
    height: 24px;
    line-height: 24px;
    margin: 0 15px;

    @media (max-width: $screen-xs) {
        margin: 0 3px;
    }

    i {
        color: inherit;
        display: inline-block;
        margin: 0;
        position: static;
    }

    &:hover {
        color: $cr-dark-grey;
    }

    &.centered {
        vertical-align: middle;
    }
}
