.stats-add-column-modal {
    @media (max-width: 992px) {
        width: 90%;
    }
    .stats-modal-content {
        padding: 0px 30px;
        max-height: calc(70vh - 90px);
        overflow-y: scroll;
        overflow-x: hidden;
        @media (max-width: 576px) {
            padding: 0px;
            max-height: calc(80vh - 90px);
        }
        .modal-close {
            display: block;
            position: absolute;
            top: 21px;
            right: 30px;
            z-index: 1;
            font-size: em(20);
            font-weight: 300;
        }

        .input-field {
            margin-top: 0;

            i {
                top: 10px;
            }
        }
        .checkbox-field {
            min-height: 42px;
            padding-bottom: 0.5em;
        }
    }

    .modal-footer.warning {
        justify-content: space-between;
    }
    .modal-footer {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-top: 20px;
        text-align: right;
        height: auto;

        .modal-warning {
            margin: 0 6px;
        }

        @media (max-width: 992px) {
            .modal-warning {
                max-width: 100%;

                margin: 6px 0;
            }
            flex-flow: column;
        }

        @media (min-width: 992px) {
            .modal-action {
                width: 100px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
