// Z-levels
.z-depth-0 {
    box-shadow: none !important;
}
.z-depth-1 {
    box-shadow: $shadow1;
}
.z-depth-1-half {
    box-shadow: $shadow1-half;
}
.z-depth-2 {
    box-shadow: $shadow2;
}
.z-depth-3 {
    box-shadow: $shadow3;
}
.z-depth-4 {
    box-shadow: $shadow4;
}
.z-depth-5 {
    box-shadow: $shadow5;
}

.hoverable {
    transition: box-shadow 0.25s;
    box-shadow: 0;
}

.hoverable:hover {
    transition: box-shadow 0.25s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

ul {
    padding: 0;
    li {
        list-style-type: none;
    }
}

// classic clearfix
.clearfix {
    clear: both;
}

.filter-form {
    padding: 0 15px;
}

// Badges
span.badge {
    min-width: 3rem;
    padding: 0 6px;
    text-align: center;
    font-size: 1rem;
    line-height: inherit;
    color: color('grey', 'darken-1');
    position: absolute;
    right: 15px;
    box-sizing: border-box;

    &.new {
        font-weight: 300;
        font-size: 0.8rem;
        color: #fff;
        background-color: $badge-bg-color;
        border-radius: 2px;
    }
    &.new:after {
        content: ' new';
    }
}

.badge-tag {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    text-align: center;
    font-size: 1rem;
    line-height: inherit;
    color: #fff;
    box-sizing: border-box;
    margin-right: 10px;
    background-color: $cr-green;
    cursor: pointer;

    .material-icons {
        display: inline-block;
        float: right;
        font-size: 1em;
        margin-top: 3px;
        margin-left: 5px;
    }

    &:hover {
        .material-icons {
            color: #000;
        }
    }
}

// Tables
table,
th,
td {
    border: none;
}

table {
    width: 100%;
    display: table;

    &.bordered > thead > tr,
    &.bordered > tbody > tr {
        border-bottom: 1px solid $table-border-color;
    }

    &.striped > tbody {
        > tr:nth-child(odd) {
            background-color: $table-striped-color;
        }

        > tr > td {
            border-radius: 0px;
        }
    }

    &.highlight > tbody > tr {
        transition: background-color 0.25s ease;
        &:hover {
            background-color: $table-striped-color;
        }
    }

    &.centered {
        thead tr th,
        tbody tr td {
            text-align: center;
        }
    }
}

thead {
    border-bottom: 1px solid $table-border-color;
}

td,
th {
    padding: 15px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
}

// Responsive Table
@media #{$medium-and-down} {
    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;

        th,
        td {
            margin: 0;
            vertical-align: top;
        }

        th {
            text-align: left;
        }
        thead {
            display: block;
            float: left;

            tr {
                display: block;
                padding: 0 10px 0 0;

                th::before {
                    content: '\00a0';
                }
            }
        }
        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            tr {
                display: inline-block;
                vertical-align: top;
            }
        }
        th {
            display: block;
            text-align: right;
        }
        td {
            display: block;
            min-height: 1.25em;
            text-align: left;
        }
        tr {
            padding: 0 10px;
        }

        /* sort out borders */
        thead {
            border: 0;
            border-right: 1px solid $table-border-color;
        }

        &.bordered {
            th {
                border-bottom: 0;
                border-left: 0;
            }
            td {
                border-left: 0;
                border-right: 0;
                border-bottom: 0;
            }
            tr {
                border: 0;
            }
            tbody tr {
                border-right: 1px solid $table-border-color;
            }
        }
    }
}

.circle {
    border-radius: 50%;
}

.anchor {
    position: relative;
    top: -80px;
}
