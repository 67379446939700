#profile-referrals {
  max-width: 1920px;
  padding-bottom: 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 38px 0 20px 0;
    @media (max-width: 1300px) {
      flex-flow: column;
    }
    h1 {
      font-size: 2.5rem;
      margin: 0;
      @media (max-width: 1300px) {
        margin-bottom: 22px;
      }
      .info {
        margin-left: 0.5rem;
        @media (max-width: 1300px) {
          margin-left: 20px;
        }
        svg {
          height: 27px;
          width: 27px;
        }
      }
    }

    .checkmark-list {
      display: flex;
      justify-content: space-between;
      gap: 70px;
      @media (max-width: 1300px) {
        flex-flow: column;
        justify-content: flex-start;
        gap: 18px;
      }
      .option-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1300px) {
          justify-content: flex-start;
        }
        svg {
          margin-right: 0.5rem;
        }
        color: #212529;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  h2 {
    color: #212529;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
    @media (max-width: 769px) {
      font-size: 2.3rem;
    }
  }

  h3 {
    font-size: 0.85rem;
    color: $cr-greyText;
    margin-bottom: 0.4rem;
    margin-top: 23px;
  }

  .referrals-explanation {
    display: flex;
    margin-bottom: 15px;
    max-height: 400px;

    @media (max-width: 1300px) {
      flex-flow: column;
      max-height: 100%;
    }

    h2 {
      font-weight: bold;
      font-size: 30px;
      @media (max-width: 540px) {
        font-size: 28px;

        .mobile {
          display: none;
        }
      }
    }
    .bg {
      width: 50%;
      @media (max-width: 1200px) {
        width: 100%;
        display: block;
        border-radius: 0.5rem 0.5rem 0 0;
      }
    }
    .descr {
      display: flex;
      align-items: center;
      width: 50%;
      border-radius: 0 0.5rem 0.5rem 0;

      background: #00bd00
        repeating-linear-gradient(-40deg, #6dd400 -10%, #2ac008 40%, #00bd00 40%, #2ac008 80%, #00bd00 80%, #6dd400 100%);

      @media (max-width: 1200px) {
        border-radius: 0 0 0.5rem 0.5rem;
        width: 100%;
      }
      .descr-box {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 85%;

        p {
          margin: 0;
          font-size: 15px;
          color: #fff;
          padding-bottom: 10px;

          svg {
            margin-right: 5px;
          }

          @media (max-width: 720px) {
            .mobile {
              display: none;
            }
          }

          @media (max-width: 540px) {
            padding-bottom: 15px;
            font-size: 14px;
          }

          .bold {
            font-weight: 700;
          }

          &:last-child {
            padding-top: 25px;

            @media (max-width: 540px) {
              padding-top: 15px;
            }
          }

          .knowledge-link {
            color: #fff;
            text-decoration: underline;
            transition: all 0.3s ease-out;
            &:hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
  }

  .banners {
    @include flexbox(row, nowrap, flex-start);
    gap: 16px;
    justify-content: center;
    @media (max-width: 768px) {
      flex-flow: column;
    }

    .banner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      border-radius: 0.75rem;
      margin-bottom: 2rem;
      position: relative;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
      @media (max-width: 768px) {
        width: 100%;
      }
      .header-banner {
        height: 212px;
        width: 100%;
        padding: 10px;
        border-radius: 0.75rem 0.75rem 0 0;
        &.fansrevenue {
          background: linear-gradient(180deg, #530e8c 0%, #190661 100%);
          position: relative;
          .info-icon {
            position: absolute;
            width: 27px;
            height: 27px;
            cursor: pointer;
          }
          .fr-logo {
            max-width: 371px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 576px) {
              width: 250px;
            }
          }

          .powered-by {
            position: absolute;
            top: 15px;
            right: 12px;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        &.crakrevenue {
          padding: 0;
          background: url(/img/cr-referrals-bg.png);
          background-size: cover;
          position: relative;
          svg {
            max-width: 350px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 576px) {
              width: 250px;
            }
            .cls-1 {
              font-family: Montserrat-Black, Montserrat;
              font-weight: 800;
            }

            .cls-1,
            .cls-2 {
              fill: #fff;
              font-size: 54.61px;
            }

            .cls-3 {
              letter-spacing: -0.08em;
            }

            .cls-4 {
              letter-spacing: -0.02em;
            }

            .cls-5 {
              letter-spacing: -0.06em;
            }

            .cls-6 {
              letter-spacing: -0.08em;
            }

            .cls-7 {
              letter-spacing: 0em;
            }

            .cls-2 {
              font-family: Montserrat-Light, Montserrat;
              font-weight: 300;
            }

            .cls-8 {
              letter-spacing: 0.02em;
            }

            .cls-9 {
              letter-spacing: 0em;
            }

            .cls-10 {
              letter-spacing: -0.03em;
            }

            .cls-11 {
              letter-spacing: 0em;
            }

            .cls-12 {
              fill: #00b300;
              stroke-width: 0px;
            }
          }
        }
      }

      .black-pills-box {
        display: flex;
        flex-flow: row;
        gap: 10px;
        .black-pills {
          width: fit-content;
          display: flex;
          padding: 5px 25px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 60px;
          background: #212529;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          @media (max-width: 1150px) {
            padding: 5px 16px;
            font-size: 16px;
          }
        }
      }
      .actions-section {
        flex: unset;
        .copyBtn {
          display: flex;
          max-width: 219px;
          height: 46px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 0px 12px 12px 0px;
        }
      }

      .copy-value-widget {
        @media (max-width: 768px) {
          flex-direction: row;
        }
      }

      .copy-value-display {
        height: 3rem;
        line-height: 3rem;
        border-radius: 5px 0 0 5px;
        @media (max-width: 768px) {
          width: 100%;
          border-radius: 5px;
        }
      }

      .text-container {
        padding: 20px 12px;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        gap: 16px;
        flex-grow: 1;

        h2 {
          @media (max-width: 576px) {
            font-size: 24px;
          }
        }
        h2,
        p {
          margin: 0;
        }
        p {
          flex-grow: 1;
          color: #212529;
          font-size: 14px;
          font-weight: 400;
          strong {
            font-weight: 800;
          }
        }

        .copy-value-display {
          display: flex;
          width: 454px;
          height: 46px;
          padding: 15px 20px;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 12px 0px 0px 12px;
          background: #212529;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
        }

        .details {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          height: 3rem;
          width: 20%;
          border-radius: 5px;
          float: right;
          padding: 0;
          justify-content: center;
          align-self: flex-end;
          margin-top: 20px;

          @media (max-width: 769px) {
            width: 40%;
          }
        }
      }
    }
  }

  .help-section {
    display: flex;
    width: 100%;
    padding: 25px 10px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: #212529;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 19px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .need-help-box {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        @media (max-width: 768px) {
          width: 50px;
          height: 50px;
        }
      }
      .need-help-text {
        display: flex;
        flex-flow: column;
        gap: 5px;
        color: #fff;
        h2 {
          color: #fff;
          @media (max-width: 768px) {
            font-size: 24px;
          }
        }
        h2,
        p {
          margin: 0;
        }
      }
    }

    .btn {
      border-radius: 12px;
      font-weight: 700;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  #referrals-filters {
    @include flexbox(row, nowrap, space-between);

    #search-filters-line {
      @media screen and (max-width: 1399px) {
        display: none;
      }

      @include flexbox(row, nowrap, flex-start);

      .filter-by {
        align-self: center;
        color: $cr-darker-pale-grey;
        font-size: 1.1rem;
        margin-right: 1.1rem;
        min-width: 75px;
      }
    }

    #search-filters-dropdown {
      @media screen and (min-width: 1400px) {
        display: none;
      }
    }

    .hidden-referrals-search {
      visibility: hidden;
    }
  }

  #referrals-infos {
    position: relative;

    ul.tabs {
      @include flexbox(row, nowrap, flex-start);
      margin: 0;
      overflow: hidden;

      li {
        @include flexbox(row, nowrap, flex-start);
        & > * {
          align-self: center;
          margin: 0.5rem;
        }
        align-self: flex-end;
        background-color: #d8d8d8;
        border-radius: 0.25rem 0.25rem 0 0;
        color: $cr-grey49;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        height: 3rem;
        padding: 0 1rem;
        &.selected {
          background-color: #ffffff;
          height: 3.5rem;
        }
        svg g {
          fill: $cr-grey49;
        }
      }
    }

    .viewmode {
      position: absolute;
    }
  }

  #referrals-cards {
    @include flexbox(column, nowrap, flex-start);
    .cards {
      @include flexbox(row, wrap);
      justify-content: center;
      @include flexbox(row, wrap);
      .referral-card {
        flex: 1;
        margin: 1rem;
        text-align: center;
        width: 20rem;
        min-width: 20rem;
        max-width: 20rem;

        .company {
          font-size: 1rem;
          font-weight: 300;
          color: $cr-grey49;
          margin-bottom: 1em;
        }

        .amount {
          font-size: 1.15rem;
          font-weight: 500;
          color: $cr-grey49;
          margin-bottom: 5px;
        }

        .joindate {
          border-top: 1px solid rgba(0, 0, 0, 0.35);
          font-size: 0.85rem;
          font-weight: 400;
          color: $cr-dark-grey;
          margin-top: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }
}

.fansrevenue-referrals-modal {
  border-radius: 5px;
  max-width: 784px;

  .modal-box {
    @media (max-width: 576px) {
      overflow-y: scroll;
      max-height: 75vh;
    }
  }

  .header-banner {
    height: 212px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    &.fansrevenue {
      background: linear-gradient(180deg, #530e8c 0%, #190661 100%);
      position: relative;
      .fr-logo {
        max-width: 371px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 576px) {
          width: 250px;
        }
      }
    }
  }

  .content-box {
    h2 {
      color: #1e1e1e;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.88px;

      @media (max-width: 768px) {
        font-size: 25.734px;
      }
    }

    p {
      color: #1e1e1e;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      letter-spacing: -0.176px;

      @media (max-width: 768px) {
        font-size: 15px;
      }
      a {
        color: #1e1e1e;
        text-decoration-line: underline;
      }

      .bold {
        font-weight: 800;
      }
    }
  }

  .btn {
    border-radius: 0.75rem;
    height: 59px;
    width: 100%;
    box-shadow: unset;
    font-weight: 700;
  }
}
