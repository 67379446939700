.dimmer {
  animation: renderIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 60000;
  cursor: pointer;
}

.hide-modal {
  animation: renderOut 0.5s ease-out !important;
  animation-fill-mode: forwards;
}

.modal-component {
  animation: renderIn 0.5s ease-in;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: unset;
  background-color: #fafafa;
  padding: 0;
  width: 70%;
  margin: auto;
  overflow: hidden;
  border-radius: 25px;
  z-index: 60000;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  &.OfferRequestModal,
  &.OfferRequestSentModal {
    width: calc(100% - 50px) !important;
    max-height: calc(100% - 50px) !important;
    max-width: 1024px;
    padding: 50px 35px;
    padding-bottom: 0;
    border-radius: 0;

    @media (max-width: 1023px) {
      padding: 40px 25px;
      padding-bottom: 0;
    }

    .modal-inner {
      padding: 0;
    }

    .modal-close {
      right: 0 !important;
      top: 0 !important;
    }
  }

  &.OfferRequestSentModal {
    .modal-close {
      display: none !important;
    }
  }

  @media (max-width: 992px) {
    overflow: auto;
  }

  @media (max-width: 481px) {
    width: 90%;
  }

  .modal {
    overflow-y: unset !important;
  }

  .modal-inner {
    padding: 24px;
    position: relative;

    .modal-close {
      position: absolute;
      right: 20px;
      z-index: 101;
      color: #000;
      font-size: 1.4285714286em;
      font-weight: 300;
      cursor: pointer;
    }
    .modal-apply {
      color: #fff;
    }

    h1 {
      font-size: 1.5714285714em;
      padding: 5px 0 24px;
      border-bottom: 1px solid #979797;
    }

    h2 {
      font-size: 1.3em;
      color: #000;
      margin-bottom: 25px;
      font-weight: 600;
    }
  }

  .modal-warning {
    display: flex;
    align-items: center;
    background-color: #6a6a6a;
    margin: 6px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.1;
    color: #fff;
    text-align: left;

    @media (max-width: 481px) {
      margin-right: 0;
    }

    img {
      width: 15px;
      margin-right: 8px;
    }
  }

  .modal-apply {
    margin: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 481px) {
      display: unset;
    }
  }

  .modal-footer-multiple {
    position: relative;
    padding: 0 30px;
    text-align: right;
    height: auto;
    background-color: #fafafa;
    width: 100%;

    @media (max-width: 481px) {
      display: block !important;
    }
  }
}

@keyframes renderIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes renderOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
