.jerkmate-advantages {
    position: relative;
    display: flex;
    margin-top: 100px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 30px;
    }

    .shape-blue,
    .shape-blue-rotate {
        position: absolute;
        top: -250px;
        left: -800px;
        z-index: 0;

        @media (max-width: 768px) {
            position: absolute;
            z-index: 0;
            height: 1500px;
        }
    }

    .shape-blue {
        @media (max-width: 768px) {
            top: -150px;
            left: -400px;
            height: 1500px;
        }
    }

    .shape-blue-rotate {
        display: none;
        @media (max-width: 768px) {
            display: block;
            top: 300px;
            left: -900px;
            transform: rotate(310deg);
            height: 2000px;
        }
    }

    .world-freemium-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fb4d94;
        border-radius: 10px;
        padding: 20px;
        max-width: 290px;
        margin-right: 40px;

        @media (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 40px;
            justify-content: unset;
            max-width: unset;
        }

        .world-freemium-txt {
            font-size: 32px;
            font-weight: 900;
            color: #fff;
            margin: 0;

            @media (max-width: 768px) {
                font-size: 28px;
                width: 100%;
                margin-right: 0;
            }
        }

        .promote-btn {
            background-color: #fb4d94;
            color: #fff;
            border: 1px #fff solid;
            margin-top: 40px;
            width: auto;

            @media (max-width: 768px) {
                font-size: 14px;
            }

            &:hover {
                color: #fb4d94;
                background-color: #fff;
            }
        }
    }

    .advantages-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .advantages-list-item {
            display: flex;

            @media (max-width: 992px) {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .icon {
                width: 50px;
                height: 50px;
            }

            .advantages-content {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                @media (max-width: 768px) {
                    margin-top: 15px;
                    margin-left: 0;
                }

                h2 {
                    font-size: 22px;
                    font-weight: bold;
                    margin-top: 0;
                    margin-bottom: 5px;

                    @media (max-width: 768px) {
                        font-size: 18px;
                        margin-top: 5px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: normal;

                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }

            p {
                margin: 0;
            }
        }
    }
}
