.copy-value-widget {
    @include flexbox(column, wrap, flex-start);

    & > * {
        flex: 1;
        min-width: 150px;
        height: 3.5rem;
        line-height: 3.5rem;
    }

    .your-link {
        @include flexbox(row, wrap, space-around);

        text-align: center;
        color: #fff;
        font-size: 1.25rem;
        text-transform: uppercase;
        * {
            align-self: center;
        }
    }

    .copy-value-display {
        padding: 0 1.5rem;
        border: 0;
        outline: 0;
        background-color: $light-grey;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .actions-section {
        @include flexbox(row, nowrap, flex-start);

        & > * {
            flex: 1;
            padding: 0;
            min-width: 75px;
            height: 3.5rem;
            line-height: 3.5rem;
            text-align: center;

            i {
                padding: 0;
                font-size: 3rem;
                color: #fff;
            }
        }

        .copy-btn {
            background-color: $cr-green;
        }
        .btn-delete {
            background-color: $warning-color !important;
            i {
                font-size: 2.5rem;
            }
        }
    }

    @include responsive('tablet at least') {
        @include flexbox(row, wrap, flex-start);

        & > *:not(.copy-value-display) {
            flex: 0;
        }
    }
}
