.jerkmate-offers {
  position: relative;
  margin-top: 150px;

  .offers-header {
    text-align: center;
    position: relative;

    .shape-zigzag {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

      @media (max-width: 768px) {
        left: 55%;
      }
    }

    h2 {
      position: relative;
      font-size: 36px;
      font-weight: 900;
      margin-bottom: 150px;
    }
  }

  .jerkmate-offers-list {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 30px 0;
    justify-content: space-between;

    @media (max-width: 1250px) {
      margin: 100px 0 0;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    a {
      &:nth-last-child(1):nth-child(odd) {
        flex: 100%;
        display: flex;
        justify-content: center;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .jerkmate-offers-list-item {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      width: 435px;
      margin-bottom: 50px;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      color: #191862;

      @media (max-width: 1250px) {
        max-width: 390px;
        overflow: unset;
      }

      @media (max-width: 1100px) {
        max-width: 320px;
      }

      @media (max-width: 991px) {
        max-width: 390px;
      }

      @media (max-width: 850px) {
        max-width: 345px;
      }

      @media (max-width: 768px) {
        max-width: unset;
        width: 100%;
      }

      @media (max-width: 1080px) {
        .payout {
          font-weight: bold;
        }
      }

      .offer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .new-tag {
          background-color: #fb4d94;
          padding: 5px 10px;
          border-radius: 17px;
          font-size: 13px;
          font-weight: 900;
          color: #fff;
        }
      }

      svg {
        overflow: unset;
      }
      &:hover {
        .jerkmate-offers-btn {
          opacity: 1;
          margin-top: 10px;
        }

        .payout {
          font-weight: bold;
        }
      }
      h3 {
        font-size: 18px;
        font-weight: 900;
      }

      p {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
      }

      .jerkmate-offers-btn {
        opacity: 0;
        background-color: #03bc04;
        text-align: center;
        padding: 10px 0;
        border-radius: 10px;
        margin-top: -51px;
        color: #fff;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        @media (max-width: 1080px) {
          overflow: unset;
          margin-top: 10px;
          opacity: 1;
        }
      }
    }
  }
}
