#application {
  &.login {
    #page-wrap {
      overflow: hidden;
    }
  }
}

.outer-login {
  .offline-container {
    max-width: 1500px !important;
    margin: 0 auto !important;

    .back-to-website {
      margin-left: 30px;
      @media (max-width: 767px) {
        margin-left: 50px;
      }

      @media (max-width: 480px) {
        margin-left: 30px;
      }
    }
  }
}

#login-page {
  margin: 0;
  display: flex;
  h1 {
    color: $cr-grey;
    font-size: $h2-fontsize;
    margin-bottom: 1rem;
    margin-top: 4.1rem;
  }

  .image-container {
    position: relative;
    max-height: 630px;
    background-size: cover;
    text-align: center;

    &.image-sign-up {
      background: url(/img/nice-to-meet-you.jpg) center center no-repeat;
    }

    &.image-login {
      background: url(/img/affiliates/whale-login.jpg) center center no-repeat;
      border-radius: 20px;
      width: 50%;
      background-size: cover;
      margin-left: 30px;
    }

    .image-sign-up {
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
      a.image-link {
        text-decoration: underline;
        cursor: pointer;
        color: #fff;
        margin-left: 4px;
      }
    }

    .image-text {
      color: #fff;
      font-size: 46px;
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      line-height: 1;
      padding: 0 50px;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);

      &:before {
        content: '\201D';
        font-family: Georgia, serif;
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        top: -70px;
        left: calc(50% - 15px);
        transform: rotate(180deg);
      }

      &:after {
        content: '\201D';
        font-family: Georgia, serif;
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        bottom: -70px;
        left: calc(50% - 15px);
      }
    }

    img {
      display: block;
      margin-left: -15px;
      width: 100%;
      height: auto;
    }
  }

  .text-container {
    margin: 0 30px;
    min-height: 540px;
    width: 50%;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
    }

    .google-btn-box {
      padding-left: 15px;
      padding-right: 15px;
    }

    .input-field,
    .social-login {
      margin: 20px 0 0 0;
      padding: 0 100px;

      @media (max-width: 991px) {
        padding: 0 25px;
      }

      @media (max-width: 767px) {
        padding: 0 50px;
      }
      @media (max-width: 480px) {
        padding: 0 45px;
      }
      input {
        margin: 0;
      }
      label {
        position: initial;
        &.active {
          transform: none;
        }
      }
      &.error {
        label {
          color: initial;
        }
      }
    }
  }

  .registration-links {
    padding: 60px 0 50px 0;
    text-align: center;
    color: $cr-greyText;
    a {
      display: inline-block;
      padding: 0 30px;
      font-size: 30px;
      border-right: 1px solid $cr-pale-grey;
      position: relative;
      color: $cr-grey;
      @media (max-width: 991px) {
        padding: 0;
      }
      &:last-child {
        border-right: none;
      }
      span {
        cursor: pointer;
        display: inline-block;
        &.active {
          font-weight: bold;
        }
      }
    }
  }

  .password-reset {
    padding: 0 100px;
    margin: 20px 0 0 0;
    @media (max-width: 991px) {
      padding: 0 25px;
    }

    @media (max-width: 767px) {
      padding: 0 50px;
    }
    a {
      color: $cr-greyText;

      &:hover {
        color: #000;
      }
    }
  }

  .form-buttons {
    margin-top: 40px;
  }

  .remember-me {
    margin-top: 20px;
  }

  .btn {
    padding: 15px 75px;
    line-height: 1.3em;
    height: auto;
  }

  .remember-me {
    margin-bottom: 20px;

    input:not(:checked) {
      & + label {
        color: $cr-greyText;

        &:after {
          border-color: $cr-greyText;
        }
      }
    }
  }

  .login-errors {
    color: $cr-red-error;
    position: absolute;
    width: 100%;
    text-align: center;
  }
  .barre {
    max-width: 157px;
    margin: 0 auto;
    border: none;
    height: 1px;
    background-color: #d2d2d2;
    margin-bottom: 22px;
  }

  .btn {
    @media (max-width: 991px) {
      padding: 0.7em 2em !important;
    }
  }
  .simple-signup__google-bot-border {
    color: #333;
    border-bottom: 1px solid #dadada;
    span {
      font-size: 13px;
    }
  }
}

@include responsive('tablet') {
  #login-page {
    .registration-links {
      span {
        padding: 0 5px;
      }
    }

    .image-container {
      min-height: 240px;

      .image-text {
        top: 100px;

        &:before {
          top: -70px;
          left: 40px;
        }

        &:after {
          bottom: auto;
          right: 40px;
          left: auto;
          top: -40px;
        }
      }
    }
  }
}

@include responsive('phone to tablet') {
  #login-page {
    .registration-links {
      span {
        padding: 0 10px;
      }
    }
  }
}

@include responsive('phone') {
  #login-page {
    .registration-links {
      span {
        padding: 0 10px;
        border-right: none;
      }
    }

    .text-container {
      margin: 0;

      .signup-container {
        padding: 20px;

        h4 {
          font-size: 28px;
        }
      }
    }
  }
}
