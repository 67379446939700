#offers {
  padding-top: 100px;
  padding-bottom: 30px;
  max-width: 1920px;

  #newsbox {
    margin-bottom: 1.75rem;
  }

  h1 {
    margin: 0;
    font-size: em(36);
  }

  #viewmode-component {
    padding-left: 0;
  }

  #pagination {
    padding-right: 0;
  }

  .switch {
    margin: 0 1rem 1rem 0;
    float: left;
  }

  .audience {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .offers-list-header {
    display: flex;
    align-items: center;
    margin: 10px 0;
    @media (max-width: 992px) {
      justify-content: flex-end;
      margin: 20px 0;
    }
    #pagination {
      margin-left: auto;
      display: flex;
      align-items: center;
      .clearfix {
        display: flex;
        align-items: center;
      }

      .rowsPerPage {
        display: flex;
        align-items: center;
        .text {
          padding: 0;
        }

        .input-field {
          margin-bottom: 0;
        }
      }
      .pagination-select {
        width: 70px;
      }
    }

    .sort.customSelects {
      width: fit-content;
      padding-left: 30px;

      @media (max-width: 992px) {
        padding-right: 7px;
      }
      .fields {
        .field {
          margin-top: 10px;
          display: flex;
          flex-flow: row;
          align-items: center;
          gap: 12px;

          .labelBox {
            white-space: nowrap;
            margin-bottom: 0;
            width: fit-content;
            label {
              color: #000;
              font-size: 15px;
              font-weight: 400;
              line-height: 18.2px; /* 121.333% */
            }
          }

          .inputRow {
            border: 0;
            .inputBox {
              border: 0;
              input {
                border-radius: 5px;
                border: 1px solid #8b8b8b;
                padding: 5px 10px;
                color: #252525;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.2px;
                text-transform: capitalize;
              }

              .dropdown {
                border-radius: 5px;
                overflow-y: auto;
                box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
                li {
                  border-bottom: 1px solid #e3e3e3;
                }
              }
            }
          }
        }
      }
    }
  }
}

#offers-video-icon-button {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  top: 3px;
}

#payoutType-video-icon {
  & > span {
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    width: 17px;
    height: 17px;
  }

  svg {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 3px;
  }
}

#offers-video-icon {
  margin-left: 10px;
}

#payout-type-video-modal-button {
  position: relative;
  top: 7px;
  padding-left: 3px;
  cursor: pointer;
}

@include responsive('phone to tablet') {
  #offers {
    .switch {
      float: none;
      margin-bottom: 1rem;

      label {
        .lever {
          float: left;
          margin: 0.2rem 1rem 0 0;
        }
      }
    }
  }
}

.offer-tabs {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e6e7eb;

  @media (max-width: 400px) {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menuTab {
    padding: 5px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    white-space: nowrap;

    &.selected {
      color: #00bd00;
      border-color: #00bd00;
      cursor: default;
    }
  }
}
