$green: #00bd00;
$grey: #e6e7eb;
$borderRadius: 8px;
$tagSpacing: 15px;
$mobileTabSpacing: 15px;
$mobileTitleHeight: 80px;
$mobileBottomCtaBoxHeight: 80px;

$br1: 991px;
$mWidthbr1: 992px;

@mixin searchAndTitleWidth-style() {
  width: calc(100% - 30px);
}

@mixin searchbar-style() {
  .searchBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 40px;
    border-radius: $borderRadius;
    border: 1px solid $grey;

    input,
    input:not(.select-dropdown):not([id^='tellUsMore']):focus {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 0 0 35px;
      margin: 0;
      font-size: 14px;
      box-shadow: none !important;
      &:focus {
        outline: none !important;
        border: 0 !important;
        box-shadow: none !important;
      }
    }

    svg {
      width: 13px;
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

@mixin scrollable-style() {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #898989;
  }
}

#offersFilterLegacy {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: $br1) {
    padding: 0 15px 10px;
    overflow: hidden;
  }

  .searchMainBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0;

    .clear {
      margin-left: 15px;
    }

    @media (max-width: $br1) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @include searchbar-style();

    .searchBar {
      width: 100%;
      max-width: 200px;
      flex-shrink: 0;
      @media (max-width: $br1) {
        max-width: none;
      }
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: $br1) {
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }

    .tag {
      height: 30px;
      background: #00bd00;
      color: #ffffff;
      font-size: 14px;
      padding: 0 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      position: relative;
      z-index: 10;
      margin-right: 10px;

      @media (max-width: $br1) {
        margin-top: $tagSpacing;
      }

      &:first-child {
        margin-left: $tagSpacing;
        @media (max-width: $br1) {
          margin-left: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        fill: #ffffff;
        width: 12px;
        margin: 0 0 0 9px;
      }
    }
  }

  .dropdownTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    @media (min-width: $mWidthbr1) {
      @include searchAndTitleWidth-style();
    }

    @media (max-width: $br1) {
      width: 100%;
      height: $mobileTitleHeight;
      padding: 0 $mobileTabSpacing;
    }

    &.mainTitle {
      @media (min-width: $mWidthbr1) {
        display: none;
      }
    }

    .text {
      font-size: 20px;
      font-weight: 500;
      cursor: default;
    }

    .clear {
      font-size: 14px;
      color: #03bc04;
      margin: 0 0 0 15px;
      cursor: pointer;
    }
  }

  .dropdownOuterBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &.greyTabs {
      @media (min-width: $mWidthbr1) {
        .scrollableGreyTabs {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
        }
      }
    }

    &.filtersDropdown {
      @media (min-width: $mWidthbr1) {
        width: 270px;
        padding: 15px 0 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 40px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
        user-select: none;
        z-index: 100;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        display: none;

        &.active {
          display: flex;
          height: auto;
          opacity: 1;
        }

        &.alignRight {
          right: 0;
          left: auto;
        }

        .scrollable {
          max-height: 190px;
          padding: 15px 10px;
          padding-top: 0;
          margin: 15px 0 0;
          @include scrollable-style();
        }
      }

      @include searchbar-style();

      .searchBar {
        margin: 15px 0 0;
        @media (max-width: $br1) {
          margin-bottom: 5px;
        }
        @include searchAndTitleWidth-style();
      }
    }

    .mobileCtaBottom {
      width: 100%;
      height: $mobileBottomCtaBoxHeight;
      padding: 0 $mobileTabSpacing;
      display: none;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $grey;
    }

    @media (max-width: $br1) {
      width: 100%;
      height: 100%;
      background: #ffffff;
      position: fixed;
      right: -100%;
      top: 0;
      z-index: 999999;
      transition: 0.2s ease-out;

      .scrollable,
      .scrollableGreyTabs {
        @include scrollable-style();
        height: calc(100% - $mobileTitleHeight - $mobileBottomCtaBoxHeight);
        padding-bottom: $mobileTabSpacing;
      }

      .mobileCtaBottom {
        display: flex;
      }

      &.slideCenter {
        right: 0;
      }
      &.slideLeft {
        right: 100%;
      }
    }
  }

  .selectableTab {
    &.greyTab {
      border-bottom: 1px solid $grey;

      @media (min-width: $mWidthbr1) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        margin: 0 $tagSpacing $tagSpacing 0;
        border: 0;

        &:last-child {
          margin-right: 0;
        }

        .trigger {
          height: 30px;
          background: #151618;
          color: #ffffff;
          font-size: 14px;
          padding: 0 14px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;
          user-select: none;
          position: relative;
          z-index: 10;

          &.active {
            background: #4a4b52;
          }
        }

        .activeCircle {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #03bc04;
          border: 1px solid #ffffff;
          position: absolute;
          right: -5px;
          top: -5px;
          transform: scale(0);
          opacity: 0;
          transition: 0.2s;
          z-index: 200;
          &.active {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      svg.arrowDown {
        fill: #ffffff;
        width: 8px;
        margin: 0 0 0 9px;
        @media (max-width: $br1) {
          display: none;
        }
      }
    }

    &.filterTab {
      @include searchAndTitleWidth-style();
      border-radius: 10px;
      margin: 5px 0 0;

      &:first-child {
        margin: 0;
      }

      &:hover {
        @media (min-width: $mWidthbr1) {
          background: #dbf5db;
        }
      }

      &.active {
        background: #dbf5db;
        color: #35c936;
        svg.check {
          opacity: 1;
        }
      }

      @media (min-width: $mWidthbr1) {
        width: 100%;
        cursor: pointer;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }
    }

    svg.check {
      width: 18px;
      height: 18px;
      fill: #00bd00;
      opacity: 0;
      transition: 0.2s;
    }

    @media (max-width: $br1) {
      width: 100%;
      font-size: 14px;
      padding: 13px $mobileTabSpacing;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      position: relative;

      &:last-child {
        border: 0;
      }

      .scrollable,
      .scrollableGreyTabs {
        width: 100%;
        cursor: default;
        @include scrollable-style();
      }
    }
    &.clear {
      font-size: 14px;
      color: #03bc04;
      line-height: 30px;
      cursor: pointer;
      font-weight: 700;
      br {
        display: none;
      }
      @media (max-width: $br1) {
        display: none;
      }
    }
  }

  .categoryRightTab {
    height: 100%;
    position: absolute;
    right: $mobileTabSpacing;
    top: 0;
    bottom: 0;
    margin: auto;
    display: none;
    justify-content: center;
    align-items: center;

    @media (max-width: $br1) {
      display: flex;
    }

    .totalCircle {
      height: 20px;
      padding: 0 10px;
      color: #ffffff;
      border-radius: 20px;
      font-size: 12px;
      background: #00bd00;
      margin: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  svg.arrowMobile {
    width: 15px;
    cursor: pointer;
    stroke: #000000;
    display: none;

    @media (max-width: $br1) {
      display: block;
    }

    transform: rotate(-90deg);

    &.backPanelArrow {
      transform: rotate(90deg);
    }
  }

  .largeCta {
    display: flex;
    flex-grow: 1;
    height: 50px;
    font-weight: 600;
    background: #00bd00;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    svg.filterIcon {
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .filter-box {
    align-items: center;
    display: none;
    flex-direction: row;
    width: 100%;
    @media (max-width: $br1) {
      display: flex;
    }
    .clear {
      font-size: 14px;
      color: #03bc04;
      margin: 0 0 0 15px;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .tags-list {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;

    @media (max-width: 992px) {
      margin-top: 15px;
    }
    .tag {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 999px;
      border: 1px solid #000;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 18.2px;
      text-transform: capitalize;

      &:hover {
        background: #00bd00;
        color: #fff;
        border-color: #00bd00;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }
    }
  }
}
