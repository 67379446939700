$br1: '767px';

.offer-overlay-container {
  position: relative;
  padding-top: 80px;

  .app-container {
    padding-top: 0;
  }

  #offer-content {
    max-width: 1920px;
    padding-top: 0;

    .spacing-text {
      padding: 1em 0;
    }

    .offersCreativesCards {
      margin-bottom: 30px;

      #viewmode-component {
        padding-left: 0;
      }

      #pagination {
        padding-right: 0;
      }
    }

    #offer-tracking-link-container {
      .info {
        @media (max-width: $screen-xs) {
          margin: 0;
          vertical-align: baseline;
        }
      }
    }

    .payout-type .info {
      line-height: 1;
      position: absolute;
      margin: -2px 0 0 5px;
      i {
        font-size: 16px;
      }
    }

    #link-section {
      @include flexbox(column, nowrap, flex-start);

      @include responsive('xlarge') {
        @include flexbox(row, nowrap, flex-start);

        #offer-tracking-link-container {
          .offercontainer {
            &.isOpen {
              height: 94%;
            }
          }
          flex: 2;
        }
        #offer-tracking-link-customization-container {
          margin-left: 1rem;
          flex: 1;
        }
      }
    }

    #ad-tools #offer-creatives {
      padding: 1rem;

      @media (max-width: $br1) {
        padding: 1rem 5px;
      }
      #bannerWaves {
        max-height: 145px;
      }
      #offer-creatives-banner-filters,
      #offer-creatives-html-filters {
        padding-top: 1rem;
        .btn {
          margin: 1rem;
        }
      }

      .tabs-creatives {
        opacity: 0;
        transition: 0.2s;
        &.active {
          opacity: 1;
        }
      }

      .scroller-container {
        @media (max-width: $br1) {
          padding: 0 10px;
        }
      }
    }

    .offer-unapproved-overlay {
      @include flexbox(row, nowrap, space-around);

      width: 100%;
      height: 100%;
      color: #aaa;
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    @include responsive('phone to tablet') {
      #offer-content .tracking-link-container h2 {
        margin-top: 0;
      }
    }
  }
  .adToolAreaBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: 0.1s;

    &.banner {
      opacity: 1 !important;
    }
  }

  .offer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    color: #fff;
    text-align: center;

    h2 {
      margin-top: 10rem;
      font-size: 5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }

    .offer-overlay-waves {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      svg {
        width: 100%;
      }
    }
  }
}

#offer-tracking-link-container {
  .offercontainer {
    &.isOpen {
      height: 94%;
    }
  }
  flex: 2;
}

.adToolArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .fullWidth {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .adToolLoading {
    width: 100%;
  }
}

.adToolInner {
  width: 100%;
  height: 45px;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  gap: 10px;

  &:before,
  &:after {
    display: none !important;
  }

  .adToolInnerTab {
    width: 50%;
    height: 100%;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-weight: 500;
    font-size: 21px;
    color: #333333;
    cursor: pointer;
    gap: 10px;

    @media (max-width: 400px) {
      font-size: 18px;
    }

    svg {
      width: 25px;
      min-width: 25px;
    }

    &.active {
      background: #00bd00;
      color: #ffffff;
    }
  }
}

.popCodeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0 !important;

  .popCodeParagraph {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 25px;
    margin: 0 !important;
    gap: 20px;

    .iconBox {
      width: 88px;
      min-width: 88px;
      height: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      background: #e9e9e9;

      @media (max-width: 650px) {
        display: none;
      }

      svg {
        width: 50%;
        color: #333333;
      }
    }

    .contentBox {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .contentBoxTitle {
        width: 100%;
        font-size: 21px;
        color: #00bd00;
        font-weight: 500;
      }

      .contentBoxParagraph {
        width: 100%;
        font-size: 16px;
        margin: 3px 0 0;
      }
    }

    &:before,
    &:after {
      display: none !important;
    }
  }
}
