$transitionAmount: 1s;

$br0: 1900px;
$br1: 1355px;
$br2: 767px;

.promotools-section {
  width: 100%;
  max-width: 1180px;
  margin: 95px auto 0;
  padding-bottom: 50px;

  @media (max-width: $br1) {
    padding-bottom: 70px;
  }

  .promotools-header {
    opacity: 0;
    transition: $transitionAmount;

    &.active {
      opacity: 1;

      h2 {
        opacity: 1;
        transform: translateY(0);
      }

      p {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  h2 {
    text-transform: capitalize;
    opacity: 0;
    transform: translateY(-100%);
    transition: $transitionAmount;
  }

  p {
    color: #7b7b7b;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0 50px;
    opacity: 0;
    transform: translateY(-100%);
    transition: $transitionAmount;
  }

  .tools-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    align-items: stretch;

    @media (max-width: $br2) {
      align-items: center;
    }
  }

  @import './card.react.scss';
}
