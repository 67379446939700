/* This is needed for some mobile phones to display the Google Icon font properly */
.material-icons {
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
}

/*
   * CSS File of Material icons.
   * CSS code based on:
   * https://google.github.io/material-design-icons/#icon-font-for-the-web
   */

//= depend_on_asset "MaterialIcons-Regular.eot"
//= depend_on_asset "MaterialIcons-Regular.woff2"
//= depend_on_asset "MaterialIcons-Regular.woff"
//= depend_on_asset "MaterialIcons-Regular.ttf"

/* Base class */
.material-icons,
.mi {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

/* Rules for sizing the icon. */
.material-icons.md-18,
.mi.md-18 {
    font-size: 18px;
}

.material-icons.md-24,
.mi.md-24 {
    font-size: 24px;
}

.material-icons.md-36,
.mi.md-36 {
    font-size: 36px;
}

.material-icons.md-48,
.mi.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark,
.mi.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive,
.mi.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light,
.mi.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive,
.mi.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

/* Rules to rotate items */
.material-icons.r90,
.mi.r90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.material-icons.r180,
.mi.r180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.material-icons.r270,
.mi.r270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.material-icons.flip-horizontal,
.mi.flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.material-icons.flip-vertical,
.mi.flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

/* USED ICONS */

.material-icons.star:before,
.mi.star:before {
    content: '\e838';
}

.material-icons.star_border:before,
.mi.star_border:before {
    content: '\e83a';
}

.material-icons.star_half:before,
.mi.star_half:before {
    content: '\e839';
}

.material-icons.add:before,
.mi.add:before {
    content: '\e145';
}

.material-icons.close:before,
.mi.close:before {
    content: '\e5cd';
}

.material-icons.close:before,
.mi.close:before {
    content: '\e5cd';
}

.material-icons.remove:before,
.mi.remove:before {
    content: '\e15b';
}

.material-icons.done:before,
.mi.done:before {
    content: '\e876';
}

/* Unicode */
// .material-icons.three_d_rotation:before,
// .mi.three_d_rotation:before {
//   content: '\e84d';
// }

// .material-icons.ac_unit:before,
// .mi.ac_unit:before {
//   content: '\eb3b';
// }

// .material-icons.access_alarm:before,
// .mi.access_alarm:before {
//   content: '\e190';
// }

// .material-icons.access_alarms:before,
// .mi.access_alarms:before {
//   content: '\e191';
// }

// .material-icons.access_time:before,
// .mi.access_time:before {
//   content: '\e192';
// }

// .material-icons.accessibility:before,
// .mi.accessibility:before {
//   content: '\e84e';
// }

// .material-icons.accessible:before,
// .mi.accessible:before {
//   content: '\e914';
// }

// .material-icons.account_balance:before,
// .mi.account_balance:before {
//   content: '\e84f';
// }

// .material-icons.account_balance_wallet:before,
// .mi.account_balance_wallet:before {
//   content: '\e850';
// }

// .material-icons.account_box:before,
// .mi.account_box:before {
//   content: '\e851';
// }

// .material-icons.account_circle:before,
// .mi.account_circle:before {
//   content: '\e853';
// }

// .material-icons.adb:before,
// .mi.adb:before {
//   content: '\e60e';
// }

// .material-icons.add_a_photo:before,
// .mi.add_a_photo:before {
//   content: '\e439';
// }

// .material-icons.add_alarm:before,
// .mi.add_alarm:before {
//   content: '\e193';
// }

// .material-icons.add_alert:before,
// .mi.add_alert:before {
//   content: '\e003';
// }

// .material-icons.add_box:before,
// .mi.add_box:before {
//   content: '\e146';
// }

// .material-icons.add_circle:before,
// .mi.add_circle:before {
//   content: '\e147';
// }

// .material-icons.add_circle_outline:before,
// .mi.add_circle_outline:before {
//   content: '\e148';
// }

// .material-icons.add_location:before,
// .mi.add_location:before {
//   content: '\e567';
// }

// .material-icons.add_shopping_cart:before,
// .mi.add_shopping_cart:before {
//   content: '\e854';
// }

// .material-icons.add_to_photos:before,
// .mi.add_to_photos:before {
//   content: '\e39d';
// }

// .material-icons.add_to_queue:before,
// .mi.add_to_queue:before {
//   content: '\e05c';
// }

// .material-icons.adjust:before,
// .mi.adjust:before {
//   content: '\e39e';
// }

// .material-icons.airline_seat_flat:before,
// .mi.airline_seat_flat:before {
//   content: '\e630';
// }

// .material-icons.airline_seat_flat_angled:before,
// .mi.airline_seat_flat_angled:before {
//   content: '\e631';
// }

// .material-icons.airline_seat_individual_suite:before,
// .mi.airline_seat_individual_suite:before {
//   content: '\e632';
// }

// .material-icons.airline_seat_legroom_extra:before,
// .mi.airline_seat_legroom_extra:before {
//   content: '\e633';
// }

// .material-icons.airline_seat_legroom_normal:before,
// .mi.airline_seat_legroom_normal:before {
//   content: '\e634';
// }

// .material-icons.airline_seat_legroom_reduced:before,
// .mi.airline_seat_legroom_reduced:before {
//   content: '\e635';
// }

// .material-icons.airline_seat_recline_extra:before,
// .mi.airline_seat_recline_extra:before {
//   content: '\e636';
// }

// .material-icons.airline_seat_recline_normal:before,
// .mi.airline_seat_recline_normal:before {
//   content: '\e637';
// }

// .material-icons.airplanemode_active:before,
// .mi.airplanemode_active:before {
//   content: '\e195';
// }

// .material-icons.airplanemode_inactive:before,
// .mi.airplanemode_inactive:before {
//   content: '\e194';
// }

// .material-icons.airplay:before,
// .mi.airplay:before {
//   content: '\e055';
// }

// .material-icons.airport_shuttle:before,
// .mi.airport_shuttle:before {
//   content: '\eb3c';
// }

// .material-icons.alarm:before,
// .mi.alarm:before {
//   content: '\e855';
// }

// .material-icons.alarm_add:before,
// .mi.alarm_add:before {
//   content: '\e856';
// }

// .material-icons.alarm_off:before,
// .mi.alarm_off:before {
//   content: '\e857';
// }

// .material-icons.alarm_on:before,
// .mi.alarm_on:before {
//   content: '\e858';
// }

// .material-icons.album:before,
// .mi.album:before {
//   content: '\e019';
// }

// .material-icons.all_inclusive:before,
// .mi.all_inclusive:before {
//   content: '\eb3d';
// }

// .material-icons.all_out:before,
// .mi.all_out:before {
//   content: '\e90b';
// }

// .material-icons.android:before,
// .mi.android:before {
//   content: '\e859';
// }

// .material-icons.announcement:before,
// .mi.announcement:before {
//   content: '\e85a';
// }

// .material-icons.apps:before,
// .mi.apps:before {
//   content: '\e5c3';
// }

// .material-icons.archive:before,
// .mi.archive:before {
//   content: '\e149';
// }

// .material-icons.arrow_back:before,
// .mi.arrow_back:before {
//   content: '\e5c4';
// }

// .material-icons.arrow_downward:before,
// .mi.arrow_downward:before {
//   content: '\e5db';
// }

// .material-icons.arrow_drop_down:before,
// .mi.arrow_drop_down:before {
//   content: '\e5c5';
// }

// .material-icons.arrow_drop_down_circle:before,
// .mi.arrow_drop_down_circle:before {
//   content: '\e5c6';
// }

// .material-icons.arrow_drop_up:before,
// .mi.arrow_drop_up:before {
//   content: '\e5c7';
// }

// .material-icons.arrow_forward:before,
// .mi.arrow_forward:before {
//   content: '\e5c8';
// }

// .material-icons.arrow_upward:before,
// .mi.arrow_upward:before {
//   content: '\e5d8';
// }

// .material-icons.art_track:before,
// .mi.art_track:before {
//   content: '\e060';
// }

// .material-icons.aspect_ratio:before,
// .mi.aspect_ratio:before {
//   content: '\e85b';
// }

// .material-icons.assessment:before,
// .mi.assessment:before {
//   content: '\e85c';
// }

// .material-icons.assignment:before,
// .mi.assignment:before {
//   content: '\e85d';
// }

// .material-icons.assignment_ind:before,
// .mi.assignment_ind:before {
//   content: '\e85e';
// }

// .material-icons.assignment_late:before,
// .mi.assignment_late:before {
//   content: '\e85f';
// }

// .material-icons.assignment_return:before,
// .mi.assignment_return:before {
//   content: '\e860';
// }

// .material-icons.assignment_returned:before,
// .mi.assignment_returned:before {
//   content: '\e861';
// }

// .material-icons.assignment_turned_in:before,
// .mi.assignment_turned_in:before {
//   content: '\e862';
// }

// .material-icons.assistant:before,
// .mi.assistant:before {
//   content: '\e39f';
// }

// .material-icons.assistant_photo:before,
// .mi.assistant_photo:before {
//   content: '\e3a0';
// }

// .material-icons.attach_file:before,
// .mi.attach_file:before {
//   content: '\e226';
// }

// .material-icons.attach_money:before,
// .mi.attach_money:before {
//   content: '\e227';
// }

// .material-icons.attachment:before,
// .mi.attachment:before {
//   content: '\e2bc';
// }

// .material-icons.audiotrack:before,
// .mi.audiotrack:before {
//   content: '\e3a1';
// }

// .material-icons.autorenew:before,
// .mi.autorenew:before {
//   content: '\e863';
// }

// .material-icons.av_timer:before,
// .mi.av_timer:before {
//   content: '\e01b';
// }

// .material-icons.backspace:before,
// .mi.backspace:before {
//   content: '\e14a';
// }

// .material-icons.backup:before,
// .mi.backup:before {
//   content: '\e864';
// }

// .material-icons.battery_alert:before,
// .mi.battery_alert:before {
//   content: '\e19c';
// }

// .material-icons.battery_charging_full:before,
// .mi.battery_charging_full:before {
//   content: '\e1a3';
// }

// .material-icons.battery_full:before,
// .mi.battery_full:before {
//   content: '\e1a4';
// }

// .material-icons.battery_std:before,
// .mi.battery_std:before {
//   content: '\e1a5';
// }

// .material-icons.battery_unknown:before,
// .mi.battery_unknown:before {
//   content: '\e1a6';
// }

// .material-icons.beach_access:before,
// .mi.beach_access:before {
//   content: '\eb3e';
// }

// .material-icons.beenhere:before,
// .mi.beenhere:before {
//   content: '\e52d';
// }

// .material-icons.block:before,
// .mi.block:before {
//   content: '\e14b';
// }

// .material-icons.bluetooth:before,
// .mi.bluetooth:before {
//   content: '\e1a7';
// }

// .material-icons.bluetooth_audio:before,
// .mi.bluetooth_audio:before {
//   content: '\e60f';
// }

// .material-icons.bluetooth_connected:before,
// .mi.bluetooth_connected:before {
//   content: '\e1a8';
// }

// .material-icons.bluetooth_disabled:before,
// .mi.bluetooth_disabled:before {
//   content: '\e1a9';
// }

// .material-icons.bluetooth_searching:before,
// .mi.bluetooth_searching:before {
//   content: '\e1aa';
// }

// .material-icons.blur_circular:before,
// .mi.blur_circular:before {
//   content: '\e3a2';
// }

// .material-icons.blur_linear:before,
// .mi.blur_linear:before {
//   content: '\e3a3';
// }

// .material-icons.blur_off:before,
// .mi.blur_off:before {
//   content: '\e3a4';
// }

// .material-icons.blur_on:before,
// .mi.blur_on:before {
//   content: '\e3a5';
// }

// .material-icons.book:before,
// .mi.book:before {
//   content: '\e865';
// }

// .material-icons.bookmark:before,
// .mi.bookmark:before {
//   content: '\e866';
// }

// .material-icons.bookmark_border:before,
// .mi.bookmark_border:before {
//   content: '\e867';
// }

// .material-icons.border_all:before,
// .mi.border_all:before {
//   content: '\e228';
// }

// .material-icons.border_bottom:before,
// .mi.border_bottom:before {
//   content: '\e229';
// }

// .material-icons.border_clear:before,
// .mi.border_clear:before {
//   content: '\e22a';
// }

// .material-icons.border_color:before,
// .mi.border_color:before {
//   content: '\e22b';
// }

// .material-icons.border_horizontal:before,
// .mi.border_horizontal:before {
//   content: '\e22c';
// }

// .material-icons.border_inner:before,
// .mi.border_inner:before {
//   content: '\e22d';
// }

// .material-icons.border_left:before,
// .mi.border_left:before {
//   content: '\e22e';
// }

// .material-icons.border_outer:before,
// .mi.border_outer:before {
//   content: '\e22f';
// }

// .material-icons.border_right:before,
// .mi.border_right:before {
//   content: '\e230';
// }

// .material-icons.border_style:before,
// .mi.border_style:before {
//   content: '\e231';
// }

// .material-icons.border_top:before,
// .mi.border_top:before {
//   content: '\e232';
// }

// .material-icons.border_vertical:before,
// .mi.border_vertical:before {
//   content: '\e233';
// }

// .material-icons.branding_watermark:before,
// .mi.branding_watermark:before {
//   content: '\e06b';
// }

// .material-icons.brightness_1:before,
// .mi.brightness_1:before {
//   content: '\e3a6';
// }

// .material-icons.brightness_2:before,
// .mi.brightness_2:before {
//   content: '\e3a7';
// }

// .material-icons.brightness_3:before,
// .mi.brightness_3:before {
//   content: '\e3a8';
// }

// .material-icons.brightness_4:before,
// .mi.brightness_4:before {
//   content: '\e3a9';
// }

// .material-icons.brightness_5:before,
// .mi.brightness_5:before {
//   content: '\e3aa';
// }

// .material-icons.brightness_6:before,
// .mi.brightness_6:before {
//   content: '\e3ab';
// }

// .material-icons.brightness_7:before,
// .mi.brightness_7:before {
//   content: '\e3ac';
// }

// .material-icons.brightness_auto:before,
// .mi.brightness_auto:before {
//   content: '\e1ab';
// }

// .material-icons.brightness_high:before,
// .mi.brightness_high:before {
//   content: '\e1ac';
// }

// .material-icons.brightness_low:before,
// .mi.brightness_low:before {
//   content: '\e1ad';
// }

// .material-icons.brightness_medium:before,
// .mi.brightness_medium:before {
//   content: '\e1ae';
// }

// .material-icons.broken_image:before,
// .mi.broken_image:before {
//   content: '\e3ad';
// }

// .material-icons.brush:before,
// .mi.brush:before {
//   content: '\e3ae';
// }

// .material-icons.bubble_chart:before,
// .mi.bubble_chart:before {
//   content: '\e6dd';
// }

// .material-icons.bug_report:before,
// .mi.bug_report:before {
//   content: '\e868';
// }

// .material-icons.build:before,
// .mi.build:before {
//   content: '\e869';
// }

// .material-icons.burst_mode:before,
// .mi.burst_mode:before {
//   content: '\e43c';
// }

// .material-icons.business:before,
// .mi.business:before {
//   content: '\e0af';
// }

// .material-icons.business_center:before,
// .mi.business_center:before {
//   content: '\eb3f';
// }

// .material-icons.cached:before,
// .mi.cached:before {
//   content: '\e86a';
// }

// .material-icons.cake:before,
// .mi.cake:before {
//   content: '\e7e9';
// }

// .material-icons.call:before,
// .mi.call:before {
//   content: '\e0b0';
// }

// .material-icons.call_end:before,
// .mi.call_end:before {
//   content: '\e0b1';
// }

// .material-icons.call_made:before,
// .mi.call_made:before {
//   content: '\e0b2';
// }

// .material-icons.call_merge:before,
// .mi.call_merge:before {
//   content: '\e0b3';
// }

// .material-icons.call_missed:before,
// .mi.call_missed:before {
//   content: '\e0b4';
// }

// .material-icons.call_missed_outgoing:before,
// .mi.call_missed_outgoing:before {
//   content: '\e0e4';
// }

// .material-icons.call_received:before,
// .mi.call_received:before {
//   content: '\e0b5';
// }

// .material-icons.call_split:before,
// .mi.call_split:before {
//   content: '\e0b6';
// }

// .material-icons.call_to_action:before,
// .mi.call_to_action:before {
//   content: '\e06c';
// }

// .material-icons.camera:before,
// .mi.camera:before {
//   content: '\e3af';
// }

// .material-icons.camera_alt:before,
// .mi.camera_alt:before {
//   content: '\e3b0';
// }

// .material-icons.camera_enhance:before,
// .mi.camera_enhance:before {
//   content: '\e8fc';
// }

// .material-icons.camera_front:before,
// .mi.camera_front:before {
//   content: '\e3b1';
// }

// .material-icons.camera_rear:before,
// .mi.camera_rear:before {
//   content: '\e3b2';
// }

// .material-icons.camera_roll:before,
// .mi.camera_roll:before {
//   content: '\e3b3';
// }

// .material-icons.cancel:before,
// .mi.cancel:before {
//   content: '\e5c9';
// }

// .material-icons.card_giftcard:before,
// .mi.card_giftcard:before {
//   content: '\e8f6';
// }

// .material-icons.card_membership:before,
// .mi.card_membership:before {
//   content: '\e8f7';
// }

// .material-icons.card_travel:before,
// .mi.card_travel:before {
//   content: '\e8f8';
// }

// .material-icons.casino:before,
// .mi.casino:before {
//   content: '\eb40';
// }

// .material-icons.cast:before,
// .mi.cast:before {
//   content: '\e307';
// }

// .material-icons.cast_connected:before,
// .mi.cast_connected:before {
//   content: '\e308';
// }

// .material-icons.center_focus_strong:before,
// .mi.center_focus_strong:before {
//   content: '\e3b4';
// }

// .material-icons.center_focus_weak:before,
// .mi.center_focus_weak:before {
//   content: '\e3b5';
// }

// .material-icons.change_history:before,
// .mi.change_history:before {
//   content: '\e86b';
// }

// .material-icons.chat:before,
// .mi.chat:before {
//   content: '\e0b7';
// }

// .material-icons.chat_bubble:before,
// .mi.chat_bubble:before {
//   content: '\e0ca';
// }

// .material-icons.chat_bubble_outline:before,
// .mi.chat_bubble_outline:before {
//   content: '\e0cb';
// }

// .material-icons.check:before,
// .mi.check:before {
//   content: '\e5ca';
// }

// .material-icons.check_box:before,
// .mi.check_box:before {
//   content: '\e834';
// }

// .material-icons.check_box_outline_blank:before,
// .mi.check_box_outline_blank:before {
//   content: '\e835';
// }

// .material-icons.check_circle:before,
// .mi.check_circle:before {
//   content: '\e86c';
// }

// .material-icons.chevron_left:before,
// .mi.chevron_left:before {
//   content: '\e5cb';
// }

// .material-icons.chevron_right:before,
// .mi.chevron_right:before {
//   content: '\e5cc';
// }

// .material-icons.child_care:before,
// .mi.child_care:before {
//   content: '\eb41';
// }

// .material-icons.child_friendly:before,
// .mi.child_friendly:before {
//   content: '\eb42';
// }

// .material-icons.chrome_reader_mode:before,
// .mi.chrome_reader_mode:before {
//   content: '\e86d';
// }

// .material-icons.class:before,
// .mi.class:before {
//   content: '\e86e';
// }

// .material-icons.clear:before,
// .mi.clear:before {
//   content: '\e14c';
// }

// .material-icons.clear_all:before,
// .mi.clear_all:before {
//   content: '\e0b8';
// }

// .material-icons.closed_caption:before,
// .mi.closed_caption:before {
//   content: '\e01c';
// }

// .material-icons.cloud:before,
// .mi.cloud:before {
//   content: '\e2bd';
// }

// .material-icons.cloud_circle:before,
// .mi.cloud_circle:before {
//   content: '\e2be';
// }

// .material-icons.cloud_done:before,
// .mi.cloud_done:before {
//   content: '\e2bf';
// }

// .material-icons.cloud_download:before,
// .mi.cloud_download:before {
//   content: '\e2c0';
// }

// .material-icons.cloud_off:before,
// .mi.cloud_off:before {
//   content: '\e2c1';
// }

// .material-icons.cloud_queue:before,
// .mi.cloud_queue:before {
//   content: '\e2c2';
// }

// .material-icons.cloud_upload:before,
// .mi.cloud_upload:before {
//   content: '\e2c3';
// }

// .material-icons.code:before,
// .mi.code:before {
//   content: '\e86f';
// }

// .material-icons.collections:before,
// .mi.collections:before {
//   content: '\e3b6';
// }

// .material-icons.collections_bookmark:before,
// .mi.collections_bookmark:before {
//   content: '\e431';
// }

// .material-icons.color_lens:before,
// .mi.color_lens:before {
//   content: '\e3b7';
// }

// .material-icons.colorize:before,
// .mi.colorize:before {
//   content: '\e3b8';
// }

// .material-icons.comment:before,
// .mi.comment:before {
//   content: '\e0b9';
// }

// .material-icons.compare:before,
// .mi.compare:before {
//   content: '\e3b9';
// }

// .material-icons.compare_arrows:before,
// .mi.compare_arrows:before {
//   content: '\e915';
// }

// .material-icons.computer:before,
// .mi.computer:before {
//   content: '\e30a';
// }

// .material-icons.confirmation_number:before,
// .mi.confirmation_number:before {
//   content: '\e638';
// }

// .material-icons.contact_mail:before,
// .mi.contact_mail:before {
//   content: '\e0d0';
// }

// .material-icons.contact_phone:before,
// .mi.contact_phone:before {
//   content: '\e0cf';
// }

// .material-icons.contacts:before,
// .mi.contacts:before {
//   content: '\e0ba';
// }

// .material-icons.content_copy:before,
// .mi.content_copy:before {
//   content: '\e14d';
// }

// .material-icons.content_cut:before,
// .mi.content_cut:before {
//   content: '\e14e';
// }

// .material-icons.content_paste:before,
// .mi.content_paste:before {
//   content: '\e14f';
// }

// .material-icons.control_point:before,
// .mi.control_point:before {
//   content: '\e3ba';
// }

// .material-icons.control_point_duplicate:before,
// .mi.control_point_duplicate:before {
//   content: '\e3bb';
// }

// .material-icons.copyright:before,
// .mi.copyright:before {
//   content: '\e90c';
// }

// .material-icons.create:before,
// .mi.create:before {
//   content: '\e150';
// }

// .material-icons.create_new_folder:before,
// .mi.create_new_folder:before {
//   content: '\e2cc';
// }

// .material-icons.credit_card:before,
// .mi.credit_card:before {
//   content: '\e870';
// }

// .material-icons.crop:before,
// .mi.crop:before {
//   content: '\e3be';
// }

// .material-icons.crop_16_9:before,
// .mi.crop_16_9:before {
//   content: '\e3bc';
// }

// .material-icons.crop_3_2:before,
// .mi.crop_3_2:before {
//   content: '\e3bd';
// }

// .material-icons.crop_5_4:before,
// .mi.crop_5_4:before {
//   content: '\e3bf';
// }

// .material-icons.crop_7_5:before,
// .mi.crop_7_5:before {
//   content: '\e3c0';
// }

// .material-icons.crop_din:before,
// .mi.crop_din:before {
//   content: '\e3c1';
// }

// .material-icons.crop_free:before,
// .mi.crop_free:before {
//   content: '\e3c2';
// }

// .material-icons.crop_landscape:before,
// .mi.crop_landscape:before {
//   content: '\e3c3';
// }

// .material-icons.crop_original:before,
// .mi.crop_original:before {
//   content: '\e3c4';
// }

// .material-icons.crop_portrait:before,
// .mi.crop_portrait:before {
//   content: '\e3c5';
// }

// .material-icons.crop_rotate:before,
// .mi.crop_rotate:before {
//   content: '\e437';
// }

// .material-icons.crop_square:before,
// .mi.crop_square:before {
//   content: '\e3c6';
// }

// .material-icons.dashboard:before,
// .mi.dashboard:before {
//   content: '\e871';
// }

// .material-icons.data_usage:before,
// .mi.data_usage:before {
//   content: '\e1af';
// }

// .material-icons.date_range:before,
// .mi.date_range:before {
//   content: '\e916';
// }

// .material-icons.dehaze:before,
// .mi.dehaze:before {
//   content: '\e3c7';
// }

// .material-icons.delete:before,
// .mi.delete:before {
//   content: '\e872';
// }

// .material-icons.delete_forever:before,
// .mi.delete_forever:before {
//   content: '\e92b';
// }

// .material-icons.delete_sweep:before,
// .mi.delete_sweep:before {
//   content: '\e16c';
// }

// .material-icons.description:before,
// .mi.description:before {
//   content: '\e873';
// }

// .material-icons.desktop_mac:before,
// .mi.desktop_mac:before {
//   content: '\e30b';
// }

// .material-icons.desktop_windows:before,
// .mi.desktop_windows:before {
//   content: '\e30c';
// }

// .material-icons.details:before,
// .mi.details:before {
//   content: '\e3c8';
// }

// .material-icons.developer_board:before,
// .mi.developer_board:before {
//   content: '\e30d';
// }

// .material-icons.developer_mode:before,
// .mi.developer_mode:before {
//   content: '\e1b0';
// }

// .material-icons.device_hub:before,
// .mi.device_hub:before {
//   content: '\e335';
// }

// .material-icons.devices:before,
// .mi.devices:before {
//   content: '\e1b1';
// }

// .material-icons.devices_other:before,
// .mi.devices_other:before {
//   content: '\e337';
// }

// .material-icons.dialer_sip:before,
// .mi.dialer_sip:before {
//   content: '\e0bb';
// }

// .material-icons.dialpad:before,
// .mi.dialpad:before {
//   content: '\e0bc';
// }

// .material-icons.directions:before,
// .mi.directions:before {
//   content: '\e52e';
// }

// .material-icons.directions_bike:before,
// .mi.directions_bike:before {
//   content: '\e52f';
// }

// .material-icons.directions_boat:before,
// .mi.directions_boat:before {
//   content: '\e532';
// }

// .material-icons.directions_bus:before,
// .mi.directions_bus:before {
//   content: '\e530';
// }

// .material-icons.directions_car:before,
// .mi.directions_car:before {
//   content: '\e531';
// }

// .material-icons.directions_railway:before,
// .mi.directions_railway:before {
//   content: '\e534';
// }

// .material-icons.directions_run:before,
// .mi.directions_run:before {
//   content: '\e566';
// }

// .material-icons.directions_subway:before,
// .mi.directions_subway:before {
//   content: '\e533';
// }

// .material-icons.directions_transit:before,
// .mi.directions_transit:before {
//   content: '\e535';
// }

// .material-icons.directions_walk:before,
// .mi.directions_walk:before {
//   content: '\e536';
// }

// .material-icons.disc_full:before,
// .mi.disc_full:before {
//   content: '\e610';
// }

// .material-icons.dns:before,
// .mi.dns:before {
//   content: '\e875';
// }

// .material-icons.do_not_disturb:before,
// .mi.do_not_disturb:before {
//   content: '\e612';
// }

// .material-icons.do_not_disturb_alt:before,
// .mi.do_not_disturb_alt:before {
//   content: '\e611';
// }

// .material-icons.do_not_disturb_off:before,
// .mi.do_not_disturb_off:before {
//   content: '\e643';
// }

// .material-icons.do_not_disturb_on:before,
// .mi.do_not_disturb_on:before {
//   content: '\e644';
// }

// .material-icons.dock:before,
// .mi.dock:before {
//   content: '\e30e';
// }

// .material-icons.domain:before,
// .mi.domain:before {
//   content: '\e7ee';
// }

// .material-icons.done_all:before,
// .mi.done_all:before {
//   content: '\e877';
// }

// .material-icons.donut_large:before,
// .mi.donut_large:before {
//   content: '\e917';
// }

// .material-icons.donut_small:before,
// .mi.donut_small:before {
//   content: '\e918';
// }

// .material-icons.drafts:before,
// .mi.drafts:before {
//   content: '\e151';
// }

// .material-icons.drag_handle:before,
// .mi.drag_handle:before {
//   content: '\e25d';
// }

// .material-icons.drive_eta:before,
// .mi.drive_eta:before {
//   content: '\e613';
// }

// .material-icons.dvr:before,
// .mi.dvr:before {
//   content: '\e1b2';
// }

// .material-icons.edit:before,
// .mi.edit:before {
//   content: '\e3c9';
// }

// .material-icons.edit_location:before,
// .mi.edit_location:before {
//   content: '\e568';
// }

// .material-icons.eject:before,
// .mi.eject:before {
//   content: '\e8fb';
// }

// .material-icons.email:before,
// .mi.email:before {
//   content: '\e0be';
// }

// .material-icons.enhanced_encryption:before,
// .mi.enhanced_encryption:before {
//   content: '\e63f';
// }

// .material-icons.equalizer:before,
// .mi.equalizer:before {
//   content: '\e01d';
// }

// .material-icons.error:before,
// .mi.error:before {
//   content: '\e000';
// }

// .material-icons.error_outline:before,
// .mi.error_outline:before {
//   content: '\e001';
// }

// .material-icons.euro_symbol:before,
// .mi.euro_symbol:before {
//   content: '\e926';
// }

// .material-icons.ev_station:before,
// .mi.ev_station:before {
//   content: '\e56d';
// }

// .material-icons.event:before,
// .mi.event:before {
//   content: '\e878';
// }

// .material-icons.event_available:before,
// .mi.event_available:before {
//   content: '\e614';
// }

// .material-icons.event_busy:before,
// .mi.event_busy:before {
//   content: '\e615';
// }

// .material-icons.event_note:before,
// .mi.event_note:before {
//   content: '\e616';
// }

// .material-icons.event_seat:before,
// .mi.event_seat:before {
//   content: '\e903';
// }

// .material-icons.exit_to_app:before,
// .mi.exit_to_app:before {
//   content: '\e879';
// }

// .material-icons.expand_less:before,
// .mi.expand_less:before {
//   content: '\e5ce';
// }

// .material-icons.expand_more:before,
// .mi.expand_more:before {
//   content: '\e5cf';
// }

// .material-icons.explicit:before,
// .mi.explicit:before {
//   content: '\e01e';
// }

// .material-icons.explore:before,
// .mi.explore:before {
//   content: '\e87a';
// }

// .material-icons.exposure:before,
// .mi.exposure:before {
//   content: '\e3ca';
// }

// .material-icons.exposure_neg_1:before,
// .mi.exposure_neg_1:before {
//   content: '\e3cb';
// }

// .material-icons.exposure_neg_2:before,
// .mi.exposure_neg_2:before {
//   content: '\e3cc';
// }

// .material-icons.exposure_plus_1:before,
// .mi.exposure_plus_1:before {
//   content: '\e3cd';
// }

// .material-icons.exposure_plus_2:before,
// .mi.exposure_plus_2:before {
//   content: '\e3ce';
// }

// .material-icons.exposure_zero:before,
// .mi.exposure_zero:before {
//   content: '\e3cf';
// }

// .material-icons.extension:before,
// .mi.extension:before {
//   content: '\e87b';
// }

// .material-icons.face:before,
// .mi.face:before {
//   content: '\e87c';
// }

// .material-icons.fast_forward:before,
// .mi.fast_forward:before {
//   content: '\e01f';
// }

// .material-icons.fast_rewind:before,
// .mi.fast_rewind:before {
//   content: '\e020';
// }

// .material-icons.favorite:before,
// .mi.favorite:before {
//   content: '\e87d';
// }

// .material-icons.favorite_border:before,
// .mi.favorite_border:before {
//   content: '\e87e';
// }

// .material-icons.featured_play_list:before,
// .mi.featured_play_list:before {
//   content: '\e06d';
// }

// .material-icons.featured_video:before,
// .mi.featured_video:before {
//   content: '\e06e';
// }

// .material-icons.feedback:before,
// .mi.feedback:before {
//   content: '\e87f';
// }

// .material-icons.fiber_dvr:before,
// .mi.fiber_dvr:before {
//   content: '\e05d';
// }

// .material-icons.fiber_manual_record:before,
// .mi.fiber_manual_record:before {
//   content: '\e061';
// }

// .material-icons.fiber_new:before,
// .mi.fiber_new:before {
//   content: '\e05e';
// }

// .material-icons.fiber_pin:before,
// .mi.fiber_pin:before {
//   content: '\e06a';
// }

// .material-icons.fiber_smart_record:before,
// .mi.fiber_smart_record:before {
//   content: '\e062';
// }

// .material-icons.file_download:before,
// .mi.file_download:before {
//   content: '\e2c4';
// }

// .material-icons.file_upload:before,
// .mi.file_upload:before {
//   content: '\e2c6';
// }

// .material-icons.filter:before,
// .mi.filter:before {
//   content: '\e3d3';
// }

// .material-icons.filter_1:before,
// .mi.filter_1:before {
//   content: '\e3d0';
// }

// .material-icons.filter_2:before,
// .mi.filter_2:before {
//   content: '\e3d1';
// }

// .material-icons.filter_3:before,
// .mi.filter_3:before {
//   content: '\e3d2';
// }

// .material-icons.filter_4:before,
// .mi.filter_4:before {
//   content: '\e3d4';
// }

// .material-icons.filter_5:before,
// .mi.filter_5:before {
//   content: '\e3d5';
// }

// .material-icons.filter_6:before,
// .mi.filter_6:before {
//   content: '\e3d6';
// }

// .material-icons.filter_7:before,
// .mi.filter_7:before {
//   content: '\e3d7';
// }

// .material-icons.filter_8:before,
// .mi.filter_8:before {
//   content: '\e3d8';
// }

// .material-icons.filter_9:before,
// .mi.filter_9:before {
//   content: '\e3d9';
// }

// .material-icons.filter_9_plus:before,
// .mi.filter_9_plus:before {
//   content: '\e3da';
// }

// .material-icons.filter_b_and_w:before,
// .mi.filter_b_and_w:before {
//   content: '\e3db';
// }

// .material-icons.filter_center_focus:before,
// .mi.filter_center_focus:before {
//   content: '\e3dc';
// }

// .material-icons.filter_drama:before,
// .mi.filter_drama:before {
//   content: '\e3dd';
// }

// .material-icons.filter_frames:before,
// .mi.filter_frames:before {
//   content: '\e3de';
// }

// .material-icons.filter_hdr:before,
// .mi.filter_hdr:before {
//   content: '\e3df';
// }

// .material-icons.filter_list:before,
// .mi.filter_list:before {
//   content: '\e152';
// }

// .material-icons.filter_none:before,
// .mi.filter_none:before {
//   content: '\e3e0';
// }

// .material-icons.filter_tilt_shift:before,
// .mi.filter_tilt_shift:before {
//   content: '\e3e2';
// }

// .material-icons.filter_vintage:before,
// .mi.filter_vintage:before {
//   content: '\e3e3';
// }

// .material-icons.find_in_page:before,
// .mi.find_in_page:before {
//   content: '\e880';
// }

// .material-icons.find_replace:before,
// .mi.find_replace:before {
//   content: '\e881';
// }

// .material-icons.fingerprint:before,
// .mi.fingerprint:before {
//   content: '\e90d';
// }

// .material-icons.first_page:before,
// .mi.first_page:before {
//   content: '\e5dc';
// }

// .material-icons.fitness_center:before,
// .mi.fitness_center:before {
//   content: '\eb43';
// }

// .material-icons.flag:before,
// .mi.flag:before {
//   content: '\e153';
// }

// .material-icons.flare:before,
// .mi.flare:before {
//   content: '\e3e4';
// }

// .material-icons.flash_auto:before,
// .mi.flash_auto:before {
//   content: '\e3e5';
// }

// .material-icons.flash_off:before,
// .mi.flash_off:before {
//   content: '\e3e6';
// }

// .material-icons.flash_on:before,
// .mi.flash_on:before {
//   content: '\e3e7';
// }

// .material-icons.flight:before,
// .mi.flight:before {
//   content: '\e539';
// }

// .material-icons.flight_land:before,
// .mi.flight_land:before {
//   content: '\e904';
// }

// .material-icons.flight_takeoff:before,
// .mi.flight_takeoff:before {
//   content: '\e905';
// }

// .material-icons.flip:before,
// .mi.flip:before {
//   content: '\e3e8';
// }

// .material-icons.flip_to_back:before,
// .mi.flip_to_back:before {
//   content: '\e882';
// }

// .material-icons.flip_to_front:before,
// .mi.flip_to_front:before {
//   content: '\e883';
// }

// .material-icons.folder:before,
// .mi.folder:before {
//   content: '\e2c7';
// }

// .material-icons.folder_open:before,
// .mi.folder_open:before {
//   content: '\e2c8';
// }

// .material-icons.folder_shared:before,
// .mi.folder_shared:before {
//   content: '\e2c9';
// }

// .material-icons.folder_special:before,
// .mi.folder_special:before {
//   content: '\e617';
// }

// .material-icons.font_download:before,
// .mi.font_download:before {
//   content: '\e167';
// }

// .material-icons.format_align_center:before,
// .mi.format_align_center:before {
//   content: '\e234';
// }

// .material-icons.format_align_justify:before,
// .mi.format_align_justify:before {
//   content: '\e235';
// }

// .material-icons.format_align_left:before,
// .mi.format_align_left:before {
//   content: '\e236';
// }

// .material-icons.format_align_right:before,
// .mi.format_align_right:before {
//   content: '\e237';
// }

// .material-icons.format_bold:before,
// .mi.format_bold:before {
//   content: '\e238';
// }

// .material-icons.format_clear:before,
// .mi.format_clear:before {
//   content: '\e239';
// }

// .material-icons.format_color_fill:before,
// .mi.format_color_fill:before {
//   content: '\e23a';
// }

// .material-icons.format_color_reset:before,
// .mi.format_color_reset:before {
//   content: '\e23b';
// }

// .material-icons.format_color_text:before,
// .mi.format_color_text:before {
//   content: '\e23c';
// }

// .material-icons.format_indent_decrease:before,
// .mi.format_indent_decrease:before {
//   content: '\e23d';
// }

// .material-icons.format_indent_increase:before,
// .mi.format_indent_increase:before {
//   content: '\e23e';
// }

// .material-icons.format_italic:before,
// .mi.format_italic:before {
//   content: '\e23f';
// }

// .material-icons.format_line_spacing:before,
// .mi.format_line_spacing:before {
//   content: '\e240';
// }

// .material-icons.format_list_bulleted:before,
// .mi.format_list_bulleted:before {
//   content: '\e241';
// }

// .material-icons.format_list_numbered:before,
// .mi.format_list_numbered:before {
//   content: '\e242';
// }

// .material-icons.format_paint:before,
// .mi.format_paint:before {
//   content: '\e243';
// }

// .material-icons.format_quote:before,
// .mi.format_quote:before {
//   content: '\e244';
// }

// .material-icons.format_shapes:before,
// .mi.format_shapes:before {
//   content: '\e25e';
// }

// .material-icons.format_size:before,
// .mi.format_size:before {
//   content: '\e245';
// }

// .material-icons.format_strikethrough:before,
// .mi.format_strikethrough:before {
//   content: '\e246';
// }

// .material-icons.format_textdirection_l_to_r:before,
// .mi.format_textdirection_l_to_r:before {
//   content: '\e247';
// }

// .material-icons.format_textdirection_r_to_l:before,
// .mi.format_textdirection_r_to_l:before {
//   content: '\e248';
// }

// .material-icons.format_underlined:before,
// .mi.format_underlined:before {
//   content: '\e249';
// }

// .material-icons.forum:before,
// .mi.forum:before {
//   content: '\e0bf';
// }

// .material-icons.forward:before,
// .mi.forward:before {
//   content: '\e154';
// }

// .material-icons.forward_10:before,
// .mi.forward_10:before {
//   content: '\e056';
// }

// .material-icons.forward_30:before,
// .mi.forward_30:before {
//   content: '\e057';
// }

// .material-icons.forward_5:before,
// .mi.forward_5:before {
//   content: '\e058';
// }

// .material-icons.free_breakfast:before,
// .mi.free_breakfast:before {
//   content: '\eb44';
// }

// .material-icons.fullscreen:before,
// .mi.fullscreen:before {
//   content: '\e5d0';
// }

// .material-icons.fullscreen_exit:before,
// .mi.fullscreen_exit:before {
//   content: '\e5d1';
// }

// .material-icons.functions:before,
// .mi.functions:before {
//   content: '\e24a';
// }

// .material-icons.g_translate:before,
// .mi.g_translate:before {
//   content: '\e927';
// }

// .material-icons.gamepad:before,
// .mi.gamepad:before {
//   content: '\e30f';
// }

// .material-icons.games:before,
// .mi.games:before {
//   content: '\e021';
// }

// .material-icons.gavel:before,
// .mi.gavel:before {
//   content: '\e90e';
// }

// .material-icons.gesture:before,
// .mi.gesture:before {
//   content: '\e155';
// }

// .material-icons.get_app:before,
// .mi.get_app:before {
//   content: '\e884';
// }

// .material-icons.gif:before,
// .mi.gif:before {
//   content: '\e908';
// }

// .material-icons.golf_course:before,
// .mi.golf_course:before {
//   content: '\eb45';
// }

// .material-icons.gps_fixed:before,
// .mi.gps_fixed:before {
//   content: '\e1b3';
// }

// .material-icons.gps_not_fixed:before,
// .mi.gps_not_fixed:before {
//   content: '\e1b4';
// }

// .material-icons.gps_off:before,
// .mi.gps_off:before {
//   content: '\e1b5';
// }

// .material-icons.grade:before,
// .mi.grade:before {
//   content: '\e885';
// }

// .material-icons.gradient:before,
// .mi.gradient:before {
//   content: '\e3e9';
// }

// .material-icons.grain:before,
// .mi.grain:before {
//   content: '\e3ea';
// }

// .material-icons.graphic_eq:before,
// .mi.graphic_eq:before {
//   content: '\e1b8';
// }

// .material-icons.grid_off:before,
// .mi.grid_off:before {
//   content: '\e3eb';
// }

// .material-icons.grid_on:before,
// .mi.grid_on:before {
//   content: '\e3ec';
// }

// .material-icons.group:before,
// .mi.group:before {
//   content: '\e7ef';
// }

// .material-icons.group_add:before,
// .mi.group_add:before {
//   content: '\e7f0';
// }

// .material-icons.group_work:before,
// .mi.group_work:before {
//   content: '\e886';
// }

// .material-icons.hd:before,
// .mi.hd:before {
//   content: '\e052';
// }

// .material-icons.hdr_off:before,
// .mi.hdr_off:before {
//   content: '\e3ed';
// }

// .material-icons.hdr_on:before,
// .mi.hdr_on:before {
//   content: '\e3ee';
// }

// .material-icons.hdr_strong:before,
// .mi.hdr_strong:before {
//   content: '\e3f1';
// }

// .material-icons.hdr_weak:before,
// .mi.hdr_weak:before {
//   content: '\e3f2';
// }

// .material-icons.headset:before,
// .mi.headset:before {
//   content: '\e310';
// }

// .material-icons.headset_mic:before,
// .mi.headset_mic:before {
//   content: '\e311';
// }

// .material-icons.healing:before,
// .mi.healing:before {
//   content: '\e3f3';
// }

// .material-icons.hearing:before,
// .mi.hearing:before {
//   content: '\e023';
// }

// .material-icons.help:before,
// .mi.help:before {
//   content: '\e887';
// }

// .material-icons.help_outline:before,
// .mi.help_outline:before {
//   content: '\e8fd';
// }

// .material-icons.high_quality:before,
// .mi.high_quality:before {
//   content: '\e024';
// }

// .material-icons.highlight:before,
// .mi.highlight:before {
//   content: '\e25f';
// }

// .material-icons.highlight_off:before,
// .mi.highlight_off:before {
//   content: '\e888';
// }

// .material-icons.history:before,
// .mi.history:before {
//   content: '\e889';
// }

// .material-icons.home:before,
// .mi.home:before {
//   content: '\e88a';
// }

// .material-icons.hot_tub:before,
// .mi.hot_tub:before {
//   content: '\eb46';
// }

// .material-icons.hotel:before,
// .mi.hotel:before {
//   content: '\e53a';
// }

// .material-icons.hourglass_empty:before,
// .mi.hourglass_empty:before {
//   content: '\e88b';
// }

// .material-icons.hourglass_full:before,
// .mi.hourglass_full:before {
//   content: '\e88c';
// }

// .material-icons.http:before,
// .mi.http:before {
//   content: '\e902';
// }

// .material-icons.https:before,
// .mi.https:before {
//   content: '\e88d';
// }

// .material-icons.image:before,
// .mi.image:before {
//   content: '\e3f4';
// }

// .material-icons.image_aspect_ratio:before,
// .mi.image_aspect_ratio:before {
//   content: '\e3f5';
// }

// .material-icons.import_contacts:before,
// .mi.import_contacts:before {
//   content: '\e0e0';
// }

// .material-icons.import_export:before,
// .mi.import_export:before {
//   content: '\e0c3';
// }

// .material-icons.important_devices:before,
// .mi.important_devices:before {
//   content: '\e912';
// }

// .material-icons.inbox:before,
// .mi.inbox:before {
//   content: '\e156';
// }

// .material-icons.indeterminate_check_box:before,
// .mi.indeterminate_check_box:before {
//   content: '\e909';
// }

// .material-icons.info:before,
// .mi.info:before {
//   content: '\e88e';
// }

// .material-icons.info_outline:before,
// .mi.info_outline:before {
//   content: '\e88f';
// }

// .material-icons.input:before,
// .mi.input:before {
//   content: '\e890';
// }

// .material-icons.insert_chart:before,
// .mi.insert_chart:before {
//   content: '\e24b';
// }

// .material-icons.insert_comment:before,
// .mi.insert_comment:before {
//   content: '\e24c';
// }

// .material-icons.insert_drive_file:before,
// .mi.insert_drive_file:before {
//   content: '\e24d';
// }

// .material-icons.insert_emoticon:before,
// .mi.insert_emoticon:before {
//   content: '\e24e';
// }

// .material-icons.insert_invitation:before,
// .mi.insert_invitation:before {
//   content: '\e24f';
// }

// .material-icons.insert_link:before,
// .mi.insert_link:before {
//   content: '\e250';
// }

// .material-icons.insert_photo:before,
// .mi.insert_photo:before {
//   content: '\e251';
// }

// .material-icons.invert_colors:before,
// .mi.invert_colors:before {
//   content: '\e891';
// }

// .material-icons.invert_colors_off:before,
// .mi.invert_colors_off:before {
//   content: '\e0c4';
// }

// .material-icons.iso:before,
// .mi.iso:before {
//   content: '\e3f6';
// }

// .material-icons.keyboard:before,
// .mi.keyboard:before {
//   content: '\e312';
// }

// .material-icons.keyboard_arrow_down:before,
// .mi.keyboard_arrow_down:before {
//   content: '\e313';
// }

// .material-icons.keyboard_arrow_left:before,
// .mi.keyboard_arrow_left:before {
//   content: '\e314';
// }

// .material-icons.keyboard_arrow_right:before,
// .mi.keyboard_arrow_right:before {
//   content: '\e315';
// }

// .material-icons.keyboard_arrow_up:before,
// .mi.keyboard_arrow_up:before {
//   content: '\e316';
// }

// .material-icons.keyboard_backspace:before,
// .mi.keyboard_backspace:before {
//   content: '\e317';
// }

// .material-icons.keyboard_capslock:before,
// .mi.keyboard_capslock:before {
//   content: '\e318';
// }

// .material-icons.keyboard_hide:before,
// .mi.keyboard_hide:before {
//   content: '\e31a';
// }

// .material-icons.keyboard_return:before,
// .mi.keyboard_return:before {
//   content: '\e31b';
// }

// .material-icons.keyboard_tab:before,
// .mi.keyboard_tab:before {
//   content: '\e31c';
// }

// .material-icons.keyboard_voice:before,
// .mi.keyboard_voice:before {
//   content: '\e31d';
// }

// .material-icons.kitchen:before,
// .mi.kitchen:before {
//   content: '\eb47';
// }

// .material-icons.label:before,
// .mi.label:before {
//   content: '\e892';
// }

// .material-icons.label_outline:before,
// .mi.label_outline:before {
//   content: '\e893';
// }

// .material-icons.landscape:before,
// .mi.landscape:before {
//   content: '\e3f7';
// }

// .material-icons.language:before,
// .mi.language:before {
//   content: '\e894';
// }

// .material-icons.laptop:before,
// .mi.laptop:before {
//   content: '\e31e';
// }

// .material-icons.laptop_chromebook:before,
// .mi.laptop_chromebook:before {
//   content: '\e31f';
// }

// .material-icons.laptop_mac:before,
// .mi.laptop_mac:before {
//   content: '\e320';
// }

// .material-icons.laptop_windows:before,
// .mi.laptop_windows:before {
//   content: '\e321';
// }

// .material-icons.last_page:before,
// .mi.last_page:before {
//   content: '\e5dd';
// }

// .material-icons.launch:before,
// .mi.launch:before {
//   content: '\e895';
// }

// .material-icons.layers:before,
// .mi.layers:before {
//   content: '\e53b';
// }

// .material-icons.layers_clear:before,
// .mi.layers_clear:before {
//   content: '\e53c';
// }

// .material-icons.leak_add:before,
// .mi.leak_add:before {
//   content: '\e3f8';
// }

// .material-icons.leak_remove:before,
// .mi.leak_remove:before {
//   content: '\e3f9';
// }

// .material-icons.lens:before,
// .mi.lens:before {
//   content: '\e3fa';
// }

// .material-icons.library_add:before,
// .mi.library_add:before {
//   content: '\e02e';
// }

// .material-icons.library_books:before,
// .mi.library_books:before {
//   content: '\e02f';
// }

// .material-icons.library_music:before,
// .mi.library_music:before {
//   content: '\e030';
// }

// .material-icons.lightbulb_outline:before,
// .mi.lightbulb_outline:before {
//   content: '\e90f';
// }

// .material-icons.line_style:before,
// .mi.line_style:before {
//   content: '\e919';
// }

// .material-icons.line_weight:before,
// .mi.line_weight:before {
//   content: '\e91a';
// }

// .material-icons.linear_scale:before,
// .mi.linear_scale:before {
//   content: '\e260';
// }

// .material-icons.link:before,
// .mi.link:before {
//   content: '\e157';
// }

// .material-icons.linked_camera:before,
// .mi.linked_camera:before {
//   content: '\e438';
// }

// .material-icons.list:before,
// .mi.list:before {
//   content: '\e896';
// }

// .material-icons.live_help:before,
// .mi.live_help:before {
//   content: '\e0c6';
// }

// .material-icons.live_tv:before,
// .mi.live_tv:before {
//   content: '\e639';
// }

// .material-icons.local_activity:before,
// .mi.local_activity:before {
//   content: '\e53f';
// }

// .material-icons.local_airport:before,
// .mi.local_airport:before {
//   content: '\e53d';
// }

// .material-icons.local_atm:before,
// .mi.local_atm:before {
//   content: '\e53e';
// }

// .material-icons.local_bar:before,
// .mi.local_bar:before {
//   content: '\e540';
// }

// .material-icons.local_cafe:before,
// .mi.local_cafe:before {
//   content: '\e541';
// }

// .material-icons.local_car_wash:before,
// .mi.local_car_wash:before {
//   content: '\e542';
// }

// .material-icons.local_convenience_store:before,
// .mi.local_convenience_store:before {
//   content: '\e543';
// }

// .material-icons.local_dining:before,
// .mi.local_dining:before {
//   content: '\e556';
// }

// .material-icons.local_drink:before,
// .mi.local_drink:before {
//   content: '\e544';
// }

// .material-icons.local_florist:before,
// .mi.local_florist:before {
//   content: '\e545';
// }

// .material-icons.local_gas_station:before,
// .mi.local_gas_station:before {
//   content: '\e546';
// }

// .material-icons.local_grocery_store:before,
// .mi.local_grocery_store:before {
//   content: '\e547';
// }

// .material-icons.local_hospital:before,
// .mi.local_hospital:before {
//   content: '\e548';
// }

// .material-icons.local_hotel:before,
// .mi.local_hotel:before {
//   content: '\e549';
// }

// .material-icons.local_laundry_service:before,
// .mi.local_laundry_service:before {
//   content: '\e54a';
// }

// .material-icons.local_library:before,
// .mi.local_library:before {
//   content: '\e54b';
// }

// .material-icons.local_mall:before,
// .mi.local_mall:before {
//   content: '\e54c';
// }

// .material-icons.local_movies:before,
// .mi.local_movies:before {
//   content: '\e54d';
// }

// .material-icons.local_offer:before,
// .mi.local_offer:before {
//   content: '\e54e';
// }

// .material-icons.local_parking:before,
// .mi.local_parking:before {
//   content: '\e54f';
// }

// .material-icons.local_pharmacy:before,
// .mi.local_pharmacy:before {
//   content: '\e550';
// }

// .material-icons.local_phone:before,
// .mi.local_phone:before {
//   content: '\e551';
// }

// .material-icons.local_pizza:before,
// .mi.local_pizza:before {
//   content: '\e552';
// }

// .material-icons.local_play:before,
// .mi.local_play:before {
//   content: '\e553';
// }

// .material-icons.local_post_office:before,
// .mi.local_post_office:before {
//   content: '\e554';
// }

// .material-icons.local_printshop:before,
// .mi.local_printshop:before {
//   content: '\e555';
// }

// .material-icons.local_see:before,
// .mi.local_see:before {
//   content: '\e557';
// }

// .material-icons.local_shipping:before,
// .mi.local_shipping:before {
//   content: '\e558';
// }

// .material-icons.local_taxi:before,
// .mi.local_taxi:before {
//   content: '\e559';
// }

// .material-icons.location_city:before,
// .mi.location_city:before {
//   content: '\e7f1';
// }

// .material-icons.location_disabled:before,
// .mi.location_disabled:before {
//   content: '\e1b6';
// }

// .material-icons.location_off:before,
// .mi.location_off:before {
//   content: '\e0c7';
// }

// .material-icons.location_on:before,
// .mi.location_on:before {
//   content: '\e0c8';
// }

// .material-icons.location_searching:before,
// .mi.location_searching:before {
//   content: '\e1b7';
// }

// .material-icons.lock:before,
// .mi.lock:before {
//   content: '\e897';
// }

// .material-icons.lock_open:before,
// .mi.lock_open:before {
//   content: '\e898';
// }

// .material-icons.lock_outline:before,
// .mi.lock_outline:before {
//   content: '\e899';
// }

// .material-icons.looks:before,
// .mi.looks:before {
//   content: '\e3fc';
// }

// .material-icons.looks_3:before,
// .mi.looks_3:before {
//   content: '\e3fb';
// }

// .material-icons.looks_4:before,
// .mi.looks_4:before {
//   content: '\e3fd';
// }

// .material-icons.looks_5:before,
// .mi.looks_5:before {
//   content: '\e3fe';
// }

// .material-icons.looks_6:before,
// .mi.looks_6:before {
//   content: '\e3ff';
// }

// .material-icons.looks_one:before,
// .mi.looks_one:before {
//   content: '\e400';
// }

// .material-icons.looks_two:before,
// .mi.looks_two:before {
//   content: '\e401';
// }

// .material-icons.loop:before,
// .mi.loop:before {
//   content: '\e028';
// }

// .material-icons.loupe:before,
// .mi.loupe:before {
//   content: '\e402';
// }

// .material-icons.low_priority:before,
// .mi.low_priority:before {
//   content: '\e16d';
// }

// .material-icons.loyalty:before,
// .mi.loyalty:before {
//   content: '\e89a';
// }

// .material-icons.mail:before,
// .mi.mail:before {
//   content: '\e158';
// }

// .material-icons.mail_outline:before,
// .mi.mail_outline:before {
//   content: '\e0e1';
// }

// .material-icons.map:before,
// .mi.map:before {
//   content: '\e55b';
// }

// .material-icons.markunread:before,
// .mi.markunread:before {
//   content: '\e159';
// }

// .material-icons.markunread_mailbox:before,
// .mi.markunread_mailbox:before {
//   content: '\e89b';
// }

// .material-icons.memory:before,
// .mi.memory:before {
//   content: '\e322';
// }

// .material-icons.menu:before,
// .mi.menu:before {
//   content: '\e5d2';
// }

// .material-icons.merge_type:before,
// .mi.merge_type:before {
//   content: '\e252';
// }

// .material-icons.message:before,
// .mi.message:before {
//   content: '\e0c9';
// }

// .material-icons.mic:before,
// .mi.mic:before {
//   content: '\e029';
// }

// .material-icons.mic_none:before,
// .mi.mic_none:before {
//   content: '\e02a';
// }

// .material-icons.mic_off:before,
// .mi.mic_off:before {
//   content: '\e02b';
// }

// .material-icons.mms:before,
// .mi.mms:before {
//   content: '\e618';
// }

// .material-icons.mode_comment:before,
// .mi.mode_comment:before {
//   content: '\e253';
// }

// .material-icons.mode_edit:before,
// .mi.mode_edit:before {
//   content: '\e254';
// }

// .material-icons.monetization_on:before,
// .mi.monetization_on:before {
//   content: '\e263';
// }

// .material-icons.money_off:before,
// .mi.money_off:before {
//   content: '\e25c';
// }

// .material-icons.monochrome_photos:before,
// .mi.monochrome_photos:before {
//   content: '\e403';
// }

// .material-icons.mood:before,
// .mi.mood:before {
//   content: '\e7f2';
// }

// .material-icons.mood_bad:before,
// .mi.mood_bad:before {
//   content: '\e7f3';
// }

// .material-icons.more:before,
// .mi.more:before {
//   content: '\e619';
// }

// .material-icons.more_horiz:before,
// .mi.more_horiz:before {
//   content: '\e5d3';
// }

// .material-icons.more_vert:before,
// .mi.more_vert:before {
//   content: '\e5d4';
// }

// .material-icons.motorcycle:before,
// .mi.motorcycle:before {
//   content: '\e91b';
// }

// .material-icons.mouse:before,
// .mi.mouse:before {
//   content: '\e323';
// }

// .material-icons.move_to_inbox:before,
// .mi.move_to_inbox:before {
//   content: '\e168';
// }

// .material-icons.movie:before,
// .mi.movie:before {
//   content: '\e02c';
// }

// .material-icons.movie_creation:before,
// .mi.movie_creation:before {
//   content: '\e404';
// }

// .material-icons.movie_filter:before,
// .mi.movie_filter:before {
//   content: '\e43a';
// }

// .material-icons.multiline_chart:before,
// .mi.multiline_chart:before {
//   content: '\e6df';
// }

// .material-icons.music_note:before,
// .mi.music_note:before {
//   content: '\e405';
// }

// .material-icons.music_video:before,
// .mi.music_video:before {
//   content: '\e063';
// }

// .material-icons.my_location:before,
// .mi.my_location:before {
//   content: '\e55c';
// }

// .material-icons.nature:before,
// .mi.nature:before {
//   content: '\e406';
// }

// .material-icons.nature_people:before,
// .mi.nature_people:before {
//   content: '\e407';
// }

// .material-icons.navigate_before:before,
// .mi.navigate_before:before {
//   content: '\e408';
// }

// .material-icons.navigate_next:before,
// .mi.navigate_next:before {
//   content: '\e409';
// }

// .material-icons.navigation:before,
// .mi.navigation:before {
//   content: '\e55d';
// }

// .material-icons.near_me:before,
// .mi.near_me:before {
//   content: '\e569';
// }

// .material-icons.network_cell:before,
// .mi.network_cell:before {
//   content: '\e1b9';
// }

// .material-icons.network_check:before,
// .mi.network_check:before {
//   content: '\e640';
// }

// .material-icons.network_locked:before,
// .mi.network_locked:before {
//   content: '\e61a';
// }

// .material-icons.network_wifi:before,
// .mi.network_wifi:before {
//   content: '\e1ba';
// }

// .material-icons.new_releases:before,
// .mi.new_releases:before {
//   content: '\e031';
// }

// .material-icons.next_week:before,
// .mi.next_week:before {
//   content: '\e16a';
// }

// .material-icons.nfc:before,
// .mi.nfc:before {
//   content: '\e1bb';
// }

// .material-icons.no_encryption:before,
// .mi.no_encryption:before {
//   content: '\e641';
// }

// .material-icons.no_sim:before,
// .mi.no_sim:before {
//   content: '\e0cc';
// }

// .material-icons.not_interested:before,
// .mi.not_interested:before {
//   content: '\e033';
// }

// .material-icons.note:before,
// .mi.note:before {
//   content: '\e06f';
// }

// .material-icons.note_add:before,
// .mi.note_add:before {
//   content: '\e89c';
// }

// .material-icons.notifications:before,
// .mi.notifications:before {
//   content: '\e7f4';
// }

// .material-icons.notifications_active:before,
// .mi.notifications_active:before {
//   content: '\e7f7';
// }

// .material-icons.notifications_none:before,
// .mi.notifications_none:before {
//   content: '\e7f5';
// }

// .material-icons.notifications_off:before,
// .mi.notifications_off:before {
//   content: '\e7f6';
// }

// .material-icons.notifications_paused:before,
// .mi.notifications_paused:before {
//   content: '\e7f8';
// }

// .material-icons.offline_pin:before,
// .mi.offline_pin:before {
//   content: '\e90a';
// }

// .material-icons.ondemand_video:before,
// .mi.ondemand_video:before {
//   content: '\e63a';
// }

// .material-icons.opacity:before,
// .mi.opacity:before {
//   content: '\e91c';
// }

// .material-icons.open_in_browser:before,
// .mi.open_in_browser:before {
//   content: '\e89d';
// }

// .material-icons.open_in_new:before,
// .mi.open_in_new:before {
//   content: '\e89e';
// }

// .material-icons.open_with:before,
// .mi.open_with:before {
//   content: '\e89f';
// }

// .material-icons.pages:before,
// .mi.pages:before {
//   content: '\e7f9';
// }

// .material-icons.pageview:before,
// .mi.pageview:before {
//   content: '\e8a0';
// }

// .material-icons.palette:before,
// .mi.palette:before {
//   content: '\e40a';
// }

// .material-icons.pan_tool:before,
// .mi.pan_tool:before {
//   content: '\e925';
// }

// .material-icons.panorama:before,
// .mi.panorama:before {
//   content: '\e40b';
// }

// .material-icons.panorama_fish_eye:before,
// .mi.panorama_fish_eye:before {
//   content: '\e40c';
// }

// .material-icons.panorama_horizontal:before,
// .mi.panorama_horizontal:before {
//   content: '\e40d';
// }

// .material-icons.panorama_vertical:before,
// .mi.panorama_vertical:before {
//   content: '\e40e';
// }

// .material-icons.panorama_wide_angle:before,
// .mi.panorama_wide_angle:before {
//   content: '\e40f';
// }

// .material-icons.party_mode:before,
// .mi.party_mode:before {
//   content: '\e7fa';
// }

// .material-icons.pause:before,
// .mi.pause:before {
//   content: '\e034';
// }

// .material-icons.pause_circle_filled:before,
// .mi.pause_circle_filled:before {
//   content: '\e035';
// }

// .material-icons.pause_circle_outline:before,
// .mi.pause_circle_outline:before {
//   content: '\e036';
// }

// .material-icons.payment:before,
// .mi.payment:before {
//   content: '\e8a1';
// }

// .material-icons.people:before,
// .mi.people:before {
//   content: '\e7fb';
// }

// .material-icons.people_outline:before,
// .mi.people_outline:before {
//   content: '\e7fc';
// }

// .material-icons.perm_camera_mic:before,
// .mi.perm_camera_mic:before {
//   content: '\e8a2';
// }

// .material-icons.perm_contact_calendar:before,
// .mi.perm_contact_calendar:before {
//   content: '\e8a3';
// }

// .material-icons.perm_data_setting:before,
// .mi.perm_data_setting:before {
//   content: '\e8a4';
// }

// .material-icons.perm_device_information:before,
// .mi.perm_device_information:before {
//   content: '\e8a5';
// }

// .material-icons.perm_identity:before,
// .mi.perm_identity:before {
//   content: '\e8a6';
// }

// .material-icons.perm_media:before,
// .mi.perm_media:before {
//   content: '\e8a7';
// }

// .material-icons.perm_phone_msg:before,
// .mi.perm_phone_msg:before {
//   content: '\e8a8';
// }

// .material-icons.perm_scan_wifi:before,
// .mi.perm_scan_wifi:before {
//   content: '\e8a9';
// }

// .material-icons.person:before,
// .mi.person:before {
//   content: '\e7fd';
// }

// .material-icons.person_add:before,
// .mi.person_add:before {
//   content: '\e7fe';
// }

// .material-icons.person_outline:before,
// .mi.person_outline:before {
//   content: '\e7ff';
// }

// .material-icons.person_pin:before,
// .mi.person_pin:before {
//   content: '\e55a';
// }

// .material-icons.person_pin_circle:before,
// .mi.person_pin_circle:before {
//   content: '\e56a';
// }

// .material-icons.personal_video:before,
// .mi.personal_video:before {
//   content: '\e63b';
// }

// .material-icons.pets:before,
// .mi.pets:before {
//   content: '\e91d';
// }

// .material-icons.phone:before,
// .mi.phone:before {
//   content: '\e0cd';
// }

// .material-icons.phone_android:before,
// .mi.phone_android:before {
//   content: '\e324';
// }

// .material-icons.phone_bluetooth_speaker:before,
// .mi.phone_bluetooth_speaker:before {
//   content: '\e61b';
// }

// .material-icons.phone_forwarded:before,
// .mi.phone_forwarded:before {
//   content: '\e61c';
// }

// .material-icons.phone_in_talk:before,
// .mi.phone_in_talk:before {
//   content: '\e61d';
// }

// .material-icons.phone_iphone:before,
// .mi.phone_iphone:before {
//   content: '\e325';
// }

// .material-icons.phone_locked:before,
// .mi.phone_locked:before {
//   content: '\e61e';
// }

// .material-icons.phone_missed:before,
// .mi.phone_missed:before {
//   content: '\e61f';
// }

// .material-icons.phone_paused:before,
// .mi.phone_paused:before {
//   content: '\e620';
// }

// .material-icons.phonelink:before,
// .mi.phonelink:before {
//   content: '\e326';
// }

// .material-icons.phonelink_erase:before,
// .mi.phonelink_erase:before {
//   content: '\e0db';
// }

// .material-icons.phonelink_lock:before,
// .mi.phonelink_lock:before {
//   content: '\e0dc';
// }

// .material-icons.phonelink_off:before,
// .mi.phonelink_off:before {
//   content: '\e327';
// }

// .material-icons.phonelink_ring:before,
// .mi.phonelink_ring:before {
//   content: '\e0dd';
// }

// .material-icons.phonelink_setup:before,
// .mi.phonelink_setup:before {
//   content: '\e0de';
// }

// .material-icons.photo:before,
// .mi.photo:before {
//   content: '\e410';
// }

// .material-icons.photo_album:before,
// .mi.photo_album:before {
//   content: '\e411';
// }

// .material-icons.photo_camera:before,
// .mi.photo_camera:before {
//   content: '\e412';
// }

// .material-icons.photo_filter:before,
// .mi.photo_filter:before {
//   content: '\e43b';
// }

// .material-icons.photo_library:before,
// .mi.photo_library:before {
//   content: '\e413';
// }

// .material-icons.photo_size_select_actual:before,
// .mi.photo_size_select_actual:before {
//   content: '\e432';
// }

// .material-icons.photo_size_select_large:before,
// .mi.photo_size_select_large:before {
//   content: '\e433';
// }

// .material-icons.photo_size_select_small:before,
// .mi.photo_size_select_small:before {
//   content: '\e434';
// }

// .material-icons.picture_as_pdf:before,
// .mi.picture_as_pdf:before {
//   content: '\e415';
// }

// .material-icons.picture_in_picture:before,
// .mi.picture_in_picture:before {
//   content: '\e8aa';
// }

// .material-icons.picture_in_picture_alt:before,
// .mi.picture_in_picture_alt:before {
//   content: '\e911';
// }

// .material-icons.pie_chart:before,
// .mi.pie_chart:before {
//   content: '\e6c4';
// }

// .material-icons.pie_chart_outlined:before,
// .mi.pie_chart_outlined:before {
//   content: '\e6c5';
// }

// .material-icons.pin_drop:before,
// .mi.pin_drop:before {
//   content: '\e55e';
// }

// .material-icons.place:before,
// .mi.place:before {
//   content: '\e55f';
// }

// .material-icons.play_arrow:before,
// .mi.play_arrow:before {
//   content: '\e037';
// }

// .material-icons.play_circle_filled:before,
// .mi.play_circle_filled:before {
//   content: '\e038';
// }

// .material-icons.play_circle_outline:before,
// .mi.play_circle_outline:before {
//   content: '\e039';
// }

// .material-icons.play_for_work:before,
// .mi.play_for_work:before {
//   content: '\e906';
// }

// .material-icons.playlist_add:before,
// .mi.playlist_add:before {
//   content: '\e03b';
// }

// .material-icons.playlist_add_check:before,
// .mi.playlist_add_check:before {
//   content: '\e065';
// }

// .material-icons.playlist_play:before,
// .mi.playlist_play:before {
//   content: '\e05f';
// }

// .material-icons.plus_one:before,
// .mi.plus_one:before {
//   content: '\e800';
// }

// .material-icons.poll:before,
// .mi.poll:before {
//   content: '\e801';
// }

// .material-icons.polymer:before,
// .mi.polymer:before {
//   content: '\e8ab';
// }

// .material-icons.pool:before,
// .mi.pool:before {
//   content: '\eb48';
// }

// .material-icons.portable_wifi_off:before,
// .mi.portable_wifi_off:before {
//   content: '\e0ce';
// }

// .material-icons.portrait:before,
// .mi.portrait:before {
//   content: '\e416';
// }

// .material-icons.power:before,
// .mi.power:before {
//   content: '\e63c';
// }

// .material-icons.power_input:before,
// .mi.power_input:before {
//   content: '\e336';
// }

// .material-icons.power_settings_new:before,
// .mi.power_settings_new:before {
//   content: '\e8ac';
// }

// .material-icons.pregnant_woman:before,
// .mi.pregnant_woman:before {
//   content: '\e91e';
// }

// .material-icons.present_to_all:before,
// .mi.present_to_all:before {
//   content: '\e0df';
// }

// .material-icons.print:before,
// .mi.print:before {
//   content: '\e8ad';
// }

// .material-icons.priority_high:before,
// .mi.priority_high:before {
//   content: '\e645';
// }

// .material-icons.public:before,
// .mi.public:before {
//   content: '\e80b';
// }

// .material-icons.publish:before,
// .mi.publish:before {
//   content: '\e255';
// }

// .material-icons.query_builder:before,
// .mi.query_builder:before {
//   content: '\e8ae';
// }

// .material-icons.question_answer:before,
// .mi.question_answer:before {
//   content: '\e8af';
// }

// .material-icons.queue:before,
// .mi.queue:before {
//   content: '\e03c';
// }

// .material-icons.queue_music:before,
// .mi.queue_music:before {
//   content: '\e03d';
// }

// .material-icons.queue_play_next:before,
// .mi.queue_play_next:before {
//   content: '\e066';
// }

// .material-icons.radio:before,
// .mi.radio:before {
//   content: '\e03e';
// }

// .material-icons.radio_button_checked:before,
// .mi.radio_button_checked:before {
//   content: '\e837';
// }

// .material-icons.radio_button_unchecked:before,
// .mi.radio_button_unchecked:before {
//   content: '\e836';
// }

// .material-icons.rate_review:before,
// .mi.rate_review:before {
//   content: '\e560';
// }

// .material-icons.receipt:before,
// .mi.receipt:before {
//   content: '\e8b0';
// }

// .material-icons.recent_actors:before,
// .mi.recent_actors:before {
//   content: '\e03f';
// }

// .material-icons.record_voice_over:before,
// .mi.record_voice_over:before {
//   content: '\e91f';
// }

// .material-icons.redeem:before,
// .mi.redeem:before {
//   content: '\e8b1';
// }

// .material-icons.redo:before,
// .mi.redo:before {
//   content: '\e15a';
// }

// .material-icons.refresh:before,
// .mi.refresh:before {
//   content: '\e5d5';
// }

// .material-icons.remove_circle:before,
// .mi.remove_circle:before {
//   content: '\e15c';
// }

// .material-icons.remove_circle_outline:before,
// .mi.remove_circle_outline:before {
//   content: '\e15d';
// }

// .material-icons.remove_from_queue:before,
// .mi.remove_from_queue:before {
//   content: '\e067';
// }

// .material-icons.remove_red_eye:before,
// .mi.remove_red_eye:before {
//   content: '\e417';
// }

// .material-icons.remove_shopping_cart:before,
// .mi.remove_shopping_cart:before {
//   content: '\e928';
// }

// .material-icons.reorder:before,
// .mi.reorder:before {
//   content: '\e8fe';
// }

// .material-icons.repeat:before,
// .mi.repeat:before {
//   content: '\e040';
// }

// .material-icons.repeat_one:before,
// .mi.repeat_one:before {
//   content: '\e041';
// }

// .material-icons.replay:before,
// .mi.replay:before {
//   content: '\e042';
// }

// .material-icons.replay_10:before,
// .mi.replay_10:before {
//   content: '\e059';
// }

// .material-icons.replay_30:before,
// .mi.replay_30:before {
//   content: '\e05a';
// }

// .material-icons.replay_5:before,
// .mi.replay_5:before {
//   content: '\e05b';
// }

// .material-icons.reply:before,
// .mi.reply:before {
//   content: '\e15e';
// }

// .material-icons.reply_all:before,
// .mi.reply_all:before {
//   content: '\e15f';
// }

// .material-icons.report:before,
// .mi.report:before {
//   content: '\e160';
// }

// .material-icons.report_problem:before,
// .mi.report_problem:before {
//   content: '\e8b2';
// }

// .material-icons.restaurant:before,
// .mi.restaurant:before {
//   content: '\e56c';
// }

// .material-icons.restaurant_menu:before,
// .mi.restaurant_menu:before {
//   content: '\e561';
// }

// .material-icons.restore:before,
// .mi.restore:before {
//   content: '\e8b3';
// }

// .material-icons.restore_page:before,
// .mi.restore_page:before {
//   content: '\e929';
// }

// .material-icons.ring_volume:before,
// .mi.ring_volume:before {
//   content: '\e0d1';
// }

// .material-icons.room:before,
// .mi.room:before {
//   content: '\e8b4';
// }

// .material-icons.room_service:before,
// .mi.room_service:before {
//   content: '\eb49';
// }

// .material-icons.rotate_90_degrees_ccw:before,
// .mi.rotate_90_degrees_ccw:before {
//   content: '\e418';
// }

// .material-icons.rotate_left:before,
// .mi.rotate_left:before {
//   content: '\e419';
// }

// .material-icons.rotate_right:before,
// .mi.rotate_right:before {
//   content: '\e41a';
// }

// .material-icons.rounded_corner:before,
// .mi.rounded_corner:before {
//   content: '\e920';
// }

// .material-icons.router:before,
// .mi.router:before {
//   content: '\e328';
// }

// .material-icons.rowing:before,
// .mi.rowing:before {
//   content: '\e921';
// }

// .material-icons.rss_feed:before,
// .mi.rss_feed:before {
//   content: '\e0e5';
// }

// .material-icons.rv_hookup:before,
// .mi.rv_hookup:before {
//   content: '\e642';
// }

// .material-icons.satellite:before,
// .mi.satellite:before {
//   content: '\e562';
// }

// .material-icons.save:before,
// .mi.save:before {
//   content: '\e161';
// }

// .material-icons.scanner:before,
// .mi.scanner:before {
//   content: '\e329';
// }

// .material-icons.schedule:before,
// .mi.schedule:before {
//   content: '\e8b5';
// }

// .material-icons.school:before,
// .mi.school:before {
//   content: '\e80c';
// }

// .material-icons.screen_lock_landscape:before,
// .mi.screen_lock_landscape:before {
//   content: '\e1be';
// }

// .material-icons.screen_lock_portrait:before,
// .mi.screen_lock_portrait:before {
//   content: '\e1bf';
// }

// .material-icons.screen_lock_rotation:before,
// .mi.screen_lock_rotation:before {
//   content: '\e1c0';
// }

// .material-icons.screen_rotation:before,
// .mi.screen_rotation:before {
//   content: '\e1c1';
// }

// .material-icons.screen_share:before,
// .mi.screen_share:before {
//   content: '\e0e2';
// }

// .material-icons.sd_card:before,
// .mi.sd_card:before {
//   content: '\e623';
// }

// .material-icons.sd_storage:before,
// .mi.sd_storage:before {
//   content: '\e1c2';
// }

// .material-icons.search:before,
// .mi.search:before {
//   content: '\e8b6';
// }

// .material-icons.security:before,
// .mi.security:before {
//   content: '\e32a';
// }

// .material-icons.select_all:before,
// .mi.select_all:before {
//   content: '\e162';
// }

// .material-icons.send:before,
// .mi.send:before {
//   content: '\e163';
// }

// .material-icons.sentiment_dissatisfied:before,
// .mi.sentiment_dissatisfied:before {
//   content: '\e811';
// }

// .material-icons.sentiment_neutral:before,
// .mi.sentiment_neutral:before {
//   content: '\e812';
// }

// .material-icons.sentiment_satisfied:before,
// .mi.sentiment_satisfied:before {
//   content: '\e813';
// }

// .material-icons.sentiment_very_dissatisfied:before,
// .mi.sentiment_very_dissatisfied:before {
//   content: '\e814';
// }

// .material-icons.sentiment_very_satisfied:before,
// .mi.sentiment_very_satisfied:before {
//   content: '\e815';
// }

// .material-icons.settings:before,
// .mi.settings:before {
//   content: '\e8b8';
// }

// .material-icons.settings_applications:before,
// .mi.settings_applications:before {
//   content: '\e8b9';
// }

// .material-icons.settings_backup_restore:before,
// .mi.settings_backup_restore:before {
//   content: '\e8ba';
// }

// .material-icons.settings_bluetooth:before,
// .mi.settings_bluetooth:before {
//   content: '\e8bb';
// }

// .material-icons.settings_brightness:before,
// .mi.settings_brightness:before {
//   content: '\e8bd';
// }

// .material-icons.settings_cell:before,
// .mi.settings_cell:before {
//   content: '\e8bc';
// }

// .material-icons.settings_ethernet:before,
// .mi.settings_ethernet:before {
//   content: '\e8be';
// }

// .material-icons.settings_input_antenna:before,
// .mi.settings_input_antenna:before {
//   content: '\e8bf';
// }

// .material-icons.settings_input_component:before,
// .mi.settings_input_component:before {
//   content: '\e8c0';
// }

// .material-icons.settings_input_composite:before,
// .mi.settings_input_composite:before {
//   content: '\e8c1';
// }

// .material-icons.settings_input_hdmi:before,
// .mi.settings_input_hdmi:before {
//   content: '\e8c2';
// }

// .material-icons.settings_input_svideo:before,
// .mi.settings_input_svideo:before {
//   content: '\e8c3';
// }

// .material-icons.settings_overscan:before,
// .mi.settings_overscan:before {
//   content: '\e8c4';
// }

// .material-icons.settings_phone:before,
// .mi.settings_phone:before {
//   content: '\e8c5';
// }

// .material-icons.settings_power:before,
// .mi.settings_power:before {
//   content: '\e8c6';
// }

// .material-icons.settings_remote:before,
// .mi.settings_remote:before {
//   content: '\e8c7';
// }

// .material-icons.settings_system_daydream:before,
// .mi.settings_system_daydream:before {
//   content: '\e1c3';
// }

// .material-icons.settings_voice:before,
// .mi.settings_voice:before {
//   content: '\e8c8';
// }

// .material-icons.share:before,
// .mi.share:before {
//   content: '\e80d';
// }

// .material-icons.shop:before,
// .mi.shop:before {
//   content: '\e8c9';
// }

// .material-icons.shop_two:before,
// .mi.shop_two:before {
//   content: '\e8ca';
// }

// .material-icons.shopping_basket:before,
// .mi.shopping_basket:before {
//   content: '\e8cb';
// }

// .material-icons.shopping_cart:before,
// .mi.shopping_cart:before {
//   content: '\e8cc';
// }

// .material-icons.short_text:before,
// .mi.short_text:before {
//   content: '\e261';
// }

// .material-icons.show_chart:before,
// .mi.show_chart:before {
//   content: '\e6e1';
// }

// .material-icons.shuffle:before,
// .mi.shuffle:before {
//   content: '\e043';
// }

// .material-icons.signal_cellular_4_bar:before,
// .mi.signal_cellular_4_bar:before {
//   content: '\e1c8';
// }

// .material-icons.signal_cellular_connected_no_internet_4_bar:before,
// .mi.signal_cellular_connected_no_internet_4_bar:before {
//   content: '\e1cd';
// }

// .material-icons.signal_cellular_no_sim:before,
// .mi.signal_cellular_no_sim:before {
//   content: '\e1ce';
// }

// .material-icons.signal_cellular_null:before,
// .mi.signal_cellular_null:before {
//   content: '\e1cf';
// }

// .material-icons.signal_cellular_off:before,
// .mi.signal_cellular_off:before {
//   content: '\e1d0';
// }

// .material-icons.signal_wifi_4_bar:before,
// .mi.signal_wifi_4_bar:before {
//   content: '\e1d8';
// }

// .material-icons.signal_wifi_4_bar_lock:before,
// .mi.signal_wifi_4_bar_lock:before {
//   content: '\e1d9';
// }

// .material-icons.signal_wifi_off:before,
// .mi.signal_wifi_off:before {
//   content: '\e1da';
// }

// .material-icons.sim_card:before,
// .mi.sim_card:before {
//   content: '\e32b';
// }

// .material-icons.sim_card_alert:before,
// .mi.sim_card_alert:before {
//   content: '\e624';
// }

// .material-icons.skip_next:before,
// .mi.skip_next:before {
//   content: '\e044';
// }

// .material-icons.skip_previous:before,
// .mi.skip_previous:before {
//   content: '\e045';
// }

// .material-icons.slideshow:before,
// .mi.slideshow:before {
//   content: '\e41b';
// }

// .material-icons.slow_motion_video:before,
// .mi.slow_motion_video:before {
//   content: '\e068';
// }

// .material-icons.smartphone:before,
// .mi.smartphone:before {
//   content: '\e32c';
// }

// .material-icons.smoke_free:before,
// .mi.smoke_free:before {
//   content: '\eb4a';
// }

// .material-icons.smoking_rooms:before,
// .mi.smoking_rooms:before {
//   content: '\eb4b';
// }

// .material-icons.sms:before,
// .mi.sms:before {
//   content: '\e625';
// }

// .material-icons.sms_failed:before,
// .mi.sms_failed:before {
//   content: '\e626';
// }

// .material-icons.snooze:before,
// .mi.snooze:before {
//   content: '\e046';
// }

// .material-icons.sort:before,
// .mi.sort:before {
//   content: '\e164';
// }

// .material-icons.sort_by_alpha:before,
// .mi.sort_by_alpha:before {
//   content: '\e053';
// }

// .material-icons.spa:before,
// .mi.spa:before {
//   content: '\eb4c';
// }

// .material-icons.space_bar:before,
// .mi.space_bar:before {
//   content: '\e256';
// }

// .material-icons.speaker:before,
// .mi.speaker:before {
//   content: '\e32d';
// }

// .material-icons.speaker_group:before,
// .mi.speaker_group:before {
//   content: '\e32e';
// }

// .material-icons.speaker_notes:before,
// .mi.speaker_notes:before {
//   content: '\e8cd';
// }

// .material-icons.speaker_notes_off:before,
// .mi.speaker_notes_off:before {
//   content: '\e92a';
// }

// .material-icons.speaker_phone:before,
// .mi.speaker_phone:before {
//   content: '\e0d2';
// }

// .material-icons.spellcheck:before,
// .mi.spellcheck:before {
//   content: '\e8ce';
// }

// .material-icons.stars:before,
// .mi.stars:before {
//   content: '\e8d0';
// }

// .material-icons.stay_current_landscape:before,
// .mi.stay_current_landscape:before {
//   content: '\e0d3';
// }

// .material-icons.stay_current_portrait:before,
// .mi.stay_current_portrait:before {
//   content: '\e0d4';
// }

// .material-icons.stay_primary_landscape:before,
// .mi.stay_primary_landscape:before {
//   content: '\e0d5';
// }

// .material-icons.stay_primary_portrait:before,
// .mi.stay_primary_portrait:before {
//   content: '\e0d6';
// }

// .material-icons.stop:before,
// .mi.stop:before {
//   content: '\e047';
// }

// .material-icons.stop_screen_share:before,
// .mi.stop_screen_share:before {
//   content: '\e0e3';
// }

// .material-icons.storage:before,
// .mi.storage:before {
//   content: '\e1db';
// }

// .material-icons.store:before,
// .mi.store:before {
//   content: '\e8d1';
// }

// .material-icons.store_mall_directory:before,
// .mi.store_mall_directory:before {
//   content: '\e563';
// }

// .material-icons.straighten:before,
// .mi.straighten:before {
//   content: '\e41c';
// }

// .material-icons.streetview:before,
// .mi.streetview:before {
//   content: '\e56e';
// }

// .material-icons.strikethrough_s:before,
// .mi.strikethrough_s:before {
//   content: '\e257';
// }

// .material-icons.style:before,
// .mi.style:before {
//   content: '\e41d';
// }

// .material-icons.subdirectory_arrow_left:before,
// .mi.subdirectory_arrow_left:before {
//   content: '\e5d9';
// }

// .material-icons.subdirectory_arrow_right:before,
// .mi.subdirectory_arrow_right:before {
//   content: '\e5da';
// }

// .material-icons.subject:before,
// .mi.subject:before {
//   content: '\e8d2';
// }

// .material-icons.subscriptions:before,
// .mi.subscriptions:before {
//   content: '\e064';
// }

// .material-icons.subtitles:before,
// .mi.subtitles:before {
//   content: '\e048';
// }

// .material-icons.subway:before,
// .mi.subway:before {
//   content: '\e56f';
// }

// .material-icons.supervisor_account:before,
// .mi.supervisor_account:before {
//   content: '\e8d3';
// }

// .material-icons.surround_sound:before,
// .mi.surround_sound:before {
//   content: '\e049';
// }

// .material-icons.swap_calls:before,
// .mi.swap_calls:before {
//   content: '\e0d7';
// }

// .material-icons.swap_horiz:before,
// .mi.swap_horiz:before {
//   content: '\e8d4';
// }

// .material-icons.swap_vert:before,
// .mi.swap_vert:before {
//   content: '\e8d5';
// }

// .material-icons.swap_vertical_circle:before,
// .mi.swap_vertical_circle:before {
//   content: '\e8d6';
// }

// .material-icons.switch_camera:before,
// .mi.switch_camera:before {
//   content: '\e41e';
// }

// .material-icons.switch_video:before,
// .mi.switch_video:before {
//   content: '\e41f';
// }

// .material-icons.sync:before,
// .mi.sync:before {
//   content: '\e627';
// }

// .material-icons.sync_disabled:before,
// .mi.sync_disabled:before {
//   content: '\e628';
// }

// .material-icons.sync_problem:before,
// .mi.sync_problem:before {
//   content: '\e629';
// }

// .material-icons.system_update:before,
// .mi.system_update:before {
//   content: '\e62a';
// }

// .material-icons.system_update_alt:before,
// .mi.system_update_alt:before {
//   content: '\e8d7';
// }

// .material-icons.tab:before,
// .mi.tab:before {
//   content: '\e8d8';
// }

// .material-icons.tab_unselected:before,
// .mi.tab_unselected:before {
//   content: '\e8d9';
// }

// .material-icons.tablet:before,
// .mi.tablet:before {
//   content: '\e32f';
// }

// .material-icons.tablet_android:before,
// .mi.tablet_android:before {
//   content: '\e330';
// }

// .material-icons.tablet_mac:before,
// .mi.tablet_mac:before {
//   content: '\e331';
// }

// .material-icons.tag_faces:before,
// .mi.tag_faces:before {
//   content: '\e420';
// }

// .material-icons.tap_and_play:before,
// .mi.tap_and_play:before {
//   content: '\e62b';
// }

// .material-icons.terrain:before,
// .mi.terrain:before {
//   content: '\e564';
// }

// .material-icons.text_fields:before,
// .mi.text_fields:before {
//   content: '\e262';
// }

// .material-icons.text_format:before,
// .mi.text_format:before {
//   content: '\e165';
// }

// .material-icons.textsms:before,
// .mi.textsms:before {
//   content: '\e0d8';
// }

// .material-icons.texture:before,
// .mi.texture:before {
//   content: '\e421';
// }

// .material-icons.theaters:before,
// .mi.theaters:before {
//   content: '\e8da';
// }

// .material-icons.thumb_down:before,
// .mi.thumb_down:before {
//   content: '\e8db';
// }

// .material-icons.thumb_up:before,
// .mi.thumb_up:before {
//   content: '\e8dc';
// }

// .material-icons.thumbs_up_down:before,
// .mi.thumbs_up_down:before {
//   content: '\e8dd';
// }

// .material-icons.time_to_leave:before,
// .mi.time_to_leave:before {
//   content: '\e62c';
// }

// .material-icons.timelapse:before,
// .mi.timelapse:before {
//   content: '\e422';
// }

// .material-icons.timeline:before,
// .mi.timeline:before {
//   content: '\e922';
// }

// .material-icons.timer:before,
// .mi.timer:before {
//   content: '\e425';
// }

// .material-icons.timer_10:before,
// .mi.timer_10:before {
//   content: '\e423';
// }

// .material-icons.timer_3:before,
// .mi.timer_3:before {
//   content: '\e424';
// }

// .material-icons.timer_off:before,
// .mi.timer_off:before {
//   content: '\e426';
// }

// .material-icons.title:before,
// .mi.title:before {
//   content: '\e264';
// }

// .material-icons.toc:before,
// .mi.toc:before {
//   content: '\e8de';
// }

// .material-icons.today:before,
// .mi.today:before {
//   content: '\e8df';
// }

// .material-icons.toll:before,
// .mi.toll:before {
//   content: '\e8e0';
// }

// .material-icons.tonality:before,
// .mi.tonality:before {
//   content: '\e427';
// }

// .material-icons.touch_app:before,
// .mi.touch_app:before {
//   content: '\e913';
// }

// .material-icons.toys:before,
// .mi.toys:before {
//   content: '\e332';
// }

// .material-icons.track_changes:before,
// .mi.track_changes:before {
//   content: '\e8e1';
// }

// .material-icons.traffic:before,
// .mi.traffic:before {
//   content: '\e565';
// }

// .material-icons.train:before,
// .mi.train:before {
//   content: '\e570';
// }

// .material-icons.tram:before,
// .mi.tram:before {
//   content: '\e571';
// }

// .material-icons.transfer_within_a_station:before,
// .mi.transfer_within_a_station:before {
//   content: '\e572';
// }

// .material-icons.transform:before,
// .mi.transform:before {
//   content: '\e428';
// }

// .material-icons.translate:before,
// .mi.translate:before {
//   content: '\e8e2';
// }

// .material-icons.trending_down:before,
// .mi.trending_down:before {
//   content: '\e8e3';
// }

// .material-icons.trending_flat:before,
// .mi.trending_flat:before {
//   content: '\e8e4';
// }

// .material-icons.trending_up:before,
// .mi.trending_up:before {
//   content: '\e8e5';
// }

// .material-icons.tune:before,
// .mi.tune:before {
//   content: '\e429';
// }

// .material-icons.turned_in:before,
// .mi.turned_in:before {
//   content: '\e8e6';
// }

// .material-icons.turned_in_not:before,
// .mi.turned_in_not:before {
//   content: '\e8e7';
// }

// .material-icons.tv:before,
// .mi.tv:before {
//   content: '\e333';
// }

// .material-icons.unarchive:before,
// .mi.unarchive:before {
//   content: '\e169';
// }

// .material-icons.undo:before,
// .mi.undo:before {
//   content: '\e166';
// }

// .material-icons.unfold_less:before,
// .mi.unfold_less:before {
//   content: '\e5d6';
// }

// .material-icons.unfold_more:before,
// .mi.unfold_more:before {
//   content: '\e5d7';
// }

// .material-icons.update:before,
// .mi.update:before {
//   content: '\e923';
// }

// .material-icons.usb:before,
// .mi.usb:before {
//   content: '\e1e0';
// }

// .material-icons.verified_user:before,
// .mi.verified_user:before {
//   content: '\e8e8';
// }

// .material-icons.vertical_align_bottom:before,
// .mi.vertical_align_bottom:before {
//   content: '\e258';
// }

// .material-icons.vertical_align_center:before,
// .mi.vertical_align_center:before {
//   content: '\e259';
// }

// .material-icons.vertical_align_top:before,
// .mi.vertical_align_top:before {
//   content: '\e25a';
// }

// .material-icons.vibration:before,
// .mi.vibration:before {
//   content: '\e62d';
// }

// .material-icons.video_call:before,
// .mi.video_call:before {
//   content: '\e070';
// }

// .material-icons.video_label:before,
// .mi.video_label:before {
//   content: '\e071';
// }

// .material-icons.video_library:before,
// .mi.video_library:before {
//   content: '\e04a';
// }

// .material-icons.videocam:before,
// .mi.videocam:before {
//   content: '\e04b';
// }

// .material-icons.videocam_off:before,
// .mi.videocam_off:before {
//   content: '\e04c';
// }

// .material-icons.videogame_asset:before,
// .mi.videogame_asset:before {
//   content: '\e338';
// }

// .material-icons.view_agenda:before,
// .mi.view_agenda:before {
//   content: '\e8e9';
// }

// .material-icons.view_array:before,
// .mi.view_array:before {
//   content: '\e8ea';
// }

// .material-icons.view_carousel:before,
// .mi.view_carousel:before {
//   content: '\e8eb';
// }

// .material-icons.view_column:before,
// .mi.view_column:before {
//   content: '\e8ec';
// }

// .material-icons.view_comfy:before,
// .mi.view_comfy:before {
//   content: '\e42a';
// }

// .material-icons.view_compact:before,
// .mi.view_compact:before {
//   content: '\e42b';
// }

// .material-icons.view_day:before,
// .mi.view_day:before {
//   content: '\e8ed';
// }

// .material-icons.view_headline:before,
// .mi.view_headline:before {
//   content: '\e8ee';
// }

// .material-icons.view_list:before,
// .mi.view_list:before {
//   content: '\e8ef';
// }

// .material-icons.view_module:before,
// .mi.view_module:before {
//   content: '\e8f0';
// }

// .material-icons.view_quilt:before,
// .mi.view_quilt:before {
//   content: '\e8f1';
// }

// .material-icons.view_stream:before,
// .mi.view_stream:before {
//   content: '\e8f2';
// }

// .material-icons.view_week:before,
// .mi.view_week:before {
//   content: '\e8f3';
// }

// .material-icons.vignette:before,
// .mi.vignette:before {
//   content: '\e435';
// }

// .material-icons.visibility:before,
// .mi.visibility:before {
//   content: '\e8f4';
// }

// .material-icons.visibility_off:before,
// .mi.visibility_off:before {
//   content: '\e8f5';
// }

// .material-icons.voice_chat:before,
// .mi.voice_chat:before {
//   content: '\e62e';
// }

// .material-icons.voicemail:before,
// .mi.voicemail:before {
//   content: '\e0d9';
// }

// .material-icons.volume_down:before,
// .mi.volume_down:before {
//   content: '\e04d';
// }

// .material-icons.volume_mute:before,
// .mi.volume_mute:before {
//   content: '\e04e';
// }

// .material-icons.volume_off:before,
// .mi.volume_off:before {
//   content: '\e04f';
// }

// .material-icons.volume_up:before,
// .mi.volume_up:before {
//   content: '\e050';
// }

// .material-icons.vpn_key:before,
// .mi.vpn_key:before {
//   content: '\e0da';
// }

// .material-icons.vpn_lock:before,
// .mi.vpn_lock:before {
//   content: '\e62f';
// }

// .material-icons.wallpaper:before,
// .mi.wallpaper:before {
//   content: '\e1bc';
// }

// .material-icons.warning:before,
// .mi.warning:before {
//   content: '\e002';
// }

// .material-icons.watch:before,
// .mi.watch:before {
//   content: '\e334';
// }

// .material-icons.watch_later:before,
// .mi.watch_later:before {
//   content: '\e924';
// }

// .material-icons.wb_auto:before,
// .mi.wb_auto:before {
//   content: '\e42c';
// }

// .material-icons.wb_cloudy:before,
// .mi.wb_cloudy:before {
//   content: '\e42d';
// }

// .material-icons.wb_incandescent:before,
// .mi.wb_incandescent:before {
//   content: '\e42e';
// }

// .material-icons.wb_iridescent:before,
// .mi.wb_iridescent:before {
//   content: '\e436';
// }

// .material-icons.wb_sunny:before,
// .mi.wb_sunny:before {
//   content: '\e430';
// }

// .material-icons.wc:before,
// .mi.wc:before {
//   content: '\e63d';
// }

// .material-icons.web:before,
// .mi.web:before {
//   content: '\e051';
// }

// .material-icons.web_asset:before,
// .mi.web_asset:before {
//   content: '\e069';
// }

// .material-icons.weekend:before,
// .mi.weekend:before {
//   content: '\e16b';
// }

// .material-icons.whatshot:before,
// .mi.whatshot:before {
//   content: '\e80e';
// }

// .material-icons.widgets:before,
// .mi.widgets:before {
//   content: '\e1bd';
// }

// .material-icons.wifi:before,
// .mi.wifi:before {
//   content: '\e63e';
// }

// .material-icons.wifi_lock:before,
// .mi.wifi_lock:before {
//   content: '\e1e1';
// }

// .material-icons.wifi_tethering:before,
// .mi.wifi_tethering:before {
//   content: '\e1e2';
// }

// .material-icons.work:before,
// .mi.work:before {
//   content: '\e8f9';
// }

// .material-icons.wrap_text:before,
// .mi.wrap_text:before {
//   content: '\e25b';
// }

// .material-icons.youtube_searched_for:before,
// .mi.youtube_searched_for:before {
//   content: '\e8fa';
// }

// .material-icons.zoom_in:before,
// .mi.zoom_in:before {
//   content: '\e8ff';
// }

// .material-icons.zoom_out:before,
// .mi.zoom_out:before {
//   content: '\e900';
// }

// .material-icons.zoom_out_map:before,
// .mi.zoom_out_map:before {
//   content: '\e56b';
// }
