#options {
    position: relative;
    cursor: pointer;

    i {
        vertical-align: bottom;
    }

    .menu {
        background-color: #fff;
        z-index: 501;
        display: none;

        .item {
            padding: 1rem 1.5rem;
            color: $cr-grey65;
            cursor: pointer;
            font-size: 1.1rem;
            text-transform: uppercase;

            a {
                color: $cr-grey65;
            }

            &:hover {
                color: #fff;
                background-color: $cr-green;

                a {
                    color: #fff;
                }
            }
        }
    }
}
