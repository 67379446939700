#signup-selection {
    .signup-container {
        padding: 100px 20px;
        background-color: #fff;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
        border-radius: 3px;
        text-align: center;
        margin-bottom: 20px;
        transition: box-shadow 0.35s ease-out, transform 0.3s ease-out;

        &:hover {
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.35);
            transform: translate(0, -3px);
        }

        h4 {
            font-size: 32px;
            color: $cr-grey;
            text-transform: uppercase;

            .green-text {
                color: $cr-green;
            }
        }

        .tagline {
            min-height: 44px;
        }

        div {
            font-size: 16px;
            color: $cr-grey;
        }
    }
}
