.tooltip-wrapper {
  display: inline-block;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.tooltip {
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #00bd00;
  display: flex;
  font-size: 12px;
  padding: 10px 15px;
  pointer-events: auto;
  position: absolute;
  transform: translateX(-50%);
  z-index: 99999;

  &.epc-tooltip {
    padding: 20px 20px 20px 30px;
    width: unset;
    max-width: 435px;

    @media (max-width: 576px) {
      max-width: 240px;
    }
  }

  button {
    &.tooltip-close {
      background: none;
      border: none;
      cursor: pointer;
      display: inline-flex;
      margin-left: 10px;
      padding: 0;

      svg {
        width: 20px;
        height: 20px;
      }

      .close-gamification-icon path {
        fill: #404040;
      }
    }
  }

  p {
    color: #404040 !important;
    margin: 0;
    padding: 0;
    font-size: 11px;
  }

  a {
    color: #00bd00;
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    border-width: 7px;
    border-style: solid;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  &[data-position='top']::after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: #f4f4f4 transparent transparent transparent;
  }

  &[data-position='bottom']::after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: transparent transparent #f4f4f4 transparent;
  }

  &[data-position='left']::after {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-color: transparent transparent transparent #f4f4f4;
  }

  &[data-position='right']::after {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-color: transparent #f4f4f4 transparent transparent;
  }
}
