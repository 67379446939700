#signup-progress {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #333333;
    text-align: center;
    padding: 15px 0 82px;
    z-index: 4;

    h2 {
        font-size: em(24);
        color: #fff;
        margin: 0 0 10px;
    }

    p {
        font-size: em(16);
        color: #fff;
    }

    .progress-bar {
        display: block;
        position: relative;
        width: 100%;
        height: 70px;
        padding: 8px 30px;

        .progress {
            display: block;
            position: relative;
            height: 100%;
            background-color: $cr-green;
            transition: width 800ms $inEaseing;

            .progress-text {
                display: block;
                position: absolute;
                bottom: -35px;
                right: 0px;
                color: #fff;
                font-size: em(13);
                font-weight: 200;
            }
        }

        .thebar {
            display: block;
            position: relative;
            width: 100%;
            height: 18px;
            background-color: #505050;

            .tierSeparator {
                display: block;
                position: absolute;
                top: -8px;
                width: 1px;
                height: 34px;
                border-left: 1px dashed #fff;

                &:nth-child(1) {
                    left: 50%;
                }
                &:nth-child(2) {
                    left: 75%;
                }
                &:nth-child(3) {
                    left: 90%;
                }
            }
        }
    }
}

@include responsive('normal') {
    #signup-progress {
        .signup-container {
            padding-left: 85px;
            padding-right: 30px;
        }
    }
}

@include responsive('tablet') {
    #signup-progress {
        .signup-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

@include responsive('phone to tablet') {
    #signup-progress {
        position: relative;
        bottom: auto;
        left: auto;
        padding-bottom: 0px;

        &.without-header {
            padding: 115px 0 15px;
        }

        .signup-container {
            padding-left: 15px;
            padding-right: 15px;
        }

        h2 {
            font-size: em(18);
        }

        p {
            font-size: em(14);
        }

        .progress-text {
            font-size: em(12);
        }
    }
}
