.password-reset {
  .back-to-website {
    @media (max-width: 766px) {
      margin-left: 20px !important;
      margin-bottom: 20px;
    }
  }

  #password-reset-page {
    margin: 0;
    display: flex;

    form {
      display: flex;
      flex-flow: column;
    }

    h1 {
      color: $cr-grey;
      font-size: $h2-fontsize;
      margin-bottom: 1rem;
      margin-top: 4.1rem;
    }

    .image-container {
      position: relative;
      min-height: 630px;
      background-size: cover;
      text-align: center;

      &.image-sign-up {
        background: url(/img/nice-to-meet-you.jpg) center center no-repeat;
      }

      &.image-login {
        background: url(/img/affiliates/whale-login.jpg) center center no-repeat;
        border-radius: 20px;
        width: 50%;
        background-size: cover;
        margin-left: 30px;
      }

      .image-sign-up {
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        a.image-link {
          text-decoration: underline;
          cursor: pointer;
          color: #fff;
          margin-left: 4px;
        }
      }

      .image-text {
        color: #fff;
        font-size: 46px;
        position: absolute;
        top: 200px;
        left: 0;
        right: 0;
        line-height: 1;
        padding: 0 50px;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);

        &:before {
          content: '\201D';
          font-family: Georgia, serif;
          font-size: 60px;
          font-weight: bold;
          position: absolute;
          top: -70px;
          left: calc(50% - 15px);
          transform: rotate(180deg);
        }

        &:after {
          content: '\201D';
          font-family: Georgia, serif;
          font-size: 60px;
          font-weight: bold;
          position: absolute;
          bottom: -70px;
          left: calc(50% - 15px);
        }
      }

      img {
        display: block;
        margin-left: -15px;
        width: 100%;
        height: auto;
      }
    }

    .text-container {
      margin: 0 30px;
      min-height: 540px;
      width: 50%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 767px) {
        width: 100%;
        justify-content: flex-start;
      }

      .form-info {
        margin: 0 90px;

        @media (max-width: 991px) {
          margin: 0;
        }
        h2 {
          color: #333;
          font-size: 27.3px;
          font-style: normal;
          font-weight: 700;
          margin: 0 0 20px 0;
        }

        p {
          color: #333;
          text-align: left;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0 0 30px 0;
        }
      }

      .input-field {
        padding: 0 90px;
        margin: 0 0 20px 0;

        .reveal-password {
          &.close,
          &.open {
            i {
              right: 93px !important;

              @media (max-width: 991px) {
                right: 0 !important;
              }
            }
          }
        }

        @media (max-width: 991px) {
          margin-bottom: 40px;
          padding: 0;
        }
        input {
          margin: 0;
        }
        label {
          left: unset;
        }
        &.error {
          label {
            color: initial;
          }
        }
      }
    }

    .form-buttons {
      margin-right: 90px;
      margin-left: 90px;

      @media (max-width: 991px) {
        margin-right: 0;
        margin-left: 0;
      }
      button {
        width: 100%;

        @media (max-width: 991px) {
          padding: 20px 86px !important;
        }
      }
    }

    .need-help {
      color: #969696;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin: 10px auto 0 auto;

      @media (max-width: 991px) {
        margin: 20px auto 0 auto;
      }

      .support-link {
        color: #969696;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

    .btn {
      padding: 15px 75px;
      line-height: 1.3em;
      height: auto;
    }

    .login-errors {
      margin-right: 90px;
      margin-left: 90px;
      text-align: left;

      @media (max-width: 991px) {
        margin-top: 10px;
        margin-right: 0;
        margin-left: 0;
      }

      div {
        padding: 0 !important;
      }
    }
    .barre {
      max-width: 157px;
      margin: 0 auto;
      border: none;
      height: 1px;
      background-color: #d2d2d2;
      margin-bottom: 22px;
    }
  }
}

@include responsive('tablet') {
  #password-reset-page {
    .registration-links {
      span {
        padding: 0 5px;
      }
    }

    .image-container {
      min-height: 240px;

      .image-text {
        top: 100px;

        &:before {
          top: -70px;
          left: 40px;
        }

        &:after {
          bottom: auto;
          right: 40px;
          left: auto;
          top: -40px;
        }
      }
    }
  }
}

@include responsive('phone to tablet') {
  #password-reset-page {
    .registration-links {
      span {
        padding: 0 10px;
      }
    }
  }
}

@include responsive('phone') {
  #password-reset-page {
    .registration-links {
      span {
        padding: 0 10px;
        border-right: none;
      }
    }

    .text-container {
      margin: 0;

      .signup-container {
        padding: 20px;

        h4 {
          font-size: 28px;
        }
      }
    }
  }
}
