.main-nav-dimmer {
  animation: renderIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50000;
}

.mainNavigation {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100vh;
  z-index: 50001;
  -webkit-transition: left 200ms ease-in;
  transition: left 200ms ease-in;

  @media (max-width: 992px) {
    left: -240px;
  }

  .trust-pilot {
    border-radius: 7px;
    border: 1px solid #00bd00;
    background: #252525;
    box-shadow: 40px 28px 80px 0px rgba(0, 0, 0, 0.08);
    padding: 6px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    iframe {
      height: 90px;

      @media (max-width: 992px) {
        height: 95px;
      }
    }
  }

  .sticky-aff-manager-info {
    background: #252525;
    box-shadow: 0px -2px 20px 2px rgba(0, 0, 0, 0.2);
    position: sticky;
    bottom: 0;
    margin: 0;
    max-width: 240px;
    padding: 0 10px;
  }

  &.open,
  nav a span {
    left: 0;
  }

  nav {
    position: relative;
    width: 240px;
    float: right;
    margin: 80px 0 92px;
    display: flex;
    flex-flow: column;
  }

  .scrollable {
    max-height: 100vh;
    height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
    display: flex;
    flex-flow: column;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  & .mainList {
    flex-grow: 1;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 20px;

    .sub-menu-handle * {
      user-select: none;
    }

    .sub-menu-handle {
      margin-top: 10px;

      ul {
        transition: all 0.3s ease;
      }
    }

    & .mainItem {
      margin: 0 10px;
      &:not(:first-child) {
        margin-top: 6px;
      }
      a {
        padding: 12px 10px;
        display: block;
        &:hover {
          background-color: rgba(0, 189, 0, 0.8);
          border-radius: 7px;
        }

        &.active {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 7px;
        }

        &.active-pink {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 7px;
        }
      }
    }

    .mainNavigationTabTitle {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      margin-right: 10px;

      @media (max-width: 1500px) {
        font-size: 12px;
      }
    }
  }

  & .sectionHeader {
    cursor: pointer;
    padding: 0 12px 6px;
    color: #00bd00 !important;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    div {
      font-weight: 800;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      &:hover {
        text-decoration: underline;
      }
      span {
        margin-left: 0;
      }
    }
  }

  & .sectionItem {
    margin: 6px 10px 0;

    .mainNavigationTab {
      svg {
        &.new-icon {
          width: 37px;
        }
        width: 20px;
      }
    }

    #main-navigation-logout {
      padding-left: 12px;
    }

    a {
      display: block;
      padding: 12px 10px;
      &:hover {
        opacity: 0.8;
        background-color: #00bd00;
        border-radius: 7px;
      }
    }

    .active {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 7px;
    }

    .offers-nav {
      &:hover {
        opacity: 0.8;
        background-color: #00bd00;
        border-radius: 7px;
      }

      .mainNavigationTabTitle {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 10px;
      }
    }

    .jerkmate-nav {
      &:hover {
        background-color: rgba(251, 77, 148, 1);
        border-radius: 7px;
      }

      .mainNavigationTabTitle {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .active-pink {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 7px;
    }
  }

  .newSticker {
    margin-left: 10px;
  }

  & .seeAllOffers,
  & .seeAllPromotools {
    .mainNavigationTab {
      padding: 12px 0;
      &:hover {
        .mainNavigationTabTitle {
          color: #00bd00;
        }

        svg {
          path {
            fill: #00bd00;
          }
        }
      }
    }
    .mainNavigationTabTitle {
      padding-right: 6px;
      margin: 0 0 0 20px;
    }
  }

  & .seeAllPromotools {
    & div {
      align-items: center;
    }
  }
}
