$br1: 1500px;
$br2: 767px;

#smart-link {
  @media (max-width: 992px) {
    &.app-container,
    &.container-fluid {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &:before {
    content: none;
  }
  .header-container {
    margin-right: 65px;
    margin-left: 65px;

    @media (max-width: 1390px) {
      margin-top: 40px;
      margin-right: 0;
      margin-left: 0;
    }

    &.promotools {
      margin-top: 0px;
    }

    &.smartlink-choice {
      @media (max-width: 1390px) {
        margin-top: 0;
      }

      .header-box {
        h1 {
          @media (max-width: 1390px) {
            margin-bottom: 0;
          }
        }
      }
    }

    .header-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      @media (max-width: 1390px) {
        flex-direction: column;
      }

      h1,
      h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000 !important;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
        @media (max-width: 1390px) {
          font-size: 25px;
          margin-top: 0;
          svg {
            width: 33px;
          }
        }
      }

      .upgraded {
        margin-left: 20px;
        display: flex;
        padding: 6px 15px 6px 12px;
        align-items: center;
        gap: 5px;
        border-radius: 6px;
        background: #00bd00;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18.2px; /* 130% */
        text-transform: capitalize;

        @media (max-width: 1390px) {
          margin-top: 5px;
          margin-left: 0;
          margin-bottom: 25px;
        }
      }
    }

    .header-adtool-smartlink-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-box {
        position: relative;
        @media (max-width: 1390px) {
          flex-direction: row;
        }
      }

      .link-settings-button {
        display: flex;
        padding: 10px 30px 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
        border: 1px solid #00bd00;
        color: #00bd00;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: uppercase;
        cursor: pointer;
        position: absolute;
        right: 0;
        &.open {
          border-radius: 5px;
          border: 1px solid #00bd00;
          background: #00bd00;
          color: #fff;

          svg {
            path {
              fill: #fff;
            }
          }
        }

        &:hover {
          border-radius: 5px;
          border: 1px solid #00bd00;
          background: #00bd00;
          color: #fff;

          svg {
            path {
              fill: #fff;
            }
          }
        }

        @media (max-width: 992px) {
          padding: 10px;
          margin-bottom: 10px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .smartlink-description {
    color: #000000 !important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
    margin-top: 0;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &.smartlink-description-customize {
      br {
        display: none;
      }
      @media (max-width: 550px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        br {
          display: block;
        }
      }
    }

    &.smartlink-description-choice {
      @media (max-width: 550px) {
        width: 71%;
        margin-left: auto;
        margin-right: auto;
      }
      p {
        margin: 0;

        @media (max-width: 768px) {
          &:last-child {
            margin-top: 25px;
          }
        }
      }
    }

    .tooltip-wrapper {
      margin-left: 10px;
    }

    svg {
      &:hover {
        path {
          fill: #00bd00;
        }
      }
    }
  }

  .settings-modal {
    animation: renderIn 0.5s ease-in-out;
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    max-width: 620px;
    padding: 40px;
    flex-direction: column;
    gap: 20px;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.25);
    z-index: 10000000;
    transition: all 0.5s ease;
    transform: translateZ(0);

    @media (max-width: 576px) {
      height: 80vh;
      top: 80px;
      padding: 40px 30px;
    }

    &.open {
      display: flex;
    }

    .modal-content {
      @media (max-width: 576px) {
        flex: 1;
        margin-bottom: 125px;
        overflow-y: auto;
        padding-top: 5px;
      }
    }

    .input-field {
      margin-bottom: 0;
      input {
        &::placeholder {
          color: #505050;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      label {
        left: 0;

        span {
          color: #000;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .aff-sub-container {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;

      .input-field {
        width: 260px;
        @media (max-width: 680px) {
          width: 100%;
        }
      }
    }

    .switchs {
      display: flex;
      flex-flow: row;
      gap: 40px;

      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: flex-start;

        .text-center {
          text-align: unset;
        }
      }
    }

    .lever {
      height: 41px;
      width: 67px;
      border-radius: 999px;
      box-shadow: unset;
      @media (max-width: 576px) {
        width: 52px;
        height: 32px;
      }
      &:after {
        width: 25px;
        height: 25px;
        box-shadow: unset;
        top: 8px;
        left: 8px;

        @media (max-width: 576px) {
          top: 6px;
          left: 7px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .switch {
      label {
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        input[type='checkbox']:checked + .lever {
          &::after {
            left: 35px;
            @media (max-width: 576px) {
              left: 25px;
            }
          }
        }
      }
    }

    .settings-modal-footer {
      display: flex;
      gap: 12px;

      @media (max-width: 576px) {
        flex-shrink: 0;
        flex-direction: column;
        position: fixed;
        bottom: 35px;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 0 30px;
      }
      button {
        background: none repeat scroll 0 0 transparent;
        border: medium none;
        border-spacing: 0;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.42rem;
        list-style: none outside none;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-indent: 0;

        &.cancel-button {
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border-radius: 5px;
          background: rgba(217, 217, 211, 0.69);
          color: #424242;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
        }

        &.apply-button {
          display: flex;
          padding: 16px 10px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          flex: 1 0 0;
          border-radius: 5px;
          background: #00bd00;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
  }

  .icons-container {
    position: absolute;

    .icon-shadow {
      padding: 1.15rem 1.1rem;
      box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.3);
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      margin: 0 auto;

      &.first {
        margin-top: 24rem;
      }
    }

    .bar-shadow {
      box-shadow: 0 3px 0 0 $pale-grey inset, 0 -5px 0 0 $pale-grey inset, 1px 0 6px -1.5px rgba(0, 0, 0, 0.35) inset,
        -1px 0 6px -1.5px rgba(0, 0, 0, 0.35) inset;
      width: 1rem;
      margin: -2px auto -4px auto;
      position: relative;

      &.first {
        height: 17.5rem;
      }

      &.second {
        height: 18.5rem;
      }
    }
  }

  .smart-link-container {
    position: relative;
    padding: 60px 20px 80px 20px !important;
    gap: 40px;
    align-self: stretch;
    border-radius: 40px;
    background: #fff;
    margin: 0 auto 30px auto;
    max-width: 1480px;
    width: 100%;

    @media (max-width: 1390px) {
      width: 95%;
      padding: 50px 10px 80px 10px !important;
    }

    .back-btn {
      position: absolute;
      left: 30px;
      top: 30px;
      display: flex;
      padding-left: 10px;
      align-items: center;
      gap: 10px;
      color: #424242;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;

      @media (max-width: 1390px) {
        padding-left: 20px;
        left: 0;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
    form {
      .select-field {
        .dropdown-content li span:empty {
          padding: 18px;
        }
        label {
          color: $cr-grey65;
        }
      }
    }
    .title {
      @media (min-width: $screen-sm) {
        #big-icon {
          margin-left: -65px;
        }
      }
      #big-icon {
        width: 65px;
        height: 65px;
        margin-right: 10px;
        bottom: -5px;
      }
      > div {
        display: block;
        text-align: center;
        @media (min-width: $screen-sm) {
          white-space: nowrap;
        }
        @media (max-width: $screen-sm-min) {
          h2 {
            font-size: 2.5rem;
            width: 100%;
          }
        }
        width: 100%;
      }
      .big-icon {
        display: inline-block;
      }

      h2 {
        display: inline-block;
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
        color: $cr-grey;
      }

      h3 {
        text-align: center;
        font-size: 1.1rem;
        line-height: 1.5;
        color: $cr-grey65;
      }
    }

    #smart-link-trackinglink {
      margin-bottom: 2rem;
    }
    .adToolAreaBox.banner {
      margin-right: -15px;
      margin-left: -15px;
      .banner-cards {
        gap: 35px 18px;
        justify-content: flex-start;

        @media (max-width: $br2) {
          justify-content: center;
        }
        .banner-card {
          width: calc(33% - 10px);

          @media (max-width: $br1) {
            width: 48%;
          }

          @media (max-width: $br2) {
            width: 100%;
            max-width: 350px;
          }

          .top-title {
            .text {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .link-container {
      display: flex;
      padding: 30px 28px;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #00bd00;
      margin-bottom: 20px;

      @media (max-width: 1390px) {
        padding: 20px;
      }
      .creatives-tracking-link {
        width: 100%;

        .your-link {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          text-transform: uppercase;
          display: flex;
          padding: 10px 20px 10px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px 0px 0px 5px;
          background: #333;
        }
        .copy-value-widget {
          margin-bottom: 0 !important;

          .copy-value-display {
            display: flex;
            padding: 12px 20px 12px 15px;
            align-items: center;
            gap: 10px;
            border-radius: 0px 5px 5px 0px;
            background: rgba(217, 217, 211, 0.15);

            &:focus,
            &.focused {
              border-color: unset !important;
              box-shadow: unset !important;
              text-overflow: clip;
            }
          }

          .actions-section {
            min-width: 113px;
            margin-left: 10px;

            @media (max-width: 1390px) {
              margin-left: 0px;
            }
            .copyBtn {
              display: flex;
              flex-flow: row;
              justify-content: center;
              align-items: center;
              gap: 5px;
              padding: 10px 30px 10px 24px;
              border-radius: 5px;
              background: #00bd00;
            }
            .text {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              text-transform: uppercase;
              padding: 0;
            }
          }
        }
      }
    }
    &.adtool-container {
      @media (max-width: 576px) {
        margin-top: 0;
      }
    }
  }

  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 13px;
    padding-bottom: 13px;

    @media (min-width: 1390px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    p {
      margin: 0 5px;
      white-space: nowrap;
      font-size: 20px;
    }
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #d6d6d6;
  }
}

#smart-link-adtool .link {
  max-width: 900px;
  margin: 4rem auto;
  @media (max-width: $screen-sm-min) {
    margin: 2rem auto;
  }
}

.smartlink-alert {
  padding-top: 20px;
  margin-right: -40px;
  margin-left: -40px;

  @media (max-width: 992px) {
    margin-right: 0;
    margin-left: 0;
  }
}
@keyframes renderIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
