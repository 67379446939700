.slider-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.slider-wrapper {
  display: flex;
  transition: transform 500ms ease-out;
  width: 100%;
  height: 100vh;
}

.slider-slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  flex-flow: column;
  background-color: white;
  opacity: 1;
  padding: 0 3px;
  &.active {
    background-color: white;
    opacity: 1;
  }

  .header {
    padding: 11px 0 0;
    margin-bottom: -52px;
    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  .field-container {
    margin: auto;
    .time-left {
      float: right;
    }
  }

  h2 {
    margin: 0 !important;
    display: flex;
    padding: 8px 17px;
    align-items: center;
    gap: 4px;
    width: fit-content;
    color: #87e764 !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 999px;
    background: #2c2c2c;
  }
}

.control-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  gap: 10px;
  .prev-button {
    display: flex;
    width: 205px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #a9a9a9;
    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .next-button {
    width: 100%;
    border-radius: 4px;
    background: #0cc722;
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    position: relative;

    &.loading-btn {
      background-color: #dfdfdf !important;
      overflow: hidden;
    }

    &.loading-btn::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #0cc722;
      z-index: 1;
      animation: loadingOpacity 10s linear forwards;
    }

    &.loading-btn span {
      position: relative;
      z-index: 2;
    }

    @keyframes loadingOpacity {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      &.full-width {
        width: 100%;
      }
      width: 50%;
    }
  }
}

.pagination {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
}

.pagination-progress[value] {
  width: 100%;
  height: 10px;
  appearance: none;
  background-color: #dadada;
  &::-moz-progress-bar {
    background-color: #00bd00;
  }
  &::-webkit-progress-value {
    background-color: #00bd00;
  }
  &::-webkit-progress-bar {
    background-color: #dadada;
  }
}
