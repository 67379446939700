#profile {
    display: block;
    position: relative;
    height: 100%;

    .form-container {
        padding-top: 40px;
        max-width: 1400px;
    }

    .navigation-container {
        padding-top: 4rem;
    }

    .hide {
        opacity: 0;
        transition: $inAnimationLinks;
    }

    .card {
        display: block;
        margin-bottom: 20px;

        .card-icon {
            text-align: center;
            background-color: $cr-pale-grey;
            padding: 10px;

            i {
                color: #fff;
                font-size: em(140);
            }
        }
        .card-action {
            padding: 10px;
            font-size: em(16.42);
            color: $cr-dark-grey;
            z-index: 1;
        }
    }

    .form-buttons {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .profile-children {
        display: block;
        position: relative;
        height: 100%;
        padding-bottom: 218px;

        h1 {
            font-size: em(24);
            margin-top: 0;
            display: flex;

            i {
                font-size: 26px;
                margin-right: 15px;
                float: left;
            }
        }
    }

    .input-field,
    .select-field {
        label {
            color: $cr-grey65;
        }
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='url'],
    input[type='time'],
    input[type='date'],
    input[type='datetime-local'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    textarea.materialize-textarea {
        border-bottom-color: $cr-grey65;

        &:focus {
            border-bottom-color: $cr-green;
            & + label {
                color: $cr-green;
            }
        }
    }

    input[type='text']:disabled,
    input[type='text'][readonly='readonly'],
    input[type='password']:disabled,
    input[type='password'][readonly='readonly'],
    input[type='email']:disabled,
    input[type='email'][readonly='readonly'],
    input[type='url']:disabled,
    input[type='url'][readonly='readonly'],
    input[type='time']:disabled,
    input[type='time'][readonly='readonly'],
    input[type='date']:disabled,
    input[type='date'][readonly='readonly'],
    input[type='datetime-local']:disabled,
    input[type='datetime-local'][readonly='readonly'],
    input[type='tel']:disabled,
    input[type='tel'][readonly='readonly'],
    input[type='number']:disabled,
    input[type='number'][readonly='readonly'],
    input[type='search']:disabled,
    input[type='search'][readonly='readonly'],
    textarea.materialize-textarea:disabled,
    textarea.materialize-textarea[readonly='readonly'] {
        color: rgba(0, 0, 0, 0.6);

        & + label {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

@include responsive('tablet') {
    #application {
        &.payment-history,
        &.referrals {
            overflow: auto;
        }
    }

    #profile {
        .profile-section {
            padding-left: 15px;
            padding-top: 0;
        }

        .infos {
            position: relative;
        }
    }
}

@include responsive('phone to tablet') {
    #application {
        &.payment-history,
        &.referrals {
            overflow: auto;
        }
    }

    #profile {
        .infos {
            position: relative;
        }
    }
}
