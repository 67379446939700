#daterange-component {
  .calendar-icon {
    margin-top: 22px;
    color: $cr-grey;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  #date-present-mobile {
    @media screen and (min-width: 992px) {
      display: none;
    }
    margin-bottom: 15px;
  }

  #date-present-desktop {
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (min-width: 991px) {
      margin-top: -3px;
      width: 100%;

      .col-md-12 {
        padding-top: 4px;
      }
    }
  }
  #container-select-date {
    display: flex;
    gap: 15px;
    max-width: 500px;

    @media (max-width: 576px) {
      width: 100%;
      max-width: unset;
      justify-content: space-between;
    }
  }
}
