.fixed-action-btn {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: inline-block;
            position: relative;
            margin-bottom: 15px;
        }
    }
}
