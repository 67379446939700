.alert-header {
    display: block;
    position: relative;
    background-color: #505050;
    padding-top: 0;
    letter-spacing: 0.8px;
    &.alert-sitewide {
        background-color: #212121;
        top: 80px;
        font-weight: bold;
        z-index: 1;
        .title-block {
            padding: 16px 0;
            p {
                font-size: 1em;
                width: 100%;
                padding: 0;
            }
            .x-circle {
                top: 4px;
            }
            .image-tag,
            .notice-svg {
                width: 42px;
                height: 42px;
                border-radius: 50px;
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -21px;
                + p {
                    padding-left: 42px;
                }
                @include responsive('phone') {
                    &.with-btn {
                        top: calc(50% - 30px);
                    }
                }
            }
            .notice-svg {
                border-radius: 0;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                left: 10px;
            }
        }
        .content-with-btn {
            padding-right: 230px;
            display: block;
            @include responsive('phone') {
                padding: 0;
            }
        }
        .btn {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            right: 15px;
            @include responsive('phone') {
                position: relative;
                margin-top: 25px;
            }
        }
    }
    &.no-close {
        .title-block {
            .x-circle {
                display: none;
            }
        }
        .content-with-btn {
            padding-right: 230px;
            @include responsive('phone') {
                padding: 0;
            }
        }
        .btn {
            right: 0;
        }
    }
    .title-block {
        position: relative;
        padding-right: 45px;
        padding-bottom: 15px;
        color: #fff;
        font-weight: 300;
        text-align: left;

        p {
            display: block;
            position: relative;
            font-size: 1em;
            padding-top: 5px;
        }

        svg {
            display: inline-block;
            position: absolute;
            top: 10px;
            left: -10px;
            width: 40px;
        }

        .x-circle {
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            background-color: #333333;
            color: #ffffff;
            text-align: center;
            border-radius: 100%;
            top: 0px;
            right: -30px;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                background-color: $cr-green;
            }

            i {
                display: inline-block;
                position: relative;
                top: 2px;
                font-size: 1.4em;
            }
        }
    }

    &.iconVisible {
        p {
            padding-left: 42px;
        }
    }
}

@include responsive('large') {
    .alert-header {
        .title-block svg {
            top: 10px;
            left: 0px;
        }
    }
}

@include responsive('normal') {
    .alert-header {
        .title-block svg {
            top: 10px;
            left: 0px;
        }
    }
}

@include responsive('tablet') {
    .alert-header {
        padding-left: 40px;
        .title-block svg {
            top: -3px;
            left: -10px;
        }
    }
}

@include responsive('phone to tablet') {
    .alert-header {
        padding-left: 40px;
        .title-block svg {
            top: -3px;
            left: -10px;
        }
    }
}
