#offer-postbacks-container {
  .div-container-content {
    padding: 1rem;
    line-height: 2rem;
  }

  #add-postback {
    @include flexbox(row, wrap, flex-start);

    * {
      margin: 0;
    }
    & > * {
      margin: 2.25rem 0.5rem 0;
      padding: 0;
    }

    .postback-goal {
      min-width: 150px;
      max-width: 160px;
      margin-top: 5px;
    }
    .postback-link {
      flex: 1;
      min-width: 150px;
    }

    .actions-section {
      @include flexbox(row, wrap, flex-start);

      & > * {
        flex: 1;
        width: 160px;
        height: 100%;
        min-height: 4.5rem;

        &:not(:first-child) {
          margin-left: 0.25rem;
        }
        &:disabled {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  #postbacks-list {
    margin-top: 2rem;

    .postback {
      margin-top: 0.25rem;
    }
  }
}
#postbacks-add-variables-modal {
  @include flexbox(column, wrap, flex-start);
  line-height: 2rem;
}
