#profile-header {
  display: block;
  position: relative;
  background-color: $cr-grey;

  &.new-profile-header {
    background: url(/img/profile/profile-header-bg.png) center center no-repeat;
    background-size: cover;
    @media (max-width: 1100px) {
      background: url(/img/profile/profile-header-bg-mobile.png) center center no-repeat;
      display: flex;
      justify-content: center;
      background-size: cover;
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 260px;

      @media (max-width: 992px) {
        padding-left: 0;
        flex-direction: column;
        justify-content: center;
        max-width: 420px;
      }

      .profile-section {
        @media (max-width: 1100px) {
          width: 100%;
          max-height: unset;
          margin-left: 0;
          padding-left: 20px;
        }
      }

      .achievements-box-container {
        border: 2px solid #00bd00;
        box-shadow: 0 0 16px 0 #00bd00 inset, 0 0 8px 0 #00bd00;
        border-radius: 20px;
        padding: 18px 24px;
        background: rgba(1, 38, 46, 0.75);
        max-height: 108px;
        flex-shrink: 0;
        margin: 20px 20px 20px auto;
        @media (max-width: 1100px) {
          max-height: unset;
          margin-left: 20px;
        }

        &.no-rank {
          @media (max-width: 1100px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .achievements-cta {
              width: 100%;
            }
          }
        }

        .achievements-box {
          z-index: 2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 30px;

          @media (max-width: 1100px) {
            margin-bottom: 15px;
          }

          .ranking-position {
            display: flex;
            flex-direction: column;
            height: 69px;

            .ranking-text {
              color: #00bd00;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18.2px; /* 130% */
              text-transform: capitalize;
              margin-bottom: 15px;
            }
            .aff-ranking-box {
              display: flex;
              align-items: center;
              gap: 10px;
              .ranking-number {
                color: #87e764;
                font-size: 38px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;

                &.top-20-size {
                  font-size: 24px;

                  @media (max-width: 1100px) {
                    font-size: 20px;
                  }
                }

                @media (max-width: 1100px) {
                  font-size: 26px;
                }
              }

              .rank-indicator {
                display: flex;
                span {
                  display: flex;
                  flex-flow: row;
                  width: fit-content;

                  &.inverse-arrow {
                    transform: rotate(180deg);
                    svg {
                      path {
                        stroke: #ff0d0d;
                      }
                    }
                  }
                }
              }
            }
          }

          .latest-achievements {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 69px;

            .achievements-text {
              color: #00bd00;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18.2px; /* 130% */
              text-transform: capitalize;
              margin-bottom: 15px;
            }

            .badge-list {
              display: flex;
              align-items: center;
              gap: 10px;
              z-index: 2;
              position: relative;

              .badge-box {
                position: relative;
                svg {
                  position: absolute;
                  right: 3px;
                  top: 2px;
                }

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .achievements-box-bg {
                position: absolute;
                z-index: -1;
                bottom: -9px;
                left: -180px;
              }
            }
          }
        }

        .achievements-cta {
          display: flex;
          padding: 12px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 5px;
          border: 1px solid #00bd00;

          color: #00bd00;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          z-index: 2;
        }
      }
    }
  }

  .header-container {
    overflow: hidden;
    padding-right: 0px;
  }

  .title-block {
    padding-top: 20px;
    padding-bottom: 15px;

    .profile-header-aff-id {
      color: #a4a4a4;
    }
    .subtitle {
      color: $cr-pale-green;
      font-size: 1em;
    }
    .infos {
      h1 {
        text-transform: uppercase;
        color: #fff;
        font-size: em(22);
        font-weight: 400;
        margin: 58px 0 5px 0;
      }

      &.company {
        h1 {
          margin: 47px 0 5px 0;
        }
      }
    }

    &.new-user-block {
      display: flex;
      align-items: center;
      gap: 15px;

      @media (max-width: 1100px) {
        text-align: left;
        padding-bottom: 0;
        margin: 20px 20px 0 0;
      }

      .ranking-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 52.996px;
          height: 69.685px;
        }
      }
      h1 {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: unset;
        margin: 0 0 5px;
      }

      .profile-header-aff-id {
        color: #e4e4e4;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 5px;
      }

      .subtitle {
        color: #87e764;
        font-size: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

@include responsive('tablet') {
  #profile-header {
    position: relative;

    .header-container {
      overflow: visible;
      padding-left: 0;
    }

    .title-block {
      h1 {
        margin: 0px;
      }

      .company {
        h1 {
          padding-top: 30px;
        }
      }

      .circle {
        height: 0;
      }
    }
  }
}

@include responsive('phone to tablet') {
  #profile-header {
    position: relative;

    .header-container {
      overflow: visible;
      padding-left: 0;
    }

    .title-block {
      padding-top: 5px;
      text-align: center;

      .infos {
        h1 {
          margin: 0px;
          padding-top: 0;
        }
      }

      .circle {
        margin-right: 0;
        float: none;
        height: 0;
      }
    }

    .profile-section {
      padding-left: 15px;
      padding-top: 0;
      margin-top: 20px;
    }
  }
}
