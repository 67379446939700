#december-challenge {
    font-size: 1.2rem;

    p {
        font-size: 1.2rem;
    }
    .subscription {
        p {
            font-size: 1.4rem;
        }
    }
    .december-important {
        font-size: 2.5rem;
    }

    .content {
        h1 {
            font-size: 2.57143em;
        }

        & > * {
            margin: 2rem 0;
        }

        .challenge-description {
            max-width: 1200px;

            img {
                max-width: 100%;
            }
        }

        .terms-and-conditions {
            ol li {
                margin: 1rem 0;
                line-height: 1.5rem;
            }
        }

        .ready {
            p {
                font-weight: 800;
            }

            ul.images-links-list {
                margin: 0;

                li {
                    padding: 0 4px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
