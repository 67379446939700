.payment-history-graph {
  $gauge-height: 6px;

  display: flex;
  padding: 8px 0 6px;
  color: inherit;

  .summary,
  .details {
    margin-right: 12px;
  }

  .caption,
  .days-left {
    margin-bottom: 2px;
    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      margin: 0 !important;
    }
  }

  .caption {
    color: #fff;
  }

  .gauge {
    position: relative;
    width: 100%;
    height: $gauge-height;

    .gauge-ticks {
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      left: 0;
      height: 3 * $gauge-height;

      span {
        width: 1px;
        height: 100%;
        border-left: 1px solid #32b91f;
      }
    }

    .gauge-bkg {
      position: absolute;
      top: $gauge-height;
      right: 0;
      left: 0;
      height: 100%;
      background-color: $grey;
      outline: 2px solid $cr-grey;
    }

    .gauge-fill {
      position: absolute;
      top: $gauge-height;
      height: 100%;
      max-width: 100%;
      display: block;
      animation: animate 4s linear forwards;

      @keyframes animate {
        0% {
          width: 0%;
          background-color: $warning-color;
        }
        100% {
          background-color: var(--finalColor, $cr-pale-green);
        }
      }
    }
  }

  .days-left {
    color: $cr-pale-green;

    &.hurry-up {
      color: #fe2f56;
    }
  }

  .more-info {
    height: auto;
    margin: 0 7px;
    line-height: 1;
    cursor: pointer;
    i {
      font-size: 1em;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }

  .minimum-payout {
    color: #8c8c8c;

    svg {
      width: 1em;
      height: auto;
      margin-right: 3px;

      .fill {
        fill: #8c8c8c;
      }
    }

    &.completed {
      color: #fff;

      svg .fill {
        fill: $cr-pale-green;
      }
    }
  }
  .paymenthistorygraph-mobile & {
    .summary {
      flex-grow: 1;
    }
  }
}

.payment-history-graph-tooltip {
  // !important to fight the CSS inlined by the plugin
  max-width: 330px !important;
  margin-top: 10px !important;
  margin-left: 4px !important;
  pointer-events: all !important;
  z-index: 50002 !important;
  a {
    &:hover {
      color: #00bd00;
    }
  }
}
