#statistics-page {
  padding-top: 100px;

  #newsbox {
    margin-bottom: 1.75rem;
  }

  h1 {
    margin: 0;
    font-size: em(36);
    color: $cr-green;
  }

  .drilldown-breadcrumb {
    padding: 9px 0 8px;

    > span {
      &:not(:last-child):after {
        content: '>';
        margin: 0 0.5rem;
      }
    }
    .link {
      font-size: 1rem;
      cursor: pointer;
      color: $cr-grey49;

      &:hover {
        color: $cr-grey65;
      }
    }
  }

  .refresh-btn {
    line-height: 1.6em;
    background-color: #fbc02d;
    margin: 2rem 15px 1rem 0;
    padding: 0.45em 1em 0.2em;
    i {
      font-size: 1.6em;
    }
  }

  .statistics-table-section {
    border-radius: 10px;

    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      margin: 0 !important;
    }

    .round-border {
      border-radius: 10px;
    }

    @media (max-width: $screen-xs) {
      margin: 0 -15px;
    }

    .is-fetching {
      background-color: #e0e0e0;

      .statisticsTable {
        table {
          .totals-row {
            background-color: transparent;
          }

          tr {
            &.selected {
              background-color: transparent;
            }

            &:hover {
              background-color: transparent;
            }

            th {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  .statistics-container-div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .dateRange {
    margin: 0 10px 10px 10px;
    padding-top: 20px;
  }

  .timezone-component {
    padding-top: 6px;
  }

  //.allFilters{
  //  padding: 20px 0;
  //}

  .pannelHeader {
    display: block;
    position: relative;
    padding: 10px 15px;
  }

  .filterRange {
    .input-field {
      margin-top: 0;
    }
  }

  .addColumnsModalLink,
  .addFiltersModalLink {
    display: inline-block;
    position: relative;
    font-size: 1.2em;
    color: $cr-green;
    padding-left: 30px;
    background-color: white;
    border: none;

    i {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .addColumnsModalLink {
    text-transform: uppercase;
  }

  .addFiltersModalLink {
    float: right;
  }

  .statisticsTable {
    table {
      tr {
        th {
          &:first-child {
            padding-left: 36px !important;
          }
          &:last-child {
            padding-right: 36px !important;
          }
        }
        td {
          &:first-child {
            & > div {
              padding-left: 36px !important;
            }
          }
          &:last-child,
          &.text-right {
            & > div {
              padding-right: 36px !important;
            }
          }
        }
      }

      border-bottom: 1px solid #c7c7c7;

      .checkbox-field {
        label {
          top: 5px;
        }
      }

      .totals-row {
        background-color: #f0f0f0;
        font-weight: bold;
      }

      tr {
        &.nodata {
          font-weight: bold;
        }
        &.selected {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: $cr-grey;
            border-color: $cr-grey;
          }
        }
        &.selected.selected-0 {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: $cr-green;
            border-color: $cr-green;
          }
        }
        &.selected.selected-1 {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: #3484be;
            border-color: #3484be;
          }
        }
        &.selected.selected-2 {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: #ff9f00;
            border-color: #ff9f00;
          }
        }
        &.selected.selected-3 {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: #884ca2;
            border-color: #884ca2;
          }
        }
        &.selected.selected-4 {
          .filled-in[type='checkbox']:checked + label:after {
            background-color: #d03c04;
            border-color: #d03c04;
          }
        }

        &.selected {
          background-color: darken(#f2f2f2, 2.5%);

          &:hover {
            background-color: darken(darken(#f2f2f2, 2.5%), 2.5%);
          }
        }

        th {
          position: relative;
          color: $cr-greyText;
          font-weight: 400;
          font-size: 0.88em;
          padding-right: 40px;
          cursor: pointer;

          span {
            white-space: nowrap;
          }

          &:first-child {
            padding-right: 10px;

            div {
              padding-left: 5px;
            }
          }
          &:last-child {
            padding-right: 5px;
          }

          &.checkbox {
            width: 85px;
          }

          .arrowSort {
            display: none;
            position: absolute;
            font-size: 1.5em;
            margin-left: 10px;
          }

          &.noSorting {
            cursor: unset;
          }

          &:hover {
            background-color: #f2f2f2;
          }

          &.sorted,
          &:hover {
            .arrowSort {
              display: inline-block;
            }
          }
        }

        td {
          padding: 0;

          div {
            padding: 5px 20px 5px 5px;
            white-space: nowrap;
          }

          &:not(.canDrillDown) {
            div {
              overflow: hidden;
            }
          }

          &:first-child {
            padding-left: 5px;
          }
          &:last-child {
            padding-right: 5px;
          }

          &.canDrillDown {
            position: static;

            & > div {
              position: static;
              padding-right: 55px;
            }

            .driller {
              display: inline-block;
              margin-left: 15px;
              cursor: pointer;

              .dropdown-content {
                z-index: 100;
              }

              i {
                display: inline-block;
                position: relative;
                font-size: 1.4em;
                top: 5px;
                -webkit-transition: all 200ms $inEaseing;
                transition: all 200ms $inEaseing;

                &.active {
                  color: black;
                  transform: rotate(-90deg);
                  -webkit-transition: all 200ms $inEaseing;
                  transition: all 200ms $inEaseing;
                }
              }
            }
          }
        }
      }
    }
  }

  .save-stat {
    margin: 1.25rem;
  }
  .stat-export .link,
  .save-stat .link {
    @include flexbox(row, nowrap, flex-end);

    * {
      align-self: center;
    }
    span {
      margin-left: 0.5rem;
      text-transform: uppercase;
    }
    &:not(.disabled) {
      color: $cr-grey49;
      cursor: pointer;
    }
    &.disabled {
      color: #cecece;
      cursor: default;
    }
  }

  #daterange-component {
    padding: 0;

    .daterange-component-container {
      display: flex;
      flex-flow: row;
      gap: 15px;
    }

    #datepicker__startdate,
    #datepicker__enddate {
      width: 80%;
      display: block;
      appearance: none;
      position: relative;
      background: none;
      border: 1px solid #656565;
      border-radius: 5px;
      outline: none;
      text-overflow: ellipsis;
      cursor: pointer;
      height: 3rem;
      padding: 0 10px 0 15px;
    }
  }

  .info {
    vertical-align: middle;
  }
  .search-component .search-tag.clear-filters {
    color: #656565;
  }

  .statistics-paid-conversion-tooltip .material-icons {
    display: inline-block;
    top: 3px;
    right: -2px;
    font-size: 0.9rem;

    @media (max-width: 768px) {
      position: absolute;
      top: 5%;
      right: 15%;
    }
  }

  .paidConversions {
    position: relative;
  }

  #statistics-paid-conversion-tooltip {
    max-width: 400px;
    text-align: justify;
    text-wrap: wrap;
    @media (max-width: 768px) {
      position: absolute;
      top: 5%;
      right: 15%;
    }
  }
}

#delete-report-modal {
  width: auto;
}

#save-report-modal {
  h2 {
    margin-bottom: 0.6em;
    display: fiex;
    align-items: center;
    text-transform: uppercase;
  }

  h2 .link {
    margin-top: 3px;
    margin-right: 10px;
    float: left;
    color: #00bd00;
  }

  .subtitle {
    font-size: 1.75rem;
    font-weight: 400;
  }

  .btn.apply {
    margin-bottom: 1rem;
  }
}

#stats-video-icon-button {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  top: 3px;
}

#statistics-reports,
#statistics-sub-reports {
  .by-text {
    display: inline-block;
    position: relative;
    font-size: em(16);
    color: #a4a4a4;
    float: left;
    padding: 5px 10px 0 0;
  }

  .savedReports {
    .disabled {
      border-bottom-color: #cecece !important;
      span {
        cursor: default;
        color: #cecece;
      }
    }
  }

  .reports-sub-menu {
    margin: 0 0 10px;
    padding: 10px 0;

    ul {
      display: inline-block;
      position: relative;
      float: left;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        position: relative;
        float: left;
        margin-right: 20px;
        border-bottom: 2px solid #ececec;

        &:hover {
          border-bottom-color: $cr-pale-green;
        }

        &.active {
          border-bottom-color: $cr-green;
        }

        span {
          display: block;
          position: relative;
          padding: 2px 2px 3px;
          font-size: em(18);
          color: $cr-grey;
          cursor: pointer;
          float: left;
        }

        .delete-saved-report {
          float: left;
          font-size: em(18);
          padding: 2px 2px 3px;
          cursor: pointer;
          position: relative;
          top: 7px;
        }

        .delete-saved-report {
          float: left;
          font-size: em(18);
          padding: 2px 2px 3px;
          cursor: pointer;
          position: relative;
          top: 7px;
        }
      }
    }
  }

  @media (max-width: $screen-xs) {
    + .z-depth-2 {
      margin: 0 -15px;
    }
  }
}

#statistics-summary {
  overflow: hidden;

  nav {
    overflow: hidden;
    border-right: 5px solid $cr-green;
  }

  .summary-row {
    min-width: 892px;
    overflow: hidden;

    .col-1-5 {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .col-1-5 {
    width: calc(100% / 5);
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 10%;
        right: 0;
        width: 1px;
        height: 80%;
        background-color: $cr-pale-grey;
      }
    }
  }

  .section {
    padding: 15px 10px 5px;

    & > span {
      font-size: em(44);
      font-weight: 200;
      line-height: 1.3em;
      color: $cr-grey49;
    }

    .title {
      font-size: em(14);
      color: $cr-green;

      .symbol {
        color: $cr-grey65;
      }
    }
  }
}

.statistics-reports-container {
  padding-bottom: 20px;
}
#statistics-sub-reports {
  padding-top: 15px;
}

#statistics-graph {
  .graph-container {
    padding-left: 15px;
    padding-right: 15px;

    label {
      font-weight: normal;
    }
  }

  .graphLabel {
    display: inline-block;
    position: relative;
    float: left;
    font-size: em(12);
    margin-top: 27px;
    margin-right: 10px;
  }
  .graphField {
    display: inline-block;
    position: relative;
    float: left;
    width: 240px;
  }
}

#statistics-line-chart-wrapper {
  width: 100%;
  padding-bottom: 1rem;
  #statistics-line-chart {
    width: 100%;
  }

  #no-stats {
    margin-top: 15px;
  }
}

.stats-add-filter-modal {
  width: 55%;
  overflow: visible;
  border-radius: 10px;

  @media (max-width: 991px) {
    width: 95%;
  }

  .modal-inner {
    background: #fff;
    border-radius: 10px;
    @media (max-width: 991px) {
      padding: 10px 5px;
    }

    .modal-footer-multiple {
      background: #fff;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

#statistics-filters {
  .baseFilters,
  .secondaryFilters {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 992px) {
      flex-direction: column;
      margin: 10px;
    }

    .filterInput,
    .filterInputText {
      margin-top: 0;
      margin-bottom: 25px;
      flex: 0 1 calc(25% - 30px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media (max-width: 992px) {
        width: 100%;
      }
      .stats-filters-dropdown {
        max-width: 278px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px 0px 0px 5px;
        margin: -4px 0 0;
        @media (max-width: 992px) {
          max-width: unset;
        }
        .multi-select {
          .text {
            max-width: 278px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media (max-width: 992px) {
              max-width: unset;
            }
          }
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding-left: 46px;
          display: flex;
          flex-direction: column;
          &:before {
            left: 17px;
          }
        }
      }
    }

    .filterInputText {
      max-height: 43px;
      margin-top: 25px;

      input {
        margin-bottom: 0;
      }
      label {
        left: 0;
      }
    }
  }
}

@include responsive('large') {
  #statistics-filters {
    &.row-eq-height {
      display: table;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      & > [class*='col-'] {
        float: none !important;
        display: table-cell !important;
        vertical-align: top;
      }

      .filters-buttons {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .col-md-3 {
        position: relative;

        button {
          display: inline-block;
          position: relative;
        }
      }

      .col-md-9 {
        padding-left: 0;
      }
    }
  }
}

@include responsive('normal') {
  #statistics-filters {
    &.row-eq-height {
      display: table;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      & > [class*='col-'] {
        float: none !important;
        display: table-cell !important;
        vertical-align: top;
      }

      .filters-buttons {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .col-md-3 {
        position: relative;

        button {
          display: inline-block;
          position: relative;
        }
      }

      .col-md-9 {
        padding-left: 0;
      }
    }
  }
}

@include responsive('tablet') {
  #statistics-filters {
    &.row-eq-height {
      .filters-buttons {
        display: block;
        text-align: right;
      }

      .col-md-3 {
        position: relative;

        button {
          display: inline-block;
          position: relative;
          float: left;
        }
      }
    }
  }
}

@include responsive('phone to tablet') {
  #statistics-filters {
    &.row-eq-height {
      .filters-buttons {
        display: block;
      }

      .col-md-3 {
        position: relative;

        button {
          display: inline-block;
          position: relative;
          float: left;
        }
      }
    }
  }
}

.mobile-device #statistics-page .statisticsTable .table-responsive table tr {
  th,
  td {
    padding-right: 5px !important;
    padding-left: 5px !important;

    div {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
  th:first-child,
  td:first-child {
    display: none;
  }

  td.canDrillDown .driller {
    display: none !important;
  }
  td.text-right > div {
    padding-right: 0 !important;
  }
}
