$brSwitchLayout: 991px;

.offerListingSort {
  position: relative;

  &.isInlineSort {
    width: 190px;
    height: 34px;
    border: 1px solid #8b8b8b;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;

    @media (max-width: $brSwitchLayout) {
      display: none;
    }

    .valueBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      gap: 5px;
      color: #424242;
      padding: 0 20px;

      svg {
        width: 20px;
        min-width: 20px;
        transition: 0.2s;
        margin: -1px 0 0;

        &.dropdownOpen {
          transform: rotate(180deg);
          margin: 0;
        }
      }
    }

    .offerListingSortDropdown {
      width: 190px;
    }
  }

  &.isSortCta {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .valueBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .offerListingSortDropdown {
      width: 100%;
    }
  }

  .offerListingSortDropdown {
    font-size: 14px;
    background: #ffffff;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 200;
    color: #424242;

    li {
      width: 100%;
      cursor: pointer;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .circleBox {
            background: #a0a0a0;
          }
        }
      }

      .circleBox {
        width: 20px;
        height: 20px;
        background: #e3e3e3;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 55%;
        transition: 0.2s;

        &.selected {
          background: #00bd00;

          svg {
            display: block;
          }
        }

        svg {
          width: 100%;
          display: none;
        }
      }
    }
  }
}
