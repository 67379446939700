$br1: 1355px;
$br2: 576px;
$br3: 429px;

.referral-section {
  margin: 75px auto;
  display: flex;
  gap: 25px;
  width: 100%;
  max-width: 1000px;

  @media (max-width: $br1) {
    flex-flow: column;
    max-width: 600px;
    gap: 55px;
  }

  .program {
    width: 100%;
    height: 468px;
    border-radius: 18px;
    background: #f1f1f1;
    padding: 65px;
    display: flex;
    flex-flow: column;
    gap: 25px;
    @media (max-width: $br1) {
      width: 100%;
      height: auto;
      max-width: unset;
      padding: 25px;
      padding-bottom: 75px;
    }

    .title {
      color: #181761;
      font-size: 28px;
      font-weight: 800;
      text-transform: capitalize;
      margin: 0;

      @media (max-width: $br1) {
        font-size: 20px;
        font-weight: 800;
      }
    }

    .commission {
      color: #fa4d92;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      text-transform: capitalize;
      margin: 0;
      line-height: 97%;
      @media (max-width: $br1) {
        font-size: 48px;
        font-weight: 800;
      }
    }

    p {
      color: #7b7b7b;
      font-size: 15px;
      font-weight: 400;
      margin: 10px 0 0;

      @media (max-width: $br1) {
        margin: 0;
      }

      .dark-grey {
        color: #7b7b7b;
        font-weight: 800;
      }
    }

    .copy-value-display {
      border-radius: 5px;
      background: #aaa;
      color: #fff;
      @media (max-width: $br2) {
        flex: unset;
      }
    }

    .copy-value-widget {
      flex-flow: row;

      .actions-section {
        @media (max-width: $br2) {
          width: 100%;
          min-width: unset;
        }
      }
    }

    .copyBtn {
      border-radius: 5px;
      margin-left: -5px;
    }
  }

  .jerky-blue {
    width: 379px;
    min-width: 379px;
    border-radius: 18px;
    background: #181761;
    position: relative;

    @media (max-width: $br1) {
      width: 100%;
      min-width: unset;
      height: 240px;
    }

    @media (max-width: $br3) {
      height: 210px;
    }

    .jerky-anim img,
    .jerky-coins-left img,
    .jerky-coins-right img {
      transition: 0.5s;
    }

    .jerky-anim {
      width: 379px;
      min-width: 379px;
      height: 468px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $br1) {
        margin: auto;
        transform: scale(0.95);
        top: -22%;
        left: 6%;
      }

      @media (max-width: $br3) {
        transform: scale(0.85);
        left: 2%;
      }

      &.active {
        .jerky-body-box {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;

          img {
            position: absolute;

            &.jerky-head {
              top: 130px;
              left: 35px;
              transform: rotate(0deg);
            }
            &.jerky-body {
              bottom: 78px;
              left: 123px;
              transform: rotate(0deg);
            }
            &.jerky-arm-left {
              left: 40px;
              bottom: 128px;
              transform: rotate(28deg);
            }
            &.jerky-arm-right {
              right: -28px;
              top: 16px;
              transform: rotate(-11deg);
            }
          }
        }
        .jerky-coins-left {
          img {
            &:first-child {
              left: 2px;
              top: 90px;
              transform: rotate(0deg);
            }

            &:nth-child(2) {
              left: -50px;
              bottom: 97px;
              transform: rotate(0deg);
              @media (max-width: $br1) {
                left: -51px;
                bottom: 105px;
              }
            }
          }
        }

        .jerky-coins-right {
          img {
            &:first-child {
              right: 140px;
              top: 80px;
              transform: rotate(0deg);
            }

            &:nth-child(2) {
              right: -6px;
              bottom: 227px;
              transform: rotate(0deg);
            }

            &:last-child {
              right: -25px;
              bottom: 15px;
              transform: rotate(0deg);
              @media (max-width: $br1) {
                bottom: 45px;
                right: -27px;
              }
            }
          }
        }
      }

      .jerky-anim img,
      .jerky-coins-left img,
      .jerky-coins-right img {
        transition: 0.3s;
      }

      .jerky-body-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;

        img {
          position: absolute;

          &.jerky-head {
            width: 260px;
            z-index: 200;
            top: 130px;
            left: 30px;
            transform: rotate(-2deg);
          }
          &.jerky-body {
            width: 105px;
            bottom: 74px;
            left: 120px;
            transform: rotate(0deg);
          }
          &.jerky-arm-left {
            width: 70px;
            left: 46px;
            bottom: 123px;
            transform: rotate(1deg);
            transform-origin: center right;
          }
          &.jerky-arm-right {
            width: 175px;
            right: -49px;
            top: 28px;
            transform: rotate(-1deg);
            z-index: 300;
          }
        }
      }
    }

    .jerky-coins-left,
    .jerky-coins-right {
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        position: absolute;

        &:first-child {
          z-index: 100;
        }

        &:nth-child(2) {
          z-index: 100;
        }
      }
    }

    .jerky-coins-left {
      left: 0;
      align-items: flex-start;

      img {
        &:first-child {
          width: 105px;
          left: -15px;
          top: 110px;
          transform: rotate(29deg);
        }

        &:nth-child(2) {
          width: 110px;
          left: -67px;
          bottom: 80px;
          transform: rotate(-26deg);
          @media (max-width: $br1) {
            width: 105px;
            left: -60px;
            bottom: 100px;
          }
        }
      }
    }

    .jerky-coins-right {
      right: 0;
      align-items: flex-end;

      img {
        &:first-child {
          width: 76px;
          right: 125px;
          top: 90px;
          transform: rotate(30deg);
        }

        &:nth-child(2) {
          width: 95px;
          bottom: 220px;
          right: -15px;
          transform: rotate(-11deg);
        }

        &:last-child {
          width: 168px;
          right: -47px;
          bottom: 8px;
          transform: rotate(15deg);
          @media (max-width: $br1) {
            bottom: 38px;
          }
        }
      }
    }
  }
}
