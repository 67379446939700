#offer-additionnal-info {
    @include flexbox(column, nowrap, flex-start);

    h2.title svg path {
        fill: $low-highlight-color;
    }
    .infos {
        padding: 1rem;
        & > * {
            &:not(:first-child) {
                border-top: 2px solid #dcdcdc;
            }
            padding: 0.5rem 0;
        }
    }
}
