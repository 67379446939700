#signupQuestionsModal {
    width: 75%;
    overflow-x: hidden;

    .modal-content {
        display: block;
        position: relative;
        padding: 0px;
    }

    .modal-close {
        top: 5px;
        color: #fff;
    }

    .signupQuestionsModal-header {
        background-color: #282828;
        border-bottom: 4px solid #9d9d9d;
        padding: 30px 24px 16px;
        text-align: center;
        color: #fff;

        h1 {
            font-size: em(28);
            text-transform: uppercase;

            i {
                display: inline-block;
                position: relative;
                width: 36px;
                height: 36px;
                background: url(/img/policy-update.svg) no-repeat 0 0;
                margin-right: 15px;
                top: 10px;
            }
        }
    }

    .form-container {
        padding: 40px 0px;
    }
}

#signupQuestionsCompletedModal {
    background-color: #fff;
    height: auto;

    .modal-content {
        position: relative;
        height: 100%;
        text-align: center;
        color: $cr-dark-grey;
    }

    h1 {
        font-size: em(20);
        font-weight: 400;
        margin: 30px 0 15px;
    }

    p {
        font-weight: 200;
        font-size: em(22);
        margin: 10px 0 0;
    }

    .login-return {
        margin-top: 30px;
        background-color: $cr-pale-green;
    }

    hr {
        display: inline-block;
        position: relative;
        width: 20%;
        border: 0;
        border-top: 2px solid $cr-pale-green;
    }

    .icon {
        display: block;
        position: relative;
        width: 94px;
        height: 94px;
        margin: auto;
        background-color: $cr-green;
        color: #fff;
        text-align: center;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        i {
            font-size: 60px;
            margin-top: 16px;
        }
    }
}

@include responsive('tablet') {
    #signupQuestionsModal {
        .formContainer {
            padding: 0 30px;
        }
    }
}

@include responsive('phone to tablet') {
    #signupQuestionsModal {
        .formContainer {
            padding: 0 30px;
        }
    }
}
