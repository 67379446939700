#newsbox {
    &.no-margin-top {
        margin-top: 0;
    }
    #NewsBox {
        width: 100%;
        text-align: center;
        p {
            color: #fff;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 1px;
            b {
                font-weight: 500;
            }
            a {
                color: white;
                text-decoration: underline;
            }
        }
    }
}
