$br1: 1355px;

.get-in-touch-box {
  margin: 120px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 100%;
  max-width: 980px;
  color: #000;

  @media (max-width: $br1) {
    flex-flow: column;
    width: 100%;
    margin-top: 70px;
  }

  .description-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $br1) {
      max-width: unset;
      width: 100%;
      max-width: 900px;
    }

    h2 {
      text-transform: capitalize;
    }

    p {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      max-width: 900px;
      margin: 15px 0 0;
    }

    .button-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      margin: 35px 0 0;

      .need-help {
        margin: 0 0 7px;
      }
    }
  }

  .jerkmate-btn {
    @media (max-width: $br1) {
      width: 100%;
      max-width: 600px;
    }
  }

  .benefits-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;

    @media (max-width: $br1) {
      width: 100%;
      max-width: 600px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
    }

    .benefit-box {
      padding: 10px 20px;
      align-items: center;
      border-radius: 18px;
      background: #f1f1f1;
      text-align: center;
      margin-bottom: 15px;
      gap: 20px;
      display: flex;

      @media (min-width: 1336px) {
        &:nth-child(1) {
          margin-right: 15px;
        }
        &:nth-child(3) {
          margin-left: 15px;
        }
        &:nth-child(4) {
          text-align: center;
          margin: 0 auto;
        }
      }

      @media (max-width: $br1) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 230px;
        width: calc(50% - 5px);
        margin: 10px 0 0 !important;
      }

      svg {
        width: 53px;
        height: 53px;

        @media (max-width: $br1) {
          width: 77px;
          height: 77px;
        }
      }

      p {
        color: #131313;
        text-align: center;
        font-size: 15px;
        line-height: 150%;
        font-weight: 400;
        margin: 0;

        @media (max-width: $br1) {
          width: 100%;
          max-width: 220px;
        }

        @media (max-width: 429px) {
          font-size: 3.7vw;
        }
      }
    }
  }
}
