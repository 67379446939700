#home-survey {
    background: #fff;
    position: fixed;
    bottom: 0;
    height: 290px;
    width: 100%;
    z-index: 502;
    box-shadow: 0 0 16px rgba(30, 30, 30, 0.35);
    border-radius: 6px;
    transition: all 300ms ease-out;
    max-width: calc(100% - 330px);
    left: 280px;
    &.closed {
        transform: translateY(100%);
    }
    &.extended {
        left: 112px;
        max-width: calc(100% - 164px);
    }
    .container {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0;
        .close {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 13px;
            &:hover {
                cursor: pointer;
            }
        }
        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
    @media (max-width: 1030px) {
        display: none;
    }
}
