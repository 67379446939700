$br1: 1355px;
$br2: 450px;

$transitionAmount: 0.3s;

@mixin info-box {
  border: 1px solid rgba(33, 33, 33, 0.15);
  background: linear-gradient(to top, rgba(237, 238, 239, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-radius: 14px;
  position: relative;
  box-shadow: 4px 14px 14px 0px rgba(123, 123, 123, 0.251);
}

@mixin info-title {
  font-size: 14px;
}

.jerkmate-offer-card {
  width: 417px;
  height: 525px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  border: 1px solid rgba(33, 33, 33, 0.25);
  background: linear-gradient(to top, rgba(248, 248, 248, 1) 0%, rgba(255, 255, 255, 1) 100%);
  color: #000000;
  padding: 20px;
  padding-bottom: 0;
  cursor: pointer;
  transform-origin: left top;
  transition: $transitionAmount;
  position: relative;
  font-size: 12px;

  > * {
    transition: $transitionAmount;
  }

  &.modalDiv {
    @media (max-width: 767px) {
      padding: 15px 10px;
      height: auto;

      .info-offer p {
        margin-bottom: 10px !important;
      }
    }
  }

  &.active {
    .jerkmate-icon,
    .info-offer-box,
    .epc-box,
    .payout-box,
    .stats-right,
    .jerkmate-offer-btn,
    .tag.grey {
      box-shadow: 4px 8px 8px 0px rgba(0, 0, 0, 0.5) !important;
    }

    .info-offer-box {
      border-color: #181761;
    }

    .background-hover-gradient {
      opacity: 1;
    }

    .offer-title {
      color: #fb4d94;
    }

    .total {
      font-weight: 700;
    }

    .jerkmate-offer-btn {
      font-weight: 700;
    }

    .payout-total-box {
      span {
        font-weight: 700;
      }
    }

    .bold-title-hover {
      font-weight: 700;
      &.epc {
        font-weight: 800;
      }
    }

    .jerkmate-icon {
      transform: scale(1.02);
      margin: -2px 0 0;
    }

    .jerky-right {
      img {
        &.offer-jerky-head {
          right: 5px;
          top: 7px;
        }
        &.offer-jerky-arm-left {
          right: 63px;
          bottom: 3px;
          transform: rotate(-24deg);
        }
        &.offer-jerky-arm-right {
          right: 4px;
          bottom: 20px;
          transform: rotate(8deg);
        }
        &.offer-jerky-body {
          right: 25px;
          bottom: -12px;
        }
      }
    }
  }

  @media (max-width: $br2) {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }

  .bold-title-hover {
    transition: $transitionAmount;
  }

  .background-hover-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, #0808d8 0%, #16156e 100%);
    border-radius: 18px;
    z-index: 0;
    opacity: 0;
  }

  .inner-offer-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 100;

    .new-tag-box {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .new-tag {
        display: flex;
        width: 39px;
        height: 17px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 5px;
        background: #f95294;
        color: #fff;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .offer-spacing {
    margin-top: 8px;
  }

  .offer-spacing2 {
    margin-top: 15px;
  }

  .tag {
    border-radius: 55px;
    padding: 1px 6px;
    font-size: 10px;

    strong {
      font-weight: 700;
    }

    &.grey {
      border: 1px solid rgba(33, 33, 33, 0.15);
      box-shadow: 2px 4px 4px 0px rgba(123, 123, 123, 0.15);
      margin: 5px 0 0;
      color: #7b7b7b;
      background: #ffffff;
      font-size: 11px;

      strong {
        line-height: 50%;
        color: #5b5b5b;
        font-size: 12px;
      }
    }

    &.green {
      background: #00bc00;
      color: #ffffff;
      margin: 0 0 0 10px;
    }
  }

  .offer-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      &.jerkmate-icon {
        width: 73px;
        height: 73px;
        transition: $transitionAmount;
        border-radius: 50%;
      }
    }

    .title-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 0 20px;
      position: relative;

      .top {
        width: 100%;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        padding-right: 15px;

        .circle {
          width: 9px;
          min-width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #777777;
          position: relative;
          top: -9px;
          left: 7px;
          display: inline-block;

          &.approved {
            background: #00bc00;
          }
        }
      }
    }
  }

  .info-offer-box {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;

    @include info-box;

    .info-offer {
      height: 130px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 15px 12px;
      color: #555555;

      @media (max-width: $br2) {
        height: 120px;
      }

      .title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include info-title;
      }

      p {
        width: 98%;
        margin: 7px 0 5px;
        line-height: 122%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .read-more {
        width: 100%;
        font-size: 10px;
      }
    }
  }

  .jerky-right {
    width: 108px;
    min-width: 108px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    .jerky-inner {
      height: 90px;
      position: relative;
      transform: scale(1.5);
      transform-origin: right bottom;
      right: -35px;

      @media (max-width: $br2) {
        transform: scale(1.3);
        right: -25px;
        bottom: 5px;
      }
    }

    img {
      transition: $transitionAmount;

      &.offer-jerky-head {
        width: 102px;
        position: absolute;
        right: 1px;
        top: 10px;
      }
      &.offer-jerky-arm-left {
        width: 31px;
        position: absolute;
        right: 57px;
        bottom: 3px;
      }
      &.offer-jerky-arm-right {
        width: 23px;
        position: absolute;
        right: 3px;
        bottom: 17px;
      }
      &.offer-jerky-body {
        width: 36px;
        position: absolute;
        right: 21px;
        bottom: -18px;
        transform: rotate(-17deg);
      }
    }

    .circle {
      width: 176px;
      height: 176px;
      position: absolute;
      border-radius: 50%;
      background: #181761;
      right: -57%;
      top: -19%;

      @media (max-width: $br2) {
        right: -67%;
      }
    }
  }

  .info-offer-stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .stats-left.full-width {
      width: 100%;
    }

    .stats-left {
      width: 66.5%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 8px 0 0;

      .epc-box,
      .payout-box {
        width: 100%;
        padding: 15px 20px;
        padding-bottom: 17px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @include info-box;
      }

      .epc-box.full-width {
        max-width: unset;

        .title {
          .left {
            span {
              max-width: 200px;
            }
          }
        }
      }

      .epc-box {
        max-width: 195px;
        position: relative;
        padding-right: 10px;
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          color: #555555;

          .bold-title-hover {
            @include info-title;
          }

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              max-width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin: 0 0 0 5px;
            }
          }
        }

        .total-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 4px 0 0;

          .amount {
            font-size: 28px;
            color: #343434;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 100%;
          }

          svg {
            width: 9px;
            fill: #00bc00;
          }
        }

        .jerkmate-offer-card-fire-icon {
          position: absolute;
          right: -6px;
          top: -6px;
        }
      }

      .payout-box {
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: flex-start;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              @include info-title;
              margin: 0 0 0 8px;
            }
          }
        }

        .payout-total-box {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 4px 0 0;

          &.REV {
            span {
              @media (max-width: $br2) {
                font-size: 9vw;
              }
            }
          }

          span.payout {
            font-size: 48px;
            letter-spacing: -1px;
            line-height: 100%;
            color: #131313;
            transition: $transitionAmount;

            @media (max-width: $br2) {
              font-size: 10vw;
            }
          }

          div {
            font-size: 8px;
            text-decoration: underline;
            margin: 0 0 0 10px;
            white-space: nowrap;
          }
        }
      }
    }

    .stats-right {
      width: 33.5%;
      min-width: 33.5%;
      padding: 8px;
      padding-top: 13px;
      @include info-box;

      .title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #555555;

        span {
          @include info-title;
          line-height: 120%;
          margin: -2px 0 0 5px;
        }

        svg {
          width: 15px;
          min-width: 15px;
        }
      }

      .countries {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 12px 0 0;

        .country {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 0;

          &:first-child {
            margin: 0;
          }

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 20px;
              border-radius: 50%;
              font-size: 0;
            }

            span {
              color: #555555;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }
  .jerkmate-offer-btn {
    width: 100%;
    height: 40px;
    max-width: 225px;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    border-radius: 55px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(269deg, #fa4d92 9.29%, #ec6c9f 90.85%);

    @media (max-width: $br1) {
      max-width: none;
    }
  }

  .jerkmate-offer-card-fire-icon {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(33, 33, 33, 0.15);
    background: linear-gradient(to top, rgb(237, 238, 239) 0%, rgb(255, 255, 255) 100%);
    border-radius: 50%;

    svg {
      width: 85%;
    }
  }
}
