.jerkmate-page {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    color: #191862;

    @import 'jerkmate/JerkmateIntro.react.scss';
    @import 'jerkmate/JerkmateAdvantages.react.scss';
    @import 'jerkmate/JerkmateStats.react.scss';
    @import 'jerkmate/JerkmateOffers.react.scss';

    .jerkmate-pink {
        color: #fb4d94;
    }

    .jerkmate-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 290px;
        height: 55px;
        background-color: #fb4d94;
        border-radius: 10px;
        cursor: pointer;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        transition: all 150ms ease 0s;
        position: relative;
        z-index: 1;

        &:hover {
            color: #fb4d94;
            background-color: #fff;
            border-color: #fff;
        }

        @media (max-width: 540px) {
            width: 100%;
        }
    }

    .jerkmate-container {
        max-width: 1000px;
        margin: 2rem auto;

        @media (max-width: 992px) {
            margin: 2rem 15px;
        }

        @media (max-width: 540px) {
            margin: 0;
        }
    }

    .shape-light-blue,
    .shape-light-blue-rotate {
        position: absolute;
        top: -300px;
        left: -100px;
        z-index: 0;

        @media (max-width: 540px) {
            top: -50px;
            left: -300px;
            z-index: 0;
            height: 500px;
        }
    }

    .shape-light-blue-rotate {
        display: none;
        transform: rotate(220deg);
        @media (max-width: 540px) {
            display: block;
            top: 500px;
            left: -150px;
            z-index: 0;
            height: 500px;
        }
    }
}
