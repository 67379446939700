#viewmode-component {
  @media (max-width: 1240px) {
    display: none;
  }

  margin-top: 20px;
  padding-left: 15px;
  display: flex;
  flex-flow: row-reverse;
  & > span {
    margin-left: 10px;
  }

  .viewmode-text {
    display: inline-block;
    position: relative;
    color: $cr-grey;
    font-size: 15px;
  }

  .btn-icon {
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: $cr-grey;
  }
}
