.jerkmate-stats {
    margin-top: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 80px;
    position: relative;
    background-color: #fff;

    @media (max-width: 992px) {
        flex-direction: column;
    }

    @media (max-width: 576px) {
        padding: 20px 0;
    }

    .stats-item {
        display: flex;
        flex-direction: column;
        text-align: center;

        @media (max-width: 992px) {
            margin-bottom: 15px;
        }

        @media (max-width: 768px) {
            &:not(:last-child) {
                margin-bottom: 80px;
            }
        }
        .stats-nb {
            font-size: 36px;
            font-weight: 800;
        }

        .stats-desc {
            font-size: 22px;
            font-weight: normal;
        }
    }
}
