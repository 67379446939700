.generator {
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: em(18);
    color: #282828;
    text-transform: uppercase;

    .collapse-arrow {
      position: relative;
      margin: 0 0 0 10px;
      top: 5px;
      cursor: pointer;
    }
  }

  ul.tabs {
    margin: 0;
    li {
      float: left;
      margin-right: 1px;
      overflow: hidden;
      background-color: #d8d8d8;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
      border-radius: 4px;
      position: relative;
      bottom: -12px;
      &.textSettings {
        i {
          font-size: 26px;
          top: 12px;
        }
      }
      svg {
        width: 19px;
      }
      svg > g > g {
        fill: #494949;
      }
      &.selected {
        bottom: -6px;
      }
      &.disabled {
        opacity: 0.5;
      }

      a {
        position: relative;
        display: block;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;

        span {
          font-size: 14px;
          padding-top: 8px;
          display: inline-block;
          padding-left: 28px;
        }

        &:active,
        &:visited,
        &:link {
          color: #494949;
          text-decoration: none;
        }
        @media screen and (max-width: 1300px) {
          span {
            padding-left: 0;
          }
          i {
            display: none;
          }
        }
      }
      &.selected a {
        background-color: #ffffff;
      }
      &.template a:before {
        content: '';
      }
      &.nicheSettings i {
        width: 24px;
        height: 23px;
      }
    }
  }

  .input-field {
    label {
      left: 0;
    }
    &.with-units {
      padding-right: 25px;
    }
  }

  [type='radio'] + label:before,
  [type='radio'] + label:after {
    margin-left: 0;
  }

  .radio-field {
    display: block;
    margin-right: 20px;
    float: left;
    label {
      padding-left: 1.7rem;
    }
    &.last {
      margin-right: 0;
    }
  }

  button:not(.btn-link):not(.inputBoxBtn),
  .disabled-button-layer {
    border-radius: 2px;
    background-color: $cr-green;
    padding: 15px 30px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f8f8f8;
    border: 0;
    &[disabled] {
      background-color: #dfdfdf !important;
      box-shadow: none;
      color: #9f9f9f !important;
      cursor: default;
    }
  }

  @extend #survey-generator-page;
  padding-top: initial;
  .modal,
  #survey-generate-modal,
  #live-cam-widget-modal {
    max-height: 85vh !important;
    top: 10vh !important;
    .modal-content {
      max-height: 85vh !important;
    }
  }

  .config-section {
    padding-bottom: 20px;
    margin-top: 45px;
    margin-bottom: 15px;
    &.no-margin {
      margin-top: 15px;
    }
    &.separator-left {
      border-left: 1px solid #d8d8d8;
      &:before {
        display: none;
      }
    }
    &.separator-right {
      border-right: 1px solid #d8d8d8;
      &:after {
        display: none;
      }
    }
    .radio-field label span {
      text-transform: none;
    }
    .radio-aligned {
      .radio-field {
        padding: 0 30px 20px 0;
        &:first-child {
          padding-left: 0;
        }
      }
      .checkbox-field {
        padding: 0 30px 20px 0;
        label {
          &:after {
            transform: scale(0.85);
          }
        }
      }
    }
    h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 500;
      display: block;
      padding-bottom: 1rem;
      margin: -35px 0 0;
      &.no-margin {
        margin-top: 0;
      }
      [data-tooltip-id] {
        position: absolute;
        margin: -9px 0 0 -26px;
      }
      .info {
        margin: 0;
        i {
          font-size: 15px;
          position: absolute;
          top: 10px;
          display: inline-block;
          margin: 0 0 0 6px;
        }
      }
    }
    .buffer {
      display: block;
      margin: 0;
      padding: 0;
      height: 40px;
      clear: both;
    }
  }

  ul.tabs li a i.material-icons {
    position: absolute;
    top: 15px;
    font-size: 20px;
  }
  .radio-field {
    margin-right: 0;
  }
  div.cust-label {
    font-size: 0.85714em;
  }
  .row.etiquettes {
    padding: 0 15px 0 15px;
    &.categories .col-md-12 {
      padding: 0;
    }
    .col-md-12 > div {
      float: none;
      &:not(.row) {
        float: left;
        margin-top: 1rem;
      }
    }
  }
}

.textarea-css {
  border: 1px solid #000;
  font-family: monospace;
  min-height: 346px;
  overflow-y: auto;
  resize: none;
  width: 100%;
  background-image: linear-gradient(
    0deg,
    #f6f6f6 25%,
    #ffffff 25%,
    #ffffff 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-size: 90px 80px;
  font-size: 14px;
  line-height: 20px;
  padding: 0 4px;
  &[disabled] {
    color: $cr-greyText;
    cursor: not-allowed;
  }
  &.example {
    cursor: text;
    color: #8c8c8c;
  }
}

.demoIframe .iframe-placeholder .livecam-generator-iframe-placeholder.placeholder-content {
  background: #282828 url(/img/Tiling_WidgetMFC.png) center top no-repeat;
  background-size: contain;
  padding: 114px 47px 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0;
  p {
    font-size: 16px;
    margin: 0 0 25px;
  }
  br + p {
    margin-top: 27px;
  }
  ul {
    margin: 32px 0 34px 3px;
    li {
      padding: 0 0 12px 41px;
      &:before {
        left: 18px;
      }
    }
  }
}
