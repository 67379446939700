$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

@mixin large {
  font-size: 15px;
  gap: 10px;

  svg {
    width: 25px;
  }
}

@mixin medium {
  font-size: 13px;
  gap: 6px;

  svg {
    width: 20px;
  }
}

.offerListingPreviewChip {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #00bd00;
  @include large;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $br1) {
    @include medium;
  }

  @media (max-width: $br3) {
    @include large;
  }
}
