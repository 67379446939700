#profile-emailpreferences {
    padding-top: 20px;
    max-width: 1400px;

    h2 {
        font-size: 1.2em;
    }

    .email-lists {
        margin-top: 1em;
    }
}
