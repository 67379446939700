#offline {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background: #fff;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 60px;
  font-family: 'Proxima', arial, sans-serif;
  z-index: 50003;

  #password-reset-page {
    #password-reset-form {
      position: relative;
      .btn {
        &:disabled {
          background-color: #dfdfdf !important;
        }
      }

      .input-field {
        .reveal-password {
          border: none;
          margin: 0;
          padding: 0;
          background: #fff;
          all: unset;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: none;
          color: inherit;
          font: inherit;
          text-align: center;
          appearance: none;
          i.icon-open,
          i.icon-close {
            font-size: 17px;
            position: absolute;
            right: 15px;
            z-index: 3;
            color: #000 !important;
          }
          &.open {
            i.icon-open {
              display: none;
            }
            i.icon-close {
              display: inline-block;
            }
          }
          &.close {
            i.icon-open {
              display: inline-block;
            }
            i.icon-close {
              display: none;
            }
          }
        }
      }
    }
  }

  .greyed-out {
    opacity: 0.5;
  }
  .logo {
    display: block;
    position: relative;
    padding: 11px 0 0;
    @media (max-width: 550px) {
      padding: 11px 15px 0;
    }
  }

  .offline-container {
    max-width: 900px;
    margin: 0 auto;

    .back-to-website {
      margin-top: 50px;
      margin-bottom: 10px;
      padding-left: 0;

      a {
        color: $cr-grey;

        i {
          position: relative;
          top: 7px;
        }
      }
    }

    .is-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .legacy-login {
      background-color: #fff;
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
      padding: 15px 10px;
      box-shadow: -6px 7px 34px 0 rgb(142, 142, 142);

      a {
        color: $cr-green;
        padding-left: 15px;

        i {
          position: relative;
          top: 7px;
        }
      }
    }

    .shadow-container {
      background-color: #fff;
      box-shadow: -6px 7px 34px 0 #8e8e8e;
    }

    .forgot-message {
      color: $cr-green;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .home-link {
      display: inline-block;
      position: relative;
      color: #fff;
      font-size: 1em;
      padding: 13px 0 6px;
      margin: 5px 0 0;
      width: 300px;
      max-width: 100%;

      img {
        width: 100%;
        height: auto;
      }

      i {
        display: inline-block;
        position: relative;
        float: left;
        margin-right: 8px;
        margin-top: 5px;
      }

      span {
        display: inline-block;
        position: relative;
        float: left;
        padding: 7px 0 0;
      }
    }
  }
}

@include responsive('phone to tablet') {
  #offline {
    .legacy-login {
      font-size: 12px;
    }
  }
}

@include responsive('phone') {
  #offline {
    .btn {
      padding: 0.7em 2em;
    }

    .legacy-login {
      font-size: 12px;
    }
  }
}
