.offer-landing-page-modal {
  .modal-inner {
    padding: 40px 50px 50px 50px;

    @media (max-width: 576px) {
      padding: 30px 10px;
    }
  }
  .available-landing-modal {
    position: relative;
    height: 71vh;
    @media (max-width: 992px) {
      height: 81vh;
    }

    .modal-box {
      max-height: 65vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 15px;

      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(1, 1);

      &.performer-modal-box {
        overflow: unset;

        @media (max-height: 844px) {
          overflow-x: hidden;
          overflow-y: auto;
        }

        @media (max-width: 992px) {
          overflow-x: hidden;
          overflow-y: auto;
          padding: 0 5px;
          max-height: 75vh;
        }
      }

      @media (max-width: 992px) {
        padding: 0 5px;
        max-height: 75vh;
      }

      .modal-header {
        display: flex;
        justify-content: space-between;
        flex-flow: column;

        @media (max-width: 992px) {
          flex-flow: column;
        }

        .sub-header {
          .preview-type.mobile-preview {
            display: none;

            @media (max-width: 992px) {
              display: flex;
              margin-right: 15px;
            }

            .desktop-icon {
              margin-right: 10px;
            }
          }

          .offer-tabs {
            margin: 20px 0px;

            @media (max-width: 576px) {
              overflow: unset;
            }

            .menuTab {
              padding: 5px 20px 5px 0;
              color: #6d6d6d;
              font-size: 21px;
              font-style: normal;
              font-weight: 600;
              line-height: 18.2px;
              text-transform: capitalize;

              @media (max-width: 576px) {
                font-size: 18px;
              }

              @media (max-width: 376px) {
                font-size: 16px;
              }

              svg {
                margin-right: 10px;
              }
              &.selected {
                color: #333;
              }
            }
          }

          .performer-page-sub-header {
            display: flex;
            flex-flow: column;
            margin-top: 50px;
            gap: 10px;
            .header {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            h2 {
              color: #000;
              font-size: 20px;
              font-weight: 700;
              margin: 0;
            }
            p {
              color: #000;
              font-weight: 400;
              margin: 0;
              font-size: 16px;
              font-style: normal;
              line-height: 24px;

              @media (max-width: 576px) {
                font-size: 13px;
                line-height: unset;
              }

              a {
                color: #000;
                font-style: normal;
                font-weight: 400;
                text-decoration-line: underline;
              }
            }
          }
        }

        .search-tool {
          display: flex;
          justify-content: space-between;

          @media (max-width: 992px) {
            flex-flow: column;
          }
          .desktop-results {
            @media (max-width: 992px) {
              display: none;
            }
          }
          .search-input {
            width: 35%;
            @media (max-width: 992px) {
              width: 100%;
            }
            input {
              padding-left: 2em;
              backface-visibility: visible;
            }
          }

          .select-dropdown {
            margin: 0;
          }
        }

        h4 {
          margin: 0;
          padding: 0;
          color: #00bd00;
          font-weight: bold;

          @media (max-width: 992px) {
            margin: 0px 10px;
          }

          @media (max-width: 576px) {
            font-size: 20px;
            margin: 0px 10px;
          }
        }

        .devices-icons {
          .mobile-results {
            display: none;
            @media (max-width: 992px) {
              display: flex;
              align-items: center;
            }
          }
          .preview-type {
            display: flex;
            align-items: center;

            &.desktop-preview {
              @media (max-width: 992px) {
                display: none;
              }
            }
          }
        }

        .select-field {
          margin: 0;
        }

        .sort-by {
          display: flex;
          align-items: center;
          margin-left: 20px;

          @media (max-width: 992px) {
            margin-left: 0;
          }
          &.fields {
            width: auto;
            .field {
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;
              .inputRow,
              .inputBox {
                width: auto;
              }
            }
          }
          .labelBox {
            width: auto;
            label {
              font-weight: normal;
            }
          }
        }

        .devices-icons {
          display: flex;
          align-items: center;
          margin-bottom: 25px;

          .rightside-tools {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            @media (max-width: 992px) {
              justify-content: space-between;
              width: 100%;
            }
            .customSelects {
              width: auto;
            }
          }
          span {
            margin-right: 5px;
          }

          @media (max-width: 992px) {
            align-items: initial;
            justify-content: space-between;

            .sort-by {
              margin-left: 0px;
              justify-content: flex-end;
            }
          }
        }
      }
      .label-landing {
        margin: 0;
        font-size: 14px;

        &:nth-child(2n) .screenshot-box {
          background-color: #ececec;
        }

        .screenshot-box {
          display: flex;
          flex-flow: row;
          margin: 15px 0;
          cursor: pointer;
          padding: 0 10px;
          border: 2px solid transparent;

          @media (max-width: 992px) {
            flex-flow: column;
            padding: 10px;
            &.selected {
              border-color: #00bd00;
            }
          }

          .screenshot-section {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin: 15px 0;
            cursor: pointer;
            height: 100%;

            @media (max-width: 992px) {
              margin: 0;
              justify-content: center;
              text-align: center;
            }

            .radio-field {
              display: flex;
              align-self: center;

              @media (max-width: 992px) {
                display: none;
              }

              label {
                height: 100%;
                margin-right: 20px;
              }
            }

            .desktop {
              img {
                width: 320px;
                height: auto;
                max-height: 220px;
                border: 1px solid #f0f0f0;
                vertical-align: bottom;
              }
            }

            .mobile {
              overflow: hidden;
              max-height: 320px;
              position: relative;
              img {
                min-width: 187.5px;
                max-height: 406px;
                border: 1px solid #f0f0f0;
                vertical-align: bottom;
              }
              &::before {
                display: block;
                position: absolute;
                background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
                margin-top: 255px;
                height: 65px;
                width: 100%;
                content: '';
              }
            }

            #cr-screenshot-loading {
              border: none;
              max-width: 200px;
            }
          }

          .landing-details {
            margin-left: 20px;
            width: 100%;
            display: flex;
            flex-flow: column;

            @media (max-width: 992px) {
              margin-left: 0;
            }

            .featured_container {
              display: flex;

              align-items: center;

              .featured {
                margin-right: 10px;

                padding: 0;

                height: 30px;
              }
            }

            .featured {
              width: 30px;

              min-width: 30px;

              max-width: 30px;

              height: auto;
            }

            .landing-name {
              font-size: 20px;
              font-weight: 900;
              border-bottom: 2px solid #cacaca;
              padding: 5px 0;
              margin-bottom: 0.7rem;
            }

            .epc {
              border-bottom: 2px solid #cacaca;
              padding: 5px 0;
              display: flex;
              gap: 7px;

              svg {
                width: 20px;
                height: 20px;
              }
            }

            .preview-landing-page {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              border: none;
              padding-top: 5px;

              a {
                display: flex;
                width: auto;

                &:hover {
                  opacity: 0.75;
                }
              }

              span {
                margin: 2px 0 0 5px;
              }
            }
          }
        }

        &.optimized {
          display: block;
          border-bottom: 2px solid #cacaca;
          margin-bottom: 15px;
        }
      }

      .no-result {
        display: flex;
        align-items: center;
        flex-flow: column;

        p {
          border-top: 2px solid #333;
          padding-top: 20px;
        }
        .warning-change-error-icon {
          font-size: 80px;
        }
      }

      .model-selection-box {
        display: flex;
        flex-flow: column;
        gap: 20px;

        .trending-models {
          h3 {
            color: #6d6d6d;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
            margin-top: 40px;
          }
        }

        .performers {
          display: flex;
          gap: 56px;
          @media (max-width: 576px) {
            flex-flow: column;
            gap: 20px;
          }
          .performer {
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;

            .performer-img {
              img {
                width: 50px;
                height: 50px;
                border-radius: 100%;
              }
            }
            .performer-info {
              display: flex;
              flex-flow: column;

              .name {
                color: #000;
                font-size: 16px;
                font-weight: 700;
              }

              .pname {
                color: #6b6b6b;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .performer-input-title {
          font-size: 18px;
          font-weight: 800;
          text-transform: capitalize;
          margin-bottom: -25px;
          margin-top: 20px;
          @media (max-width: 992px) {
            display: none;
          }
        }

        .performer-input-box {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          flex-flow: column;
          @media (max-width: 992px) {
            align-items: flex-start;
          }
          .input-field {
            width: calc(100% - 2em);
            margin: 0;
            @media (max-width: 992px) {
              width: 100%;
            }
            input {
              margin: 0;
              backface-visibility: visible;
              padding-left: 2em;
            }
          }

          .input-selection {
            width: 100%;
            display: flex;
            flex-flow: row;
            gap: 10px;
            margin-top: 25px;
            @media (max-width: 992px) {
              margin-top: 0;
              gap: 5px;
              flex-flow: column;
            }

            .selection-box {
              width: 100%;
              position: relative;
              .destination {
                color: #6d6d6d;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                margin-left: 20px;
                @media (max-width: 992px) {
                  margin-left: 0;
                }
              }

              .performers {
                background: #fff;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                flex-flow: column;
                justify-content: flex-start;
                gap: 0;
                margin: -17px 0 50px 0;
                padding: 10px;
                width: 100%;
                position: absolute;
                z-index: 1;

                @media (max-height: 844px) {
                  position: inherit;
                }

                @media (max-width: 992px) {
                  margin-bottom: 0;
                  position: inherit;
                }
                .performer {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 10px 10px 20px;
                  &:not(:first-child) {
                    border-top: 1px solid #6d6d6d;
                  }
                  .performer-card {
                    display: flex;
                    gap: 10px;
                    cursor: pointer;
                  }
                }
                .select-performer-btn {
                  background: none;
                  border: none;
                  padding: 0;
                  font: inherit;
                  cursor: pointer;
                  outline: inherit;
                  color: #00bd00;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                }
              }
            }
          }

          .performer-action-btns {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            @media (max-width: 992px) {
              margin-top: 25px;
            }

            .test-btn {
              color: #00bd00;

              &:focus {
                background: transparent;
              }

              &:hover {
                background: transparent;
              }
            }
          }

          button {
            padding: 10px 56px;
            height: fit-content;

            @media (max-width: 992px) {
              padding: 10px 28px;
            }
          }
        }
      }
    }
    .modal-footer {
      padding-top: 20px;
      bottom: 0;
      right: 0;
      background-color: #fff;
      width: auto;
      margin: 0 18px;
      display: flex;
      flex-flow: row;
      justify-content: flex-end;

      .cancel-button {
        margin-right: 10px;

        &:hover {
          opacity: 0.75;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
    .hidden {
      visibility: hidden;
    }
  }
}
