.share-modal {
    @media (max-width: 768px) {
        padding: 15px;
    }
    .modal-close {
        position: absolute;
        right: 6px;
        z-index: 101;
        color: #000;
        top: 8px;
    }
    .row-bottom {
        margin-top: 25px;
    }
    .input-field {
        margin-top: 0;
        margin-left: 10px;
        display: inline-block;
    }
    textarea {
        margin-top: 25px;
        min-height: 200px;
    }
    .btn-copy-information {
        margin-top: 10px;
        font-size: 16px;
    }
    h1 {
        font-weight: bold;
        font-size: 1.8rem;
    }
    p {
        font-size: 1.2rem;
    }
    .important {
        color: #ffa143;
        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
    }
    a {
        color: #00bd00;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.offerShareModalStyled {
    width: 60%;

    @media (max-width: 992px) {
        height: 80vh;
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 95%;
    }

    .top-content-container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-flow: column;
            justify-content: center;
        }
    }
    .shareImage {
        max-width: 285px;
        margin-right: 6px;

        @media (max-width: 992px) {
            height: 100%;
        }
    }

    .select-field {
        margin-bottom: 0px;
    }

    .formatted-message {
        margin: 5px;
    }

    .btn-copy-information {
        @media (max-width: 768px) {
            width: 100%;
            .text {
                font-size: 16px;
            }
        }
    }
}
