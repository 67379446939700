$br1: 1335px;
$br2: 450px;

@mixin slider-h2 {
  font-size: 20px;
  font-weight: 800;
  line-height: 125%;
  margin-top: 0;
  margin-bottom: 20px;

  @media (max-width: $br1) {
    margin-bottom: 7px;
  }
}

@mixin slider-p {
  color: #7b7b7b;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 980px;
  margin: 120px 0 0;

  @media (max-width: $br1) {
    max-width: 417px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 80px 0 0;
  }

  @media (max-width: $br2) {
    width: 100%;
    max-width: none;
  }

  .slider-left {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .slider-title {
      width: 100%;
      color: #181761;
      font-size: 64px;
      font-weight: 800;
      line-height: 68px;

      @media (max-width: $br1) {
        font-size: 50px;
        line-height: 53px;
        br {
          &.br1 {
            display: none;
          }
        }
      }

      @media (max-width: $br2) {
        font-size: 12vw;
      }
    }

    .slider-title,
    .offer-box {
      margin-left: 40px;

      @media (max-width: $br1) {
        margin: 0;
      }
    }

    .offer-outer-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      position: relative;
      margin: 40px 0 0;

      @media (max-width: $br1) {
        display: none;
      }
    }

    .offers-list {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
    }

    .offer-box {
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 0 20px;
      transition: 0.4s;

      .inner {
        width: 100%;
        transition: 0.4s;
        cursor: pointer;
      }

      h2 {
        @include slider-h2;
      }

      p {
        width: 400px;
        @include slider-p;
      }

      &.active {
        .inner {
          border-radius: 15px;
          background: #fff;
          box-shadow: 4px 7px 14px 0px rgba(123, 123, 123, 0.25);
          padding: 20px;
          padding-right: 0;
          margin: 0 0 5px;
        }
      }
    }

    .indicator {
      width: 2px;
      height: 100%;
      border-radius: 10px;
      background: #555;
      position: absolute;
      left: 0;
      top: 0;

      .active-indicator {
        width: 100%;
        display: block;
        border-radius: 10px;
        background: #00bd00;
        position: absolute;
        transition: 0.2s;
      }
    }
  }

  .slider-right {
    width: 417px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: $br1) {
      margin: 35px 0 0;
    }

    @media (max-width: $br2) {
      width: 100%;
    }

    .vertical-slides {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: hidden;

      @media (max-width: $br1) {
        overflow-x: hidden;
      }

      .vertical-slide-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(100%);
        opacity: 0;

        &:first-child {
          position: relative;
        }

        &.slide-center,
        &.slide-out {
          transition: 0.5s;
        }

        &.slide-center {
          transform: translateY(0);
          opacity: 1;
        }

        &.slide-out {
          transform: translateY(-100%);
        }

        @media (max-width: $br1) {
          &.nextDirection {
            transform: translateX(100%);

            &.slide-center {
              transform: translateX(0);
            }

            &.slide-out {
              transform: translateX(-100%);
            }
          }

          &.previousDirection {
            transform: translateX(-100%);

            &.slide-center {
              transform: translateX(0);
            }

            &.slide-out {
              transform: translateX(100%);
            }
          }
        }

        .slide-mobile-content {
          width: 100%;
          display: none;

          h2 {
            @include slider-h2;
          }

          p {
            @include slider-p;
          }

          @media (max-width: $br1) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0 0 35px;
          }
        }

        .vertical-slide {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .slider-next-prev-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
    margin: 20px 0 0;

    .slide-button {
      width: 49%;
      height: 42px;
      border-radius: 55px;
      border: 1px solid #000000;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 15px;
      }
    }

    @media (max-width: $br1) {
      display: flex;
    }
  }
}
