@media screen and (min-width: 500px) and (min-height: 950px) {
  #application.create-account,
  #application.confirm-email {
    overflow: hidden;
    max-height: 100vh;
  }
}

#application.tell-us-about-you,
#application.create-account {
  #offline {
    background: #fff;

    .offline-header {
      position: fixed;
      @media (max-width: 992px) {
        position: relative;
      }
    }
  }
}

/** Layout **/
.outer-container-simplified {
  position: relative;
  width: 100%;
  .offline-container-simplified {
    max-width: 510px;
    margin: 0 auto;
    @media screen and (max-width: 550px) {
      padding: 15px 30px;
    }
  }
}
.simple-signup {
  &__container {
    .simplified-confirm-email {
      @media screen and (max-width: 550px) {
        margin-top: 70px;
      }
      text-align: center;
      h1 {
        margin: 20px 0;
      }
      p {
        font-size: 15px;
      }
      .simple-signup__confirmEmail {
        color: #00bd00;
      }
    }
    #simplified-thanks-registering {
      @media screen and (max-width: 550px) {
        margin-top: 70px;
      }
      text-align: center;
      h1 {
        margin: 20px 0;
      }
      p {
        font-size: 15px;
      }
      a {
        font-size: 15px;
        color: #00bd00;
        text-decoration: none;
      }
    }
    #simplified-create-account {
      h1 {
        text-align: center;
        margin: 80px 0 30px;
        @media screen and (max-width: 550px) {
          margin: 20px 0 30px;
        }
      }
    }
    #simplified-tellusaboutyou {
      overflow: initial;
      h1 {
        margin: 80px 0 30px;
        @media screen and (max-width: 550px) {
          margin: 20px 0 30px;
        }
      }

      .prefix {
        top: 2px;
        right: 5px;
      }

      .select-dropdown {
        border: 1px solid #dadada !important;
        border-radius: 0;
        box-sizing: border-box;
        color: #636363;
        padding: 0 50px 0 16px;
        width: 100%;
      }

      .dropdown-content {
        padding: 0;
      }

      .select-dropdown.dropdown-trigge {
        padding: 0;
      }
    }
    .select-wrapper + label {
      top: -25px;
      margin: 0;
    }
    .vpn-information {
      background-color: #f8f8f8;
      padding: 5px 30px;
      margin: 0 10px;
      @media screen and (max-width: 410px) {
        margin: 0;
        padding: 5px 15px;
      }
      border-radius: 5px;
      .title {
        color: #00bd00;
        font-size: 1.2rem;
        font-weight: bold;
        padding-bottom: 12px;
      }
      .description {
        display: flex;
        align-items: center;
        p {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          text-align: left;
        }
        button {
          &.btn {
            padding: 0.4em !important;
            line-height: 1rem;
          }
          svg {
            width: 20px;
            height: 20px;
            .cls-1 {
              fill: #fff;
            }
          }
        }
      }
    }
    a {
      text-decoration: underline;
      transition: color 0.15s linear;
      &:visited,
      &:hover {
        color: #333;
      }
    }
    h1 {
      font-size: 27px;
      font-weight: bold;
      margin: 70px 0 10px;
    }
    p {
      font-size: 16px;
      margin-block-start: 0;
    }
    .recaptcha {
      margin-top: 1.3em;
      > div > div {
        margin: 0 auto;
      }
    }
    .under-form {
      position: absolute;
      bottom: 0;
      transform: translate(0, 100%);
      display: block;
      width: 100%;
      left: 0;
      text-align: center;
      p {
        margin: 0 0 5px;
        padding: 0;
        font-weight: bold;
        a {
          text-decoration: none;
          color: #00bd00;
        }
      }
    }
    .simplify {
      .icon-validation {
        position: absolute;
        top: 9px;
        right: 8px;
      }
      .caret {
        top: 14px;
        right: 50px !important;
      }
    }
    .about-business,
    .about-you {
      margin: 40px 0 20px 0;
      font-weight: bold;
      color: #00bd00;
      font-size: 22px;
    }
    .custom-label {
      font-size: 1em;
      font-weight: bold;
      color: #333;
    }
    .custom-add {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .input-field {
        margin: 0;
      }

      .social1,
      .social2,
      .social3,
      .howYouHear,
      .messaging_platform_name {
        width: 20rem;
        margin: 0 22px 26px 0;
      }

      .custom-svg svg {
        margin: 0 0 22px 15px;
        cursor: pointer;
      }

      .simplified-input {
        width: 100%;
        margin-bottom: 26px;
      }
    }
    &__box {
      height: auto;
      .form-container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 15px;
      }
      .row {
        margin-left: 0;
        margin-right: 0;
      }
      .btn {
        text-transform: none;
        background-color: #dadada;
      }
      .simplified-input {
        text-align: left;
        margin-bottom: 15px;

        label {
          button,
          input[type='submit'],
          input[type='reset'] {
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
          }
        }

        #password-tooltip {
          max-width: 90vw;
        }

        .simplified-optional-label {
          margin-left: 0.25rem;
        }
        .icon-info {
          font-size: 15px;
        }
        .reveal-password {
          border: none;
          margin: 0;
          padding: 0;
          display: block;
          float: right;
          font-weight: normal;
          font-size: 13px;
          position: relative;
          background: #fff;
          &:active,
          &:hover,
          &:focus {
            background: #fff;
            border: none;
            margin: 0;
            padding: 0;
          }
          &:before,
          &:after {
            display: inline-block;
            margin-left: 5px;
          }
          i.icon-open,
          i.icon-close {
            font-size: 17px;
            display: inline-block;
            position: absolute;
            margin-left: -19px;
          }
          &.open {
            i.icon-open {
              display: none;
            }
            i.icon-close {
              display: inline-block;
            }
            &:before {
              position: absolute;
              right: 15px;
              content: 'Hide Password';
              width: 100px;
              top: -2px;
            }
          }
          &.close {
            i.icon-open {
              display: inline-block;
            }
            i.icon-close {
              display: none;
            }
            &:before {
              content: 'Show Password';
              position: absolute;
              right: 15px;
              width: 100px;
              top: -2px;
            }
          }
        }
        .icon-validation {
          position: absolute;
          margin-left: -32px;
          margin-top: 9px;
        }
        input {
          border: 1px solid #dadada !important;
          border-radius: 0;
          padding: 0 10px 0 17px;
          font-weight: normal;
          box-sizing: border-box;
          color: #636363;

          @media (max-width: 400px) {
            padding: 0 10px;
            font-size: 0.9rem !important;
          }

          &:focus {
            box-shadow: none;
          }
        }
        &:not(:focus-within) label {
          font-weight: 400;
        }
      }
      label,
      legend {
        font-size: em(14);
        margin-bottom: 4px;
        display: inline-block;
        font-weight: bold;
        span::after {
          font-weight: normal;
        }
      }
      input[type='text'],
      input[type='password'] {
        margin-bottom: 0;
      }
      .field-error {
        text-align: left;
        margin: 2px 0 -18px 4px;
        font-size: 11px;
        &.big {
          margin: 20px 0 2px;
          font-size: 1em;
        }
      }
      .select-error {
        margin-top: -20px;
      }
      .checkbox-field.simple-signup__checkbox {
        margin-top: 15px;
        label {
          color: #000;
          padding-left: 40px;
          font-weight: bold;
          line-height: 16px;
          span a {
            color: #00bd00;
            text-decoration: none;
          }
          &:before {
            top: 1px;
            left: 0;
            width: 10px;
            height: 22px;
          }
          &:after {
            border-width: 1px;
            border-color: #dadada;
            width: 25px;
            height: 25px;
            border-radius: 0;
            top: 3px;
            left: 0;
          }
        }
        &.error {
          label {
            color: #d83d20;
            &:after {
              border-color: #d83d20;
            }
          }
        }
      }

      select.native {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        &::-moz-scrollbar {
          display: none;
        }
        &::-o-scrollbar {
          display: none;
        }
        &::-google-ms-scrollbar {
          display: none;
        }
        &::-khtml-scrollbar {
          display: none;
        }
        option {
          padding: 15px 0;
        }
        + span {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #dadada;
          position: absolute;
          right: 15px;
          top: 22px;
        }
      }

      .select-field {
        margin: 22px 0 26px;
        label {
          left: 0;
          top: 4px;
        }
        input.select-dropdown,
        select.native {
          border: 1px solid #dadada !important;
          border-color: #dadada !important;
          border-radius: 0;
          padding: 0 50px 0 16px;
          box-sizing: border-box;
          width: 100%;
          color: #636363;
          &:focus {
            cursor: auto !important;
          }
        }
        .multiple-select-dropdown {
          li {
            padding-bottom: 6px;
            span {
              line-height: 5px;
            }
          }
        }
        .dropdown-content {
          li {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            &:hover {
              label:before {
                border-color: #fff;
              }
              &.active label:before {
                border-top-color: transparent;
                border-left-color: transparent;
              }
            }
          }
        }
        .caret {
          right: 17px;
          color: #dadada;
          transform: scaleX(1.4);
        }
        &.error {
          label {
            color: #070707;
          }
          .caret {
            color: #d83d20;
          }
        }
      }
      .simplified-radios-wrapper {
        margin-bottom: 17px;
      }
      .radio-field {
        display: inline-block;
        margin-right: 25px;
        &.error label:before {
          border-color: #d83d20;
        }
        label {
          font-weight: normal;
          padding-left: 30px;
          margin: 0;
          &:before,
          &:after {
            border-radius: 0;
            transition: background 0.28s ease;
          }
          &:before {
            border-width: 1px;
            border-color: #dadada;
            width: 15px;
            height: 15px;
            border-radius: 0;
          }
        }
        [type='radio']:checked + label:before {
          top: 1px;
          left: 0;
          width: 6px;
          height: 12px;
          border-top: 2px solid transparent !important;
          border-left: 2px solid transparent !important;
          border-right: 2px solid #fff !important;
          border-bottom: 2px solid #fff !important;
          transform: rotateZ(37deg);
          transform-origin: 100% 100%;
          z-index: 1;
        }
      }
      /** Adaptations specifiques **/
      #create-account-send-form {
        padding-left: 0;
        padding-right: 0;

        .link-confirm-account button {
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
      label[for='simplified_promo-code'] {
        font-weight: normal;
        + input {
          background: rgba(222, 222, 222, 0.1);
        }
      }
      #simplified-thanks-registering {
        .blog-link {
          margin-top: 20px;
          a {
            display: inline;
          }
        }
      }
    } // __box
    &.path-access-confirm-email,
    &.path-access-connection,
    &.path-access-registered {
      @media screen and (min-height: 598px) and (min-width: 550px) {
        margin-top: calc(50vh - 300px);
      }
    }
    &.path-access-registered {
      text-align: center;
      a {
        margin: 20px 0 10px;
        display: block;
      }
    }
  } // __container

  &__form {
    display: block;
  }

  &__google-signup-container {
    margin-bottom: -15px;
  }

  &__google-top-border {
    margin: 20px 0;
    line-height: 0.5;
    border-bottom: 1px solid #dadada;
  }

  &__google-link {
    background-color: #dd4b39;
    width: 100%;
    height: 59px !important;
    line-height: 2em !important;
    margin-top: 17px;

    &:hover {
      background-color: #d83d20;
    }

    .Fill-1 {
      height: 45px;
      width: 45px;
      float: left;
    }
  }

  &__google-bot-border {
    line-height: 0.5;
    text-align: center;
    margin: 25px 0 20px 0;
    border-bottom: 1px solid #dadada;
    & span {
      top: 3px;
      vertical-align: middle;
      width: 30px;
      background-color: white;

      font-weight: bold;
      font-size: 1em;
      display: inline-block;
      position: relative;
    }
  }

  &__im-in {
    width: 100%;
    height: 59px !important;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__disclaimer {
    font-size: 10px;
  }
}

#howDidYouHearAboutUsSelect {
  position: relative;

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 800;
    display: none;
  }
}
