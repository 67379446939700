#profile-billing {
  .paymentTerms {
    margin-bottom: 0.5rem;

    .paymentTermsDiv {
      display: flex;
    }
  }

  h2 {
    font-size: em(20);
  }

  &.locked,
  .locked {
    &.transparent {
      > div:not(.locked-content) {
        opacity: 0.6;
      }
    }
    &.no-pointer {
      div:not(.locked-content) * {
        pointer-events: none;
      }
    }
    .locked-content {
      text-align: center;
      padding-bottom: 60px;
      h2 {
        font-size: 1.7em;
      }
    }
  }
  .customSelects {
    display: block;
    .labelBox label {
      font-weight: normal;
    }
    .inputRow button {
      margin-top: 0;
      margin-bottom: 15px;
    }
    ul {
      margin: 0;
    }
  }
  .wireIntemediaryBank_section {
    margin-top: 15px;
  }
}
.billing-change-error-modal-styled {
  text-align: center;
  font-family: 'Proxima', arial, sans-serif;
  h2 {
    font-size: 10em;
    text-transform: Uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    margin: 0 0 33px;
  }
  hr {
    border-color: #c1c1c1;
  }
  p {
    font-size: 1.4em;
    margin: 33px 0 42px;
    line-height: 1.4;
  }
  .btn {
    padding: 14px 22px 12px;
    font-size: 25px;
    min-width: 173px;
    border-radius: 5px;
    line-height: 1.3em;
    height: auto;
  }
  #warning-change-error-icon {
    font-size: 100px;
  }
  width: 40%;
  @media screen and (max-width: 810px) {
    width: 90%;
    .btn {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 410px) {
    width: 90%;
    .btn {
      font-size: 16px;
    }
  }
}
.billing-change-modal-styled,
.billing-change-confirm-modal-styled {
  text-align: center;
  font-family: 'Proxima', arial, sans-serif;
  width: 40%;
  #warn-message,
  #confirm-message {
    font-size: 32px;
    text-transform: Uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    margin: 0 0 25px;
  }
  hr {
    border-color: #c1c1c1;
  }
  p {
    font-size: 20px;
    margin: 33px 0 42px;
    line-height: 1.4;
  }
  .btn {
    padding: 14px 22px 12px;
    font-size: 17px;
    min-width: 173px;
    border-radius: 5px;
    line-height: 1.3em;
    height: auto;
  }
  @media screen and (max-width: 810px) {
    width: 90%;
    .btn {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 410px) {
    width: 90%;
    .btn {
      font-size: 16px;
    }
    #confirm-message {
      font-size: 25px;
    }
  }
}

#firstChoicePay-video-icon {
  display: inline-block;
  position: relative;
  height: 38px;
  width: 38px;
  vertical-align: middle;
  text-align: center;

  & > span {
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
    margin-top: 8px;
  }
}
