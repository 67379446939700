#message {
    margin: 7px 20px;

    .message-container {
        background-color: #555;
        z-index: 10;
        position: relative;
        color: #fff;

        .btn {
            margin: 0.5rem 0;
        }

        .message-body {
            float: left;
            width: calc(100% - 32px);

            h4 {
                font-size: 16px;
                line-height: 1.3;
            }

            p {
                font-size: 13px;
                margin: 0;
                color: #d6d6d6;
            }
        }

        &.closable {
            padding-right: 40px;
        }
    }

    .close-message {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        svg {
            &:hover {
                circle {
                    fill: $cr-green;
                }
            }
        }
    }

    i {
        color: #fff;

        &.title-icon {
            margin-right: 7px;
            position: relative;
            top: 6px;
        }

        &.yellow {
            color: #ffe200;
        }
    }
}
