.promo-tool {
    max-width: 600px !important;
    max-height: none !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    right: unset;
    .modal-inner {
        padding: 24px;

        @media (max-height: 900px) {
            height: 100%;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
        }

        @media (max-width: 992px) {
            padding: 24px 10px;
        }
        .promo-tool-modal {
            @media (max-height: 900px) {
                flex: 1;
                overflow: auto;
                padding: 0 10px;
            }
            h2 {
                margin-bottom: 5px;
            }
            .header-box {
                display: flex;

                .header-title {
                    margin-top: 0;
                }
                .icon-box {
                    display: flex;
                    justify-content: center;
                    margin-right: 20px;
                    margin-top: 5px;

                    img {
                        height: 50px;
                    }
                }

                .header {
                    text-align: left;

                    p {
                        margin-top: 5px;
                    }

                    h2 {
                        margin-bottom: 5px;
                    }
                }
            }

            .video-box {
                .video-title {
                    text-align: left;
                    margin-bottom: 10px;
                }
                .video-js {
                    .vjs-big-play-button {
                        font-size: 6em;
                        border: none;
                        background-color: transparent;

                        &:focus {
                            background-color: transparent !important;
                        }

                        .vjs-icon-placeholder {
                            transition: all 0.3s;
                            &:hover {
                                opacity: 0.7;
                            }
                            &:before {
                                content: ' ';
                                background-image: url('../../../../../components/PromoTools/promoToolVideo/button/play.svg');
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                    }
                }
            }

            .advantages {
                text-align: left;
                margin-bottom: 20px;

                li {
                    svg {
                        margin-right: 5px;
                    }
                    display: flex;
                    align-items: center;
                    margin: 10px 0;
                    font-weight: bold;
                }
            }

            .description {
                text-align: left;
            }
            a {
                color: initial;
                &:hover {
                    opacity: 0.8;
                }
            }
            .offers-box {
                text-align: left;

                .offer {
                    display: flex;
                    margin: 25px 0;

                    .offer-name {
                        font-weight: bold;
                    }

                    .payout {
                        margin-left: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span {
                            background-color: #00bd0060;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;
                            width: 51px;
                            height: 32px;
                            font-size: 12px;
                        }
                    }

                    .offer-details {
                        margin: 0 10px;
                        display: flex;
                        flex-flow: column;
                        font-size: 12px;

                        .separation {
                            margin: 0 2px;
                        }

                        .offer-info {
                            display: inline-block;
                            margin: 2px 0 0 0;
                        }

                        .country-list {
                            justify-content: flex-start;

                            li {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
            }

            .warning {
                display: flex;
                text-align: left;

                .icon-warning {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 15px;
                }
            }
        }
    }

    .control-box {
        border-top: 1px solid gray;
        margin: 0 -24px;
        display: flex;
        justify-content: flex-end;
        padding-top: 24px;
        padding-right: 20px;
        color: #fff;

        @media (max-width: 992px) {
            margin: 0 -10px;
        }

        @media (max-width: 481px) {
            justify-content: center;
            padding-right: 0;
        }

        .btn-flat {
            margin-right: 20px;

            &:hover {
                opacity: 0.75;
                background-color: transparent;
                box-shadow: none;
            }
        }

        a {
            color: inherit;
        }
    }
}

.to-manager,
.to-tool-page {
    .control-box {
        @media (max-width: 576px) {
            flex-flow: column-reverse;
            padding-left: 10px;
            .btn-large {
                margin: 10px 20px;
                font-size: 12px;
            }
        }
    }
}

.to-manager {
    .modal-inner {
        @media (max-height: 900px) {
            max-height: 90vh;
        }
    }
}
