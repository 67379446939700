.card-panel {
  transition: box-shadow 0.25s;
  padding: $card-padding;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border-radius: 2px;
  @extend .z-depth-1;
  background-color: $card-bg-color;
}

.card {
  position: relative;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  background-color: $card-bg-color;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  @extend .z-depth-1-half;

  &:hover {
    @extend .z-depth-2;
  }

  .card-title {
    font-size: 24px;
    font-weight: 300;
    &.activator {
      cursor: pointer;
    }
  }

  // Card Sizes
  &.small,
  &.medium,
  &.large {
    position: relative;

    .card-image {
      max-height: 60%;
      overflow: hidden;
    }
    .card-content {
      max-height: 40%;
      overflow: hidden;
    }
    .card-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.small {
    height: 300px;
  }

  &.medium {
    height: 400px;
  }

  &.large {
    height: 500px;
  }

  .card-image {
    position: relative;

    // Image background for content
    img {
      display: block;
      border-radius: 2px 2px 0 0;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .card-title {
      color: $card-bg-color;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $card-padding;
    }
  }

  .card-content {
    padding: $card-padding;
    border-radius: 0 0 2px 2px;

    p {
      margin: 0;
      color: inherit;
    }
    .card-title {
      line-height: 48px;
    }
  }

  .card-action {
    position: relative;
    background-color: inherit;
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: $card-padding;
    z-index: 2;

    a:not(.btn):not(.btn-large):not(.btn-floating) {
      color: $card-link-color;
      margin-right: $card-padding;
      transition: color 0.3s ease;
      text-transform: uppercase;

      &:hover {
        color: $card-link-color-light;
      }
    }

    & + .card-reveal {
      z-index: 1;
      padding-bottom: 64px;
    }
  }

  .card-reveal {
    padding: $card-padding;
    position: absolute;
    background-color: $card-bg-color;
    width: 100%;
    overflow-y: auto;
    top: 100%;
    height: 100%;
    z-index: 3;
    display: none;

    .card-title {
      cursor: pointer;
      display: block;
    }
  }
}
