#offer-tracking-link-display {
    @include flexbox(column, wrap, flex-start);

    .copy-value-widget {
        margin-top: 1rem;
        margin-bottom: 12px;
    }

    #extra-earnings {
        @include flexbox(row, wrap, space-between);

        margin-bottom: 12px;

        .label {
            @include flexbox(row, wrap, flex-start);

            align-self: center;
            margin-right: 1rem;
        }
        .switches {
            @include flexbox(row, wrap, space-between);

            .switch {
                @media (max-width: 992px) {
                    margin-right: 5px;
                }
            }

            .lever {
                @media (max-width: 992px) {
                    margin: 0 8px;
                }
            }
        }
    }
    @include responsive('xlarge') {
        padding: 1rem;
    }

    .btn-open-modal {
        cursor: pointer;
        text-transform: uppercase;
        color: #00bd00;
        margin-left: 10px;
        z-index: 400;
        font-weight: bold;
        margin-top: 5px;

        @media (max-width: 992px) {
            margin: 0;
            margin-top: 5px;
        }
    }

    .landing-notif {
        display: flex;
        justify-content: center;
        align-items: center;
        background-origin: padding-box, border-box;
        background-repeat: no-repeat; /* this is important */
        border: 4px solid transparent;
        border-radius: 7px;
        border-width: 8px;
        position: relative;
        color: #fff;
        padding: 10px;
        margin-bottom: 24px;

        @media (max-width: 992px) {
            flex-flow: column;
            align-items: flex-start;
        }

        &::after {
            content: '';
            position: absolute;
            top: -4px;
            bottom: -4px;
            left: -4px;
            right: -4px;
            border-radius: 4px;
        }

        p {
            margin: 0;
        }

        svg {
            margin-right: 10px;
            width: 20px;
        }

        .gold {
            color: #e9df74;
            text-transform: uppercase;
            font-weight: 900;
        }
    }
}
