.iframe-container-code {
  position: relative;
  .preview-container {
    min-height: 344px;

    .empty {
      width: 100%;
      font-family: monospace;
      padding: 1rem;
    }
  }
  @media (max-width: $screen-md) {
    text-align: center;
    .preview-container {
      position: relative;
      overflow: hidden;
      padding-top: 84%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .copyBtn {
    display: block;
  }

  > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;

    @media (max-width: $screen-md) {
      display: block;
      flex-direction: inherit;
      width: 100%;
    }

    textarea {
      border: 1px solid black !important;
    }

    .title {
      height: 61px;
      line-height: 50px;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: $screen-md) {
        flex: 0;
        display: block;
        flex-direction: inherit;
        width: 100%;
      }
      @media (min-width: $screen-md + 1) {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .filterInput {
      z-index: 500;
    }
    .filterInput.fields {
      z-index: auto;

      .field {
        margin: 0 0 20px;
        .labelBox label {
          font-weight: normal;
        }
      }
    }

    iframe {
      border: 0;
    }

    .code-preview {
      border: 1px solid black;
      padding: 1em;
      .input-field {
        margin-top: 2em;
        + div {
          text-align: center;
        }
      }

      .row {
        @media (max-width: 992px) {
          margin: 0;

          .fields {
            padding: 0;
          }
        }
      }
    }
  }
}

.code-input {
  position: relative;
  .loading-bar {
    width: calc(100% - 2px);
    margin-left: 1px;
    min-height: 350px;
  }
  .title {
    padding: 8px 18px 7px;
    font-weight: 400;
    font-size: em(24);
    color: #fff;
    background-color: #333;
    margin-top: 10px;
    height: 61px;
    line-height: 50px;

    & > i {
      display: inline-block;
      font-size: 1.2em;
      color: $cr-green;
      margin-right: 10px;
      position: relative;
      bottom: -5px;
    }
  }

  textarea,
  .loadingCodeInput {
    margin-top: 0;
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    border-bottom: 0 solid transparent;
    min-height: 200px;
    padding: 10px 15px;
    box-sizing: border-box;
    resize: none;
    @extend .textarea-css;
  }
}

.code-input {
  textarea,
  .loadingCodeInput {
    height: 100%;
    &[readonly] {
      font-family: monospace;
      padding: 1rem;
    }
  }
}

.code-input-wrapper,
.iframe-container-code {
  .copyBtn i {
    position: absolute;
    margin-left: -20px;
  }

  & + .code-input-wrapper {
    margin-top: 55px;
  }
}
