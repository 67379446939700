.search-component {
    .tags-container {
        border-top: 1px solid $cr-pale-grey;
        padding: 1.4rem 0;

        .tags-container {
            margin: 0;
        }
    }

    .cr-featured-container {
        margin-top: 1.8rem;

        .cr-featured-only {
            float: left;
            margin-bottom: 1.5rem;
        }

        .cr-featured-only-flag {
            float: left;
            margin: 1px 7px;
        }
    }

    .filter-btn {
        margin-bottom: 1rem;
    }

    .filters-container {
        display: block;
        position: relative;
        height: 0px;
        overflow: hidden;
        -webkit-transition: all 200ms $inEaseing;
        transition: all 200ms $inEaseing;

        &.open {
            height: auto;
            overflow: visible;
        }
    }

    .search-input {
        .input-field {
            label {
                color: $cr-greyText;
            }
            i {
                color: $cr-green;
                padding-top: 15px;
            }
            input[type='text'] {
                border-bottom: 2px solid $cr-green;
            }
        }

        .btn-icon {
            display: inline-block;
            position: relative;
            margin-top: 35px;
            cursor: pointer;
            color: $cr-grey;

            &:hover,
            &.active {
                color: $cr-green;
            }
        }
    }

    hr {
        border: 0px;
        border-top: 1px solid #c7c7c7;
    }

    .search-tag {
        display: inline-block;
        position: relative;
        float: left;
        font-size: 1em;
        padding: 6px 8px 6px 5px;
        border-radius: 2px;
        margin: 5px 10px 5px 0;
        cursor: pointer;

        &.clear-filters {
            float: right;
            color: $cr-greyText;
            text-transform: uppercase;
            line-height: 1.8em;
            padding: 4px 0 3px 6px;

            i {
                font-size: 1.8em;
            }

            &:hover {
                color: #000;
            }
        }

        .tag-text,
        i {
            display: inline-block;
            position: relative;
            float: left;
        }

        .tag-text {
            margin-right: 4px;
        }

        i {
            font-size: 1.5em;
        }

        &.searchstring-tag {
            background-color: $cr-green;
            color: #fff;

            &:hover {
                i {
                    color: darken($cr-green, 10%);
                }
            }
        }

        &.filters-tag {
            background-color: $cr-grey;
            color: #fff;

            &:hover {
                i {
                    color: $cr-dark-grey;
                }
            }
        }

        &.exclude {
            background-color: $cr-red-error;
        }
    }

    .filters-selection-container {
        height: 44px;
        overflow: visible;
        position: relative;
        top: 20px;

        .filters-selection {
            padding: 0 15px;
            position: absolute;
            background-color: $pale-grey;
            z-index: 1;
            width: 100%;

            .checkbox-field {
                margin: 15px 0;
            }
        }
    }
}

@include responsive('normal') {
    .search-component {
        .cr-featured-container {
            margin-top: 0;
        }
    }
}

@include responsive('tablet') {
    .search-component {
        .cr-featured-container {
            margin-top: 0;
        }
    }
}

@include responsive('phone to tablet') {
    .search-component {
        .cr-featured-container {
            margin-top: 0;
        }

        #search-string {
            margin: 0;

            .add-filters-container {
                float: left;
                margin-top: 0;
            }

            .input-field {
                i {
                    padding-top: 7px;
                }

                label.active {
                    opacity: 0;
                    transform: translateY(0%);
                }
            }

            .btn-icon {
                margin-top: 25px;
                color: #969696;
            }
        }

        .filters-container {
            .select-field {
                margin-top: 30px;
            }
        }

        .searchstring-tags,
        .filters-tags {
            margin-top: 15px;
        }
    }
}

@include responsive('phone') {
    .search-component {
        .cr-featured-container {
            margin-top: 0;
        }
    }
}
