.promoTools {
    &__header {
        & .title {
            font-size: 33px;
            font-weight: bold;
            margin: 1em 0 0 0;
            @media (max-width: 481px) {
                font-size: 22px;
                margin: 30px 0 0 10px;
            }
        }
        & .subtitle {
            font-size: 18px;
            font-weight: bold;
            margin-block-start: 0;
            margin-block-end: 0;
            @media (max-width: 481px) {
                font-size: 14px;
                margin: 0 0 20px 10px;
            }
        }
    }

    .promoTools__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        column-gap: 10px;
        row-gap: 40px;
        justify-content: space-around;
        margin: 30px auto;

        @media (max-width: 481px) {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
        }

        .promoTool {
            border-radius: 10px;
            width: 250px;
            height: 260px;
            background: white;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;

            @media (max-width: 481px) {
                width: 100%;
                margin: 10px;
            }

            &:hover {
                transition: all 0.3s;
                box-shadow: 0px 3px 6px #00000029;

                & .promoTool__title {
                    transition: all 0.3s;
                    color: $cr-green;
                }

                & .promoTool__btn {
                    transition: all 0.3s;
                    border: 1px solid $cr-green;
                    background: $cr-green;
                    color: white;
                }
            }

            &__image {
                margin-top: 20px;
                width: 100%;

                img {
                    transition: all 0.3s;
                    margin: 0 auto;
                    height: 50px;
                    width: 50px;
                }
            }

            &__title {
                font-size: 20px;
                font-weight: bold;
                margin: 0;
            }

            &__description {
                padding: 0 10px;
                height: 55px;
                overflow: hidden;
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: 12px;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            &__btn {
                height: 35px;
                width: 150px;
                background: #fff;
                border-radius: 3px;
                border: 1px solid #333;
                margin-bottom: 15px;
            }
        }
    }
}
