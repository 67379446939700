$br1: 767px;

.customSelects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  button,
  input[type='submit'],
  input[type='reset'] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  h2 {
    margin: 40px 0 20px 0;
    font-weight: bold;
    color: #00bd00;
    font-size: 22px;
  }

  select,
  textarea {
    border-color: #dadada !important;
  }

  .fields {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .simplified-input {
      flex-grow: 1;
      margin: 0 !important;
    }

    .custom-add {
      .simplified-input {
        margin: 0 0 15px !important;
      }
    }

    .custom-svg {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin: 5px 0 0 10px !important;
      }
    }

    .field {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0;

      .maxSelected {
        font-size: 12px;
        font-style: italic;
        white-space: nowrap;
        margin: 0 0 0 15px;

        &.new-flow-upper-max-selected-mobile {
          margin: 15px 0 0 15px;
          @media (max-width: 992px) {
            display: none;
          }
        }

        &.new-flow-max-selected-mobile {
          display: none;
          @media (max-width: 992px) {
            display: flex;
            justify-content: flex-end;
            color: #1e1e1e;
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: fit-content;
            float: right;
          }
        }

        &.maxCountReached {
          color: #00bd00;
          animation: scaleIt 0.4s;
          font-weight: 700;
        }
        @keyframes scaleIt {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.4);
          }
          100% {
            transform: scale(1);
          }
        }
      }

      &:first-child {
        margin: 0;
      }

      &.affiliateType {
        margin: 20px 0;
      }

      .labelBox {
        width: 100%;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 992px) {
          align-items: flex-end;
        }

        label {
          font-size: 1em;
          font-weight: bold;
          color: #333333;
        }
      }

      .inputRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .inputBox {
        width: 100%;
        position: relative;

        &.small {
          width: 34%;
          min-width: 140px;
          margin: 0 17px 0 0;
        }

        select,
        input {
          width: 100%;
          display: block;
          appearance: none;
          position: relative;
          background: none;
          border: 1px solid #656565;
          border-radius: 5px;
          outline: none;
          text-overflow: ellipsis;
          cursor: pointer;
          height: 3rem;
          padding: 0 10px 0 15px;

          &#rowsPerPageBottom,
          &#rowsPerPageTop,
          &#offerListingPagination {
            padding: 0 10px 0 15px;
          }
          &:focus {
            box-shadow: unset !important;
            border-color: #656565 !important;
          }

          @media (max-width: $br1) {
            padding-right: 55px;
          }

          &[multiple] {
            height: 175px;
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }

        button {
          width: 100%;
          appearance: none;
          position: relative;
        }

        .dropdown {
          border-radius: 6px;
          border: 1px solid #000;
          position: absolute;
          z-index: 10;
          width: 100%;
          display: none;
          max-height: 325px;
          min-height: 76px;
          background-color: #fff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          overflow-y: scroll;
          overflow-x: hidden;

          li {
            cursor: pointer;
            font-size: 14px;
            padding: 14px 16px;

            &.search-bar {
              cursor: default;
              margin: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;
              height: 40px;
              border-radius: 8px;
              border: 1px solid #e6e7eb;

              svg {
                width: 13px;
                position: absolute;
                left: 12px;
                top: 0;
                bottom: 0;
                margin: auto;
              }

              &:hover {
                background-color: unset;
                color: inherit;
              }
              input {
                border: 0;
                cursor: text;
                margin-bottom: 0;
                padding-left: 15px;
              }
            }

            &.disable {
              cursor: default;
              color: #989898;
              &:hover {
                background-color: unset;
                color: #989898;
              }

              &:before {
                border-color: #989898 !important;
                color: #989898;
              }
              &:hover {
                &:before {
                  border-color: #989898 !important;
                  color: #989898;
                }
              }
            }

            &.multi-select {
              position: relative;
              padding-left: 35px;

              &:hover {
                &:before {
                  border-color: #fff;
                  color: #fff;
                }
              }
              &:before {
                border: 2px solid #5a5a5a;
                border-radius: 1px;
                content: '';
                height: 18px;
                left: 8px;
                position: absolute;
                width: 18px;
                z-index: 0;
                top: 50%;
                transform: translateY(-50%);
              }

              &.selected {
                background-color: #eee;
                &:hover {
                  background-color: #00bd00;
                  &:before {
                    border-color: #fff;
                    color: #fff;
                  }
                }
                &:before {
                  backface-visibility: hidden;
                  border-color: #00bd00;
                  border-top-color: rgb(0, 189, 0);
                  border-left-color: rgb(0, 189, 0);
                  border-left: 3px;
                  border-top: none;
                  height: 22px;
                  top: 25%;
                  left: 4px;
                  transform: rotate(40deg);
                  -webkit-transform-origin: 100% 100%;
                  transform-origin: 100% 100%;
                  width: 12px;
                }
              }
            }

            &:hover {
              background-color: #00bd00;
              color: #fff;
            }
          }
        }

        .open {
          display: block;
        }

        .svgsRight {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 0;
          display: flex;
          align-items: center;

          &.disabled {
            cursor: default;
            svg {
              fill: rgba(0, 0, 0, 0.26);
            }
          }

          svg {
            margin: 0 5px 0 0;

            &.approvedCheck {
              @media (max-width: $br1) {
                margin: 0 3px 0 0;
              }
            }
          }
        }

        .inputBoxBtn {
          &.focused {
            border-radius: 6px;
            border: 1px solid #000;
          }

          .arrowUp {
            rotate: 180deg;
          }

          &:not(.underlined) {
            input {
              &.disabled {
                border: 1px dotted rgba(0, 0, 0, 0.26);
                color: rgba(0, 0, 0, 0.26);
                cursor: default;
              }
            }
          }
        }

        .inputBoxBtn.underlined input {
          padding: 0;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #656565;

          &.disabled {
            border-bottom: 1px dotted rgba(0, 0, 0, 0.26);
            color: rgba(0, 0, 0, 0.26);
            cursor: default;
          }
        }
      }
    }
  }
}
