.offerRequestModalTextDiv .offerRequestModalText {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.offerRequestModalTextDiv {
    width: 60%;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
    font-weight: 200;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 17px;
    }
}
