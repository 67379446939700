$margin1: 30px;
$loadingOpacity: 0.3;

$br1: 1919px;
$br2: 1679px;
$br3: 1400px;
$br4: 767px;
$br5: 550px;

$mWidthbr1: 992px;

.creativesBannersLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .loadingDiv {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 95%;
    max-width: 200px;
    border: none;
  }
}

.creativesBannersNew {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  position: relative;

  .loadingBannerCover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: default;
    z-index: 100000;
  }

  .innerBannerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bannerTitleTabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 0;

    @media (max-width: $br4) {
      margin: 0;
    }

    .bannerTab {
      width: 50%;
      height: 50px;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 5px solid;
      border-color: #dadada;
      color: #333333;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap;

      &.totalTabs3 {
        width: calc(100% / 3);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87e764;
        }
      }

      @media (max-width: $br4) {
        height: 40px;
        font-size: 12px;
      }

      svg {
        width: 23px;
        margin: 0 10px 0 0;
        fill: #333333;

        @media (max-width: $br4) {
          display: none;
        }
      }

      &.disabled {
        cursor: default;
        border-color: #ededed;
        color: #dadada;

        svg {
          fill: #dadada;
        }
      }

      &.active {
        border-color: #00bd00;
        cursor: default;
      }
    }
  }

  .bannerFilters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 100;
    margin: $margin1 0 0;

    &.TRENDING_BANNERS {
      margin-bottom: $margin1;
      display: flex;
      flex-flow: column;
      .filtersGroup {
        width: 100%;
        flex-basis: 100%;
        display: flex;

        @media (max-width: $br4) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .trendingBannerFilters {
        .selectableTab {
          .dropdownOuterBox {
            &.days {
              @media (max-width: $br4) {
                left: -150%;
                right: auto;
              }
            }
          }
        }
      }

      .bannerDescription {
        width: 100%;
        margin-top: 20px;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        border-radius: 10px;
        background: #dff2df;
        gap: 10px;

        svg {
          @media (max-width: $br3) {
            width: 5%;
          }

          @media (max-width: $br4) {
            width: 45%;
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .standardBannerFilters {
        margin-left: auto;
        @media (max-width: $br4) {
          margin-left: 0;
        }
        .selectableTab {
          .dropdownOuterBox {
            left: auto;
            right: 0;
            &.format {
              @media (max-width: $br4) {
                left: 0;
                right: auto;
              }
              @media (max-width: $br5) {
                left: -60%;
                right: auto;
              }
            }
            &.size {
              @media (max-width: $br4) {
                left: 0;
                right: auto;
              }
            }
          }
        }
      }
    }

    &.ALL_CREATIVES {
      .selectableTab {
        @media (max-width: $br5) {
          &:nth-child(2) {
            .dropdownOuterBox {
              left: -55%;
            }
          }
          &:nth-child(n + 3) {
            .dropdownOuterBox {
              right: 0% !important;
              left: auto !important;
            }
          }
        }
      }
    }

    .standardBannerFilters {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .trendingBannerFilters {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: $br4) {
        flex-direction: column;
        align-items: flex-start;
      }

      .trendingFilterRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: $br4) {
          margin: 7px 0 30px;
        }
      }

      .trendingText {
        margin: 0 15px 0 0;
        font-weight: 700;
        cursor: default;

        @media (max-width: $br4) {
          font-size: 14px;
        }
      }
    }

    .selectableTab {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      margin: 0 15px 0 0;
      border: 0;

      &:last-child {
        margin: 0;
      }
    }

    .trigger {
      height: 30px;
      background: #151618;
      color: #ffffff;
      font-size: 14px;
      padding: 0 14px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      position: relative;
      z-index: 10;
      text-transform: capitalize;

      .text {
      }

      svg.arrowDown {
        fill: #ffffff;
        width: 8px;
        margin: 0 0 0 9px;
      }
    }

    .activeCircle {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #03bc04;
      border: 1px solid #ffffff;
      position: absolute;
      right: -5px;
      top: -5px;
      transform: scale(0);
      opacity: 0;
      transition: 0.2s;
      z-index: 200;
      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }

    .dropdownOuterBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 270px;
      padding: 15px 0 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 40px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
      user-select: none;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &.country {
        @media (max-width: $br4) {
          left: -100% !important;
        }
      }

      &.active {
        height: auto;
        opacity: 1;
      }

      .dropdownTitle {
        width: calc(100% - 30px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;
        text-transform: capitalize;

        .text {
          font-size: 20px;
          font-weight: 500;
          cursor: default;
        }

        .clear {
          font-size: 14px;
          color: #03bc04;
          margin: 0 0 0 15px;
          cursor: pointer;
        }
      }

      .scrollable {
        width: 100%;
        max-height: 190px;
        padding: 15px 10px;
        padding-top: 0;
        margin: 15px 0 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          background: #898989;
        }

        .selectableTab {
          width: 100%;
          cursor: pointer;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          border-radius: 10px;
          margin: 5px 0 0;

          &:first-child {
            margin: 0;
          }

          &:hover {
            @media (min-width: $mWidthbr1) {
              background: #dbf5db;
            }
          }

          &.active {
            background: #dbf5db;
            color: #35c936;
            svg.check {
              opacity: 1;
            }
          }

          svg.check {
            width: 18px;
            height: 18px;
            fill: #00bd00;
            opacity: 0;
            transition: 0.2s;
          }
        }
      }
    }
  }
}

.bannerTitle {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;
  margin: $margin1 0;

  &.active {
    display: flex;
  }

  @media (max-width: $br4) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;
  }

  &.loading {
    opacity: $loadingOpacity;
  }

  .title {
    font-size: 26px;
    font-weight: 700;

    @media (max-width: $br4) {
      font-size: 16px;
    }
  }

  .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $br4) {
      margin: 15px 0 0;
    }

    .green-cta {
      height: 40px;
      border-radius: 6px;
      background: #00bd00;
      color: #ffffff;
      font-size: 16px;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      @media (max-width: $br4) {
        height: 35px;
        font-size: 12px;
      }

      svg {
        width: 16px;
        margin: 0 10px 0 0;

        @media (max-width: $br4) {
          display: none;
        }
      }

      &:first-child {
        margin: 0 15px 0 0;
      }
    }
  }

  .pagination {
    display: none;
    align-items: center;
    justify-content: flex-end;

    &.active {
      display: flex;
    }
  }
}

.banner-cards {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  gap: 35px 50px;
  flex-wrap: wrap;
  margin: 0 0 30px;
  transition: 0.2s;

  @media (max-width: $br1) {
    gap: 35px 20px;
  }

  @media (max-width: $br3) {
    justify-content: space-between;
    gap: 35px 0;
  }

  @media (max-width: $br4) {
    justify-content: center;
  }

  &.loading {
    opacity: $loadingOpacity;
  }

  .banner-card {
    width: calc(25% - 38px);
    border: 1px solid #ebebeb;
    border-radius: 10px;

    @media (max-width: $br1) {
      width: calc(25% - 15px);
    }

    @media (max-width: $br2) {
      width: calc(33% - 10px);
    }

    @media (max-width: $br3) {
      width: 48%;
    }

    @media (max-width: $br4) {
      width: 100%;
      max-width: 350px;
    }

    &:hover,
    &.active {
      border-color: #00bd00;
    }

    &.active {
      .active-cover {
        opacity: 1 !important;
      }
    }

    .img-area {
      width: 100%;
      position: relative;
      background: #ebebeb;
      aspect-ratio: 295 / 153;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer;

      .active-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 35px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
        z-index: 0;
      }
    }

    .card-bottom-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;

      .top-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: default;

        .text {
          font-size: 14px;
          font-weight: 700;
          flex-grow: 1;
          padding-right: 25px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }

        .format {
          background: #d4e5fb;
          border-radius: 55px;
          text-transform: uppercase;
          font-size: 12px;
          padding: 2px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          flex-shrink: 0;

          &.gif {
            background: #ffe7f0;
          }
        }
      }

      .id-row {
        width: 100%;
        font-size: 10px;
        color: #dadada;
        margin: 0 0 10px;
      }

      .download-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .download-button {
          width: 32%;
          padding: 4px 0;
          background: #00bd00;
          color: #ffffff;
          font-size: 12px;
          border-radius: 55px;
          cursor: pointer;
          text-transform: capitalize;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;

          a {
            color: #ffffff;
          }

          svg {
            width: 14px;
            min-width: 14px;
            margin: 0 6px 0 0;
          }
        }
      }

      .bannerRatingBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 10px;

        .bannerRating {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            margin: 0 0 0 5px;
          }
        }
      }
    }
  }
}

.adToolNoBanner {
  margin: 40px 0 0;

  h4 {
    font-size: 1.52rem;
    line-height: 110%;
  }

  .bannerResetFilters {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    div {
      background: #00bd00;
      color: #ffffff;
      cursor: pointer;
      padding: 10px 25px;
      border-radius: 6px;
      font-weight: 700;
      font-size: 1.52rem;
    }
  }
}
