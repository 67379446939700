#live-cam-widget {
  #unavailable-feature {
    text-align: center;
    padding: 2rem;
  }
  padding-top: 100px;

  h1 {
    margin: 0;
    font-size: em(36);
  }

  .disabled {
    color: rgba(0, 0, 0, 0.26);
  }

  .livecam-header {
    display: flex;
    align-items: center;
    .widget-code {
      margin-left: auto;
    }
  }

  .widget-code {
    @include flexbox(row, wrap, space-between);
    & > * {
      align-self: center;
      min-width: 240px;
    }
    .btn-generate {
      line-height: 1.3em;
      height: auto;
    }
  }

  .livecam-generator-iframe-placeholder {
    @include flexbox(row, nowrap, flex-start);
    & > .text {
      flex: 5;
      padding-right: 2rem;
      * {
        color: #fff;
        text-align: left;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 900;
        line-height: 2.5rem;
        margin-bottom: 2rem;
      }
      ul {
        text-align: left;
        color: #fff;
        font-size: 16px;
        line-height: 1.2;
        list-style-type: none;
        margin: 0 0 23px -16px;
        li {
          padding: 0 0 6px 41px;
          position: relative;
          &:before {
            content: '';
            display: block;
            position: absolute;
            border-radius: 50px;
            width: 7px;
            height: 7px;
            background-color: $mfc-green;
            left: 26px;
            top: 7px;
          }
        }
      }
      .muted {
        margin-bottom: 1rem;
        span {
          color: #959595;
          font-size: 1rem;
        }
      }
    }
    & > .video {
      flex: 7;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .settings-groups {
    @include flexbox(row, wrap, flex-start);
    padding: 1rem 2rem 2rem;

    .delay {
      @include flexbox(row, nowrap, flex-start);
      margin-top: 1rem;
      .unit-label {
        align-self: center;
        flex: 0 0 15%;
        margin-left: 0.5rem;
        text-align: center;
      }
    }

    &.same-width .settings-group {
      flex: 1;
      max-width: 305px;
    }
    .settings-group {
      @include flexbox(column, nowrap, flex-start);

      padding: 1rem 2rem;

      &#settings-section {
        width: 336px;
      }
      &#categories-section {
        width: 275px;
      }
      &:not(:last-child) {
        border-right: 1px solid #d8d8d8;
      }

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      span + .checkboxes,
      span + .radio-buttons {
        margin: 0.75rem 0 0;
      }
      .checkboxes,
      .radio-buttons {
        &:not(#infobar-fields):not(.grouped) {
          padding-bottom: 1rem;
        }
        &.grouped {
          padding: 0;
          .checkbox-field,
          .radio-field {
            padding: 0.5rem 0;
          }
        }
        &.vertical {
          @include flexbox(column, nowrap, flex-start);
          .checkbox-field,
          .radio-field {
            padding-bottom: 1rem;
            .has-image {
              height: auto;
              img {
                display: block;
                margin-top: 0.35rem;
              }
            }
          }
        }
        &:not(.vertical) {
          @include flexbox(row, wrap, flex-start);
          &:not(.horizontal) .checkbox-field,
          &:not(.horizontal) .radio-field {
            flex: 0 0 50%;
          }
          &.horizontal .checkbox-field,
          &.horizontal .radio-field {
            flex: 1;
            margin-right: 1rem;
          }
        }
      }

      .offers-block {
        width: 275px;
        @include responsive('xlarge') {
          width: 300px;
        }
      }

      .selected-elements .selected-element {
        @include flexbox(row, nowrap, space-between);
        color: white;
        margin: 0.5rem 1rem;
        padding: 0.5rem 1rem;
        * {
          align-self: center;
          margin-left: 0.5rem;
        }
        .material-icons:hover {
          cursor: pointer;
        }
      }

      .clear-all {
        @include flexbox(row, nowrap, center);
        background-color: $cr-pale-grey;
        margin: 0.5rem 1rem;
        padding: 0.5rem 1rem;
        &:hover {
          cursor: pointer;
        }
        * {
          align-self: center;
          margin: 0 0.5rem;
        }
      }

      .error-msg {
        color: #d83d20;
      }

      .customSelects .fields .field {
        margin-bottom: 20px;
        .labelBox label {
          font-weight: normal;
        }
        .inputBox .open {
          z-index: 1000;
        }
      }
    }
  }

  #template-settings {
    margin: 2rem 4rem;
    .templates {
      @include flexbox(row, wrap, flex-start);
      padding: 1rem 0;
      & > * {
        flex: 1;
      }
      .template-select-box:not(.active):hover {
        cursor: pointer;
      }
    }
  }

  #type-settings {
    .settings-groups {
      @include flexbox(row, nowrap, flex-start);
    }
    #offers-section .select-field {
      margin-top: -1rem;
    }
  }

  #infobar-settings {
    #widget-infobar-display > * {
      margin-right: 1rem;
      * {
        margin: 0;
      }
    }
    #infobar-fields {
      .checkbox-field {
        flex: 0 50%;
        margin: 0.25rem 0 1.75rem;
      }
    }
    .select-field {
      margin-top: -1rem;
    }

    .widget-positions:first-child {
      padding-bottom: 5rem;
    }
    .widget-positions:last-child {
      padding-top: 2rem;
    }
  }

  .with-units {
    @include flexbox(row, nowrap, flex-start);
    flex: 1;

    .input-field {
      flex: 0 0 85%;
    }

    .unit-label {
      align-self: center;
      flex: 0 0 15%;
      margin-left: 0.5rem;
      text-align: center;
    }
  }

  #css-settings {
    @include flexbox(column, wrap, flex-start);
    & > * {
      flex: 1;
      margin: 1rem;
    }
    @media (min-width: $screen-md) {
      @include flexbox(row, wrap, flex-start);
    }
  }

  #widget-iframe {
    padding: 2rem;
    .widget-iframe-header {
      @include flexbox(row, nowrap, flex-start);
      & > * {
        align-self: center;
      }
      .devices {
        padding-bottom: 1rem;
      }
    }
    .widget-iframe-header,
    .widget-iframe-footer {
      padding: 1rem 2rem;
    }
    .widget-iframe-wrapper {
      background-color: #e8e8e8;
      height: auto;
      width: 100%;
      .widget-iframe-container {
        margin: auto;
        &.phone {
          width: 392px;
          height: 730px;
          padding: 132px 30px 108px 41px;
          background: url(/img/survey-iphone.svg) no-repeat center center;
          background-size: 392px 730px;
        }
        &.tablet {
          width: 691px;
          height: 883px;
          padding: 81px 80px 106px 85px;
          background: url(/img/survey-ipad.svg) no-repeat center center;
          background-size: 691px 883px;
        }
        &.desktop {
          height: 600px;
        }
        &.disabled {
          height: auto;
        }
      }
    }

    .deviceBox {
      &.DESKTOP {
        width: 95% !important;
        max-width: none;
      }
    }
  }

  label[for='widget-infobar-fields-room_topic'] {
    display: none;
  }
}

#live-cam-widget-modal {
  .subtitle {
    font-size: 1.75rem;
    font-weight: 400;
  }
  .inputs {
    @include flexbox(row, wrap, space-between);

    & > * {
      flex: 0 0 33%;
    }
  }
  .btn.apply {
    margin-bottom: 1rem;
  }
  .copy-btn-container {
    @include flexbox(row, nowrap, flex-end);
    margin-left: auto;
    max-width: 100px;
    .copyBtn {
      @include flexbox(row, nowrap, flex-end);
    }
  }
}
