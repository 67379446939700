$br1: 1335px;
$br2: 550px;
$br3: 429px;

.header-box {
  margin: 75px 0 0;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  width: 100%;
  max-width: 980px;

  @media (max-width: $br1) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 0 0;
  }

  .description-box {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: $br1) {
      width: 100%;
      max-width: 600px;

      br.brDesktop {
        display: none;
      }
    }

    @media (max-width: 767px) {
      padding-right: 15px;
    }

    h1 {
      margin: 0;
      color: #181761;
      font-size: 56px;
      font-weight: 800;

      @media (max-width: $br1) {
        max-width: 700px;
        font-size: 34px;
      }
    }

    p {
      width: 100%;
      color: #7b7b7b;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      margin: 20px 0 25px;

      @media (max-width: $br1) {
        margin: 20px 0 25px;
        line-height: 170%;
      }
    }

    .jerkmate-btn {
      @media (max-width: $br1) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }

  .advantages-box {
    width: 400px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 189px);
    column-gap: 20px;
    row-gap: 12px;
    justify-content: center;

    @media (max-width: $br1) {
      width: 100%;
      overflow-x: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: unset;
    }

    @media (max-width: 767px) {
      padding-right: 15px;
    }

    .advantage-box {
      height: 180px;
      display: flex;
      flex-flow: column;
      align-items: center;
      border-radius: 12px;
      background: #f6f6f6;
      justify-content: flex-start;
      padding: 30px 0 0;
      border: 0;

      a {
        color: #343434;
      }

      @media (max-width: $br1) {
        min-width: 162px;
        padding: 15px 10px 0;
      }

      @media (max-width: $br2) {
        height: 155px;
        padding: 0 10px;
      }

      @media (max-width: $br3) {
        min-width: 142px;
      }

      .imgCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 55px;

        @media (max-width: $br1) {
          margin: 15px 20px 0;
        }

        img {
          width: 100%;
        }
      }

      p {
        color: #343434;
        text-align: center;
        font-size: 14px;
        line-height: 15px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
}
