.modal-content {
    .modal-close {
        position: absolute;
        right: 20px;
        z-index: 101;
        color: #000;
    }

    .checkbox-field {
        padding-bottom: 1rem;
    }

    .input-field {
        i {
            position: absolute;
            top: 10px;
        }
    }
}

.black-modal {
    background-color: #333333;

    .modal-close {
        color: #fff;
    }

    .modal-content,
    .modal-footer {
        text-align: center !important;
        background-color: #333333;
    }

    label {
        color: $cr-green;
    }

    input {
        border-bottom: 1px solid $cr-green;
        box-shadow: 0 1px 0 0 $cr-green;
        color: #fff;
    }

    .input-field {
        margin-top: 20px;
    }

    h2 {
        font-size: em(16px);
        color: #fff !important;
        padding: 25px 0 10px;
    }

    .cancel-button {
        color: #fff;
    }
}

.modal-footer {
    .cancel-button {
        float: none !important;
    }
}

.modal-open-button {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-top: 1rem;

    i {
        position: relative;
        top: 0.3rem;
    }
}
