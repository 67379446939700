$slidePositivePosition: calc(100% + 20px);
$slideNegativePosition: calc(-100% - 20px);

#promotion {
  @media (max-width: 1500px) and (min-width: 1224px) {
    width: 58%;
    margin-right: 2% !important;
    height: 335px !important;
  }

  @media (max-width: 767px) {
    height: auto !important;
    background: none;
    box-shadow: none;
  }

  .div-container-content {
    height: 100%;
    padding: 0;
  }
}
#home-promotion {
  height: 100%;
  .promotion-wrapper {
    padding-top: 45%;
    height: 0 !important;
    @media (min-width: $screen-xlg) {
      padding-top: 50%;
    }
    @media (max-width: $screen-xlg) {
      padding-top: 35%;
    }
    @media (max-width: $screen-lg) {
      padding-top: 40%;
    }
    @media (max-width: $screen-sm) {
      padding-top: 50%;
    }
  }
  .promotion-slide-arrow {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #000000;
      }
    }

    svg {
      width: 100%;
    }

    &.left {
      left: 0;

      svg {
        transform: rotate(-180deg);
      }
    }
    &.right {
      right: 0;
    }
  }
  .home-promotion-slides {
    width: 100%;
    height: 100%;
    aspect-ratio: 1500 / 750;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
  }
  .dots {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 200;

    @media (max-width: 767px) {
      position: relative;
      bottom: auto;
      margin: 15px 0 5px;
    }

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #252525;

      &.active {
        background: #00bd00;
      }
    }
  }
  .promotion-container {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    z-index: 0;

    &.active {
      transform: translateX(0%);
      opacity: 1;
      z-index: 100;
    }

    &.next {
      transform: translateX($slidePositivePosition);
      transition: 0s;

      &.slideRight {
        transform: translateX($slideNegativePosition);
      }
    }

    &.transition {
      transition: transform 0.3s ease-in;

      &.active {
        transform: translateX($slideNegativePosition);

        &.slideRight {
          transform: translateX($slidePositivePosition);
        }
      }

      &.next {
        transform: translateX(0%);
        opacity: 1;
      }
    }

    .bottom-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 40px;
      text-align: left;
      z-index: 2;
      transition: opacity 300ms ease-out;
      p {
        display: block;
        color: #cccccc;
        margin: 4px 0;
        font-weight: 300;
        .cta {
          color: #00b827;
          text-transform: uppercase;
          svg {
            margin-left: 8px;
          }
        }
        .subtitle ~ .cta {
          margin-left: 8px;
        }
      }
      @media (max-width: $screen-md) {
        text-align: left;
        padding: 20px 30px;
        p {
          font-weight: 500;
        }
      }
      @media (max-width: $screen-sm) {
        padding: 10px 20px;
        p {
          .subtitle {
            display: none;
          }
          .subtitle ~ .cta {
            margin-left: 0px;
          }
        }
      }
      h2 {
        color: #fff;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 4px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    .behind-content {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 8px;
      padding: 40px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: opacity 300ms ease-out;
      p {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        font-family: 'Proxima Nova Alt', arial, sans-serif;
        color: #fff;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        @media (max-width: 1930px) {
          font-size: 22px;
          line-height: 24px;
        }
        @media (max-width: $screen-md) {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        @media (max-width: $screen-sm) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }
    .close {
      display: none;
    }
    .info,
    .close {
      color: #fff;
      background: none;
      outline: none !important;
      border: none;
      position: absolute;
      top: 10px;
      right: 0;
      text-transform: none;
      font-size: 16px;
      z-index: 3;
      margin: 0 15px;
      i {
        position: relative;
        top: 6px;
        margin-right: 6px;
      }
      @media (max-width: $screen-sm) {
        top: 3px;
      }
    }
    &.info-active {
      .behind-content {
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
      }
      .bottom-content {
        opacity: 0;
      }
      button.info {
        display: none;
      }
      button.close {
        display: block;
      }
    }
  }
}

@include responsive('phone to tablet') {
  #home-promotion {
  }
}
