#referring-campaigns {
    .tracking {
        #offer-tracking-link-lp {
            padding: 0 10px 15px;
            &.black-title > .div-container-title {
                margin: 0 -10px;
            }
        }
    }

    @include responsive('large') {
        .tracking {
            @include flexbox(row, nowrap, space-between);
            #offer-tracking-link-container {
                flex: 2;
                margin-right: 1rem;
            }
            #offer-tracking-link-lp {
                flex: 1;
            }
        }
    }

    @include responsive('xlarge') {
        .tracking {
            @include flexbox(row, nowrap, space-between);
            #offer-tracking-link-container {
                flex: 2;
                margin-right: 1rem;
            }
            #offer-tracking-link-lp {
                flex: 1;
            }
        }
    }
}
