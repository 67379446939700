$thumbnailRadius: 15px;

$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

.offerInfoTop {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr 175px;
  gap: 24px;
  line-height: normal;
  padding-bottom: 20px;

  @media (max-width: $br1) {
    gap: 15px;
    grid-template-columns: 80px 1fr 175px;
  }

  @media (max-width: $br5) {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .thumnnailImg {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $br1) {
      width: 80px;
      height: 80px;
    }

    @media (max-width: $br5) {
      order: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: $thumbnailRadius;
    }

    .preview {
      width: 100%;
      height: 100%;
      background: #111111;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $thumbnailRadius;
    }
  }

  .offerInfoDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    min-width: 0;

    @media (max-width: $br1) {
      gap: 5px;
    }

    @media (max-width: $br5) {
      gap: 8px;
      grid-column: span 2;
      order: 3;
    }

    .offerInfoTitle {
      width: 100%;
      font-size: 28px;
      font-weight: 700;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: $br1) {
        font-size: 20px;
      }
    }
  }

  .offerInfoShare {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;

    @media (max-width: $br5) {
      grid-column: span 1;
      order: 2;
    }

    .shareBtn {
      height: 30px;
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid #3d3d3d;
      gap: 10px;
      padding: 0 10px;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: $br5) {
        padding: 0;
        width: 30px;
        height: 30px;
      }

      span {
        @media (max-width: $br5) {
          display: none;
        }
      }
    }
  }
}
