$imgBorderRadius: 8px;
$grey: #919191;

.offerListingTags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.offerListingTooltipContent {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;

  p,
  a {
    font-size: 11px;
  }

  a {
    font-weight: 700;
  }
}
