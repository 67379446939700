#offer-navigation-bar {
    @include flexbox(row, wrap, flex-start);

    a {
        @include flexbox(row, wrap, flex-start);

        align-self: center;

        i.return-to-offers {
            align-self: center;
            color: white;
            font-size: 2rem;
        }
    }
}
