.offers-image {
    .no-preview-image {
        padding: 0;
        background-color: #111;
        color: #fff;
        text-align: center;
        border-radius: 10px;
        font-size: 10px;
    }

    .offers-thumbnail {
        display: block;
    }
}
