.offer-mailing-popup {
  @media (max-width: 992px) {
    width: 90%;
  }
  .modal-inner {
    padding: 0;
  }
}

#email-popup {
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 992px) {
    height: 90vh;
  }

  .modal-content {
    background-color: $cr-grey;
    padding: 0;

    .modal-close {
      color: white;
      top: 1rem;
      right: 0.75rem;
    }
  }
  .email-popup-content {
    @include flexbox(column, nowrap, flex-start);

    & > * {
      padding: 2rem 1.5rem 1.5rem;
    }
    .general-section {
      flex: 1;
      min-width: 250px;
      background-color: #333;
      * {
        color: #fff;
      }

      .mailing-svg {
        text-align: center;
      }

      .title {
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.2em;
        margin-top: 1.5rem;

        p {
          font-weight: 400;
          font-size: 1.25rem;
        }
      }

      .legal-title {
        @include flexbox(row, nowrap, flex-start);

        margin-top: 3rem;
        flex: 2;
        min-width: 320px;
        color: #fff;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.2em;

        svg {
          margin-right: 1rem;
        }
      }
    }
    .steps-section {
      flex: 2;
      background-color: white;
      text-align: center;

      h1.title {
        font-weight: 500;
        font-size: 4rem;
      }
      h2.step {
        font-weight: 600;
        font-size: 2rem;
      }
      p {
        font-weight: 400;
        font-size: 1.25rem;
      }
      section {
        padding: 1rem 0;
      }
      .copy-widget-value {
        @include flexbox(row, wrap, flex-start);
      }
    }
  }

  .im-done-btn {
    display: inline-block;
    font-size: 1.25rem;
    text-transform: uppercase;

    &:hover {
      color: #333;
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    .modal-content .modal-close {
      color: black !important;
    }
    .email-popup-content {
      @include flexbox(row, nowrap, flex-start);
    }
  }
}
