#return-message {
  display: block;
  position: fixed;
  width: 100%;
  max-width: 100%;
  left: 0px;
  right: 0px;
  bottom: -400px;
  text-align: center;
  padding: 20px 40px;
  z-index: 60001;
  transition: $inAnimationLinks;
  .overlay {
    z-index: 199;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #ffffff;
    position: fixed;
    opacity: 0.5;
    display: none;
    transform: inherit;
    width: 1000%;
    height: 1000%;
  }
  &.show {
    bottom: 0px;
    .overlay {
      display: block;
    }
  }
  &.isModal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    width: auto;
    transition: initial;
    .message {
      padding: 30px 40px;
      .confirm-buttons {
        display: block;
        margin-left: 0;
        .btn {
          &:first-child {
            margin-right: 30px;
          }
        }
      }
      .text {
        color: #a3a3a3;
        .confirm-buttons {
          margin-top: 30px;
        }
      }
    }
  }
  .message {
    display: inline-block;
    position: relative;
    padding: 20px 40px;
    color: #fff;
    background-color: #000;
    border-radius: 3px;
    max-width: 100%;
    .title {
      font-size: 18px;
    }
    z-index: 200;
    .text {
      word-wrap: break-word;
    }

    .close {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      cursor: pointer;

      .material-icons {
        font-size: em(18);
      }

      &:hover {
        color: $cr-grey65;
      }
    }

    .confirm-buttons {
      display: inline-block;
      position: relative;
      margin-left: 15px;

      .btn {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          background-color: #9f9f9f !important;
          color: #ffffff !important;

          &:hover {
            background-color: lighten(#9f9f9f, 10%) !important;
          }
        }
      }
    }
  }
}

@include responsive('phone to tablet') {
  #return-message {
    padding: 20px 30px 20px 15px;

    .message {
      display: block;
      width: 100%;
    }
  }
}

#return-message-cover {
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;

  #return-message {
    display: flex !important;
    justify-content: center;

    @media (max-width: 600px) {
      justify-content: flex-start;
    }

    .message {
      width: auto !important;
      background: #d83d20 !important;

      @media (max-width: 600px) {
        width: 100% !important;
      }
    }
  }
}
