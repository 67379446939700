@use "sass:math";

@function em($pixels, $context: $browser-context) {
    @return #{math.div($pixels, $context)}em;
}

@mixin createInput($borderColor, $focusColor) {
    // General Styles
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $borderColor;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;

    font-size: 1rem;
    margin: 0 0 15px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;

    // Disabled input style
    &:disabled,
    &[readonly='readonly'] {
        color: $input-disabled-color;
        border-bottom: 1px dotted $input-disabled-color;
    }
    // Disabled label style
    &:disabled + label,
    &[readonly='readonly'] + label {
        color: $input-disabled-color;
    }
    // Focused input style
    &:focus:not([readonly]) {
        border-bottom: 1px solid $focusColor;
        box-shadow: 0 1px 0 0 $borderColor;
    }
    // Focused label style
    &:focus:not([readonly]) + label {
        color: $focusColor;
    }
    // Valid Input Style
    &.valid,
    &:focus.valid {
        border-bottom: 1px solid $input-success-color;
        box-shadow: 0 1px 0 0 $input-success-color;
    }
    // Custom Success Message
    &.valid + label:after,
    &:focus.valid + label:after {
        content: attr(data-success);
        color: $input-success-color;
        opacity: 1;
    }
    // Invalid Input Style
    &.invalid,
    &:focus.invalid {
        border-bottom: 1px solid $input-error-color;
        box-shadow: 0 1px 0 0 $input-error-color;
    }
    // Custom Error message
    &.invalid + label:after,
    &:focus.invalid + label:after {
        content: attr(data-error);
        color: $input-error-color;
        opacity: 1;
    }

    // Form Message Shared Styles
    & + label:after {
        display: block;
        content: '';
        position: absolute;
        top: 65px;
        opacity: 0;
        transition: 0.2s opacity ease-out, 0.2s color ease-out;
    }
}

@mixin responsive($size) {
    @if $size == 'xlarge' {
        @media (min-width: $screen-xlg) {
            @content;
        }
    }
    @if $size == 'large' {
        @media (min-width: $screen-lg) and (max-width: $screen-xlg - 1) {
            @content;
        }
    }
    @if $size == 'normal' {
        @media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
            @content;
        }
    }
    @if $size == 'tablet' {
        @media (min-width: $screen-sm) and (max-width: $screen-md - 1) {
            @content;
        }
    }
    @if $size == 'tablet at least' {
        @media (min-width: $screen-sm) {
            @content;
        }
    }
    @if $size == 'tablet mini' {
        @media (min-width: $screen-xs+1) and (max-width: $screen-sm - 1) {
            @content;
        }
    }
    @if $size == 'phone to tablet' {
        @media (max-width: $screen-sm - 1) {
            @content;
        }
    }
    @if $size == 'phone' {
        @media (max-width: $screen-xs) {
            @content;
        }
    }
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

@mixin font-face(
    $name,
    $path,
    $weight: null,
    $style: null,
    $exts: eot woff2 woff ttf svg
) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_'),
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype',
    );

    @each $ext in $exts {
        $extmod: if(
            map-has-key($extmods, $ext),
            $ext + map-get($extmods, $ext),
            $ext
        );
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append(
            $src,
            url('/' + quote($path + '.' + $extmod)) format(quote($format)),
            comma
        );
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin flexbox($direction: row, $wrap: wrap, $justify: flex-start) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
}
