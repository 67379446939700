.offer-status-bar {
    @include flexbox(row, wrap, space-between);

    .btn-label {
        margin-top: 1px;
    }
    .btn-icon {
        @include flexbox(row, nowrap, flex-start);

        svg {
            align-self: center;
            width: 20px;
            min-width: 20px;
            max-width: 20px;
            height: auto;

            path {
                fill: white;
            }
        }
    }
    &.pending svg circle {
        fill: #f4760c;
    }
    &.rejected .standard-button:not(.btn-styled) svg path {
        fill: $warning-color;
    }
}
