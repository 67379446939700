#smart-link-adtool {
  margin-left: 65px;
  margin-right: 65px;

  @media (max-width: 1390px) {
    margin-left: 0;
    margin-right: 0;
  }

  #bannerWaves {
    max-height: 90px;
  }
  h2.section-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: $cr-green;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
}
