.material-tooltip {
    padding: 10px 8px;
    font-size: 1rem;
    z-index: 2000;
    background-color: transparent;
    border-radius: 2px;
    color: #fff;
    min-height: 36px;
    line-height: 120%;
    opacity: 0;
    display: none;
    position: absolute;
    text-align: center;
    max-width: calc(100% - 4px);
    overflow: hidden;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: top, left;
    &.white {
        max-width: 100%;
        max-width: 400px;
        background: #fff;
        color: #636363;
        font-size: 12px;
        border: 1px solid #979797;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        text-align: left;
        .backdrop {
            display: none !important;
        }
    }
}

.backdrop {
    position: absolute;
    opacity: 0;
    display: none;
    height: 7px;
    width: 14px;
    border-radius: 0 0 14px 14px;
    background-color: #323232;
    z-index: -1;
    transform-origin: 50% 10%;

    will-change: transform, opacity;
}
