#offer-info {
  padding: 15px;
  margin-top: 30px;

  @media (max-width: 991px) {
    margin-top: 15px;
  }

  .div-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  #offer-tracking-link-lp {
    @media (min-width: 1500px) {
      margin-top: 0 !important;
    }
  }

  .header-actions {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .share-icon {
      height: 30px;
      margin-left: auto;
      @media (max-width: $screen-md) {
        position: absolute;
        right: 0px;
        top: 0px;
      }
      > svg {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }
  & > .div-container-content {
    @include flexbox(column, nowrap, flex-start);
    padding: 0;
  }

  #offer-global-info {
    position: relative;

    &.initialOfferFetching {
      > * {
        opacity: 0;
      }
    }

    .skeleton {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1 !important;
    }

    @include flexbox(column, nowrap, flex-start);

    .offer-title {
      @include flexbox(row, nowrap, space-between);

      margin: 0 5px;

      > * {
        align-self: center;
      }

      h1.offer-name {
        font-size: 2rem;
        font-weight: 500;
        margin: 0 15px;
        @media (max-width: $screen-md) {
          font-size: 1.5rem;
          margin: 0 5px;
        }
      }
      img.featured {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        height: auto;
        @media (max-width: $screen-sm) {
          min-width: unset;
          max-width: unset;
          width: 20px;
          margin-left: 4px;
          height: 100%;
        }
      }
      .featured_container {
        width: 50%;
        display: flex;
        justify-content: center;
      }

      .offer-img__star {
        display: flex;
        justify-content: space-between;
      }
      .share-icon {
        svg {
          @media (max-width: $screen-sm) {
            padding: 4px;
          }
        }
      }
    }

    .offer-info {
      @include flexbox(row, wrap, flex-start);

      .offer-info-geo {
        display: flex;
        align-items: stretch;
        gap: 10px;
      }

      .offer-info-box {
        display: flex;
        padding: 30px 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border-radius: 20px;
        border: 1px solid #d6d6d6;
        background: #f8f8f81a;
        height: 140px;
        min-width: 150px;

        .label {
          align-self: stretch;
        }
      }

      &.geo {
        .tooltip-wrapper {
          height: fit-content;
        }
      }

      @media (max-width: $screen-sm) {
        &.geo {
          flex-direction: column;
          flex-wrap: wrap;
          gap: 10px;
        }

        .offer-info-geo {
          justify-content: center;
          flex-wrap: wrap;
          flex: 1 1 calc(50% - 10px);
        }

        .offer-info-box {
          flex: 1;
          padding: 15px 10px;
          min-height: 100px;
          height: unset;

          .label {
            align-self: unset;

            &.accepted {
              align-self: stretch;
            }
          }
        }

        .offer-info-box:nth-child(odd):last-child {
          flex: 1 1 100%;
        }
      }

      margin: 10px 0 0;
      padding-top: 10px;
      border-top: 1px solid rgba(20, 12, 12, 0.15);

      &.offer-info-description {
        margin-top: 0;
      }

      > * {
        flex: 1;
      }

      .label {
        font-weight: bold;
        display: flex;
        gap: 10px;
        span:after {
          content: ':';
          margin-left: 2px;
        }

        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      &.status > :not(.label) {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    #offer-description {
      @include flexbox(column, nowrap, flex-start);

      #btn-offer-description {
        &:before {
          content: '+';
          margin-right: 5px;
        }

        margin-top: 10px;
      }
    }

    #offer-description-modal h1.modal-title {
      font-size: 1.5rem;
      padding-bottom: 15px;
      margin-bottom: 25px;
      border-bottom: 1px solid #979797;
    }
  }

  h4 {
    @include flexbox(row, nowrap, flex-start);

    margin: 15px 5px 0;
    padding-top: 10px;
    font-size: 1.76rem;
    font-weight: 500;

    > * {
      flex: 1;
    }

    .title {
      @include flexbox(row, nowrap, flex-start);

      svg {
        height: 25px;
        min-height: 25px;
        max-height: 25px;
        width: auto;
        margin-right: 5px;
      }
    }
  }

  #landing-page-wrapper {
    margin-top: 15px;

    @media (min-width: 1500px) {
      margin: 0;
    }
  }

  .LinesEllipsis-ellipsis {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
    text-decoration: underline;
  }

  @include responsive('tablet mini') {
    #offer-global-info {
      .offer-title {
        @include flexbox(row, nowrap, flex-start);

        > :first-child {
          flex: 0 0 30%;
        }
        h1.offer-name {
          margin-left: 0px;
        }
      }

      .offer-info {
        > :first-child {
          flex: 0 0 30%;
        }
        > :not(:first-child) {
          flex: 0 0 70%;
        }
      }
    }

    h4 {
      > :first-child {
        flex: 0 0 30%;
      }
      > :not(:first-child) {
        flex: 0 0 70%;
      }
    }
  }

  @include responsive('tablet at least') {
    padding: 25px;

    #offer-global-info {
      .offer-title {
        @include flexbox(row, nowrap, flex-start);
        h1.offer-name {
          margin-left: 0px;
        }
      }

      .offer-img__star {
        flex: 0 0 20%;
        justify-content: space-between;
      }
      .header-actions {
        flex: 0 0 80%;
      }

      .offer-info {
        > :first-child {
          flex: 0 0 20%;
        }
        > :not(:first-child) {
          flex: 0 0 80%;
        }
      }
    }

    h4 {
      > :first-child {
        flex: 0 0 20%;
      }
      > :not(:first-child) {
        flex: 0 0 80%;
      }
    }
  }

  @include responsive('xlarge') {
    & > .div-container-content {
      @include flexbox(row, nowrap, flex-start);
    }

    #offer-global-info {
      flex: 2;
    }

    #landing-page-wrapper {
      margin-left: 50px;
      flex: 1;
    }
  }
}

.modal-description {
  max-height: 75vh;
}

.OfferDescriptionModal .modal-inner-content {
  max-height: 55vh;
  overflow-y: auto;
}
