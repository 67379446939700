.payment-history-widget-container {
    padding: 1px 15px 15px 15px;

    h2 {
        color: #fff;
        font-size: 1.1rem;

        i {
            margin-right: 15px;
            position: relative;
            top: 5px;
        }
    }

    .profile-history-button {
        float: right;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 15px;

        i {
            font-size: 32px;
            position: relative;
            top: 10px;
        }
    }
}

@include responsive('phone to tablet') {
    .payment-history-widget-container {
        .profile-history-button {
            margin-bottom: 0;
            position: relative;
            bottom: 32px;

            i {
                font-size: 48px;
            }
        }
    }
}
