#gamification-streak-modal-info {
  text-transform: none !important;
  pointer-events: all !important;
  text-decoration: none;
  @media (max-width: 576px) {
    max-width: 90vw;
  }
  font-size: 16px;
  padding: 16px 21px;
}

.WeekZeroModal {
  overflow-x: hidden;
  .header-modal {
    background: url(/img/week-zero-bg-header.png) no-repeat center center fixed;
    background-size: cover;
    height: 250px;
    margin: 0;

    button {
      right: 5%;
      top: 10%;
    }

    .whale-1 {
      position: absolute;
      width: 100px;
      bottom: 24%;
      right: 34%;
      animation: whale1-animation 5s ease-in-out forwards;
      animation-delay: 1s;

      @media (max-width: 576px) {
        bottom: 23%;
        right: 30%;
      }
    }

    .badge-1 {
      position: absolute;
      bottom: 33%;
      right: 26%;
      width: 50px;
      animation: badge1-animation 5s ease-in-out forwards;
      animation-delay: 1s;
      @media (max-width: 576px) {
        right: 17%;
      }
    }

    .badge-2 {
      position: absolute;
      width: 90px;
      bottom: -11%;
      right: 26%;
      animation: badge2-animation 5s ease-in-out forwards;
      animation-delay: 1s;

      @media (max-width: 576px) {
        right: 17%;
      }
    }

    .badge-3 {
      position: absolute;
      width: 60px;
      bottom: 40%;
      right: 42%;
      animation: badge3-animation 5s ease-in-out forwards;
      animation-delay: 1s;
    }

    .badge-4 {
      position: absolute;
      width: 90px;
      bottom: 9%;
      left: 30%;
      animation: badge4-animation 5s ease-in-out forwards;
      animation-delay: 1s;

      @media (max-width: 576px) {
        width: 80px;
        bottom: 6%;
        left: 22%;
      }
    }

    .badge-5 {
      position: absolute;
      bottom: 5%;
      left: 22%;
      width: 40px;
      animation: badge5-animation 5s ease-in-out forwards;
      animation-delay: 1s;

      @media (max-width: 576px) {
        left: 10%;
      }
    }

    .whale-2 {
      position: absolute;
      width: 90px;
      bottom: 40%;
      left: 19%;
      animation: whale2-animation 5s ease-in-out forwards;
      animation-delay: 1s;
      @media (max-width: 576px) {
        left: 9%;
      }
    }

    .black-whale {
      width: 350px;
      height: 350px;
      position: absolute;
      bottom: -47%;
      z-index: 2;
      animation: big-whale-animation 5s ease-in-out forwards;
      animation-delay: 1s;
    }
  }
  .week-streak-section {
    margin: 13px auto 23px;
    @media (max-width: 576px) {
      margin-top: 5px;
    }
    .rotate-title {
      display: flex;
      flex-flow: column;
      text-align: center;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;

      @media (max-width: 576px) {
        margin-top: 10px;
      }
      .first-title {
        transform: rotate(-4.375deg);
        font-size: 62px;
        font-weight: 900;
      }

      .second-title {
        font-size: 24px;
        line-height: 16px;
        color: #00bd00;
        @media (max-width: 576px) {
          font-size: 22px;
        }

        .tooltip-wrapper {
          margin-left: 10px;

          .more-info {
            color: #00bd00;
            margin: 0;
            cursor: pointer;
            i {
              font-size: 16px;
            }
          }
        }
      }
    }

    .progress-bar {
      margin: 25px 0;
    }

    p {
      margin: 15px 36px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      @media (max-width: 576px) {
        font-size: 14px;
        margin: 0 20px;
      }
    }
  }

  @keyframes big-whale-animation {
    10%,
    40% {
      transform: translate(30px, -50px);
      rotate: 20deg;
    }

    50%,
    90% {
      transform: translate(-30px, 0);
      rotate: -10deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes badge5-animation {
    10%,
    40% {
      transform: translate(50px, 20px);
    }

    50%,
    90% {
      transform: translate(-30px, -30px);
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes badge2-animation {
    10%,
    40% {
      transform: translate(20px, 20px);
      rotate: 20deg;
    }

    50%,
    90% {
      transform: translate(-30px, -30px);
      rotate: -40deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes badge4-animation {
    10%,
    40% {
      transform: translate(-80px, 0px);
      rotate: -15deg;
    }

    50%,
    90% {
      transform: translate(-30px, -20px);
      rotate: 40deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes whale2-animation {
    10%,
    40% {
      transform: translate(60px, 0px);
      rotate: -25deg;
    }

    50%,
    90% {
      transform: translate(-10px, -80px);
      rotate: 20deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes badge1-animation {
    10%,
    40% {
      transform: translate(-10px, -50px);
      rotate: -20deg;
    }

    50%,
    90% {
      transform: translate(-40px, -50px);
      rotate: 20deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes whale1-animation {
    10%,
    40% {
      transform: translate(-10px, -10px);
      rotate: 25deg;
    }

    50%,
    90% {
      transform: translate(-80px, -10px);
      rotate: -25deg;
    }

    100% {
      rotate: 0;
    }
  }

  @keyframes badge3-animation {
    10%,
    40% {
      transform: translate(-10px, -30px);
      rotate: -20deg;
    }

    50%,
    90% {
      transform: translate(40px, 30px);
      rotate: 30deg;
    }

    100% {
      rotate: 0;
    }
  }
}
