.offer-additionnal-single-info {
    @include flexbox(row, nowrap, flex-start);

    .label {
        flex: 0 0 40%;
        color: $low-highlight-color;

        @include responsive('tablet at least') {
            flex: 0 0 30%;
        }

        @include responsive('large') {
            flex: 0 0 40%;
        }
    }
}
