.videoModalIcon {
  &.paymentHistoryModal,
  &.statsVideoModal,
  &.offersVideoModal {
    position: relative;
    width: 24px;
    height: 24px;
    top: 3px;
    display: inline-block;
  }

  &.offersVideoModal {
    margin-left: 10px;
  }

  &.offersPayoutType {
    position: relative;
    top: 0px;
    padding-left: 3px;
  }

  .modalScroller {
    margin-top: 1rem;
  }
}

.video-modal {
  max-width: 800px;

  width: 90%;

  iframe {
    border: 0;
    width: 100%;
    margin: 25px 0;
  }
}
