.featured-offers-list {
    .categories-switch {
        display: block;
        position: absolute;
        max-width: 50%;
        right: 16px;
        top: -46px;
        .select-field {
            margin: 0;
            .select-wrapper {
                input.select-dropdown {
                    margin: 0;
                    text-align: right;
                    border: none;
                    color: #fff;
                    width: calc(100% - 20px);
                    padding-right: 20px;
                    cursor: pointer !important;
                    &:focus {
                        cursor: default !important;
                    }
                }
                .caret {
                    transform: scaleX(1.2);
                    top: 15px;
                }
                .dropdown-content {
                    margin-top: 50px;
                    margin-left: 16px;
                }
            }
        }
    }
    #pagination {
        width: 100%;
        height: 42px;
        .results {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .featured-offer {
        border-bottom: 1px solid #999;
        transition: background 125ms linear;
        &:hover {
            background: #f2f2f2;
            .offer-infos .description-containner:after {
                background: #f2f2f2;
            }
        }
    }
    .image-container {
        float: left;
        padding: 28px 10px 37px 6px;
        .offers-image {
            img,
            .no-preview-image {
                width: 87px;
                height: 87px;
                line-height: 87px;
            }
        }
    }
    .featured-offers-container {
        display: block;
        @extend .col-sm-6;
    }
    .offer-infos {
        float: left;
        max-height: 87px;
        padding: 28px 27px 0 0;
        width: calc(100% - (87px + 15px + 6px));
        .title-container {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: -4px;
            span {
                color: #2d2d2d;
                font-weight: 600;
                font-size: 1.3rem;
            }
        }
        .description-containner {
            font-weight: 300;
            overflow: hidden;
            position: relative;
            font-size: 1rem;
            line-height: 1.2em;
            max-height: 2.4em;
            min-height: 2.4em;
            text-align: justify;
            padding-right: 1em;
            color: #707070;
            margin: 3px 0 5px;
            &:before {
                content: '...';
                position: absolute;
                right: 0;
                bottom: 0;
            }
            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: 0.2em;
                background: white;
            }
        }
        .payout-container {
            color: #2d2d2d;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 600;
            font-size: 1.3rem;
            .payout {
                margin-right: 5px;
            }
        }
    }

    &.small {
        .image-container {
            padding: 20px 11px 18px 6px;
            .offers-image {
                img,
                .no-preview-image {
                    width: 62px;
                    height: 62px;
                    line-height: 62px;
                }
            }
        }
        .offer-infos {
            width: calc(100% - (62px + 15px + 6px));
            .title-container {
                font-size: 1.2rem;
            }
            .payout-container {
                color: #707070;
                font-size: 1rem;
            }
        }
    }

    &.mfc {
        .featured-offers-container {
            display: block;
            @extend .col-sm-12;
            padding: 0 10px;
        }
        .offer-infos {
            padding-top: 8px;
        }
        .image-container {
            padding: 10px 10px 10px 6px;
            .offers-image {
                img,
                .no-preview-image {
                    width: 82px;
                    height: 82px;
                    line-height: 82px;
                }
            }
        }
        #pagination {
            display: none;
        }
    }
}
