$maxw1: 767px;
$minw1: 768px;

.offer-slider-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.subtitle {
    @media (max-width: 991px) {
      padding: 0 15px;
    }
  }

  .offer-slider-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .prev {
      margin: 0 10px 0 0;
    }

    .prev,
    .next {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img,
      svg {
        width: 29px;
        height: 29px;

        @media (max-width: $maxw1) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.offers-slider-large {
  width: calc(100% + 40px);
  margin: 0 -20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 20px 0;
  gap: 20px;
  position: relative;

  &.totalSlides1 {
    .dots,
    .offer-slider-arrows {
      display: none;
    }
  }

  &.totalSlides2 {
    @media (min-width: $minw1) {
      .dots,
      .offer-slider-arrows {
        display: none;
      }
    }

    @media (max-width: $maxw1) {
      .slidesLarge {
        .sideSlides {
          width: 100%;
          transform: translateX(100%);

          .innerSlideLarge {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding: 15px;
  }

  &:before,
  &:after {
    display: none;
  }

  .slidesLarge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    position: relative;

    &.sliding {
      .innerSlideLarge {
        &.first {
          opacity: 0;
        }
      }

      .sideSlides {
        transform: translateX(0%) !important;
        transition: 0.6s cubic-bezier(0, 0.34, 0.58, 1.32) !important;
      }
    }

    &.reverse {
      justify-content: flex-end;

      .sideSlides {
        transform: translateX(-50%);
      }
    }

    &.reset {
      .sideSlides,
      .innerSlideLarge {
        transition: 0s;
      }
    }

    .sideSlides {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transform: translateX(50%);

      @media (max-width: $maxw1) {
        width: 200%;

        .innerSlideLarge {
          width: 50%;
        }
      }
    }

    .sideSlides,
    .innerSlideLarge {
      transition: 0.6s;
    }

    .innerSlideLarge {
      width: 50%;

      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-shrink: 0;
      padding: 0 5px;

      @media (max-width: $maxw1) {
        width: 100%;
      }

      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 21px;
        color: #ffffff;
        font-weight: 700;
        padding: 20px;
      }

      &.first {
        opacity: 1;
      }
    }

    .imgBox,
    img {
      border-radius: 10px;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 725 / 452;
      background: #cecece;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .dots {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #252525;

      &.active {
        background: #00bd00;
      }
    }
  }
}
