.react-colorful {
  z-index: 5;
}

.color-picker-wrapper {
  display: flex;
  justify-content: space-between;

  .picker {
    position: relative;
    height: 75px;
    span {
      font-size: 0.85em;
    }
  }

  .swatch {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 3px solid #aabbcc;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .popover {
    position: absolute;
    left: -210px;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .color-picker-input-field {
    flex: 2;
    width: 50%;
    .input-field label {
      left: 0;
    }
  }
}
