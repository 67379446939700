.lazyload-wrapper {
  width: calc(33% - 9px);
  padding-top: 15px;
  margin: 6px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  border-radius: 0.75rem;

  @media (max-width: $screen-md-max) {
    width: calc(50% - 12px);
  }

  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  .offers-card {
    &.div-container {
      position: initial;
    }

    .div-container-content {
      padding-bottom: 0.75rem;

      .epc {
        display: flex;
        align-items: center;
        gap: 5px;
        .tooltip-wrapper > div {
          display: flex;
        }
        svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }

    .offer-status {
      background-color: #c7c7c7;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 19px 5px 0 0;

      &.pending {
        opacity: 0.25;
      }

      &.rejected {
        background-color: $warning-color;
      }
    }

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      width: calc(100% - 10px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .id {
      padding-right: 0.3rem;
      font-weight: bold;
    }

    .vertical {
      color: $cr-greyText;
      font-size: 0.9rem;
    }

    .niche {
      color: $cr-greyText;
      font-size: 0.9rem;
      margin: 0.5rem 0 0;
      min-height: 18px;
    }

    .targeting {
      width: 24px;
      height: 22px;
      text-align: right;
      float: right;

      .mobile {
        height: 16px;
        width: auto;
      }
    }

    .payout {
      font-size: 3rem;
      color: $cr-grey;
      font-weight: bold;
      line-height: 0.9;
    }

    .payout-type {
      color: $cr-mid-grey;
      font-weight: bold;
      float: right;
    }

    .image-container {
      display: inline-block;
      position: relative;
      float: left;
      top: 0;
      right: 0;

      .featured-flag {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #fff;

        img {
          position: relative;
          left: 6px;
          top: 5px;
        }
      }

      .icon-new {
        position: absolute;
        top: -10px;
        right: -18px;
      }
    }

    .title {
      margin: 0.75rem 0 0.5rem;
    }

    .help {
      top: 0;
      color: inherit;
      transition: color 0.25s;
      margin-left: 4px;
      opacity: 0.9;

      i {
        display: inline-block;
        top: calc(50% - 0.45em);
        right: -1.25em;
        width: 1em;
        font-size: 0.9em;
      }
    }

    .country-list {
      max-width: 80%;
      justify-content: left;
      display: inline-block;
      margin: 0 0 -5px 0;

      li {
        display: inherit;
        margin-bottom: -3px;
      }
    }
  }
}
