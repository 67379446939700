#home-daily-statistics {
  .home-daily-statistics-container {
    display: flex;

    &.first-load {
      align-items: center;
    }

    @media (max-width: 1200px) {
      flex-flow: column;
    }

    .left-side {
      margin-bottom: 20px;
    }
  }
  .chart-container {
    position: relative;

    .title {
      display: block;
      position: relative;
      text-align: center;
      top: 15px;
      z-index: 1;
      color: $cr-greyText;
      font-weight: 500;
      font-size: 1em;
      text-transform: uppercase;
      padding-left: 42px;
      padding-right: 13px;

      span {
        display: inline-block;
        padding-top: 8px;
      }
    }
  }

  form {
    .stat-selector {
      position: relative;
      line-height: 32px;
      padding: 0.2rem 0;
      border-bottom: 1px solid $cr-pale-grey;
      color: $cr-grey;

      &:last-child {
        border: none;
      }

      .tooltip-wrapper.info {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 24px;
        i {
          font-size: em(16);
        }

        .info {
          cursor: pointer;
          margin: 0;

          @media (max-width: 992px) {
            margin: 0 5px;
          }
        }
      }
    }
  }

  .big-icon {
    display: block;
    position: relative;
    color: $cr-grey65;
    text-align: center;

    i {
      font-size: 250px;
    }
  }

  .data-form {
    height: 200px;
    .more-stats {
      margin-top: 15px;
    }
  }

  .more-stats {
    background: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
    border-radius: em(16);
    font-size: em(12);
    color: #494949;
    z-index: 100;
    padding: em(8) 12px;
    float: right;

    &:hover {
      box-shadow: 0 0 3px 0 $cr-green;
    }
  }

  .no-stats {
    text-align: center;
    position: relative;
    padding: 10% 15px 15px;
  }

  .container-date {
    padding: 21px 0 30px 0;
    overflow: hidden;
    border-bottom: 1px solid #e0e0e0;

    ul {
      display: block;
      position: relative;
      margin: 0;
      padding: 0 4px 3px;
      float: left;
      clear: both;

      li {
        display: inline-block;
        position: relative;
        float: left;
        padding: 2px 1px 1px;
        font-size: em(14);
        color: #333333;
        margin-right: 15px;
        border-bottom: 2px solid white;
        cursor: pointer;
        font-weight: 500;

        &:hover,
        &.active {
          border-bottom: 2px solid $cr-green;
        }
        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

@include responsive('xlarge') {
  #home-daily-statistics {
    form {
      .stat-selector {
        .tooltip-wrapper.info {
          left: 54px;
        }
        &.active {
          font-weight: bold;
        }
      }
    }

    .container-date {
      ul {
        li {
          margin-right: 20px;
        }
      }
    }
  }
}

@include responsive('large') {
  #home-daily-statistics {
    form {
      .stat-selector {
        .tooltip-wrapper.info {
          left: 54px;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
    .no-stats {
      padding: 10% 15px 0;
    }

    .container-date {
      ul {
        li {
          margin-right: 20px;
        }
      }
    }
  }
}

@include responsive('normal') {
  #home-daily-statistics {
    .container-date {
      padding-bottom: 15px;
    }

    .no-stats {
      padding: 0 15px 15px;
    }

    .data-form .more-stats {
      margin: 15px 0 15px 0;
    }
  }
}

@include responsive('tablet') {
  #home-daily-statistics {
    .container-date {
      padding-bottom: 15px;
    }

    .data-form .more-stats {
      margin: 15px 0 15px 0;
    }
  }
}

@include responsive('phone to tablet') {
  #home-daily-statistics {
    .container-date {
      padding-bottom: 15px;
    }

    .data-form .more-stats {
      margin: 15px 0 15px 0;
    }
  }
}

@include responsive('phone') {
  #home-daily-statistics {
    .container-date {
      padding-bottom: 15px;
    }

    .data-form .more-stats {
      margin: 15px 0 15px 0;
    }
  }
}
