#offer-additional-container {
    @include flexbox(column, nowrap, flex-start);

    & > * {
        flex: 1;
    }

    @include responsive('large') {
        @include flexbox(row, nowrap, flex-start);

        & > :not(:first-child) {
            margin-left: 1rem;
        }
    }

    @include responsive('xlarge') {
        @include flexbox(row, nowrap, flex-start);

        & > :not(:first-child) {
            margin-left: 1rem;
        }
    }
}
