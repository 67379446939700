@use 'sass:math';

#survey-generator-page {
  padding-top: 100px;
  .modal {
    max-height: 85vh;
    top: 10vh !important;
    .modal-content {
      max-height: 85vh;
    }
  }
  .btn-generate {
    float: right;
    min-width: 240px;
    padding: 1em 2em;
    line-height: 1.3em;
    height: auto;
    &.left {
      float: left;
      margin: 10px 0 20px;
      text-align: center;
    }
  }

  .colorSelector {
    cursor: pointer;
  }

  .content {
    background-color: #fff;
    margin-left: 2px;
    margin-top: -2px;
    .container-fluid {
      padding-bottom: 10px;
    }
  }

  .groupLabel {
    margin-top: 0;
  }

  h1 {
    margin: 0;
    font-size: em(36);
    color: $cr-green;

    svg {
      margin-right: 15px;
    }

    .survey-icon {
      svg {
        path {
          fill: $cr-green;
        }
      }
    }
  }

  .reward-options {
    padding-bottom: 20px;
  }

  h2 {
    margin: 0;
    font-size: em(26);
    color: #4a4a4a;
    font-weight: 600;

    &.modalH2 {
      font-size: em(26);
      color: #4a4a4a;
      font-weight: 400;
    }

    .collapse-arrow {
      position: relative;
      margin: 0 0 0 10px;
      top: 5px;
      cursor: pointer;
    }

    .info {
      i {
        display: inline-block;
        position: relative;
        top: 0;
        color: #656565;
        font-size: em(12);
      }

      &:hover {
        i {
          color: #000000;
        }
      }
    }

    & > i {
      margin-top: 3px;
      margin-right: 10px;
      float: left;
      color: $cr-green;
    }
  }

  .survey-color-picker {
    display: flex;
    flex-direction: column;
  }

  .config-section {
    padding-left: 30px;
    padding-right: 30px;
    h2 {
      font-weight: 600;
      font-size: em(16);
      letter-spacing: 0;
      margin: 22px 0;
      text-transform: uppercase;
    }

    &.separator-right {
      //border-right: 1px solid #D8D8D8;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: -1px;
        width: 1px;
        height: 90%;
        background-color: #d8d8d8;
      }
    }
    &.separator-left {
      //border-left: 1px solid #D8D8D8;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        left: -1px;
        width: 1px;
        height: 90%;
        background-color: #d8d8d8;
      }
    }

    .alignments {
      &.disabled {
        opacity: 0.3;
      }
      .title {
        display: block;
        position: relative;
        text-align: center;
        padding-bottom: 1em;
        font-weight: 400;
        font-size: em(12);
        color: #282828;
      }

      &:not([disabled]) {
        svg.align-icon {
          cursor: pointer;

          &:hover,
          &.active {
            .darken-color {
              fill: #349151;
            }
            .lighten-color {
              fill: $cr-green;
            }
          }
        }
      }
    }
  }

  ul.tabs {
    overflow: hidden;
    margin-bottom: 0;
    height: 75px;
    padding: 10px 0 10px 2px;

    li {
      float: left;
      margin-right: 1px;
      overflow: hidden;
      background-color: #d8d8d8;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
      border-radius: 4px;
      position: relative;
      bottom: -12px;

      &.selected {
        bottom: -6px;
      }

      &.disabled {
        opacity: 0.5;
      }

      & > span,
      a {
        padding: 10px 20px 26px 20px;
        display: block;
        font-size: em(14);
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $cr-grey49;

        i,
        svg {
          position: relative;
          bottom: -5px;
          margin-right: 10px;

          g {
            fill: $cr-grey49;
          }

          &.svg-icons {
            display: inline-block;
            width: 25px;
            height: 25px;

            &.header-brand {
              background: url(/img/header_settings.svg) no-repeat 0 5px;
            }
          }
        }

        &:active,
        &:visited,
        &:link {
          color: #494949;
          text-decoration: none;
        }
      }

      .color-template {
        svg {
          top: 9px;
        }
      }

      &.selected a {
        background-color: #ffffff;
        i {
          color: $cr-green;
        }
        g {
          fill: $cr-green;
        }
      }

      &.template a:before {
        content: '';
      }
    }
  }

  div.content {
    padding: 1rem 0 0 0;
  }

  .not-available {
    .card {
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;
    }
  }
}

#surveyDisplayName {
  &::placeholder {
    opacity: 0.32;
    font-weight: 300;
    color: #1f1f1f;
    padding-left: 5px;
  }
}

.survey-payouts-modal {
  top: 20%;
  width: 25%;
  text-align: center;
  line-height: 1.5em;
  height: 82px;
  overflow: hidden;

  .modal-inner {
    .modal-close {
      right: 10px;
      top: 2px;
    }
  }

  @media screen and (max-width: 1600px) {
    width: 40%;
  }
}

$templateTitleHeight: 33px;

#color-templates {
  .color-template {
    display: inline-block;
    position: relative;
    width: 185px;
    height: 185px;
    background-color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .template {
      border: 2px solid #f8f8f8;
    }

    .title {
      background-color: #f8f8f8;
    }

    &.active {
      .template {
        border: 2px solid $cr-green;
      }

      .title {
        background-color: $cr-green;
        color: #fff;
      }
    }
  }

  .template-overflow {
    display: block;
    position: relative;
    height: 210px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 3px;
  }

  .template {
    display: block;
    position: relative;
    width: 100%;
    height: 185px - $templateTitleHeight;
    padding-left: math.div((176px - (3 * 40px)), 2);
    padding-right: math.div((176px - (3 * 40px)), 2);
    padding-top: math.div(((183px - $templateTitleHeight) - 40), 2);

    .color {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);

      &:nth-child(1) {
        z-index: 1;
      }
      &:nth-child(2) {
        z-index: 2;
      }
      &:nth-child(3) {
        z-index: 3;
      }
      &:nth-child(4) {
        z-index: 4;
      }
      &:nth-child(5) {
        z-index: 5;
      }
    }
  }

  .title {
    display: block;
    position: relative;
    height: $templateTitleHeight;
    background-color: #f8f8f8;
    font-size: em(16);
    line-height: $templateTitleHeight;
    color: $cr-grey;
    text-align: center;
    font-weight: 500;
  }
}

.demoIframe {
  margin-top: 20px;
  margin-bottom: 60px;
  background-color: #fff;

  .iframe-bg {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    background-color: #e8e8e8;
  }

  iframe {
    display: block;
    position: relative;
    margin: auto;
    border: 0 solid transparent;
    height: 100%;
    width: 100%;
  }

  .iframe-container {
    position: relative;
    width: 100%;
    margin: auto;
  }

  &:not(.disabled) .iframe-container {
    height: 600px;
  }

  .iframe-placeholder {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 100;

    .placeholder-content {
      position: relative;
      text-align: center;
      z-index: 100;
      margin-bottom: -6px;

      img {
        width: 100%;
      }

      .iframe-placeholder-text {
        display: block;
        position: relative;
        width: 100%;
        padding: 70px 15px 0;
      }

      .iframe-placeholder-title,
      p {
        color: #282828;
      }

      p {
        font-size: em(24);
        margin-top: 0.1em;
        margin-bottom: 2em;

        a {
          cursor: pointer;
          color: $cr-green;
          text-decoration: underline;

          &:hover {
            color: #282828;
            text-decoration: none;
          }
        }
      }

      .iframe-placeholder-title {
        font-weight: bold;
        font-size: em(40);
      }
    }

    .textbg {
      display: block;
      position: absolute;
      top: 0;
      left: 5%;
      width: 90%;
      height: 100%;
      background: url(/img/surveygen-text-bg.png) no-repeat center 75%;
      background-size: 100%;
      z-index: 1;
    }

    .arrow {
      display: block;
      position: absolute;
      width: 536px;
      height: 653px;
      background: url(/img/survey-preview-arrow.png) no-repeat 0 0;
      z-index: 1;

      &.arrow-left {
        top: 20px;
        left: 15px;
      }
      &.arrow-right {
        top: 310px;
        right: 60px;
      }
    }
  }

  .survey-iframefooter {
    padding: 1em 15px;
  }

  &.desktop {
    .desktop-icon {
      border-bottom-color: $cr-green;
    }
  }

  &.tablet {
    .iframe-container {
      width: 691px !important;
      height: 883px !important;
      padding: 81px 80px 106px 85px;
      background: url(/img/survey-ipad.svg) no-repeat center center;
      background-size: 691px 883px;
    }

    .iframe-bg {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .tablet-icon {
      border-bottom-color: $cr-green;
    }
  }

  &.phone {
    .iframe-container {
      width: 392px !important;
      height: 730px !important;
      padding: 132px 30px 108px 41px;
      background: url(/img/survey-iphone.svg) no-repeat center center;
      background-size: 392px 730px;
    }

    .iframe-bg {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .phone-icon {
      border-bottom-color: $cr-green;
    }
  }

  .title {
    display: inline-block;
    position: relative;
    float: left;
    font-size: em(18);
    font-weight: 600;
    color: #282828;
    padding: 17px 15px;
    text-transform: uppercase;
  }

  .device-icon {
    display: inline-block;
    position: relative;
    float: left;
    color: #000000;
    height: 52px;
    text-align: center;
    cursor: pointer;
    padding-bottom: 7px;
    border-bottom: 3px solid #c6c6c6;
    margin-right: 25px;

    &:hover,
    &.active {
      border-bottom-color: $cr-green;
    }

    &.desktop-icon {
      padding-top: 12px;
      width: 40px;
      i {
        font-size: 32px;
      }
    }
    &.tablet-icon {
      padding-top: 16px;
      width: 40px;
      i {
        font-size: 24px;
      }
    }
    &.phone-icon {
      padding-top: 18px;
      width: 30px;
      i {
        font-size: 22px;
      }
    }

    &.disabled {
      color: #494949;
      opacity: 0.7;
      cursor: default;
      border-bottom: 3px solid #c6c6c6;
    }
  }
}

#templatePrimaryColor,
#templateSecondaryColor {
  text-transform: uppercase;
}

#survey-generate-modal {
  @media (max-height: 836px) {
    max-height: 80vh;
  }
}

#survey-generate-modal,
#live-cam-widget-modal {
  h2 {
    margin-bottom: 0.6em;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    .link {
      margin-top: 3px;
      margin-right: 10px;
      float: left;
      color: #00bd00;
    }
  }
  small {
    display: block;
    position: relative;
    margin-bottom: 1em;
    margin-top: 0.6em;
  }

  .apply-btn {
    margin-bottom: 20px;
  }

  ul.widgets-tabs {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      position: relative;
      font-size: em(16);
      color: #4a4a4a;
      padding: 10px 15px 5px;
      border-bottom: 4px solid #d8d8d8;
      margin-right: 15px;
      cursor: pointer;

      &:hover,
      &.active {
        border-bottom-color: $cr-green;
      }
    }
  }

  .copyBtn {
    margin-top: 10px;
    display: flex;
  }
}

#survey-questions {
  .questions {
    ul {
      margin-top: 0;
    }

    strong {
      font-weight: bold;
    }
  }
  .theme {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 10px;
    }
  }
}

#questions-survey {
  margin-top: 30px;
  color: $cr-green;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}

#payout-details-btn {
  display: inline-block;
  position: relative;
  float: right;
  background-color: $cr-grey;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  line-height: 1.3em;
  height: auto;
  padding: 0.7em 50px 0.7em 2em;

  i {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 7px;
    right: 15px;
    background-color: $cr-gold;
    border-radius: 50%;
    color: $cr-grey;
    margin-right: 0;
    margin-left: 10px;
    padding: 3px 0 0 0;
  }

  & > span {
    display: inline-block;
    color: #fff;
  }

  &:hover {
    background-color: $cr-green;
    i {
      background-color: #fff;
      color: $cr-green;
    }
  }
}
