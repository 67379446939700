$borderColor: #dcdcdc;
$dividerColor: #b7b7b7;
$mobileSpacing: 15px;

$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;
$brSwitchLayoutMin: 992px;

.offerListingRow {
  display: grid;
  grid-template-columns: 500px repeat(7, 1fr);
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid $borderColor;
  color: #3d3d3d;
  font-weight: 500;
  background: #ffffff;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: #f2f2f2;
    }
  }

  @media (max-width: $br1) {
    grid-template-columns: 375px repeat(7, 1fr);
  }

  @media (max-width: $br2) {
    grid-template-columns: 300px repeat(7, 1fr);
  }

  @media (max-width: $br3) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    border: 1px solid $borderColor;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: $br5) {
    grid-template-columns: repeat(3, 1fr);
    padding: 15px;
  }

  &.offerListingRowHeader {
    border-color: #8c8c8c;
    color: #969696;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: none;
      }
    }

    .offerListingGridColumn {
      height: 45px;
      padding: 0;

      &.column-epc,
      &.column-countries,
      &.column-targetedCountries {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 5px;
        .tooltip-wrapper {
          width: 25px;
        }
      }
    }

    @media (max-width: $br3) {
      display: none;
    }
  }

  .offerListingGridColumnMobileTitle {
    display: none;
    color: #919191;
    margin: 0 0 1px;
    font-size: 12px;
    font-weight: 500;

    @media (max-width: $br3) {
      display: block;
    }
  }

  .offerListingGridColumn {
    padding: 20px 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .offerListingGridColumnMobileTitle {
      .tooltip-wrapper {
        width: 25px;
        height: 25px;
      }
    }

    .offerListingGridColumnMobileTitle {
      display: none;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 5px;
      @media (max-width: $br3) {
        display: flex;
      }
    }

    @media (max-width: $br1) {
      padding: 20px 10px;
    }

    @media (min-width: $brSwitchLayoutMin) {
      &.column-preview {
        padding: 0 !important;
      }
    }

    @media (max-width: $br3) {
      flex-direction: column;
      padding: 30px 10px;

      &.column-status {
        order: 1;
        grid-column: span 3;
        flex-direction: row;
        gap: 10px;
      }
      &.column-offer {
        order: 2;
        border-top: 1px solid $dividerColor;
        border-bottom: 1px solid $dividerColor;
        grid-column: span 3;
      }
      &.column-divider {
        order: 3;
        grid-column: span 3;
      }
      &.column-payout {
        order: 4;
      }
      &.column-epc {
        order: 5;
      }
      &.column-payoutType {
        order: 6;
      }
      &.column-countries {
        order: 7;
      }
      &.column-targetedCountries {
        order: 8;
      }
      &.column-preview {
        order: 9;
      }
      &.column-countries,
      &.column-targetedCountries,
      &.column-preview,
      &.column-payout,
      &.column-epc,
      &.column-payoutType {
        grid-column: span 1;
        justify-content: flex-start;
      }
    }

    @media (max-width: $br5) {
      padding: 0;

      &.column-status {
        grid-column: span 3;
        order: 1;
        flex-direction: row;
        padding-bottom: $mobileSpacing;
        gap: 10px;
      }
      &.column-offer {
        order: 2;
        border-top: 1px solid $dividerColor;
        border-bottom: 1px solid $dividerColor;
        padding: $mobileSpacing 0;
      }
      &.column-payout {
        order: 3;
      }
      &.column-epc {
        order: 4;
      }
      &.column-payoutType {
        order: 5;
      }
      &.column-countries {
        order: 6;
      }
      &.column-targetedCountries {
        order: 7;
      }
      &.column-preview {
        order: 8;
      }
      &.column-offer {
        grid-column: span 3;
        border-top: 1px solid $dividerColor;
        border-bottom: 1px solid $dividerColor;
        align-items: flex-start;
      }
      &.column-payout,
      &.column-epc,
      &.column-payoutType {
        grid-column: span 1;
        padding-top: $mobileSpacing;
      }
      &.column-countries,
      &.column-targetedCountries,
      &.column-preview {
        padding-top: $mobileSpacing;
        grid-column: span 1;
      }
    }

    &.column-divider {
      display: none;
      padding: 0;

      .columnDividerLine {
        width: 100%;
        height: 1px;
        background: $dividerColor;
      }

      @media (max-width: $br3) {
        display: flex;
        padding: 0 10px;
      }

      @media (max-width: $br5) {
        display: none;
      }
    }

    &.column-offer,
    &.column-status {
      justify-content: flex-start;
      padding-left: 45px;

      @media (max-width: $br1) {
        padding-left: 25px;
      }

      @media (max-width: $br5) {
        padding-left: 0;
      }
    }
  }

  .country-list {
    max-width: 90px;
  }
}
