.dropdown-content li {
    min-height: 0;

    & > a,
    & > span {
        padding: 7px 16px;
        font-size: 14px;
        font-size: calc(10px + 0.25vw);
    }
}

.btn {
    border-radius: 2px;
    height: auto;
    line-height: 1.3em;
    padding: 0.7em 2em;
}

.btn-floating {
    border-radius: 50%;
    padding: 0;
}

.z-depth-2,
.card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
}
