$br1: 1355px;

$transitionAmount: 1s;

.contributors-section {
  margin: 75px auto 0;
  border-radius: 18px;
  background: #f6f6f6;
  padding: 30px 65px 90px;
  width: 100%;
  max-width: 980px;
  opacity: 0;
  transition: $transitionAmount;
  overflow: hidden;

  &.active {
    opacity: 1;

    h2 {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: $br1) {
    max-width: 600px;
    padding: 45px 25px 90px;
  }

  h2 {
    opacity: 0;
    transform: translateY(-100%);
    transition: $transitionAmount;
  }

  .contributors-list {
    display: flex;
    justify-content: space-between;
    margin: 25px 0 0;

    @media (max-width: $br1) {
      flex-flow: column;
      gap: 65px;
      margin: 35px 0 0;
    }

    .contributor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      opacity: 0;
      transform: translateY(100%);
      transition: $transitionAmount;

      @media (max-width: $br1) {
        transform: translateX(-100%);
      }

      &.active {
        opacity: 1;
        transform: translateY(0);

        @media (max-width: $br1) {
          transform: translateX(0);
        }
      }

      @media (max-width: $br1) {
        justify-content: center;
        flex-flow: column;
        text-align: center;
      }

      img {
        width: 40px;

        @media (max-width: $br1) {
          width: 90px;
        }
      }

      h3 {
        color: #242424;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        margin: 0;

        @media (max-width: $br1) {
          font-size: 18px;
        }
      }

      p {
        color: #616161;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;

        @media (max-width: $br1) {
          font-size: 14px;
          margin: 10px 0 0;
        }
      }
    }
  }
}
