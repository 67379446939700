.abcRioButton {
  height: 40px;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid #747775;
  box-sizing: border-box;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  background-color: #fff;
  color: #1f1f1f;
  &:hover {
    box-shadow: 0 2px 4.5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  }

  .abcRioButtonContentWrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
  }
  .abcRioButtonIcon {
    padding: 10px;
    float: none;
    width: auto;
    display: inline-block;
    background-color: #fff;
    border-radius: 1px;
  }
  .abcRioButtonSvg {
    overflow: visible;
  }
  .abcRioButtonContents {
    font-family: Roboto, arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.21px;
    vertical-align: top;
    font-size: 14px;
    line-height: 38px;
    margin-left: 0;
  }
  .abcRioButtonIconImage {
    width: 14px;
    height: 14px;
  }
  &.disabled {
    background: #f7f7f7 !important;
    color: #cecece !important;
    cursor: wait;
    .abcRioButtonIcon {
      background: #f7f7f7 !important;
    }
  }
}
