$br1: 550px;
$br2: 767px;

body {
  &.hidden-scroll {
    overflow: hidden;
  }
}

.jerkmate-new-page {
  position: relative;
  color: #191862;
  background-color: #fff;
  padding-top: 80px;
  transition: padding-left 200ms $inEaseing;
  will-change: width;

  .wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-left: 35px;
    padding-right: 35px;

    @include responsive('tablet') {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include responsive('phone to tablet') {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include responsive('phone') {
      padding-left: 15px;
      padding-right: 15px;
    }

    &.getIntouchSection {
      background: #f6f6f6;
    }

    &.headerSection {
      @media (max-width: $br2) {
        padding-right: 0 !important;
      }
    }
  }

  @import 'jerkmate2/header.react.scss';
  @import 'jerkmate2/offersSlider.react';
  @import 'jerkmate2/JerkyPinkSection.react.scss';
  @import 'jerkmate2/promotools.react.scss';
  @import 'jerkmate2/contributors.react.scss';
  @import 'jerkmate2/GetInTouch.react.scss';
  @import 'jerkmate2/JerkmateOfferCard.react.scss';
  @import 'jerkmate2/referral.react.scss';
  @import 'jerkmate2/JerkmateModal.react.scss';

  .jerkmate-page-title {
    text-align: center;
    color: #181761;
    font-size: 28px;
    font-weight: 800;
    line-height: 130%;
    margin: 0;

    @media (max-width: $br1) {
      color: #181761;
      text-align: center;
      font-size: 28px;
      font-weight: 800;
    }

    @media (max-width: 420px) {
      font-size: 6.8vw;
    }
  }

  .jerkmate-btn {
    display: flex;
    padding: 18px 78px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 120px;
    background: linear-gradient(269deg, #181761 -7.2%, #0505d8 90.85%);
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    width: fit-content;
    cursor: pointer;
    transition: 0.3s;

    &.pink {
      background: linear-gradient(269deg, #fa4d92 9.29%, #ec6c9f 90.85%);

      &:hover {
        background: linear-gradient(269deg, #fa4d92 32.23%, #ec6c9f 130.29%);
      }
    }

    &:hover {
      background: linear-gradient(269deg, #181761 32.23%, #0505d8 130.29%);
      box-shadow: 0px 7px 12px 0px rgba(24, 23, 97, 0.35);
      font-weight: 700;
    }
  }

  .jerkmate-pink {
    color: #fb4d94;
  }
}

@include responsive('xlarge') {
  #application {
    &.menuOpen {
      .jerkmate-new-page {
        padding-left: 240px; // largeur du menu
      }
    }
  }
}

@include responsive('large') {
  #application {
    &.menuOpen {
      .jerkmate-new-page {
        padding-left: 240px; // largeur du menu
      }
    }
  }
}

@include responsive('normal') {
  #application {
    &.menuOpen {
      .jerkmate-new-page {
        padding-left: 240px; // largeur du menu
      }
    }
  }
}
