$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

$borderColor: #d6d6d6;

.offerListingNoData {
  width: 100%;
  text-align: center;
  padding: 70px 0;
  color: #4a4a4a;
  font-size: 21px;
  font-weight: 600;

  @media (max-width: $br3) {
    border-top: 1px solid $borderColor;
  }

  @media (max-width: $brSwitchLayout) {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
