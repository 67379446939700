#notifications-card {
    .card-title-container {
        margin-bottom: 1rem;

        .title {
            float: left;
            color: $cr-greyText;
        }

        .date {
            float: right;
            color: $cr-greyText;
        }
    }

    .description {
        min-height: 63px;

        a {
            color: $cr-green;
        }
    }
}
