#site-footer {
  display: block;
  position: absolute;
  height: 115px;
  z-index: 5;
  bottom: 0;
  width: 100%;
  @media only screen and (max-width: 780px) {
    height: auto;
  }

  .copyrights {
    font-size: em(11, $browser-context);
    color: #ffffff;
    padding-top: 28px;
    padding-left: 75px;
    transition: padding-left 0.2s ease-in-out;
    @media only screen and (max-width: 780px) {
      text-align: center;
      display: flex;
      justify-content: space-around;
      padding-right: 0;
    }
  }
  nav {
    .menu {
      margin: 0;
      padding: 0;
      list-style-type: none;
      float: right;
      @media only screen and (max-width: 780px) {
        display: flex;
        justify-content: space-between;
        float: none;
        text-align: center;
        & li {
          padding: 20px 0 20px 0;
        }
      }
    }
    @media only screen and (max-width: 780px) {
      padding-left: 0;
      padding-right: 0;
      width: 65%;
      margin: 0 auto;
    }

    li {
      display: inline-block;
      position: relative;
      padding: 20px 0 0 20px;

      a {
        font-size: 0.8em;
        color: $cr-mid-grey;
        -webkit-transition: $inAnimationLinks;
        transition: $inAnimationLinks;

        &:hover {
          color: $cr-pale-green;
        }
      }
    }
  }

  .share {
    bottom: 34px;
    float: right;

    img {
      width: 65%;
      margin-top: 6px;
    }

    .fab-grey {
      &:hover {
        background-color: $cr-grey;
      }
    }
  }
}

@include responsive('phone') {
  #site-footer .copyrights {
    padding-left: 0 !important;
  }
}

.menuOpen #site-footer .copyrights {
  padding-left: 250px;
  transition: padding-left 0.2s ease-in-out;
}
