.modal {
    @extend .z-depth-4;

    display: none;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fafafa;
    padding: 0;
    max-height: 70%;
    width: 55%;
    margin: auto;
    overflow-y: auto;

    border-radius: 2px;
    will-change: top, opacity;

    @include responsive('phone to tablet') {
        width: 80%;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-top: 0;
    }

    .modal-content {
        padding: 24px;
    }
    .modal-close {
        cursor: pointer;
    }

    .modal-footer {
        border-radius: 0 0 2px 2px;
        background-color: #fafafa;
        padding: 4px 6px;
        height: 56px;
        width: 100%;

        .btn,
        .btn-flat {
            float: right;
            margin: 6px 0;
        }

        .modal-footer-multiple {
            display: flex;
            justify-content: flex-end;

            @include responsive('phone') {
                display: block;
            }
        }

        .modal-warning {
            display: flex;
            align-items: center;
            background-color: #6a6a6a;
            margin: 6px;
            padding: 6px 10px;
            font-size: 12px;
            line-height: 1.1;
            color: white;
            text-align: left;

            @include responsive('phone') {
                margin: 4px 0;
            }

            img {
                width: 15px;
                margin-right: 8px;
            }
        }
    }
}
.lean-overlay {
    position: fixed;
    z-index: 999;
    top: -100px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 125%;
    width: 100%;
    background: #000;
    display: none;

    will-change: opacity;
}

// Modal with fixed action footer
.modal.modal-fixed-footer {
    padding: 0;
    height: 70%;

    .modal-content {
        position: absolute;
        height: calc(100% - 56px);
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    .modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
    }
}

// Modal Bottom Sheet Style
.modal.bottom-sheet {
    top: auto;
    bottom: -100%;
    margin: 0;
    width: 100%;
    max-height: 45%;
    border-radius: 0;
    will-change: bottom, opacity;
}
