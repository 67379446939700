.badge-container,
.badge {
  will-change: transform, opacity;
}

.bar.active {
  will-change: transform, background-color;
}

.gamification-streak-modal {
  width: 565px;
  border-radius: 24px;
  background: linear-gradient(180deg, #01262e 0%, #1d1d1b 71%);
  max-height: 95vh;
  content-visibility: auto;
  animation: 500ms ease-in-out slideUp forwards;
  overflow-x: hidden;

  @media (min-width: 576px) and (max-height: 987px) {
    max-height: unset;
    animation: 500ms ease-in-out slideUpSmall forwards;
  }

  @media (width: 800px) and (height: 600px) {
    max-height: unset;
    animation: 500ms ease-in-out slideUpSmaller forwards !important;
  }

  @media (width: 1280px) and (height: 720px) {
    max-height: unset;
    animation: 500ms ease-in-out slideUpSmaller forwards !important;
  }

  @media (width: 1180px) and (height: 681px) {
    max-height: unset;
    animation: 500ms ease-in-out slideUpSmaller forwards !important;
  }

  @media (max-width: 391px) {
    max-height: unset;
    animation: 500ms ease-in-out slideUpSmall forwards !important;
  }

  @media (max-height: 865px) {
    max-height: unset;
  }

  &.hide-modal {
    animation: 500ms ease-in-out slideDown forwards !important;

    @media (max-height: 987px) and (min-width: 576px) {
      max-height: unset;
      animation: 500ms ease-in-out slideDownSmall forwards !important;
    }

    @media (width: 800px) and (height: 600px) {
      max-height: unset;
      animation: 500ms ease-in-out slideDownSmaller forwards !important;
    }

    @media (width: 1280px) and (height: 720px) {
      max-height: unset;
      animation: 500ms ease-in-out slideDownSmaller forwards !important;
    }

    @media (width: 1180px) and (height: 681px) {
      max-height: unset;
      animation: 500ms ease-in-out slideDownSmaller forwards !important;
    }

    @media (max-width: 391px) {
      max-height: unset;
      animation: 500ms ease-in-out slideDownSmall forwards !important;
    }

    @media (max-height: 865px) {
      max-height: unset;
    }
  }
  @media (max-width: 576px) {
    border-radius: 14px;
  }

  @media (max-width: 576px) {
    width: inherit;
    max-width: 95vw;
  }

  @keyframes pulse-header {
    from {
      background-size: 40% 40%;
    }
    to {
      background-size: 100% 100%;
    }
  }

  .upper-modal {
    position: relative;
  }

  .upper-modal::before,
  .upper-modal::after {
    will-change: transform;
    transform-origin: center;
    content: '';
    position: absolute;
    top: 0;
    width: 350px;
    height: 350px;
    background: radial-gradient(circle, rgba(255, 223, 0, 0.6), rgba(255, 223, 0, 0) 70%);
    pointer-events: none;
    animation: pulse 3s infinite;

    @keyframes pulse {
      0%,
      100% {
        transform: scale3d(1, 1, 1);
      }
      50% {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }

  .upper-modal::before {
    left: calc(-140px - (350px * 0.075));
  }

  .upper-modal::after {
    right: calc(-140px - (350px * 0.075));
  }

  .modal-inner {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-flow: column;
    transform: translateZ(0);
  }

  .header-modal {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 36px 36px 0 36px;

    @media (max-width: 576px) {
      margin: 16px 16px 0 16px;
    }

    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .rotate-title {
      display: flex;
      flex-flow: column;
      text-align: center;
      color: #fff;
      font-style: normal;
      font-weight: 800;
      text-transform: capitalize;
      opacity: 0;
      animation: fadeInUp 0.5s forwards;
      animation-delay: 0.5s;
      @media (max-width: 576px) {
        margin-top: 10px;
      }

      .first-title {
        font-size: 14px;
        line-height: 16px;
      }

      .second-title {
        font-size: 42px;
        font-weight: 900;
        line-height: 46px;
      }
    }

    button {
      position: absolute;
      right: 0;
      z-index: 3;
      .close-gamification-icon {
        width: 25px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  .badge-section {
    position: relative;
    margin: 60px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    @media (max-width: 576px) {
      margin: 40px 0;
    }

    .badge-bg {
      width: 100%;
    }

    .badge-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      z-index: 1;
      &.bronze {
        left: 49%;
        width: unset;
        height: unset;
        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 95px;
          height: 95px;
          content: '';
          border-radius: 100%;
          animation: shine-bronze 2s infinite;
          animation-delay: 0.5s;

          @media (max-width: 576px) {
            width: 85px;
            height: 85px;
          }
        }
      }

      &.silver {
        width: unset;
        height: unset;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 105px;
          height: 105px;
          border-radius: 100%;
          animation: shine-silver 2s infinite;
          animation-delay: 0.5s;

          @media (max-width: 576px) {
            width: 85px;
            height: 85px;
          }
        }
      }

      &.gold,
      &.emerald {
        width: unset;
        height: unset;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          animation: rotate 10s linear infinite;
          background: url(/img/sun-animation.png) no-repeat center center;
          background-size: contain;
          width: 800px;
          height: 600px;
          z-index: 2;
          opacity: 0.2;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 105px;
          height: 105px;
          border-radius: 100%;
          animation: shine-gold 2s infinite;
          animation-delay: 0.5s;
          z-index: 3;
          @media (max-width: 576px) {
            width: 85px;
            height: 85px;
          }
        }
      }
    }

    .badge {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 209px;
      height: 209px;
      z-index: 4;
      @media (max-width: 576px) {
        width: 170px;
        height: 170px;
      }

      &.bronze {
        top: 50%;
      }
    }
    .badge-level {
      color: #01262e;
      font-size: 50px;
      font-weight: 700;
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      &.emerald {
        top: 43%;
      }

      @media (max-width: 576px) {
        font-size: 35px;
      }
    }
  }

  .week-streak-section {
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 0 36px;
    margin: 0 auto 23px auto;
    @media (max-width: 576px) {
      padding: 0 16px;
    }

    .week-text {
      color: #00bd00;
      font-size: 28px;
      font-weight: 700;
      line-height: 18.2px;
      text-transform: capitalize;

      .tooltip-wrapper {
        margin-left: 10px;

        .more-info {
          color: #00bd00;
          margin: 0;
          cursor: pointer;
          i {
            font-size: 16px;
          }
        }
      }
    }

    .progress-bar {
      display: flex;
      gap: 3px;
      align-items: center;
      margin-left: -10px;
      @media (max-width: 576px) {
        margin-left: -15px;
      }

      .current-day-container {
        position: relative;
      }

      .bar {
        width: 44px;
        height: 22px;
        border: 1px solid #00bd00;
        background: #01262e;

        @media (max-width: 576px) {
          width: 34px;
          height: 17px;
        }

        &.active {
          background: #00bd00;
        }

        &.current-day {
          animation: day-pulse 2s infinite;
          animation-delay: 0.5s;
          border: 1px solid #0f0;
          box-shadow: 0px 0px 3px 2px rgba(0, 189, 0, 0.55);
          margin-top: -6px;
        }
      }

      p.current-day-name {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        animation: outline-color-pulse 2s infinite;
        animation-delay: 0.5s;
        outline: 1px solid #00bd0015;
        border-radius: 50px;
        background: #01262e;
        padding: 6px 6px;
        margin-top: 9px;
        transition: outline 1s ease-in-out;
        color: #00bd00;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }

      .whale-lvl {
        background: #01262e;
        border: 1px solid #00bd00;
        border-radius: 100%;
        margin-right: -15px;
        z-index: 1;
        position: relative;
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 576px) {
          width: 52px;
          height: 52px;
        }

        &.right {
          margin-left: -15px;
        }

        .lvl-1 {
          width: 45px;
          height: 24px;
        }

        .lvl-2 {
          height: 50px;
          width: 61px;

          @media (max-width: 576px) {
            height: 35px;
            width: 58px;
            margin-bottom: 10px;
          }
        }

        .lvl-3 {
          height: 57px;
          width: 90px;
          margin-right: 10px;

          @media (max-width: 576px) {
            height: 35px;
            width: 80px;
            margin-bottom: 10px;
            margin-right: 10px;
          }
        }

        .lvl-4 {
          height: 65px;
          width: 100px;
          margin-bottom: 20px;

          @media (max-width: 576px) {
            height: 46px;
            width: 80px;
            margin-bottom: 22px;
          }

          &.left-lvl-4 {
            height: 45px;
            margin-bottom: 0;
            width: 100px;

            @media (max-width: 576px) {
              height: 36px;
              margin-bottom: 14px;
              width: 80px;
            }
          }
        }

        .lvl-chip {
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          border-radius: 13px;
          background: #00bd00;
          padding: 0 10px;
          position: absolute;
          bottom: -4%;
          @media (min-width: 576px) and (max-height: 987px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .aff-info-section {
    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(0, 189, 0, 0.1) 0%, rgba(0, 63, 68, 0.05) 53.5%, rgba(1, 38, 46, 0) 100%);
    margin: 0 36px;

    @media (max-width: 576px) {
      margin: 0 16px;
    }

    .aff-info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 24px 40px;

      @media (max-width: 576px) {
        padding: 10px 20px;
      }

      .rank-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background: #01262e;
        border: 1px solid #00bd00;
        border-radius: 100%;
        padding: 7px;
        svg {
          width: 22px;
          height: 20px;
        }
      }

      .fill {
        fill: #00bd00;
      }

      .user-infos-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
      }

      .user-infos {
        display: flex;
        flex-flow: column;

        .user-name {
          color: #00bd00;
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 170px;
          overflow: hidden;

          @media (max-width: 576px) {
            font-size: 15px;
            width: 170px;
          }
        }
        .user-status {
          color: #00bd00;
          font-size: 10px;
          font-weight: 500;
          text-transform: capitalize;
          @media (min-width: 576px) and (max-height: 987px) {
            font-size: 12px;
          }

          @media (max-height: 865px) {
            font-size: 12px;
          }
        }
      }

      .streak-info {
        display: flex;
        gap: 12px;

        .streak-box {
          display: flex;
          flex-flow: column;
          @media (max-width: 576px) {
            display: none;
          }
          .weeks-nb {
            margin: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;

            span {
              color: #00bd00;
              font-size: 18px;
              font-weight: 900;
            }
          }

          .lvl {
            display: flex;
            justify-content: flex-end;
            color: #fff;
            font-size: 10px;
            font-weight: 500;
            text-transform: capitalize;
            @media (min-width: 576px) and (max-height: 987px) {
              font-size: 12px;
            }
          }
        }

        .badge-box {
          position: relative;
          width: 42px;
          height: 42px;
          background: #01262e;
          border: 1px solid #00bd00;
          border-radius: 100%;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 41px;

            &.bronze {
              top: 55%;
              left: 52%;
            }
          }
        }
      }
    }

    .stats {
      background: url(/img/since-streak-box-bg.png) no-repeat center center fixed;
      background-size: cover;
      padding-top: 20px;
      text-align: center;
      margin-bottom: 10px;

      h3 {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 0 32px;

        span {
          &.break-after {
            display: inline-block;
          }
        }
      }
      .all-stats {
        display: flex;
        flex-flow: row;
        justify-content: center;
        gap: 40px;
        padding: 20px 20px 0 20px;
        @media (max-width: 576px) {
          gap: 20px;
        }
        .stat-box {
          position: relative;
          width: 186px;
          border: 2px solid #00d100;
          border-radius: 20px;
          box-shadow: inset 0px 0px 39px 1px rgba(0, 209, 0, 0.5), 0px 0px 39px 1px rgba(0, 209, 0, 0.5);
          display: flex;
          justify-content: space-between;
          flex-flow: column;

          &.clicks {
            .arrow {
              opacity: 0;
              width: 33px;
              height: 44px;
              position: absolute;
              animation: moveArrow 2s ease-in-out;
              animation-delay: 0.5s;
              transform: translate3d(0, 100%, 0);
              bottom: 50%;
              left: 35%;

              svg {
                width: 33px;
                height: 44px;

                @media (max-width: 576px) {
                  width: 23px;
                  height: 34px;
                }
              }

              :root {
                --translateArrow-x: -200%;
                --translateArrow-y: -200%;
                --opacity: 1;
              }

              @media (max-width: 768px) {
                :root {
                  --translateArrow-x: -100%;
                  --translateArrow-y: -150%;
                  --opacity: 1;
                }
              }

              @keyframes moveArrow {
                0% {
                  transform: translate3d(100%, 0, 0);
                  opacity: 0;
                }
                30% {
                  transform: translate3d(
                    calc(var(--translateArrow-x) * 1.1),
                    /* Go further for a bigger bounce */ calc(var(--translateArrow-y) * 1.1),
                    0
                  );
                  opacity: var(--opacity);
                  animation-timing-function: ease-in; /* Quick snap back effect */
                }
                40% {
                  transform: translate3d(
                    var(--translateArrow-x),
                    var(--translateArrow-y),
                    0
                  ); /* Return to the correct position */
                  opacity: var(--opacity);
                  animation-timing-function: ease-out; /* Smooth return to the final position */
                }
                60% {
                  transform: translate3d(var(--translateArrow-x), var(--translateArrow-y), 0);
                  opacity: var(--opacity);
                }
                80% {
                  transform: translate3d(100%, 0, 0);
                  opacity: 0;
                }
              }

              &:nth-child(1) {
                --translateArrow-x: -50%;
                --translateArrow-y: -300%;
              }

              &:nth-child(2) {
                --translateArrow-x: -100%;
                --translateArrow-y: -400%;
              }

              &:nth-child(3) {
                --translateArrow-x: -200%;
                --translateArrow-y: -250%;
              }

              &:nth-child(4) {
                --translateArrow-x: -300%;
                --translateArrow-y: -400%;
              }

              &:nth-child(5) {
                --translateArrow-x: -350%;
                --translateArrow-y: -300%;
              }

              &:nth-child(6) {
                --translateArrow-x: -250%;
                --translateArrow-y: -500%;
              }

              &:nth-child(7) {
                --translateArrow-x: -200%;
                --translateArrow-y: -350%;
              }

              &:nth-child(8) {
                --translateArrow-x: -400%;
                --translateArrow-y: -450%;
              }

              &:nth-child(9) {
                --translateArrow-x: -350%;
                --translateArrow-y: -450%;
              }

              &:nth-child(10) {
                --translateArrow-x: -350%;
                --translateArrow-y: -500%;
              }

              &:nth-child(11) {
                --translateArrow-x: -350%;
                --translateArrow-y: -200%;
              }

              &:nth-child(12) {
                --translateArrow-x: -400%;
                --translateArrow-y: -300%;
              }

              &:nth-child(13) {
                --translateArrow-x: -100%;
                --translateArrow-y: -350%;
              }

              &:nth-child(14) {
                --translateArrow-x: -200%;
                --translateArrow-y: -500%;
              }

              &:nth-child(15) {
                --translateArrow-x: -350%;
                --translateArrow-y: -100%;
              }

              &:nth-child(16) {
                --translateArrow-x: -100%;
                --translateArrow-y: -150%;
              }

              @media (max-width: 768px) {
                &:nth-child(1) {
                  --translateArrow-x: -25%;
                  --translateArrow-y: -150%;
                }

                &:nth-child(2) {
                  --translateArrow-x: -50%;
                  --translateArrow-y: -200%;
                }

                &:nth-child(3) {
                  --translateArrow-x: -100%;
                  --translateArrow-y: -125%;
                }

                &:nth-child(4) {
                  --translateArrow-x: -150%;
                  --translateArrow-y: -200%;
                }

                &:nth-child(5) {
                  --translateArrow-x: -175%;
                  --translateArrow-y: -150%;
                }

                &:nth-child(6) {
                  --translateArrow-x: -125%;
                  --translateArrow-y: -250%;
                }

                &:nth-child(7) {
                  --translateArrow-x: -100%;
                  --translateArrow-y: -175%;
                }

                &:nth-child(8) {
                  --translateArrow-x: -200%;
                  --translateArrow-y: -225%;
                }

                &:nth-child(9) {
                  --translateArrow-x: -150%;
                  --translateArrow-y: -200%;
                }

                &:nth-child(10) {
                  --translateArrow-x: -175%;
                  --translateArrow-y: -250%;
                }

                &:nth-child(11) {
                  --translateArrow-x: -175%;
                  --translateArrow-y: -100%;
                }

                &:nth-child(12) {
                  --translateArrow-x: -200%;
                  --translateArrow-y: -150%;
                }

                &:nth-child(13) {
                  --translateArrow-x: -50%;
                  --translateArrow-y: -175%;
                }

                &:nth-child(14) {
                  --translateArrow-x: -100%;
                  --translateArrow-y: -250%;
                }

                &:nth-child(15) {
                  --translateArrow-x: -175%;
                  --translateArrow-y: -50%;
                }

                &:nth-child(16) {
                  --translateArrow-x: -50%;
                  --translateArrow-y: -75%;
                }
              }
            }
          }

          &.payout {
            .coin {
              opacity: 0;
              width: 36px;
              height: 36px;
              position: absolute;
              animation: moveCoin 2s ease-in-out;
              animation-delay: 0.5s;
              transform: translate3d(0, 100%, 0);
              bottom: 50%;
              left: 35%;

              svg {
                width: 36px;
                height: 36px;

                @media (max-width: 576px) {
                  width: 26px;
                  height: 26px;
                }
              }

              :root {
                --translate-x: 50%;
                --translate-y: -200%;
                --opacity: 1;
              }

              @media (max-width: 768px) {
                :root {
                  --translate-x: 25%;
                  --translate-y: -150%;
                  --opacity: 1;
                }
              }

              @keyframes moveCoin {
                0% {
                  transform: translate3d(0, 100%, 0);
                  opacity: 0;
                }
                30% {
                  transform: translate3d(
                    calc(var(--translate-x) * 1.1),
                    /* Go further for a bigger bounce */ calc(var(--translate-y) * 1.1),
                    0
                  );
                  opacity: var(--opacity);
                  animation-timing-function: ease-in; /* Quick snap back effect */
                }
                40% {
                  transform: translate3d(var(--translate-x), var(--translate-y), 0); /* Return to the correct position */
                  opacity: var(--opacity);
                  animation-timing-function: ease-out; /* Smooth return to the final position */
                }
                60% {
                  transform: translate3d(var(--translate-x), var(--translate-y), 0);
                  opacity: var(--opacity);
                }
                80% {
                  transform: translate3d(0, 100%, 0);
                  opacity: 0;
                }
              }

              &:nth-child(1) {
                --translate-x: 50%;
                --translate-y: -300%;
              }

              &:nth-child(2) {
                --translate-x: 100%;
                --translate-y: -400%;
              }

              &:nth-child(3) {
                --translate-x: 200%;
                --translate-y: -250%;
              }

              &:nth-child(4) {
                --translate-x: 300%;
                --translate-y: -400%;
              }

              &:nth-child(5) {
                --translate-x: 350%;
                --translate-y: -300%;
              }

              &:nth-child(6) {
                --translate-x: 250%;
                --translate-y: -500%;
              }

              &:nth-child(7) {
                --translate-x: 200%;
                --translate-y: -350%;
              }

              &:nth-child(8) {
                --translate-x: 400%;
                --translate-y: -450%;
              }

              &:nth-child(9) {
                --translate-x: 350%;
                --translate-y: -450%;
              }

              &:nth-child(10) {
                --translate-x: 350%;
                --translate-y: -500%;
              }

              &:nth-child(11) {
                --translate-x: 350%;
                --translate-y: -200%;
              }

              &:nth-child(12) {
                --translate-x: 400%;
                --translate-y: -300%;
              }

              &:nth-child(13) {
                --translate-x: 100%;
                --translate-y: -350%;
              }

              &:nth-child(14) {
                --translate-x: 200%;
                --translate-y: -500%;
              }

              &:nth-child(15) {
                --translate-x: 350%;
                --translate-y: -100%;
              }

              &:nth-child(16) {
                --translate-x: 100%;
                --translate-y: -150%;
              }

              @media (max-width: 768px) {
                &:nth-child(1) {
                  --translate-x: 25%;
                  --translate-y: -150%;
                }

                &:nth-child(2) {
                  --translate-x: 50%;
                  --translate-y: -200%;
                }

                &:nth-child(3) {
                  --translate-x: 100%;
                  --translate-y: -125%;
                }

                &:nth-child(4) {
                  --translate-x: 150%;
                  --translate-y: -200%;
                }

                &:nth-child(5) {
                  --translate-x: 175%;
                  --translate-y: -150%;
                }

                &:nth-child(6) {
                  --translate-x: 125%;
                  --translate-y: -250%;
                }

                &:nth-child(7) {
                  --translate-x: 100%;
                  --translate-y: -175%;
                }

                &:nth-child(8) {
                  --translate-x: 200%;
                  --translate-y: -225%;
                }

                &:nth-child(9) {
                  --translate-x: 150%;
                  --translate-y: -200%;
                }

                &:nth-child(10) {
                  --translate-x: 175%;
                  --translate-y: -250%;
                }

                &:nth-child(11) {
                  --translate-x: 175%;
                  --translate-y: -100%;
                }

                &:nth-child(12) {
                  --translate-x: 200%;
                  --translate-y: -150%;
                }

                &:nth-child(13) {
                  --translate-x: 50%;
                  --translate-y: -175%;
                }

                &:nth-child(14) {
                  --translate-x: 100%;
                  --translate-y: -250%;
                }

                &:nth-child(15) {
                  --translate-x: 175%;
                  --translate-y: -50%;
                }

                &:nth-child(16) {
                  --translate-x: 50%;
                  --translate-y: -75%;
                }
              }
            }
          }

          @media (max-width: 576px) {
            padding: 20px 10px 0 10px;
          }
          .title {
            color: #00bd00;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            padding: 18px 0;

            @media (max-width: 576px) {
              padding-top: 0;
            }
          }

          .stat-bg {
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
          }

          .stat-icon {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
          }

          .stat-icon-box {
            border: 1px solid #00d100;
            background: #01262e;
            border-radius: 10px;
            padding: 5px;
            position: relative;
            z-index: 1;
            width: 75px;
            height: 75px;
            @media (max-width: 576px) {
              width: 60px;
              height: 60px;
            }
            img {
              position: absolute;
              width: 55px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @media (max-width: 576px) {
                width: 60px;
              }
            }
          }

          .stat-number {
            display: flex;
            flex-flow: column;
            margin-bottom: 18px;
            .real-stat {
              position: relative;
              color: #ececec;
              font-size: 20px;
              font-weight: 700;

              .stats-up-down-icon {
                position: absolute;
                width: 20px;
                height: 15px;
              }
            }

            .new-stat {
              color: #8a8a8a;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .gamification-btn {
    margin: 17px auto 30px auto;
    border-radius: 2px;
    background: #00bd00;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    width: 240px;
  }

  @keyframes day-pulse {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  @keyframes outline-color-pulse {
    50% {
      outline-color: #00bd00;
    }
  }

  @keyframes shine-bronze {
    0% {
      box-shadow: 0 0 30px 15px rgba(241, 152, 77, 0.7);
    }
    50% {
      box-shadow: 0 0 80px 20px rgba(241, 152, 77, 1);
    }
    100% {
      box-shadow: 0 0 30px 15px rgba(241, 152, 77, 0.7);
    }
  }

  @keyframes shine-silver {
    0% {
      box-shadow: 0 0 30px 15px rgba(159, 159, 159, 0.7);
    }
    50% {
      box-shadow: 0 0 80px 20px rgba(159, 159, 159, 1);
    }
    100% {
      box-shadow: 0 0 30px 15px rgba(159, 159, 159, 0.7);
    }
  }

  @keyframes shine-gold {
    0% {
      box-shadow: 0 0 30px 15px rgba(255, 223, 0, 0.9);
    }
    50% {
      box-shadow: 0 0 80px 20px rgba(255, 223, 0, 1);
    }
    100% {
      box-shadow: 0 0 30px 15px rgba(255, 223, 0, 0.9);
    }
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes fadeInUp {
    0% {
      rotate: -4deg;
      opacity: 0;
      transform: translateY(20px) translateZ(0);
    }
    100% {
      rotate: -4deg;
      opacity: 1;
      transform: translateY(0) translateZ(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translate(-50%, 100%);
    }

    to {
      transform: translate(-50%, -50%);
    }
  }

  @keyframes slideUpSmall {
    from {
      transform: translate(-50%, 100%) scale(0.8);
    }
    to {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  @keyframes slideUpSmaller {
    from {
      transform: translate(-50%, 100%) scale(0.6);
    }
    to {
      transform: translate(-50%, -50%) scale(0.6);
    }
  }

  @keyframes slideDown {
    from {
      transform: translate(-50%, -50%);
    }

    to {
      transform: translate(-50%, 100%);
    }
  }

  @keyframes slideDownSmall {
    from {
      transform: translate(-50%, -50%) scale(0.8);
    }

    to {
      transform: translate(-50%, 100%) scale(0.8);
    }
  }

  @keyframes slideDownSmaller {
    from {
      transform: translate(-50%, -50%) scale(0.6);
    }

    to {
      transform: translate(-50%, 100%) scale(0.6);
    }
  }
}
