.template-select-box {
    // this is my label
    input {
        display: none;
    }
    input:checked + .inside {
        border-color: $cr-green;
        span.bottom {
            background-color: $cr-green;
        }
    }

    .inside {
        border: solid 4px #f8f8f8;
        margin: 0;
        padding: 0;
        display: block;
        text-align: center;
        &:hover {
            border-color: #d8d8d8;
            span.bottom {
                background-color: #d8d8d8;
            }
        }
        span {
            display: block;
        }
        .top {
            min-height: 10rem;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            background: #fff;
            span {
                margin: 0.5rem auto;
            }
        }
        .bottom {
            padding: 0.3rem 0;
            font-weight: 500;
            font-size: 16px;
            color: #333;
            background-color: #f8f8f8;
        }
    }

    &.top .inside {
        margin-bottom: 20px;
    }
}
