$br0: 1024px;
$br1: 1023px;
$br2: 767px;

$padding1: 60px;
$padding2: 18px;

.jerkmate-modal-box {
  width: 100%;
  height: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  opacity: 0;
  transition: 0s;
  display: flex;
  padding: 0;

  &.active {
    opacity: 1;
    height: 100%;
    transition: opacity 0.5s ease-out;
    padding: 60px;

    @media (max-width: $br2) {
      padding: 10px;
    }
  }

  h2 {
    font-weight: 800;
    width: 100%;
    text-align: left;
    padding-right: 40px;
  }

  .jerkmate-modal-background {
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
  }

  .closeIconBox {
    width: 100%;
    height: 75px;
    min-height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: sticky;
    top: 0;
    left: 0;
    padding: 0 30px;
    z-index: 500;
    margin-bottom: -18px;

    @media (max-width: $br2) {
      height: 55px;
      min-height: 55px;
      padding: 0 $padding2;
    }

    .closeIconCircle {
      width: 37px;
      height: 37px;
      cursor: pointer;
      background: #ffffff;
      border-radius: 50%;

      svg {
        width: 100%;
      }
    }
  }

  .jerkmate-modal {
    width: 100%;
    max-height: 100%;
    max-width: 1000px;
    background: #f6f6f6;
    border-radius: 19px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 100;
    margin: auto;
    overflow: auto;
    padding-bottom: $padding1;

    @media (max-width: $br2) {
      padding-bottom: $padding2;
    }

    &.not-offer {
      max-width: 980px;
      padding-bottom: 0 !important;
    }

    .inner-modal {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 $padding1;

      @media (max-width: $br2) {
        padding: 0 $padding2;
      }
    }
  }

  p {
    &.intro {
      width: 100%;
      font-size: 15px;
      color: #7b7b7b;
      margin: 20px 0 0;
      line-height: 170%;
    }
  }

  .inner-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 35px 0 0;

    @media (max-width: $br2) {
      padding: 30px 0 0;
    }

    p {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .advantages {
    text-align: left;
    margin-bottom: 20px;
    h2 {
      margin-top: 0;
    }

    ul {
      display: flex;
      gap: 20px;
      margin: 0;
      @media (max-width: $br1) {
        flex-flow: column;
        gap: 5px;
      }
    }

    li {
      svg {
        margin-right: 5px;
        path {
          fill: #fb4d94;
        }
      }
      display: flex;
      margin: 10px 0;
      font-weight: bold;
    }
  }

  .video-box {
    width: 80%;
    margin: 0 auto 35px;
    aspect-ratio: 573 / 322;

    @media (max-width: $br1) {
      width: 100%;
    }
    .video-title {
      text-align: left;
      margin-bottom: 10px;
    }
    .video-js {
      .vjs-big-play-button {
        font-size: 6em;
        border: none;
        background-color: transparent;

        &:focus {
          background-color: transparent !important;
        }

        .vjs-icon-placeholder {
          transition: all 0.3s;
          &:hover {
            opacity: 0.7;
          }
          &:before {
            content: ' ';
            background-image: url('../../../../../../public/img/jerkmate2/promotoolVideoPlay.svg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  .offers-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 417px);
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    @media (max-width: $br2) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .description {
    h2 {
      margin-top: 0;
    }
    p {
      color: #181761;
    }
  }

  .warning {
    display: flex;
    text-align: left;

    p {
      color: #181761;
    }

    .icon-warning {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
  }

  .jerkmate-offer-btn {
    max-width: 250px;
  }

  .control-box {
    width: 100%;
    padding: 30px $padding1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    border-radius: 0 0 19px 19px;

    @media (max-width: $br2) {
      padding: 20px $padding2;
    }

    a {
      display: flex;
      padding: 13px 78px;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      border-radius: 120px;
      background: linear-gradient(269deg, #fa4d92 9.29%, #ec6c9f 90.85%);
      color: #fff;
      text-transform: uppercase;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      width: -moz-fit-content;
      width: fit-content;
      cursor: pointer;
      transition: 0.3s;

      @media (max-width: $br0) {
        justify-content: center;
        width: 100%;
        padding: 13px 0;
      }

      &:hover {
        color: #fff;
        font-size: 15px;
        font-weight: 800;
        background: linear-gradient(269deg, #fa4d92 32.23%, #ec6c9f 130.29%);
        box-shadow: 0px 7px 12px 0px rgba(24, 23, 97, 0.35);
        transition: 0.3s;
      }
    }
  }
}
