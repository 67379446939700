.filters {
  .btn {
    margin: 2rem 15px 2rem 0;
  }
  .customSelects {
    .fields {
      z-index: auto;
      .field {
        .labelBox label {
          font-weight: normal;
        }
      }
    }
    @media (min-width: 992px) {
      width: auto;
      display: inline;
    }
    @media (max-width: 991px) {
      .filterInput {
        margin: 20px 0 0 0px;
      }
    }
  }
}
